import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentLoadingModal from "../../components/PaymentLoadingModal";
import { GymContext } from "../../hooks/FetchEverythingContext";
import FinalPaymentPage from "./FinalPaymentPage";
import { SelectPlans } from "../../components/PaymentPlans/selectPlans";

/**
 * Only works for MembershipPlanTestB => Displays monthly cards on Gym Page
 * 
 * @returns {JSX.Element}   -   Three Plan cards to select from, then final Payments Page
 */
const PaymentDetailsPage = () => {
    const { USR                         } = useContext(GymContext);
    const location                        = useLocation();
    const paymentDetails                  = location?.state;
    const navigate                        = useNavigate();
    const [             , setRedirecting] = useState(false);
    const [currentPage  , setCurrentPage] = useState(
        paymentDetails?.receiptInfo?.months === 0 || paymentDetails?.ABTesting === 'A' ? 2 : 1
    ); // if 1 day membership then no need to show plans
    const [selectedPlan, setSelectedPlan] = useState(paymentDetails?.ABTesting === 'A' ? paymentDetails?.selectedPlan : null);
    const [planPricing ,  setPlanPricing] = useState({
        mrp         : paymentDetails?.receiptInfo?.mrp,
        sellingPrice: paymentDetails?.receiptInfo?.amountToPay,
    });
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [currentPage]);
  
    useEffect(() => {
        if (!paymentDetails) {
            setRedirecting(true);
            // Display message for 4 seconds before redirecting
            const timer = setTimeout(() => {
                navigate('/');
                }, 4000);
            return () => clearTimeout(timer);
        }
    }, [paymentDetails, navigate]);
  
    if (!paymentDetails) {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <p className="text-xl font-semibold mb-4">
                    Oops, you accidentally landed on the final page.
                </p>
                <p className="text-md">Redirecting you to the homepage...</p>
            </div>
        );
    }

    return (
        <>
            <div className={`p-5 ${USR === 1 ? 'lg:px-40' : USR === 1.25 ? 'lg:px-20' : USR === 1.5 ? 'lg:px-5' : 'lg:px-20'} `}>
                {currentPage === 1 && <SelectPlans setCurrentPage={setCurrentPage} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} paymentDetails={paymentDetails} setPlanPricing={setPlanPricing}/>}
                {currentPage === 2 && <FinalPaymentPage setCurrentPage={setCurrentPage} selectedPlan={selectedPlan} paymentDetails={{...paymentDetails, mrp : planPricing.mrp, amountToPay : planPricing.sellingPrice}} setIsPaymentProcessing={setIsPaymentProcessing} />}
            </div>
            <PaymentLoadingModal isOpen={isPaymentProcessing} totalMemberships={paymentDetails?.totalMemberships}/>
        </>
    )
}

export default PaymentDetailsPage;