import { ChevronRight } from "lucide-react";
import CouponModal from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/CouponModal";
import { conditionToDisplayCoupons } from "../../Modals/PaymentConfirmationModal";
import Star from '../../assets/Website_content/Star.png'
import { useContext } from "react";
import { GymContext } from "../../hooks/FetchEverythingContext";

export const CouponAndReferralCode = ({
    selectedCoupon,
    removeCoupon,
    setShowCouponModal,
    setExpandCouponSection,
    expandCouponSection,
    coupon,
    conditionForCoupons,
    handleReferralCode,
    handleCouponSelection,
    paymentDetails,
    referralCode,
    setReferralCode,
    handleReferralCodeSubmit,
    setReferralGiver,
    referralCodeDetails,
    setReferralCodeDetails,
    selectedPlan
}) => {
    const {isMobile} = useContext(GymContext)
    return (
        <div className="flex flex-col">
            <button 
                onClick={selectedCoupon ? removeCoupon : isMobile ? () => setShowCouponModal(true) : () => setExpandCouponSection(!expandCouponSection)} 
                className={`rounded-2xl flex justify-between items-center p-2 lg:px-6`}
                style={{boxShadow : '0 1px 2px 1px lightGray'}}>
                <div className="flex items-center gap-1">
                    <img src={coupon} alt='coupon' className="h-[18vw] lg:h-[5vw] w-fit"/>
                    <div className="text-purple font-semibold lg:text-xl ml-2">{!conditionForCoupons ? 'Have a referral code?' : selectedCoupon ? selectedCoupon?.description : 'Select Coupon'}</div>
                </div>
                <div className="flex gap-1 relative">
                    <div className="text-primary font-semibold">
                        {selectedCoupon ? 'Remove Coupon' : '2 offers'}
                    </div>
                    <div className={`${expandCouponSection ? 'rotate-90' : 'rotate-0'} transition-all duration-500`}>
                        <ChevronRight />
                    </div>
                    <img src={Star} alt='star' className="absolute -bottom-1 right-0 h-2 " />
                    <img src={Star} alt='star' className="absolute -bottom-3 right-2 h-3 " />
                </div>
            </button>

            {/* Coupons and entering codes Sections for larger screens */}
            <div className={`${expandCouponSection ? 'scale-y-100' : 'scale-y-0 pointer-events-none'} flex flex-col transition-all duration-500 mt-4`}>
                <CouponModal
                    handleReferralCode={handleReferralCode}
                    handleCouponSelection={handleCouponSelection}
                    setShowCouponModal={setShowCouponModal}
                    duration={paymentDetails?.receiptInfo?.months}
                    user={paymentDetails?.user}
                    gymData={paymentDetails?.gymData}
                    isLargerScreen={expandCouponSection}
                    setExpandCouponSection={setExpandCouponSection}
                    conditionForCoupons={conditionToDisplayCoupons(paymentDetails?.gymData, paymentDetails?.receiptInfo)}
                    selectedPlan={selectedPlan}
                />
            </div>

            {/* Referral Code Input */}
            <div 
                style={{boxShadow : '0 1px 2px 1px lightGray'}}
                className="flex gap-1 lg:gap-2 rounded-2xl shadow-md mt-2 lg:mt-4 p-2 lg:px-6 pr-8 py-2 w-full">
                {/* <label htmlFor="referralCode" className="text-base lg:text-xl whitespace-nowrap">
                    Have a Referral code?
                </label> */}
                <img src={coupon} alt='coupon' className='h-[18vw] lg:h-[5vw] w-fit'/>
                <div className="flex items-center gap-1 md:gap-2 w-full">
                    <input
                        type="text"
                        id="referralCode"
                        placeholder='Enter Referral Code'
                        className="w-full rounded-full px-3 py-2 text-sm md:text-xl bg-gray-100 text-black placeholder:text-purple placeholder:font-semibold font-semibold outline-none"
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value)}
                    />
                    {Object.keys(referralCodeDetails).length > 0 ? (
                        <div className="flex items-center gap-1 -ml-4 md:ml-0 max-h-[10vw] lg:max-h-[4vw]">
                            {/* Success Checkmark */}
                            <div className="success-checkmark scale-[30%] md:scale-[40%]">
                                <div className="check-icon">
                                    <span className="icon-line line-tip"></span>
                                    <span className="icon-line line-long"></span>
                                    <div className="icon-circle"></div>
                                    <div className="icon-fix"></div>
                                </div>
                            </div>
                            {/* Remove Referral Button */}
                            <button
                                onClick={() => {
                                    setReferralCode('');
                                    setReferralCodeDetails({});
                                    setReferralGiver();
                                }}
                                className="text-purple rounded-full -ml-4 md:ml-0 lg:px-1 md:text-3xl hover:bg-red-600 transition"
                            >
                                ×
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={handleReferralCodeSubmit}
                            className='relative text-purple font-semibold text-sm lg:text-lg'
                        >
                            Apply
                            <img src={Star} alt='star' className="absolute -bottom-1 -right-2 h-2 " />
                            <img src={Star} alt='star' className="absolute -bottom-3 right-0 h-3 " />
                        </button>
                        )}
                </div>
            </div>
        </div>
    )
}