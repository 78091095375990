import { doc } from "@firebase/firestore"
import { addGymToUserDoc, increaseGymViews } from "./Helpers/pageViewAnalytics"
import { internalDB } from "../config/firebase"
import { addUserDoc, updateUserDoc } from "./Helpers/updateUserDoc";

/**
 * Adds analytics to our personal database
 * 
 * @param {String} type     -   The type of the analytics to be added
 * @param {Object} user     -   The details of the user
 * @param {Object} details  -   The details of the event that needs to be logged       
 */
export const addAnalytics = async(type, user, details) => {
    let userRef;
    if(user) {
        userRef = doc(internalDB, 'Users', user.uid);
        await addUserDoc(user, userRef);
        await updateUserDoc(type, userRef, details);
    }
    /**
     * Analytics Types  -   PageView    :   Views of a page
     *                  -   Event       :   Went to payment details page / clicked a button
     */
    switch (type) {
        /**
         * Checks if details.gymPage is true
         * If yes, checks for the gym doc in analytics and increments its views
         * Adds to UsersAnalytics -> {user}, 
         * to check what pages user is opening and differentiate between high intent leads
         */ 
        case 'pageView' :
            if (details.isGymPage) {
                // If user is present, add details to the user collection as well
                if(user) await addGymToUserDoc(userRef, details.gymData);

                // Increase gym views in the main database
                await increaseGymViews(details.gymData);
            }
            
            return true;
        
        default :
            break;
    }
    return false;

}