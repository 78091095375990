import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TRLogo from '../../assets/Icon-512.webp'
import { Actions } from '../../components/ForumComponents/ActionBar';
import Reply from '../../components/ForumComponents/Reply';
import { useContext, useEffect, useState } from 'react';
import useAuthStatus from '../../hooks/clientAuth';
import { followUnfollowForum, getAllReplies, getForumByURL, incrementViewsCount } from './ForumDatabaseHelpers';
import { UserLoginModal } from '../ModalHelpers/UserCreds';
import LoadingSpinner from '../../components/LoadingSpinner';
import { AddReplyModal } from '../../components/Modals/Forum/AddReplyModal';
import { formatRelativeTime } from '../basicHelper';
import { EditForumModal } from '../../components/Modals/Forum/EditForumModal';
import Alert from '../../components/customComponents/Alert';
import { GymContext } from '../../hooks/FetchEverythingContext';
/**
 * Main Screen
 * This Screen renders a discussion forum
 * This displays the questions and the replies/comments of the same
 * @param   {Object}  question    -   This state is fetched from the previous screen
 * @param   {user}    user        -   Gets user details from the authentication
 * @returns {Screen}
 */
const Forum = () => {
    const location = useLocation(); // Access location object

    const { question } = useParams(); // Extract the forum question from the URL
    const [myData           ,                     ] = useState({})
    const [USR              ,               setUSR] = useState(window.devicePixelRatio);  //userScreenResolution
    const [forum            ,             setForum] = useState(location.state?.forum ? location.state?.forum : location.state ? location.state : null);
    const [canEdit          ,           setCanEdit] = useState(false);    // New state for controlling edit button visibility
    const [replies          ,           setReplies] = useState();
    const [fetching         ,          setFetching] = useState(false);
    const [isFollowing      ,       setIsFollowing] = useState(false);
    const [showEditModal    ,     setShowEditModal] = useState(false); 
    const [showCredModal    ,     setShowCredModal] = useState();
    const [showAddReplyModal, setShowAddReplyModal] = useState(true);

    // adding a refreshKey to refresh the forum once the user adds their upvote/downvote
    const [refreshKey       ,        setRefreshKey] = useState(sessionStorage.getItem('refreshKey') || 0);

    const locationState = location.state;

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const navigate = useNavigate();
    const {user}                = useContext(GymContext)
    const {      isUserPresent} = useAuthStatus();

    // To increse the views count of the discussion forum
    useEffect(() => {
        const incrementViews = async () => {
            await incrementViewsCount(forum?.id);
        }

        incrementViews();
    }, [forum]);

    /**
     * Fetches the Forum 
     * In Case                          -   user goes to the forum directly using the url
     *                                  -   user presses the refresh key
     * @param   {Object}    forum       -   the discussion forum object
     * @param   {Number}    refreshKey  -   a key to know if the user has refreshed the page or not
     * @returns {Object}                -   forum object with data
     */
    useEffect(() => {
        // console.log('location.state : ', location.state.URLPrompt);
        // refreshKey is added in case the user refreshes the page
        if (locationState && refreshKey === 0) {
            setFetching(false);
            setRefreshKey(0);
            // console.log('forum : ', forum);
        }
        else if (!forum || refreshKey !== 0) {
            // Setting forum to null so the other components do not load
            setForum(null);
            const fetchForumData = async () => {
                try {
                    setFetching(true);
                    // fetching the forum from the url
                    const fetchedForum = await getForumByURL(decodeURIComponent(question));

                    if (fetchedForum) {
                        // If the forum is fetched,
                        // set the forum 
                        setForum(fetchedForum);
                        
                        // Stores the refresh key to 0 in the session storage as it is still 1
                        sessionStorage.setItem('refreshKey', '0')
                        // setting the refreshKey to 0 so that useEffect does not load again and again
                        setRefreshKey(0);
                    } else {
                        // If the Forum is not found, 
                        // Alert the user and navigate them to the previosu page
                        Alert('error', "Forum not found.");
                        navigate("/forum");
                    }
                } catch (error) {
                    // console.error("Error fetching forum:", error);
                    Alert('error', "Forum not found.");
                    navigate("/forum");
                } finally {
                    setFetching(false);
                }
            };

            fetchForumData();
        }

    }, [forum, question, navigate, refreshKey, locationState]);

    /** 
     * Detect refresh and update refreshKey
     * Handles the browser refresh button
     */
    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem('refreshKey', '1');
        };
    
        // Set refreshKey from sessionStorage if it exists
        const storedRefreshKey = sessionStorage.getItem('refreshKey');
        if (storedRefreshKey) {
            setRefreshKey(Number(storedRefreshKey));
            sessionStorage.removeItem('refreshKey'); // Clear it after setting
        }
    
        // Add event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Check if the user can edit the forum
    useEffect(() => {
        if (forum && user) {
            const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
            const currentTime = new Date().getTime();
            const postTime = new Date(forum.timestamp).getTime();
            setCanEdit(currentTime - postTime <= oneHour && forum?.authorEmail === user?.email);
        }
    }, [forum, user]);

    /**
     * Fetches all the forum replies
     * @param   {Object} forum  -   Forum data (do not load teh replies if forum is null)
     * @returns {Object}        -   Replies to the forum 
     */
    useEffect(() => {
        const getReplies = async () => {
            const replies = await getAllReplies(forum);
            setReplies(replies);
        }

        // Only call this function if forum is loaded
        // And the number of replies > 0
        if (forum && forum.repliesCount > 0) getReplies();
    }, [forum])

    /** 
     * Check if the user is following the forum on component mount
     * By checking,
     * if the user's email if present in the forum's followers' list
     */
    useEffect(() => {
        setIsFollowing(forum?.followers?.includes(user?.email));
    }, [forum, user]);

    /**
     * Adds the user's email in the forum's followers array
     */
    const handleFollow = async () => {
        const success = await followUnfollowForum(user, forum.id, isFollowing);

        if (success) { // here we did the opposite because state took some time to update and the alert were being shown first so
            if (!isFollowing) {
                // If the user is now following the forum,
                // Give an alert is now following the forum
                Alert('success', "You will now receive updates from this forum.");
            } else {
                // Otherwise say so
                Alert('success', "You have unfollowed this forum.");
            }

            // Toggle the follow state
            setIsFollowing(!isFollowing);
        } else {
            Alert('error', "Something went wrong. Please try again later!");
        }
    };


    const handleTagClick = () => {
        
    }

    if(fetching) {
        <div className="h-[100vh] w-[100vw] flex justify-center items-center fixed inset-0 z-[60] bg-black bg-opacity-65">
            <LoadingSpinner />
        </div>
    }


    return (
        <>
            <UserLoginModal modalIsOpen={showCredModal} setModalIsOpen={setShowCredModal} myData={myData} showGuest={false} />

            
            <div className={`mt-40 lg:mt-32 mx-4 md:mx-10 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'} text-left text-primary border-2 border-gray-400 shadow-md rounded-xl p-4`}>

                <div className='flex justify-between items-center'>
                    <div className='flex gap-4 lg:gap-8'>
                        {/* <img src={TRLogo} alt='TRLogo' className='h-12 w-12 lg:h-24 lg:w-24 rounded-full object-cover' /> */}
                        <div className='flex flex-col'>

                            <div className={`text-2xl ${USR <= 1 ? 'lg:text-3xl' : USR === 1.25 ? 'lg:text-3xl' : USR === 1.5 ? 'lg:text-3xl' : 'lg:text-3xl'} font-bold`}>{forum?.question}</div>
                            {/* Author name and timestamp on the same line */}
                            <div className='flex items-center gap-1'>
                                <span className={`text-sm lg:text-l`}>{forum?.isAnonymous ? 'Anonymous' : forum?.authorName}</span>
                                <span className={`font-bold text-3xl`}>·</span>
                                <span className={`text-gray-500 text-sm ${USR <= 1 ? 'lg:text-l' : 'lg:text-base'}`}>{formatRelativeTime(forum?.timestamp)}</span>

                            </div>
                        </div>
                    </div>
                    
                    <div className='flex flex-col lg:flex-row gap-2 lg:gap-4 '>
                        {canEdit && (
                            <button
                                onClick={() => setShowEditModal(true)}
                                className="text-semibold text-tertiary rounded-md lg:rounded-xl px-2 py-1 lg:px-4 lg:py-2 text-sm lg:text-2xl bg-secondary hover:-translate-y-1 hover:scale-110 hover:shadow-2xl transition-all duration-500"
                            >
                                Edit
                            </button>
                        )}
                        {/* {isUserPresent && forum?.authorEmail === user?.email && (
                            <>
                                <button
                                    onClick={handleDelete}
                                    className='text-semibold text-tertiary rounded-xl px-4 py-2 text-2xl bg-secondary hover:-translate-y-1 hover:scale-110 hover:shadow-2xl transition-all duration-500'
                                >
                                    Delete
                                </button>
                            </>
                        )} */}
                        {isFollowing !== undefined && isUserPresent && user?.email !== forum?.authorEmail && (
                            <button
                                onClick={() => (isUserPresent ? handleFollow() : setShowCredModal(true))}
                                className='text-semibold text-tertiary rounded-md lg:rounded-xl px-2 py-1 lg:px-4 lg:py-2 text-sm lg:text-2xl bg-secondary hover:-translate-y-1 hover:scale-110 hover:shadow-2xl transition-all duration-500'
                            >
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                        )}
                    </div>
                </div>

                <div className='flex flex-col lg:flex-row justify-between lg:mt-2 mb-4'>

                    {/* Tags */}
                    { forum?.tags && (
                        <div className="flex overflow-x-auto gap-x-2 gap-y-1 text-sm mb-2">
                            { forum?.tags && forum?.tags?.length > 0 ? forum.tags.slice(0, 10).map((tag, index) => (
                                <span onClick={handleTagClick} key={index} className="px-3 py-1 bg-lightSecondary text-primary rounded-full">
                                    #{tag}
                                </span>
                            )) : 
                                <div className='flex flex-wrap gap-x-2 gap-y-1'>
                                    {/* <div className='px-3 py-1 bg-secondary text-tertiary rounded-full w-fit'>#trainrex</div>
                                    <div className='px-3 py-1 bg-secondary text-tertiary rounded-full w-fit'>#health</div>
                                    <div className='px-3 py-1 bg-secondary text-tertiary rounded-full w-fit'>#fitness</div>
                                    <div className='px-3 py-1 bg-secondary text-tertiary rounded-full w-fit'>#recovery</div>
                                    <div className='px-3 py-1 bg-secondary text-tertiary rounded-full w-fit'>#weightloss</div> */}

                                </div>
                            }
                        </div>
                    )}

                    {/* Only display the image if there is one */}
                    {forum?.image &&
                        <img src={forum?.image ? forum.image : TRLogo} alt='forumImage' className={`h-[40vh] w-full ${USR <= 1 ? 'lg:h-[25vw] lg:w-[80%]' : USR === 1.25 ? 'lg:h-[26vw] lg:w-[80%]' : USR === 1.5 ? 'lg:h-[26vw] lg:w-[80%]' : 'lg:h-[26vw] lg:w-[80%]'} rounded-3xl object-cover`} />
                    }

                </div>

                {/* Description */}
                <div className={`mt-2`}>
                    {forum?.description}
                </div>

                
                {forum ? ( <Actions 
                    data={forum} 
                    setShowAddReplyModal={setShowAddReplyModal} 
                    userID={user?.uid}
                    setShowCredModal={setShowCredModal}

                />) : <></>}
                {/* {showAddReplyModal && 
                    < AddReplyModal  
                        forum={forum} 
                        user={user} 
                        setShowAddReplyModal={setShowAddReplyModal} 
                        setRefreshKey={setRefreshKey}
                    />
                } */}
            </div>

            <div className={`mx-4 md:mx-20 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'} mt-4`}>
                {showAddReplyModal && 
                    < AddReplyModal  
                        forum={forum} 
                        user={user} 
                        setShowAddReplyModal={setShowAddReplyModal} 
                        setRefreshKey={setRefreshKey}
                    />
                }
            </div>

            <div className={`mx-4 md:mx-20 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'} mt-8 `}>
                <div className='h-[1px] w-full bg-gray-300 '></div>
            </div>

            {/* Replies Section */}
            <div className='flex flex-col mt-4 mb-8'>
                    <div className={`mx-4 md:mx-20 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'} text-2xl lg:text-4xl lg:font-semibold mb-8 text-left`}>Comments</div>

                    <div className={`flex flex-col gap-8 mx-4 md:mx-20 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'}`}>
                        {replies && replies?.length > 0 && replies.map((reply, index) => {
                            return (forum ? (<Reply 
                                key={index}                                     // Can be removed?
                                reply={reply}                                   // reply data
                                user={user}                                     // user information
                                setShowCredModal={setShowCredModal}             // function to display the sign in/up modal
                                setRefreshKey={setRefreshKey}                   // To Reload the page after adding a reply
                            />) : <></>)
                        })}
                    </div>
                </div>
        
            {showEditModal && <EditForumModal forum={forum} setShowEditModal={setShowEditModal} setRefreshKey={setRefreshKey} USR={USR}/>}
        </>
    );
};

export default Forum;