
/**
 * a modal to get user choice between re filling the fitness form or just pay
 * @param {boolean}  isModalOpen                                   - bool to show or hide the modal
 * @param {function} setShowPaymentPlans                           - function to set payment modal to show or not, here only to show, it is hidden by default
 * @param {string}   setUserAgreedToFillDetailsAgainOrDirectlyPay  - to decide whether user wants to re-enter details or just pay
 * @returns a modal to get user choice between re filling the fitness form or just pay
 */
export const RenewFitnessPlan = ({resetForm, isModalOpen, setShowPaymentPlans, setUserAgreedToFillDetailsAgainOrDirectlyPay}) => {
    return (
        <div className={`${isModalOpen ? '' : 'hidden'} fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60`}>
            <div className="relative bg-white rounded-2xl p-6 w-11/12 max-w-md">
                <button onClick={resetForm} className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 text-2xl">
                    &times;
                </button>

                <h2 className="text-2xl font-bold mb-2">You Already Have an Fitness Plan</h2>
                <p className="text-gray-600 mb-6">
                    Want to update your details first?
                </p>

                <div className="flex flex-col gap-4">
                    <button
                        onClick={() => setUserAgreedToFillDetailsAgainOrDirectlyPay('ReEnterDetails')}
                        className="w-full px-5 py-3 border border-secondary text-secondary rounded-full hover:bg-secondary hover:text-white transition"
                    >
                        Update my details first
                    </button>

                    <button
                        onClick={() => {setUserAgreedToFillDetailsAgainOrDirectlyPay('DirectlyPay'); setShowPaymentPlans(true);}}
                        className="w-full px-5 py-3 bg-secondary text-white rounded-full hover:bg-secondary-dark transition"
                    >
                        Renew my plan now
                    </button>
                </div>
            </div>
        </div>
    );
};