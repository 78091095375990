import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTags } from "../../helperFunctions/DiscussionForumHelpers/GetAllTags";
import { sortPills } from "../../helperFunctions/basicHelper";
import { getAllForums } from "../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";

// const PillsContent = [
//     {content: 'motivation'},
//     {content: 'lifestyle'},
//     {content: 'mindfulness'},
//     {content: 'exercise'},
//     {content: 'meditation'},
//     {content: 'health'},
//     {content: 'training'},
//     {content: 'yoga'},
//     {content: 'muscle building'},
//     {content: 'weightloss'},
//     {content: 'cardio'},
//     {content: 'hypertrophy'},
// ]

const Pills = () => {
    const navigate = useNavigate();
    const [pills            ,              setPills]    =    useState([]);
    const [forums           ,             setForums]    =   useState();
   
    useEffect(() => {
        const fetchForums = async () => {
            const data = await getAllForums();
            setForums(data);
        }

        fetchForums();
    }, [])

    const handleNavigation = (forum) => {       
        navigate(`/forum/${forum?.URLPrompt}/`, {state : forum});
    }
    
    const fetchAndFormatPills = async () => {
        try {
            const data = await getAllTags();
            const sortedData = sortPills(data);
            const formattedPills = sortedData
                .slice(0, 12)
                .map((tag, index) => ({
                    color:
                        index % 2 === 0
                            ? "text-purple bg-lightPurple border-purple"
                            : "text-secondary bg-lightSecondary border-secondary",
                    text: tag.tag,
                    usedCount: tag.usedCount
                }));
            setPills(formattedPills);
        } catch (error) {
            console.error("Error fetching tags:", error);
            setPills([]);
        }
    };

    useEffect(() => {
        fetchAndFormatPills();
    }, [])

    return (
        <div className="flex flex-col justify-between h-full">
            <div className={`p-4 lg:px-8 lg:pb-8 rounded-3xl w-full  text-center bg-lightOlive shadow-md flex flex-col justify-between`}>
                <div className={`font-semibold text-2xl lg:text-5xl lg:pt-4`}>Looking for something in<br />particular?</div>
                
                {/* Pills */}
                <div className={`flex flex-wrap gap-1 lg:gap-4 mt-8`}>
                    {pills.map((pill, index) => (
                        <div onClick={() => navigate(`/forum`, { state: pill?.text })} key={index} className={`cursor-pointer px-3 py-[2px] lg:px-4 lg:py-2 rounded-full text-sm lg:text-xl font-semibold border-2 ${index%2 === 0 ? 'text-purple bg-lightPurple border-purple' : 'text-secondary bg-lightSecondary border-secondary'}`}>
                            {pill?.text}
                        </div>
                    ))}
                </div>
            </div>

            {/* Top Three Forums */}
            {forums && forums?.length > 3 &&
            <div className={`hidden lg:flex flex-col mt-8`}>
                <div className={`font-bold text-2xl `}>Popular Topics: </div>
                {forums.slice(0,2).map((forum, index) => (
                    <div
                        key={index}
                        onClick={() => handleNavigation(forum)}
                        className={`cursor-pointer rounded-3xl p-4 bg-lightSecondary mt-4`}
                    >
                        {/* heading */}
                        <div className={`flex justify-between items-center mb-1`}>
                            <div className={`font-semibold text-xl`}>{forum?.question}</div>
                            <div className={`whitespace-nowrap`}>{forum?.isAnonymous ? 'Anonymous' : forum?.authorName}</div>
                        </div>

                        <hr />

                        {/* Stats */}
                        <div className={`mt-2 flex gap-2`}>
                            <div>{forum?.views} Views |</div>
                            <div>{forum?.repliesCount} Replies |</div>
                            <div>{forum?.upVotes} Upvotes |</div>
                            <div>{forum?.followers?.length} People follow this</div>
                        </div>

                        {/* Tags */}
                        <div className={`flex flex-wrap gap-2 mt-2`}>
                            {forum?.tags && forum?.tags?.length > 0 && forum?.tags.map((tag, index) => (
                                <div key={index} className={`rounded-full border-2 px-4 py-1 ${index % 2 === 0 ? 'border-purple bg-lightPurple text-purple' : 'border-secondary bg-lightSecondary text-secondary'}`}>
                                    #{tag}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default Pills;

// const Pills = () => {
//     return (
//         <>
//             <div className="text-center my-10">Pills</div>
//         </>
//     )
// }

// export default Pills;