export const teamEmails = [
    // Yash
    'yash301020@gmail.com',
    'yash@trainrex.org',
    // Arnav
    'harshitarnav@gmail.com',
    'arnav@trainrex.org',
    // Anjali
    'anjalig921@gmail.com',
    'anjali@trainrex.org',
    // Kajal
    'kajal1996ma@gmail.com' ,
    'kajalkoli@trainrex.org',
    // Khushi
    'khushimalik1220@gmail.com',
    // Waseem
    'waseem.tatapartner1993@gmail.com',
    'waseemakram@trainrex.org',
    // Ashish
    'ashishsinha@trainrex.org',
    'ashish.sinha1520@gmail.com',
    // Ishita
    'ishita.garg.7946@gmail.com',
    'ishitagarg@trainrex.org',
    'ishitagarg1212@gmail.com',
    // Palash
    'palashsharmaj9@gmail.com',
    // Mukund
    'mukundkr102@gmail.com',
    // Divyansh
    'divyansh.convivial@gmail.com',
    'guruom.divyansh10@gmail.com',
    'divyanshsharma@trainrex.org',
    // Arjun
    'arjundhawan08@gmail.com',
    'arjundhawan@trainrex.org'
]