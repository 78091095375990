import { useNavigate } from 'react-router-dom';
import Card from '../Card'
import { trackNavigation } from '../../helperFunctions/Analytics/AnalyticsHelper';
// import useAuthStatus from '../../hooks/clientAuth';

/**
 * Shows the preview of the list of gyms on the Homepage
 * 
 * @param   {number} USR        -   screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @param   {List} listOfGyms   -   The list with the gym information
 * @param   {String} city       -   The selected city of the user from the NavBar
 * @returns {JSX.Element}       -   Renders the listcards of the gyms
 */
const ExploreGyms = ({ USR, listOfGyms, city }) => {
    // const {user} = useAuthStatus();

    // const recentlyViewedGyms = user?.recentlyViewedGyms || [];
    
    const navigate = useNavigate(); // Initialize the navigate function
    
    // Merge and filter out duplicate gyms based on gymID
    // const mergedGyms = [...recentlyViewedGyms, ...listOfGyms].reduce((acc, gym) => {
    //     if (!acc.some(g => g.gymName === gym.gymName)) {
    //         acc.push(gym);
    //     }
    //     return acc;
    // }, []);

    // Filters gyms to include only those with a non-empty Portfolio array containing at least one non-empty string.
    const filteredGyms = listOfGyms.filter(gym => 
        Array.isArray(gym.Portfolio) && 
        gym.Portfolio.length > 0 && 
        gym.Portfolio.some(item => item.trim() !== "")
    );

    const gymsWithoutPhotos = listOfGyms.filter(gym => 
        !Array.isArray(gym.Portfolio) || 
        gym.Portfolio.length === 0 || 
        gym.Portfolio.every(item => item.trim() === "")
    );

    const limit = window.innerWidth <= 440 ? 5 : 3;

    // If there are enough gyms with photos, show only them; otherwise, mix in gyms without photos
    const gymsToDisplay = filteredGyms.length >= limit 
        ? filteredGyms.slice(0, limit) 
        : [...filteredGyms, ...gymsWithoutPhotos.slice(0, limit - filteredGyms.length)];

    // City is stored like this?
    // Obviously it is gonna give errors no? Why not just pass the selectedCity?

    return (
        <div className='flex flex-col gap-1 lg:gap-4'>
            {/* Heading */}
            <div className={`group w-fit mx-6 text-xl ${USR >= 1.5 ? 'lg:text-2xl' : USR <= 1 ? 'lg:mx-48 lg:text-4xl' : USR === 1.25 ? 'lg:mx-20 lg:text-2xl' : 'lg:mx-40 lg:text-3xl'} font-bold items-center flex`}>
                <button
                    disabled={city === undefined}
                    onClick={() => {trackNavigation("/", `/${city.toLowerCase()}/gyms`,"On Clicking Gyms in Explore Gyms"); listOfGyms?.length > 0 ? navigate(`/${city.toLowerCase()}/gyms`, { state: listOfGyms }) : navigate(`/${city}/gyms`)}} 
                    className='opacity-70 hover:opacity-100 transition-all duration-300 cursor-pointer'
                >
                    Explore <span className='text-purple'>Gyms</span> near you
                </button>
                <div className='overflow-hidden pl-4'>
                    <button 
                        onClick={() => {trackNavigation("/", `/${city.toLowerCase()}/gyms`,"View All Gyms Button Click"); listOfGyms?.length > 0 ? navigate(`/${city.toLowerCase()}/gyms`, { state: listOfGyms }) : navigate(`/${city}/gyms`)}} 
                        className='hidden w-fit lg:block text-lg mt-1 font-semibold text-purple hover:text-primaryComplement translate-x-[-100%] opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-500'
                    >
                        View All Gyms &gt;
                    </button>
                </div>
            </div>

            {/* Cards */}
            { gymsToDisplay?.length > 0 &&
                <div className={`overflow-x-scroll no-scrollbar  lg:overflow-visible flex justify-between gap-4 px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-44 lg:gap-20' : USR === 1.25 ? 'lg:px-20 lg:gap-10' : 'lg:px-40 lg:gap-16'}`}>
                    {gymsToDisplay.map((gym, index) => (
                        <Card key={gym.gymID || index} cardData={gym} USR={USR} />
                    ))}
                    <div onClick={() => {
                        trackNavigation("/", `/${city.toLowerCase()}/gyms`, "View All Gyms Button Click");
                        if (listOfGyms?.length > 0) {
                            navigate(`/${city}/gyms`, { state: listOfGyms });
                        } else {
                            navigate(`/${city}/gyms`);
                        }
                    }} className={`lg:hidden bg-lightSecondary min-w-[67vw] p-2 relative rounded-3xl flex justify-center items-center font-bold text-xl`}>
                        Explore All Gyms &gt; &gt;
                    </div>
                </div>
            }
        </div>
    );
};

export default ExploreGyms;
