import { useEffect, useState } from "react";
import MembershipPlanTestA from "../GymPageComponents/MembershipPlanTestA";
import MembershipPlanTestB from "../GymPageComponents/MembershipPlanTestB";

const StudioMembershipPrices = ({ currGymData, USR, toggleSummaryModal }) => {
    const [testingType, setTestingType] = useState();


    useEffect(() => {
        const assignTestingType = () => {
            const randomNumber = Math.floor(Math.random() * 100); // Generates a number between 0-99
            // console.log("RAND : ", randomNumber )
            setTestingType(randomNumber < 50 ? "A" : "B");
        };
        assignTestingType();
    }, [])

    return (
        <div className="relative">
            <div>
                {/* Display Prices for Selected Class and Plan */}
                <div className="mb-8">
                    {/* <Prices
                        USR={USR}
                        gymName={gymName}
                        city={city}
                        currClassName={className}
                        currClassData={prices[currentPlan][className]}
                        isExclusive={isExclusive}
                        toggleSummaryModal={toggleSummaryModal}
                        planName={currentPlan}
                    /> */}
                    {testingType === 'A' ? 
                        <MembershipPlanTestA 
                            currGymData={{
                                country             : currGymData.country,
                                isExclusive         : currGymData.isExclusive,
                                gymName             : currGymData.gymName,
                                city                : currGymData.city,
                                commissionPercentage: currGymData.commissionPercentage,
                                freeTrial           : currGymData.freeTrial,
                                prices              : currGymData.prices,
                            }}
                            toggleSummaryModal={toggleSummaryModal}
                            isGymPage={false}
                        />
                    :
                        <MembershipPlanTestB 
                            currGymData={{
                                country             : currGymData.country,
                                isExclusive         : currGymData.isExclusive,
                                gymName             : currGymData.gymName,
                                city                : currGymData.city,
                                commissionPercentage: currGymData.commissionPercentage,
                                freeTrial           : currGymData.freeTrial,
                                prices              : currGymData.prices,
                            }}
                            toggleSummaryModal={toggleSummaryModal}
                            isGymPage={false}    
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default StudioMembershipPrices;


// const Prices = ({ USR, gymName, city, currClassName, currClassData, isExclusive, toggleSummaryModal, planName }) => {
//     return (
//         <div className="prices-container bg-white shadow-md p-4 pt-16 rounded-md">
//             <div className="prices-list">
//                 {[
//                     { duration: "1 Day"     , months: 0,  key: "dailyPrice"      },
//                     { duration: "1 Month"   , months: 1,  key: "monthlyPrice"    },
//                     { duration: "3 Months"  , months: 3,  key: "quarterlyPrice"  },
//                     // { duration: "6 Months"  , months: 6,  key: "halfYearlyPrice" },
//                     // { duration: "12 Months" , months: 12, key: "yearlyPrice"     },
//                 ].map(({ duration, months, key }) => {
//                     const mrp = currClassData?.[key];
//                     const exclusivePrice = currClassData?.[`exclusive${key.charAt(0).toUpperCase() + key.slice(1)}`];
//                     const price = getSellingPrice(exclusivePrice, isExclusive, gymName, city)
//                     const currency = getCurrencyToDisplay();
//                     const className = currClassName;

//                     return (
//                         <div key={key} className="price-item flex justify-between items-center mt-4">
//                             <span className="font-medium">{duration}</span>
//                             <div className="price-values flex items-center gap-4">
//                                 {mrp && (
//                                     <span className="line-through text-highlightRed">{`${currency}${mrp}`}</span>
//                                 )}
//                                 <button
//                                     onClick={() => toggleSummaryModal(months, price, mrp, className, planName, exclusivePrice)}
//                                     className="bg-lightSecondary px-4 py-2 rounded-full"
//                                 >
//                                     {exclusivePrice ? `${currency}${price}` : mrp ? `${currency}${price}` : "No Price"}
//                                 </button>
//                             </div>
//                         </div>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// };

