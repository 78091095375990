// import CTA1 from '../../assets/Website_content/CTA1.webp'
// import CTA2 from '../../assets/Website_content/CTA2.webp'
// import CTA3 from '../../assets/Website_content/CTA3.webp'
// import CTA1lg from '../../assets/Website_content/CTA1 lg.webp'
// import CTA2lg from '../../assets/Website_content/CTA2 lg.webp'
// import CTA3lg from '../../assets/Website_content/CTA3 lg.webp'
// import CakeSM from '../../assets/Website_content/Cake CTASM.svg'
// import CakeLG from '../../assets/Website_content/CTA Cake.svg'
import CTALG from '../../assets/Website_content/CTAFEB24.png'
import CTASM from '../../assets/Website_content/CTAFEB24SM.png'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GymContext } from '../../hooks/FetchEverythingContext'
import { ChevronDown } from 'lucide-react'

const CTA = ({ USR, scrollToGetInTouch, isLandingPage = false }) => {
    const { selectedCity } = useContext(GymContext);
    const navigate = useNavigate();
    const [isMobile] = useState(window.innerWidth <= 430);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchEndY, setTouchEndY] = useState(0);
    const [ , setBrowser] = useState("");
    const [device, setDevice] = useState('');
    const ctaRef = useRef(null); // Reference for CTA component

    useEffect(() => {
        const ctaElement = ctaRef.current; // Copy ref to a local variable
    
        const handleTouchStart = (e) => setTouchStartY(e.touches[0].clientY);
        const handleTouchMove = (e) => setTouchEndY(e.touches[0].clientY);
        const handleTouchEnd = () => {
            const swipeThreshold = 20;
            if (touchStartY - touchEndY > swipeThreshold) {
                window.scrollTo({ top: window.innerHeight * 0.8, behavior: "smooth" });
            }
        };
    
        if (ctaElement) {
            ctaElement.addEventListener("touchstart", handleTouchStart);
            ctaElement.addEventListener("touchmove", handleTouchMove);
            ctaElement.addEventListener("touchend", handleTouchEnd);
        }
    
        return () => {
            if (ctaElement) {  // Use the stored value, NOT ctaRef.current
                ctaElement.removeEventListener("touchstart", handleTouchStart);
                ctaElement.removeEventListener("touchmove", handleTouchMove);
                ctaElement.removeEventListener("touchend", handleTouchEnd);
            }
        };
    }, [touchStartY, touchEndY]); // Keep dependencies minimal    

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes("chrome") && !userAgent.includes("edg")) {
        setBrowser("Google Chrome");
        } else if (userAgent.includes("firefox")) {
        setBrowser("Mozilla Firefox");
        } else if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
        setBrowser("Safari");
        } else if (userAgent.includes("edg")) {
        setBrowser("Microsoft Edge");
        } else if (userAgent.includes("opera") || userAgent.includes("opr")) {
        setBrowser("Opera");
        } else if (userAgent.includes("msie") || userAgent.includes("trident")) {
        setBrowser("Internet Explorer");
        } else {
        setBrowser("Unknown Browser");
        }

        if (/windows/i.test(userAgent)) {
            setDevice("Windows");
        } else if (/android/i.test(userAgent)) {
            setDevice("Android");
        } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            setDevice("iPhone/iPad");
        } else if (/mac/i.test(userAgent)) {
            setDevice("Mac");
        } else if (/linux/i.test(userAgent)) {
            setDevice("Linux");
        } else {
            setDevice("Unknown Device");
        }
    }, [isMobile]);

    return (
        // <>
        //     {/* {isLoading && (
        //         <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        //             <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-lightSecondary"></div>
        //         </div>
        //     )} */}
        //     <div className={`flex flex-col gap-8 mt-28 ${USR >= 1.5 ? 'lg:h-[31vw] lg:mt-20' : USR <= 1 ? 'lg:h-[25vw] lg:mt-28' : USR === 1.25 ? 'lg:h-[28vw] lg:mt-24' : 'lg:h-[26vw] lg:mt-24'}`}>
        //         {/* <div className={`font-bold text-3xl md:text-6xl ${USR >= 1.5 ? 'lg:text-4xl' : USR <= 1 ? 'lg:text-6xl' : USR === 1.25 ? 'lg:text-4xl' : 'lg:text-5xl'} text-tertiary text-center lg:text-left`}>
        //             WHY WEIGHT FOR<br className={`lg:hidden`} /><span className='text-purple lg:ml-1'>NEW YEARS?</span>
        //         </div> */}
                
        //         <div className={`flex flex-col lg:flex-row lg:justify-between gap-8 w-full h-full`}>
        //             {/* First Image */}
        //             <div onClick={isLandingPage ? '' : () => navigate(`/${selectedCity}/gyms`)} className={`relative ${isLandingPage ? '' : 'cursor-pointer'} lg:w-[79%]`}>
        //                 <img src={isMobile ? CTA1 : CTA1lg} alt='one' className={`w-full h-40 ${isLandingPage ? 'lg:scale-x-90 lg:-ml-8 lg:h-[114%]' : ''} lg:h-full lg:min-w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`} />
        //                 <div className={`absolute -bottom-4 left-8 text-[3.5vw] lg:left-24 ${USR >= 1.5 ? 'lg:text-3xl lg:-bottom-7' : USR <= 1 ? 'lg:text-4xl lg:-bottom-8' : USR === 1.25 ? 'lg:text-3xl' : 'lg:text-4xl lg:-bottom-8'} px-4 lg:px-8 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md flex gap-1 items-center`}>
        //                     GET 25% OFF
        //                 </div>
        //                 <div className={`absolute top-6 left-8 lg:left-20 lg:top-20 whitespace-nowrap font-semibold lg:font-bold text-tertiary text-[5.5vw] ${isLandingPage ? 'lg:text-6xl' : USR >= 1.5 ? 'lg:text-4xl' : USR <= 1 ? 'lg:text-[64px]' : USR === 1.25 ? 'lg:text-5xl' : 'lg:text-4xl'} text-shadow-sm lg:leading-[1.3]`}>
        //                     Kickstart your fitness journey,<br />SAVE BIG on selected gyms!
        //                 </div>
        //             </div>

        //             <div className={`${isLandingPage ? 'hidden' : 'flex'} lg:flex-col-reverse justify-between mt-2 lg:w-[35%]  lg:mt-0 lg:gap-8`}>
        //                 {/* Second Image */}
        //                 <div
        //                     onClick={scrollToGetInTouch}
        //                     className={`relative w-[45%] lg:w-full cursor-pointer`}
        //                 >
        //                     <img
        //                         src={isMobile ? CTA2 : CTA2lg}
        //                         alt="one"
        //                         className={`w-full h-[18.7vh] ${browser === "Safari" ? "lg:h-[13.5vw]" : "lg:h-fit"} lg:w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`}
        //                     />
        //                     <div className={`absolute top-[85%] lg:top-[88%] right-2 lg:right-7 text-[3.4vw] lg:text-lg px-6 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md`}>
        //                         AVAIL NOW
        //                     </div>
        //                     <div className={`absolute top-6 left-4 lg:top-8 lg:left-7 font-bold lg:font-[800] text-lightSecondary text-2xl lg:text-4xl text-shadow-sm`}>
        //                         FREE
        //                     </div>
        //                     <div className={`absolute bottom-8 left-4 lg:bottom-6 lg:left-7 font-bold lg:font-[800] text-tertiary text-[4vw] text-shadow-sm leading-tight ${USR === 1.5 ? 'lg:text-xl' : USR > 1.5 ? 'lg:text-xl' : 'lg:text-2xl'}`}>
        //                         60 MIN FITNESS<br />CONSULTATION
        //                     </div>
        //                 </div>


        //                 {/* Third Image */}
        //                 <div onClick={() => navigate(`/${selectedCity}/gyms`)} className={`relative w-[45%] lg:w-full cursor-pointer`}>
        //                     <div className='absolute top-0 left-0 h-full w-full bg-primary rounded-2xl lg:rounded-[45px] bg-opacity-35 lg:hidden    '></div>
        //                     <img src={isMobile ? CTA3 : CTA3lg} alt='one' className={`w-full h-[18.7vh] ${browser === 'Safari' ? 'lg:h-[13.5vw]' : 'lg:h-fit'} lg:w-full rounded-2xl lg:rounded-[45px] lg:border-4 lg:border-lightSecondary`} />
        //                     <div className={`absolute top-5 -left-8  text-[3.4vw] lg:top-7 lg:-left-16 lg:text-2xl  ${USR >= 1.5 ? 'lg:top-7 lg:-left-16 lg:text-3xl' : USR <= 1 ? 'lg:top-7 lg:-left-16 lg:text-2xl' : USR === 1.25 ? 'lg:top-7 lg:-left-16 lg:text-xl' : 'lg:top-7 lg:-left-16 lg:text-xl'} px-8 py-2 rounded-full bg-lightSecondary text-primary font-[850] shadow-md`}>
        //                         GET NOW
        //                     </div>
        //                     <div className={`absolute bottom-7 left-4 lg:left-48 font-bold lg:font-[850] text-tertiary text-[4vw] leading-[6vw] lg:leading-8 ${USR >= 1.5 ? 'lg:text-xl' : USR <= 1 ? 'lg:text-3xl' : USR === 1.25 ? 'lg:text-3xl' : 'lg:text-2xl'} text-shadow-sm`}>
        //                         ONE DAY<br />PASS 
        //                         <span className={`pl-2  text-[8vw] ${USR >= 1.5 ? 'lg:text-xl' : USR <= 1 ? 'lg:text-5xl' : USR === 1.25 ? 'lg:text-4xl' : 'lg:text-2xl'} text-lightSecondary`}>
        //                             at ₹0
        //                         </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>  

        <>
            <div ref={ctaRef} className={`mt-[15vh] ${device === 'Mac' ? 'lg:mt-[10vh]' : 'lg:mt-[8vh]'} min-h-[80vh] flex flex-col items-center w-full`}>

                <div className='flex flex-col lg:flex-row-reverse lg:justify-between w-full lg:items-center'>
                    <div className='flex flex-col items-center lg:justify-center h-full lg:w-[50%]'>
                        <div className='text-4xl lg:text-[5vw] font-bold text-[#8B63D3] text-center scale-x-110 leading-[1.3] lg:leading-[1.3]'>Fitness,<br />Made Simple</div>
                        <button onClick={() => navigate(`/${selectedCity}/gyms`)} className='relative z-[1] text-white lg:text-[1vw] bg-secondary lg:w-fit px-10 py-1 lg:py-2 rounded-full mt-2 lg:mt-[2vw]'>Explore More</button>
                    </div>
                    <div className='flex items-center'>
                        <img src={isMobile ? CTASM : CTALG} alt='Cake' className={`-mt-8 lg:mt-0 w-[100vw] lg:w-[40vw] h-[50vh] lg:h-[40vw]`}/>
                    </div>
                </div>
                <div className={`lg:text-[1.5vw] font-semibold text-[#3C3734] text-center mt-4 lg:-mt-4 `}>Find your perfect fitness center, trainer, or<br />plans all in one place!</div>
                <div onClick={() => window.scrollTo({ top: window.innerHeight * 0.85, behavior: 'smooth' })} >
                    <ChevronDown className="cursor-pointer mt-4 lg:mt-0 h-20 animate-bounce" />
                </div>

            </div>
        </>
    )
}

export default CTA;