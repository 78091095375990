import { collection, doc, getDoc, getDocs } from "@firebase/firestore";
import { cityList, studioList } from "../../components/Databases"
import { db } from "../../config/firebase"
import { NCR_CITIES } from "../GymInfoHelpers/FetchGymsList";
import { removeSpacesAndConvertLowercase } from "../basicHelper";

/**
 * Fetch all studios based on the provided city input.
 * 
 * - If the city is 'ncr', the function will iterate over predefined NCR cities and fetch their studios.
 * - If the city is not 'ncr', it will fetch gyms only for the specified city.
 * 
 * @param {string} city                 -   The city for which studio data needs to be fetched. If 'ncr', studios from all NCR cities are fetched.
 * @param {Location} userLocation       -   Location of the user for sorting purposes
 * @returns {Promise<Array>}            -   Returns a promise that resolves to an array of studio objects.
 */
export const fetchAllStudios = async (city) => {
    // get city name in the firebase form
    city = removeSpacesAndConvertLowercase(city);
    const studioListData = []; // Initialize an empty array to store gym data

    // If city is 'ncr', fetch gyms for all cities in the NCR region
    if (city === 'ncr') {
        for (const ncrCity of NCR_CITIES) {
            await fetchStudios(ncrCity, studioListData);
        }
    } else {
        // Otherwise, fetch gyms for the specified city
        await fetchStudios(city, studioListData);
    }

    // return sortGyms(gymListData); // Return the list of fetched gyms
    return studioListData?.[0];
    
};

export const fetchStudios = async (city, studioListData) => {
    try {
        const studioesRef = collection(db, cityList, city, studioList);
        
        // Fetch all documents from the collection
        const querySnapshot = await getDocs(studioesRef);
        
        // Map through documents and extract data
        const studios = querySnapshot.docs.map((doc) => ({
            ...doc.data(), // Spread document data
        }));
        studioListData.push(studios);
    } catch (error) {
        // console.error("Error fetching studios:", error);
        throw error; // Propagate the error to handle it in the caller
    }
};

export const fetchStudio = async (studioName, city) => {
    try {
        const studioRef = doc(db, cityList, city, studioList, studioName);
        const docSnapshot = await getDoc(studioRef);
        
        if (docSnapshot.exists()) {
            // Return only the document data
            return docSnapshot.data();
        } else {
            // console.error("No such document found!");
            return null; // Or throw an error if needed
        }
    } catch (error) {
        // console.error("Error fetching studio document:", error);
        throw error; // Propagate the error for the caller to handle
    }
};