import ReferAFriendSM from '../../assets/Website_content/Refer a friend sm.svg'
import ReferAFriendLG from '../../assets/Website_content/Refer a friend lg.svg'
import coupon from '../../assets/Website_content/Coupon.svg'
import { specialOffersOfGym } from "../../helperFunctions/GymPageHelpers/SpecialGyms";
import { rsSymbol } from "../../assets/Symbols";
import { handlePayment } from "../../helperFunctions/RazorpayHelper";
import GetUserMissingDetailsModal from "../../Modals/GetUserMissingDetailsModal";
import FamilyModal from "../../components/FamilyModal";
import { checkIfUserHasNecessaryData } from "../../helperFunctions/userHelper";
import CouponModal from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/CouponModal";
import GiftModal from "../../components/GiftModal";
import { convertDDMMYYYYToTimestampMilliSeconds, convertMilliSecondsTimestampToISOFormat, getAvailableDates, getDate, getDate2, getDatesForDays } from "../../helperFunctions/basicHelper";
import { ChevronLeft } from "lucide-react";
import { conditionToDisplayCoupons } from "../../Modals/PaymentConfirmationModal";
import { checkReferralCodeValidity } from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/ReferralCodeHelpers";
import { useContext, useEffect, useState } from 'react';
import { GymContext } from '../../hooks/FetchEverythingContext';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/customComponents/Alert';
import { SelectDateAndTimings } from '../../components/PaymentPageComponents/SelectDateAndTimings';
import { CouponAndReferralCode } from '../../components/PaymentPageComponents/CouponAndReferralCode';
import { OrderSummary } from '../../components/PaymentPageComponents/OrderSummary';


/**
 * GiftPaymentConfirmation
 * Displays the payment confirmation details, such as gym name, membership duration, and pricing.
 * Allows users to select and apply a coupon, shows total savings, and applies Trex credits.
 * 
 * @param {object} receiptInfo             - Contains gym name, base price, membership duration, and MRP.
 * @param {function} onClose               - Function to close the modal.
 * @param {function} handleMembershipButton- Function to handle payment after confirmation.
 * @param {number} totalMemberships        - The total number of memberships selected by the user.
 * @param {string} selectedCategory        - Category of membership selected, such as "family" or "couple".
 * @param {boolean} isUserPresent          - Boolean indicating if the user is logged in.
 * @param {object} user                    - User information, including UID for fetching Trex balance.
 * @param {function} setSignInModal        - Function to open the sign-in modal when needed.
 * @returns {JSX.Element} The payment confirmation modal.
 */
const FinalPaymentPage = ({
    setCurrentPage,
    selectedPlan,
    paymentDetails, 
    setIsPaymentProcessing,
}) => {
    const {isMobile, USR} = useContext(GymContext)
    const navigate                                                            = useNavigate();
    const [selectedCoupon                ,                 setSelectedCoupon] = useState(null);                       // Stores the currently selected coupon
    const [showCouponModal               ,                setShowCouponModal] = useState(false);                      // Manages the visibility of the coupon modal
    const [trex                          ,                           setTrex] = useState(0);                          // Stores the user's Trex
    const [trexToBeUsed                  ,                   setTrexToBeUsed] = useState(false);                      // Tracks if Trex will be used
    const [appliedTrexAmount             ,              setAppliedTrexAmount] = useState(0);                          // Amount of Trex used in the discount
    const [showFamilyModal               ,                setShowFamilyModal] = useState(false);                      // Toggles displaying Family Modal
    const [referralGiver                 ,                  setReferralGiver] = useState();                           // Stores referral giver details
    // Important: selected date for gym and studios are of different formats because the calendar used in them uses different date formats
    // Have to send selectedDate as timestamp ahead, initially studio uses date object, gyms use 'DD-MM-YYYY'
    const [selectedDate                  ,                   setSelectedDate] = useState(paymentDetails?.receiptInfo?.className ? new Date(getDate2()) : getDate());
    const [showDatePicker                ,                 setShowDatePicker] = useState(false);
    const [payAtTheCounter               ,                setPayAtTheCounter] = useState(false);
    const [availableClasses              ,              setAvailableClasses ] = useState([]);
    const [selectedShift                 ,              setSelectedShift    ] = useState('');
    const [isClassAvailable              ,              setIsClassAvailable ] = useState(false);
    const [doesUserHasNecessaryData      ,       setDoesUserHasNecessaryData] = useState();
    const [showGetUserMissingDetailsModal, setShowGetUserMissingDetailsModal] = useState(false);
    const [billInfoArray                 ,                  setBillInfoArray] = useState();
    const [priceToPayGym                 ,                                  ] = useState(0);
    const [myData                        ,                         setMyData] = useState({});
    const [pendingPayment                ,                 setPendingPayment] = useState(false);
    const [successModal                  ,                   setSuccessModal] = useState(false);
    const [showGiftModal                 ,                  setShowGiftModal] = useState(false);
    const [expandCouponSection           ,            setExpandCouponSection] = useState(false);
    const [referralCode                  ,                   setReferralCode] = useState(''); // User-entered referral code
    const [referralCodeDetails           ,            setReferralCodeDetails] = useState({}); // Valid referral code details
    
    

    // console.log("2 : PaymentDetails : ", paymentDetails)
    // console.log("2 : RECEIPTS : ",paymentDetails?.receiptInfo)
    // console.log('2 : TOTLA MEME', paymentDetails?.totalMemberships)
    // console.log('2 : slected Cat', paymentDetails?.selectedCategory)
    // console.log('2 : userPresent ', paymentDetails?.isUserPresent)
    // console.log('2 : user', paymentDetails?.user)
    // console.log('2 : gymDtaa', paymentDetails?.gymData,)

    const baseAmount = parseFloat(paymentDetails.amountToPay);
    const mrp        = parseFloat(paymentDetails.mrp);
    const [totalMoneySaved      ,   setTotalMoneySaved] = useState(mrp - baseAmount);  // Starts as MRP - initial amount to pay

    // For initailly setting the amount
    const [totalAmount, setTotalAmount] = useState(paymentDetails.totalMemberships * paymentDetails.amountToPay);    // Stores the total amount after coupon


    // returns array of classes days ['monday', 'thursday' ]
    const availableWeekDays = paymentDetails?.receiptInfo?.className ? getAvailableDates(
        paymentDetails?.receiptInfo?.className,
        paymentDetails?.receiptInfo?.classesSchedule
    ) : null;
    // return array of all the available dates for the class from now to 30 days ahead
    const highlightedDates = paymentDetails?.receiptInfo?.className ? getDatesForDays(availableWeekDays) : null;

    // Fetch Trex on component mount or when user changes
    useEffect(() => {
        const fetchTrex = async () => {
            if (paymentDetails?.user?.uid) setTrex(paymentDetails?.user.trexCredits);
        };

        if (paymentDetails?.user?.uid) fetchTrex();
    }, [paymentDetails?.user]);
    
     /**
     * Automatically handles payment after user signs in if payment is pending.
     */
    useEffect(() => {
        if (pendingPayment && paymentDetails?.isUserPresent) {
            handlePayment({ ...myData, ...(paymentDetails?.user || {}) }, setIsPaymentProcessing, setSuccessModal, setBillInfoArray);
            setPendingPayment(false); // Reset the pending payment flag after payment
        }
    }, [paymentDetails?.isUserPresent, myData, pendingPayment, paymentDetails?.user, billInfoArray, setIsPaymentProcessing]);

    /**
     * handleCouponSelection
     * Applies the selected coupon and adjusts the total amount accordingly.
     * 
     * @param {object} coupon - The coupon object containing discount details.
     */
    const handleCouponSelection = (coupon) => {
        const discount = coupon.type === 'percentage'
            ? Math.min((coupon.value / 100) * baseAmount, coupon.maxDiscount)
            : coupon.value;

        const discountedAmount = baseAmount - discount;
        setTotalAmount(discountedAmount - appliedTrexAmount);
        setTotalMoneySaved(mrp - discountedAmount);
        setSelectedCoupon({ ...coupon, discountAmount: discount });
        // Do not close the modal
        // setShowCouponModal(false);
    };

    /**
     * handleReferralCode
     * Fetches details of the referral giver based on the referral code provided.
     * 
     * @param {object} referralCodeDetails - Details of the referral code used.
     */
    const handleReferralCode = async (referralCodeDetails) => {
        setReferralGiver({
            userID  :   referralCodeDetails.userID,
            code    :   referralCodeDetails.code
        });
    };

    /**
     * removeCoupon
     * Removes the applied coupon and resets the total amount to the original base amount.
     */
    const removeCoupon = () => {
        setTotalAmount(baseAmount - appliedTrexAmount);
        setSelectedCoupon(null);
        setTotalMoneySaved(mrp - baseAmount);
    };

    /**
     * toggleTrexToBeUsed
     * Toggles the usage of Trex credits, applying or removing Trex discounts as needed.
     * Ensures that Trex usage doesn’t reduce `totalAmount` below zero.
     */
    const toggleTrexToBeUsed = () => {
        const discount          = selectedCoupon ? selectedCoupon.discountAmount : 0;
        const amountAfterCoupon = baseAmount - discount;
        // Problem :  Razorpay doesn't accept 0 as an amount, 
        // thus, the max trex user can use takes down the amount to minimum Rs1 and not Rs0
        const maxTrexUsage    = Math.min(trex, amountAfterCoupon - 1); // -1 because razorpay doesn't accept 0 as an amount

        if (trexToBeUsed) {
            // Remove Trex discount
            setTotalAmount(amountAfterCoupon);
            setTotalMoneySaved(totalMoneySaved - appliedTrexAmount);
            setAppliedTrexAmount(0);
        } else {
            // Apply Trex discount
            setTotalAmount(amountAfterCoupon - maxTrexUsage);
            setTotalMoneySaved(totalMoneySaved + maxTrexUsage);
            setAppliedTrexAmount(maxTrexUsage);
        }

        setTrexToBeUsed(!trexToBeUsed);
    };

    const closeModal = () => setShowFamilyModal(false);

    useEffect(() => {
        const result = checkIfUserHasNecessaryData(paymentDetails?.user)
        setDoesUserHasNecessaryData(result);
      }, [paymentDetails?.user])

    // console.log("Selected Date : " , selectedDate )
    // console.log("Selected DAte : ", convertDDMMYYYYToTimestampMilliSeconds(selectedDate)
    
    // This handles the cash payment problem for updating the state later and running the function fist
    // useEffect(() => {
    //     if (shouldProceed) {
    //         setShouldProceed(false); // Reset it to prevent unintended re-runs
    //         handleNextStep();
    //     }
    //     // The next comment disables the ESLint warning, this is added cause it was causing an issue
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [shouldProceed]);
    
    // const handleCashPayment = () => {
    //     setPayAtTheCounter(true);
    //     setShouldProceed(true); // Triggers the effect after state updates
    // };

    /**
     * handleNextStep
     * Handles the payment process and opens the family modal if multiple memberships are selected.
     */
    const handleNextStep = async () => {
        if(doesUserHasNecessaryData?.showGetUserMissingDetailsModal) {
            setShowGetUserMissingDetailsModal(true);
            return ;
        }

        if (paymentDetails?.totalMemberships > 1) {
            setShowFamilyModal(true);
            return;
        }

        if(paymentDetails?.receiptInfo?.className && !isClassAvailable) {
            Alert('error', `${paymentDetails?.receiptInfo?.className} classes are not scheduled on ${selectedDate?.toLocaleDateString('en-GB')}`)
            return;
        } else if (paymentDetails?.receiptInfo?.className && selectedShift === '') {
            Alert('error', 'Please select a shift for your class')
            return;
        }

        if(paymentDetails?.isThisGift) {
            setShowGiftModal(true)
            return ;
        }


        // if the modal is for studio 1st function, else 2nd function
        paymentDetails?.receiptInfo?.className ?
        await handleMembershipButton({
            months              :   paymentDetails?.receiptInfo.months,
            amount              :   Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp                 :   mrp,
            className           :   paymentDetails?.receiptInfo?.className,
            totalMemberships    :   paymentDetails?.totalMemberships,
            referralGiver       :   referralGiver,
            trex                :   appliedTrexAmount,
            startDate           :   convertMilliSecondsTimestampToISOFormat(selectedDate.getTime()),  // timeStamp from date object (ISO format)
            payAtTheCounter     :   payAtTheCounter,
            selectedShift       :   selectedShift,
            class               :   paymentDetails?.receiptInfo?.className,
            selectedPlan        :   selectedPlan,
        })
        : 
        await handleMembershipButton({
            months              :   paymentDetails?.receiptInfo.months, 
            amount              :   Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp                 :   mrp, 
            className           :   paymentDetails?.receiptInfo?.className,
            totalMemberships    :   paymentDetails?.totalMemberships, 
            referralGiver       :   referralGiver, 
            trex                :   appliedTrexAmount,
            startDate           :   convertMilliSecondsTimestampToISOFormat(convertDDMMYYYYToTimestampMilliSeconds(selectedDate)), // sending ahead in timestamp format (ISO format)
            payAtTheCounter     :   payAtTheCounter,
            selectedPlan        :   selectedPlan,
        });
    };

    // Final step
    const handleMembershipButton = async (props) => {
        // setSummaryModal(false); // Close the summary modal
        const newBillArray = Array(paymentDetails?.totalMemberships ? paymentDetails?.totalMemberships : 1).fill({})
        await setBillInfoArray(newBillArray); // Initialize bill information array for each member

        // Determine if there are multiple members; if so, include user and valid members, else just the user.
        const allMembers = paymentDetails?.totalMemberships > 1
            ? [{ ...paymentDetails?.user }, ...(props.validMembers || [])]
            : props?.isThisGift ? [...props?.validMembers]
            : [{ ...paymentDetails?.user }];

        // Prepare gym data for payment processing
        // Also Checks if that gym is providing any special discount of its own
        const gymDataForPayment = specialOffersOfGym({
            gymID               :   paymentDetails?.gymData?.gymID,
            gymName             :   paymentDetails?.gymData?.name,
            gymDisplayName      :   paymentDetails?.gymData?.gymDisplayName || paymentDetails?.gymData?.studioDisplayName,
            gymContactNo        :   paymentDetails?.gymData?.gymContactNo   || paymentDetails?.gymData?.studioContactNo || '',
            gymEmail            :   paymentDetails?.gymData?.email,
            image               :   paymentDetails?.gymData?.logo,
            address             :   paymentDetails?.gymData?.address,
            cityDisplayName     :   paymentDetails?.gymData?.cityDisplayName,
            gstin               :   paymentDetails?.gymData?.gstin,
            city                :   paymentDetails?.gymData?.city,
            months              :   props.months,
            // If paying at the counter
            // Only Take 1 Rs as confirmation
            amount              :   (props.amount * 100).toFixed(2),
            
            mrp                 :   props.mrp,
            referralGiver       :   props.referralGiver       || {}, // Default to empty object if no referral giver
            trex                :   props.trex                || 0, // Default to 0 if no Trex credits are applied
            totalMemberships    :   paymentDetails?.totalMemberships          || 1,
            startDate           :   props.startDate,
            amountToPay         :   props.payAtTheCounter ? 100 : (props.amount * 100).toFixed(2), // Convert to smallest currency unit (e.g., cents)
            payAtTheCounter     :   props.payAtTheCounter? props.payAtTheCounter : false,
            priceToPayGym       :   priceToPayGym,
            selectedPlan        :   selectedPlan,
            orderType           :   paymentDetails?.receiptInfo?.className ? 'studio' : null,
        });

        if (props.payAtTheCounter) alert(`Confirm your spot for just ${rsSymbol}1! \nTrain Rex has got the rest covered for you!`);

        // Check if the user is signed in
        if (!paymentDetails?.isUserPresent) {
            // If not signed in, show sign-in modal and store payment data for post-login processing
            // setSignInModal(true);          // Display sign-in modal
            setMyData(gymDataForPayment);     // Store payment data for later processing
            setPendingPayment(true);          // Set pending payment flag
        } else {
            // User is signed in, initiate the payment process
            const response = await handlePayment(
                gymDataForPayment, 
                allMembers, 
                setIsPaymentProcessing,
                setBillInfoArray
            );
            setSuccessModal(response);
        }
    };

    /**
     * Navigates to Payment Successful Page if payment is completed
     * And BillInfoArray is righteously set
     * 
     * @param {Boolean} successModal    -   boolean to navigate to the next page
     * @param {Array} billInfoArray     -   Bill Information
     * @navigates                       -   PaymentSuccessfulPage
     */
    useEffect(() => {
        if (successModal && billInfoArray[0] !== undefined) {
            setSuccessModal(false);
            // Navigvate to next page
            navigate('/PaymentSuccessfulPage', { state: { 
                billInfo    :   JSON.parse(JSON.stringify(billInfoArray)),
            } });
        }

    }, [successModal, billInfoArray, navigate]);
    

    /**
     * Handles the submission of a referral code by validating it.
     * If valid, stores the details; otherwise, shows an error.
     */
    const handleReferralCodeSubmit = async () => {
        if(referralCode.trim() === '') {
            Alert('error', 'Please enter a referral code.')
            return;
        }
        
        const details = await checkReferralCodeValidity(referralCode);
        if (details?.isValid) {
            setReferralCodeDetails(details);
            handleReferralCode(details);
        } else {
            Alert('error', 'This referral code does not exist.');
        }
    };

    const conditionForCoupons = conditionToDisplayCoupons(paymentDetails?.gymData, paymentDetails?.receiptInfo);

    // const isPayButtonDisabled = !isClassAvailable || !selectedShift;

    // console.log("LLLLLL : ", paymentDetails)
    return (
        <>
            <div className="fixed top-0 left-0 w-full shadow-md py-4 lg:py-[1vw] px-4 lg:px-8 flex gap-4 items-center bg-[#ffffff] z-40">
                <button 
                    onClick={() => (paymentDetails?.receiptInfo?.months === 0 || paymentDetails.ABTesting === 'A') ? navigate(-1) : setCurrentPage(1)} 
                    className="cursor-pointer">
                    <ChevronLeft />
                </button>
                <div className="text-purple font-semibold text-lg lg:text-[1.5vw] ">{paymentDetails?.gymData?.gymDisplayName || paymentDetails?.gymData?.studioDisplayName}</div>
                <div className="text-purple font-semibold text-lg lg:text-[1.5vw] ">• {paymentDetails?.receiptInfo?.months === 0 ? '1' : paymentDetails?.receiptInfo?.months} {paymentDetails?.receiptInfo?.months === 0 ? 'Day' : 'Months'}</div>
            </div>

            <div className="flex flex-col  lg:justify-center lg:flex-row gap-4 lg:gap-12 mt-14 lg:mt-[5vw] min-h-[80vh]">
                {/* left side */}
                <div className={`lg:w-[55%] flex flex-col ${USR === 1 ? 'lg:max-h-[85vh]' : USR === 1.25 ? 'lg:max-h-[75vh]' : USR === 1.5 ? 'lg:max-h-[85vh]' : 'lg:max-h-[85vh]'} lg:overflow-y-scroll no-scrollbar p-1`}>
                    {/* First box */}
                    <SelectDateAndTimings 
                        paymentDetails        = {paymentDetails} 
                        selectedDate          = {selectedDate} 
                        setSelectedDate       = {setSelectedDate} 
                        highlightedDates      = {highlightedDates}
                        setAvailableClasses   = {setAvailableClasses}
                        setIsClassAvailable   = {setIsClassAvailable}
                        setSelectedShift      = {setSelectedShift}
                        isClassAvailable      = {isClassAvailable}
                        availableClasses      = {availableClasses}
                        selectedShift         = {selectedShift}
                        showDatePicker        = {showDatePicker}
                        setShowDatePicker     = {setShowDatePicker}
                    />

                    <div className="flex flex-col lg:flex-col-reverse mt-4 gap-4">
                        {/* Second Box - Refer a friend */}
                        <div className="w-full h-fit">
                            <img src={isMobile ? ReferAFriendSM : ReferAFriendLG} alt='Refer a friend' className='w-full h-fit rounded-xl overflow-hidden' style={{boxShadow : '0 1px 2px 1px lightGray'}}/>
                        </div>
                        
                        {/* Third box - Select Coupon */}
                        <CouponAndReferralCode 
                            selectedCoupon          = {selectedCoupon}
                            removeCoupon            = {removeCoupon}
                            setShowCouponModal      = {setShowCouponModal}
                            setExpandCouponSection  = {setExpandCouponSection}
                            expandCouponSection     = {expandCouponSection}
                            coupon                  = {coupon}
                            conditionForCoupons     = {conditionForCoupons}
                            referralCode            = {referralCode}
                            handleReferralCode      = {handleReferralCode}
                            handleCouponSelection   = {handleCouponSelection}
                            paymentDetails          = {paymentDetails}
                            setReferralCode         = {setReferralCode}
                            handleReferralCodeSubmit= {handleReferralCodeSubmit}
                            setReferralGiver        = {setReferralGiver}
                            referralCodeDetails     = {referralCodeDetails}
                            setReferralCodeDetails  = {setReferralCodeDetails}
                            selectedPlan            = {selectedPlan}
                        />

                        {/* {selectedCoupon && paymentDetails?.gymData.isExclusive && 
                        <button 
                            onClick={removeCoupon}
                            className="rounded-2xl flex justify-between items-center p-2 lg:px-6"
                            style={{boxShadow : '0 1px 2px 1px lightGray'}}>
                            <div className="flex items-center gap-1">
                                <img src={coupon} alt='coupon' className="h-[20vw] lg:h-[5vw] w-fit"/>
                                <div className="text-purple font-semibold text-lg lg:text-xl ml-2">Remove Coupon</div>
                            </div>
                            <div className="flex gap-2">
                                <div>2 offers</div>
                                <div><ChevronRight /></div>
                            </div>
                        </button>} */}

                    </div>
                </div>

                {/* right side */}
                <OrderSummary
                    selectedPlan       = {selectedPlan}
                    paymentDetails     = {paymentDetails} 
                    trex               = {trex}
                    toggleTrexToBeUsed = {toggleTrexToBeUsed}
                    trexToBeUsed       = {trexToBeUsed}
                    payAtTheCounter    = {payAtTheCounter}
                    setPayAtTheCounter = {setPayAtTheCounter}
                    mrp                = {mrp}
                    baseAmount         = {baseAmount}
                    selectedCoupon     = {selectedCoupon}
                    referralGiver      = {referralGiver}
                    appliedTrexAmount  = {appliedTrexAmount}
                    totalAmount        = {totalAmount}
                    handleNextStep     = {handleNextStep}
                />
            </div>

            {showCouponModal && (
                <CouponModal
                    handleReferralCode={handleReferralCode}
                    handleCouponSelection={handleCouponSelection}
                    setShowCouponModal={setShowCouponModal}
                    duration={paymentDetails?.receiptInfo?.months}
                    user={paymentDetails?.user}
                    gymData={paymentDetails?.gymData}
                    isLargerScreen={expandCouponSection}
                    setExpandCouponSection={setExpandCouponSection}
                    conditionForCoupons={conditionToDisplayCoupons(paymentDetails?.gymData, paymentDetails?.receiptInfo)}
                    selectedPlan={selectedPlan}
                />
            )}

            {/* Family Modal */}
            {showFamilyModal && (
                <FamilyModal
                    totalMemberships={paymentDetails?.totalMemberships}
                    isOpen={FamilyModal}
                    onClose={closeModal}
                    receiptInfo={{
                        ...paymentDetails?.receiptInfo,
                        mrp             :   mrp,
                        referralGiver   :   referralGiver, 
                        trex            :   appliedTrexAmount,
                        startDate       :   selectedDate,
                        payAtTheCounter :   payAtTheCounter,
                    }}
                    totalAmount={totalAmount}
                    handleMembershipButton={handleMembershipButton}
                    user={paymentDetails?.user}
                    gymData={paymentDetails?.gymData}
                />
            )}

            {showGiftModal && (
                <GiftModal
                    isOpen={GiftModal}
                    setShowGiftModal={setShowGiftModal}
                    receiptInfo={{
                        ...paymentDetails?.receiptInfo,
                        mrp: mrp,
                        referralGiver: referralGiver,
                        trex: appliedTrexAmount,
                        startDate: selectedDate,
                        payAtTheCounter: payAtTheCounter,
                    }}
                    totalAmount={totalAmount}
                    handleMembershipButton={handleMembershipButton}
                    gymData={paymentDetails?.gymData}
                />
            )}

            {/* Coupon Selection Modal */}

            {/* Getting User's missing details */}
            {showGetUserMissingDetailsModal && 
            <GetUserMissingDetailsModal 
                missingDetails={doesUserHasNecessaryData}
                setShowGetUserMissingDetailsModal={setShowGetUserMissingDetailsModal}
                userId={paymentDetails?.user?.uid}
            />}
        </>
    );
};

export default FinalPaymentPage;