import { passFunction_url } from "../../config/cloudURL";
import { themeColors } from "../../theme";

/**
 * Sends a request at the backend and 
 * Generates an apple pkpass
 * 
 * @param {Object} user     -   Information regarding the payment
 */
export const generateIMTPass = async (user) => {
    try {
        const response = await fetch(passFunction_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                // For a unique Pass
                serialNumber        :   'IMT_001',
                // Important Dates
                relevantDate        :   "2025-03-07T15:00:00.000Z",
                expirationDate      :   "2025-03-10T00:00:00.000Z",
                // Text Color
                foregroundColor     :   themeColors.tertiary,
                backgroundColor     :   themeColors.lightGreen,
                labelColor          :   themeColors.secondary,
                // QR Code
                barcodeData         :   'https://trainrexofficial.com/feedbackform/',
                thumbnail           :   user.profilePic || 'https://firebasestorage.googleapis.com/v0/b/gym-bro-101ca.appspot.com/o/TrainRexLogos%2FFooterIcon.png?alt=media&token=077269e0-34c4-41a3-9b53-1338ffb9941d',

                primary             :   { 
                    value           :   user.displayName, 
                    label           :   'Member Name' 
                },

                secondary           :   [
                    { 
                        value       :   ['Gym Beast', 'Sweat Machine', 'Gym Rat', 'Cardio Captain'][Math.floor(Math.random() * 4)],
                        label       :   "Certified"
                    },
                    { 
                        value       :   '', 
                        label       :   '' 
                    },
                ],

                auxiliary           :   [
                    { 
                        value       :   ['Leg Day', 'Cranky', 'Feed Me', 'Gains Only'][Math.floor(Math.random() * 4)], 
                        label       :   "Mood" 
                    },
                ]
            })
        });

        if (!response.ok) {
            throw new Error("Failed to download pass");
        }

        const blob = await response.blob();
        const passUrl = window.URL.createObjectURL(blob);

        // Open in Apple Wallet instead of downloading
        window.location.href = passUrl; // This will open "Add to Wallet" on iOS/macOS

        // const blob = await response.blob();
        // const url = window.URL.createObjectURL(blob);

        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "TrainRex.pkpass";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    } catch (error) {
        console.error("Error downloading pass:", error);
    }
};