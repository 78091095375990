/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import { validateSlide } from "./helpers/validateSlide";
// import { RenderSlide } from "./UIComponents/RenderSlide";
// import { NavigationButtons } from "./UIComponents/NavigationButtons";
// import { ErrorMessage } from "./UIComponents/ErrorMessage";
// import { slides } from "./helpers/slides";
import { UserLoginModal } from "../../ModalHelpers/UserCreds";
import Alert from "../../../components/customComponents/Alert";
import { handleUserSelectingAndPayingForFitnessPlan, trexToGiveNewUsers, updateOneMonthFitnessPlanBuyerUserWeeklyUpdates, updateQuestionnaire } from "../../userDatabaseHelper";
import { GymContext } from "../../../hooks/FetchEverythingContext";
import GetUserEmail from "../../../Modals/GetUserEmail";
import { RenewFitnessPlan } from "../../../Modals/RenewFitnessPlanModal";
import { ChooseYourFitnessPlan } from "../../../Modals/ChooseYourFitnessPlan";
import { sendFitnessPlanFormDataToBackend } from "./helpers/fetchWeeklyPlan";
import WeeklyUpdateDialog from "./WeeklyUpdateDialog";
import { UserActivityLevel, UserDietPreference, UserDietRestrictions, UserGender, UserGoals, UserGymOnlinePreferences, UserHealthConcerns, UserHeightWeightDOB, UserStructuredPrograms, UserTimeDedicationToWorkout, UserWorkoutPreference } from "./UIComponents/DietAndFitnessPlanQuestions";
import { collection, limit, onSnapshot, orderBy, query, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";

const UserFitnessForm = ({ isModalOpen, onClose, onLoadingStart, onDietPlanLoaded }) => {
    const {doesUserHasAFitnessPlan, entireFitnessPlanData, user, setUser} = useContext(GymContext);
    const navigate                                              = useNavigate();
    const [showPaymentPlans       ,        setShowPaymentPlans] = useState(false);
    const [selectedPlan           ,            setSelectedPlan] = useState('');
    const [trex                   ,                    setTrex] = useState(0);
    const [weeklyBaseAmount       ,                           ] = useState(29900) // in paise
    const [monthlyBaseAmount      ,                           ] = useState(79900) // in paise
    const [isUserUsingTrex        ,         setIsUserUsingTrex] = useState(false)
    const [isPlanExpired          ,           setIsPlanExpired] = useState(false)
    const [userNewFitnessPlan     ,      setUserNewFitnessPlan] = useState(null);
    const [view                   ,                    setView] = useState("userDetails");
    // const [                       ,             setPhoneNumber] = useState("");
    // const [                       ,                    setName] = useState("");
    // const [                       ,                     setOtp] = useState(Array(6).fill(""));
    // const [                       ,      setConfirmationResult] = useState(null);
    // const [                       ,            setResponseData] = useState("");
    // const [                       ,             setOtpVerified] = useState(false);
    const [showSignIn             ,              setShowSignIn] = useState(false);
    const [isPaymentProcessing    ,     setIsPaymentProcessing] = useState(false);
    const [                       ,           setBillInfoArray] = useState([]);
    const [submitting             ,              setSubmitting] = useState(false);
    const [userWillSeeThePlanLater, setUserWillSeeThePlanLater] = useState(false);
    const [showUpdateDialog       ,        setShowUpdateDialog] = useState(false);
    const [isLoading              ,               setIsLoading] = useState(false)

    // when user alreay has a plan and is expired then this state is for a pop asking for re fill or straight payment page
    const [userAgreedToFillDetailsAgainOrDirectlyPay, setUserAgreedToFillDetailsAgainOrDirectlyPay] = useState('')
    const [answers , setAnswers] = useState({});
    const [slide   ,   setSlide] = useState(0)

    //Checks if user already has a plan or is the plan expired
    useEffect(() => {
        const checkIfUserHasFitnessPlan = async () => {
            if (user && user.uid) {
                const planTypeRef = collection(db, "user", user.uid, "planType");
                const planTypeSnap = await getDocs(planTypeRef);
    
                let hasActivePlan = false;
                const now = new Date();

                if (planTypeSnap.empty) {
                    // Update your state based on the active plan
                    setIsPlanExpired(!hasActivePlan);
                    return null;
                }
            
                // For each day document (e.g., Monday, Tuesday, etc.)
                for (const planData of planTypeSnap.docs) {
                    if (planData.data()) {
                        const plan = planData.data();
    
                        if (plan.endDate >= now) {
                            // Update your state based on the active plan
                            setIsPlanExpired(hasActivePlan);
                            break; // stop checking once an active plan is found
                        }
                    }
                }
            }
        };

        checkIfUserHasFitnessPlan();
    }, [user])

    // Once the plan is ready and fetched, asking user...
    useEffect(() => {
        if(userNewFitnessPlan !== null && userNewFitnessPlan?.seen === false && !userWillSeeThePlanLater) {
            const userChoice = window.confirm(
                'Your fitness plan is ready. Do you want to checkout it out now, or later? "Yes" for now, "Cancel" for later'
            );

            if(userChoice) {
                navigate('/myFitnessPlan', {
                    state: {
                        weeklyPlan : userNewFitnessPlan?.weeklyPlan || [],
                        status     : "success",
                        message    : userNewFitnessPlan?.message,
                        userDetails: userNewFitnessPlan,
                    },
                })
            } else {
                setUserWillSeeThePlanLater(true);
                Alert('success', 'Your can visit My Fitness Plan to checkout your plan!')
            }
        }
    }, [userNewFitnessPlan]) // this gets updated using a listner

    // Real-time listener for changes in user's fitness plan document
    // Listens for any update inthe user fitness plan collection and 
    // returns the plan if plan is present else null
    useEffect(() => {
        if (!user?.uid) return;
      
        const plansRef = collection(db, "user", user.uid, "planDetails");
        const q = query(plansRef, orderBy("timestamp", "desc"), limit(1));
      
        const unsubscribe = onSnapshot(q, snapshot => {
          if (!snapshot.empty) {
            const doc = snapshot.docs[0];
            setUserNewFitnessPlan({ id: doc.id, ...doc.data() });
          } else {
            setUserNewFitnessPlan(null);
          }
        }, err => console.error("Plan listener error:", err));
      
        return unsubscribe;
      }, [user]);
      

    // Final Step Sends data to backend and backend stores the generated plan in the user > userId > plan > {randomDocId}
    const fetchDataAndSave = async (formData) => {
        try {
            // closes modal if the plan is still valid and shows user instructions about what is happening & what to do next
            Alert("success", "Preparing your personalized plan!")
            onClose()
            setTimeout(() => {
                Alert("success", "You'll be notified once the plan is ready!");
            }, 5000)

            // We are using listner, we just have send the formData to the backend
            // Backend will generate the plan and create/update the plan details document
            // Once the plan is created or updated, listner will get the data and notify the user with alert
            // SO ------
            sendFitnessPlanFormDataToBackend(user?.uid, formData)
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.");
            // Signal diet plan loading done (even with error)
            if (typeof onDietPlanLoaded === 'function') {
                onDietPlanLoaded();
            }
        }
    };

    const resetForm = () => {
        onClose(); // Call onClose if it's provided
        setView("userDetails");
        setAnswers({})
        setSlide(0);
        setShowPaymentPlans(false);
        setUserAgreedToFillDetailsAgainOrDirectlyPay('');
    };
    
    // This is for when user has 1 month plan, 
    // an update pop will be shown asking user if they want to add any updates
    // Handle submission of user changes 
    const handleUpdateSubmit = async (changes) => {
        setIsLoading(true);
        await updateOneMonthFitnessPlanBuyerUserWeeklyUpdates(entireFitnessPlanData, changes, fetchDataAndSave, user)
        setIsLoading(false);
        
    };

    // This runs on selecting weekly or monthly plan, New function to handle payment plan selection
    const handleSelectPlan = async (planName) => {
        setIsPaymentProcessing(true);
        await handleUserSelectingAndPayingForFitnessPlan(
            planName,
            setSelectedPlan,
            monthlyBaseAmount,
            weeklyBaseAmount,
            isUserUsingTrex,
            trex,
            user,
            setUser,
            setIsPaymentProcessing,
            userAgreedToFillDetailsAgainOrDirectlyPay,
            setBillInfoArray,
            setShowPaymentPlans,
            fetchDataAndSave,
            answers,
        );
        setIsPaymentProcessing(false)
    };    

    const handleSubmit = async () => {
        setSubmitting(true);
        await updateQuestionnaire(user.uid, answers, setUser)
        setSubmitting(false);
    }

    // Fetch Trex on component mount or when user changes
    useEffect(() => {
        const fetchTrex = async () => {
            if (user?.uid)  user?.dob ? setTrex(user.trexCredits*100) : setTrex(trexToGiveNewUsers()*100);
        };

        if (user?.uid) fetchTrex();
    }, [user]);
    
    if (showUpdateDialog) {
        return (
            <>
                {/* Weekly Update Dialog */}
                <WeeklyUpdateDialog
                    isOpen={showUpdateDialog}
                    onClose={() => setShowUpdateDialog(false)}
                    onSubmit={handleUpdateSubmit}
                />
    
                {/* Loading indicator */}
                {isLoading && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                        <div className="bg-bg p-4 rounded-lg shadow-lg">
                            <p>Updating your fitness plan...</p>
                            {/* Add a spinner here if you have one */}
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (showPaymentPlans === null || showUpdateDialog === null) return (<></>);

    // if userAgreedToFillDetailsAgainOrDirectlyPay is '' =>
    // user has not decided if they want to re-fill the form or just renew the plan by paying
    if(doesUserHasAFitnessPlan && isPlanExpired && userAgreedToFillDetailsAgainOrDirectlyPay === '') {
        return (
            <RenewFitnessPlan 
                resetForm={resetForm}
                isModalOpen={isModalOpen} 
                setShowPaymentPlans={setShowPaymentPlans}
                setUserAgreedToFillDetailsAgainOrDirectlyPay={setUserAgreedToFillDetailsAgainOrDirectlyPay}
            />
        )
    }

    return (
        // <ReactModal
        //     isOpen={isModalOpen}
        //     onRequestClose={resetForm}
        //     contentLabel="User Fitness Form"
        //     className="fixed inset-0 flex items-center justify-center z-50 text-black"
        //     overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
        //     ariaHideApp={false} // Add this line to fix the warning temporarily
        // >
        <div className={`${isModalOpen ? '' : 'hidden'} fixed z-[80] inset-0 h-[100vh] w-[100vw] bg-black bg-opacity-60 flex justify-center items-center`}>
            <div className="relative h-[100vh] w-[100vw] bg-[#ffffff] p-6">
                {showPaymentPlans && isPlanExpired ? (
                    <> 
                        {/* Payment Modal */}
                        <ChooseYourFitnessPlan 
                            resetForm               =   {resetForm} 
                            handleSelectPlan        =   {handleSelectPlan} 
                            trex                    =   {trex}
                            isUserUsingTrex         =   {isUserUsingTrex} 
                            setIsUserUsingTrex      =   {setIsUserUsingTrex}
                            isPaymentProcessing     =   {isPaymentProcessing}
                            selectedPlan            =   {selectedPlan}/>
                    </>           
                ) : (
                    <>
                    <div className="flex flex-col items-center justify-center">
                        <button onClick={resetForm} className="absolute top-6 right-3 text-gray-700 text-2xl hover:text-black">
                            &times;
                        </button>

                        {view === "userDetails" && (
                            <>
                                {slide === 0  && <UserGoals                   answers={answers} setAnswers={setAnswers} setSlide={setSlide} />}
                                {slide === 1  && <UserHeightWeightDOB         answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 2  && <UserGender                  answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 3  && <UserActivityLevel           answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 4  && <UserWorkoutPreference       answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 5  && <UserGymOnlinePreferences    answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 6  && <UserTimeDedicationToWorkout answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 7  && <UserDietPreference          answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 8  && <UserDietRestrictions        answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 9  && <UserHealthConcerns          answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} />}
                                {slide === 10 && <UserStructuredPrograms      answers={answers} setAnswers={setAnswers} slide={slide} setSlide={setSlide} handleSubmit={handleSubmit} submitting={submitting} user={user} setView={setView} setShowPaymentPlans={setShowPaymentPlans} isPlanExpired={isPlanExpired}/>}
                            </>
                        )}
                        {/* Will be used for new users */}
                        {view === 'emailAndDOB' &&
                            <GetUserEmail 
                                isThisFormAfterFitnessFormQuestions ={true}
                                user                                ={user} 
                                setShowPaymentPlans                 ={setShowPaymentPlans}
                                setView                             ={setView}
                                handleSubmit                        ={handleSubmit}
                                answers                             ={answers}
                            />
                        }
                    </div>
                    <UserLoginModal
                        modalIsOpen     ={showSignIn}
                        setModalIsOpen  ={setShowSignIn}
                    />
                </>
                )}
            </div>
            {/* </ReactModal> */}
        </div>

    );
};

export default UserFitnessForm;