import { ChevronDown } from "lucide-react";
import { useContext, useState } from "react"
import { GymContext } from "../../hooks/FetchEverythingContext";
import { trackButtonClick } from "../../helperFunctions/Analytics/AnalyticsHelper";
import PlanCards from "../PaymentPlans/PlanCards";
import { fillMissingPrices } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";


const MembershipPlanTestA = ({ currGymData, toggleSummaryModal, isGymPage = true }) => {
    const {USR}                                     = useContext(GymContext);
    const [duration         ,          setDuration] = useState(1);      // 0 means 1 day and 1, 3, 6, 12 are months
    const [membershipType   ,    setMembershipType] = useState(1);      // 1 is Single, 2 is Couple, more than 3 is family 
    const [dropdownOpen     ,      setDropdownOpen] = useState(false);  // For toggling dropdown, fro single couple family
    const [classDropdownOpen, setClassDropdownOpen] = useState(false);  // Drop Down for classNames
    const [className        ,         setClassName] = useState('');     // Zumba, etc

    const showDuration = () => {
        if      (duration === 1) return 'Monthly'
        else if (duration === 3) return 'Quarterly'
        else if (duration === 6) return 'Half Yearly'
        else                     return 'Yearly'
    }

    const showPlanType = () => {
        if      (membershipType === 1) return 'Single'
        else if (membershipType === 2) return 'Couple'
        else if (membershipType >= 3)  return 'Family'
    }

    const getSingleCoupleOrFamily = () => {
        if     (membershipType === 1) return 'single'
        else if(membershipType === 2) return 'couple'
        else if(membershipType >=  3) return 'family'
    }

    // const currentPlan = ;
    const availableClasses = Object.keys(currGymData?.prices?.single || {});

    function hasData(prices) {
        return Object.values(prices).some(value => value !== "");
    }

    const hasCouple = currGymData && isGymPage ? fillMissingPrices(currGymData?.prices, isGymPage)?.couple && hasData(fillMissingPrices(currGymData?.prices, isGymPage)?.couple) : false;
    const hasFamily = currGymData && isGymPage ? fillMissingPrices(currGymData?.prices, isGymPage)?.family && hasData(fillMissingPrices(currGymData?.prices, isGymPage)?.family) : false;
    
    return (
        <>
            <div className={`flex flex-col ${USR >= 1.5 ? 'lg:px-10' : USR === 1 ? 'lg:px-44' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'}`}>
                {/* Heading */}
                <div className={`w-full flex justify-between items-center px-5`}>
                    <div className='font-semibold text-2xl'>{showDuration()} Plan</div>

                    {/* Dropdown Button */}
                    <div className="relative">
                        {isGymPage && <div
                            className="flex gap-1 items-center px-6 py-1 rounded-full text-white bg-purple text-sm cursor-pointer"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <div>{showPlanType()}</div>
                            <ChevronDown className={`text-white transition-transform ${dropdownOpen ? "rotate-180" : ""}`} />
                        </div>}

                        {/* Dropdown Menu */}
                        {dropdownOpen && (
                            <div className="absolute right-0 mt-1 w-32 bg-white text-black shadow-md rounded-lg overflow-hidden z-10">
                                <div onClick={() => {setMembershipType(1); setDropdownOpen(false)}} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer">
                                    Single
                                </div>
                                <div onClick={() => {setMembershipType(2); setDropdownOpen(false);}} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer" >
                                    Couple
                                </div>
                                {hasFamily && <div onClick={() => {setMembershipType(3); setDropdownOpen(false); }} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer" >
                                    Family
                                </div>}
                            </div>
                        )}

                        {/* Class Type Dropdown */}
                        {!isGymPage && 
                        <div className="relative">
                            <div
                                onClick={() => setClassDropdownOpen(!classDropdownOpen)}
                                className="flex gap-1 items-center px-6 py-1 rounded-full bg-lightSecondary text-black text-sm cursor-pointer"
                            >
                                <div>{className ? className.charAt(0).toUpperCase() + className.slice(1) : "Select Class"}</div>
                                <ChevronDown className={`text-black transition-transform ${classDropdownOpen ? "rotate-180" : ""}`} />
                            </div>
                            {classDropdownOpen && <div className="absolute right-0 mt-1 w-40 bg-white text-black shadow-md rounded-lg overflow-hidden z-10">
                                {availableClasses.map((cls) => (
                                    <div
                                        key={cls}
                                        onClick={() => {setClassName(cls); setClassDropdownOpen(false);}}
                                        className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
                                    >
                                        {cls.charAt(0).toUpperCase() + cls.slice(1)}
                                    </div>
                                ))}
                            </div>}
                        </div>}
                    </div>
                </div>

                {/* Duration for Gym */}
                {isGymPage && 
                    <div className="w-full flex justify-between text-xs px-5 my-4">
                    {[
                      { duration: 1 , key: "monthlyPrice"   , label: "1 Month"  },
                      { duration: 3 , key: "quarterlyPrice" , label: "3 Months" },
                      { duration: 6 , key: "halfYearlyPrice", label: "6 Months" },
                      { duration: 12, key: "yearlyPrice"    , label: "12 Months"}
                    ].map(({ duration: d, key, label }) =>
                        currGymData.prices?.[getSingleCoupleOrFamily()]?.[key] ? (
                            <button
                                key={d}
                                onClick={() => setDuration(d)}
                                className={`px-2 lg:px-20 lg:text-lg py-2 border-2 rounded-md lg:rounded-xl text-purple font-semibold ${
                                    duration === d ? "border-purple bg-lightPurple" : "border-lightPurple bg-white"
                                } transition-all duration-500`}
                                >
                                {label}
                                </button>
                            ) : null
                        )}
                  </div>
                }

                {/* Duration for Studio */}
                {!isGymPage && (
                <div className="w-full flex justify-between text-xs px-5 my-4">
                    {[
                    { duration: 1, priceKey: "monthlyPrice", label: "1 Month", tracking: "1 month Studio Button Click A", },
                    { duration: 3, priceKey: "quarterlyPrice", label: "3 Months", tracking: "3 months Studio Button Click A", },
                    { duration: 6, priceKey: "halfYearlyPrice", label: "6 Months", tracking: "6 months Studio Button Click A", },
                    { duration: 12, priceKey: "yearlyPrice", label: "12 Months", tracking: "12 months Studio Button Click A", },
                    ].map((plan) =>
                    currGymData.prices.single?.[className]?.[plan.priceKey] ? (
                        <div
                            key={plan.duration}
                            onClick={() => { trackButtonClick(plan.tracking); setDuration(plan.duration); }}
                            className={`px-2 py-2 border-2 rounded-md text-purple font-semibold transition-all duration-500 ${
                                duration === plan.duration ? "border-purple bg-lightPurple" : "border-lightPurple bg-white"}`}
                        >
                            {plan.label}
                        </div>
                    ) : null
                    )}
                </div>
                )}

                {/* Cards */}
                <PlanCards isGymPage={isGymPage} currGymData={currGymData} duration={duration} membershipType={membershipType} className={className} toggleSummaryModal={toggleSummaryModal} hasCouple={hasCouple}/>
            </div>
        </>
    )
}

export default MembershipPlanTestA;