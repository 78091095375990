import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
import ReactGA from "react-ga4";

// Call this function on each page load
export function trackPageView(pageName) {
    logEvent(analytics, "page_view", { page_location: pageName });
}

// export function trackNavigation(fromPage, toPage) {
//     logEvent(analytics, "page_navigation", { from: fromPage, to: toPage });
// }

export const trackNavigation = (fromPage, toPage, source = "Default") => {
  ReactGA.event({
    category: "Navigation",
    action: source,
    label: `From ${fromPage} to ${toPage} via ${source}`
  });
};

export const trackButtonClick = (buttonName) => {
  ReactGA.event({
    category: "Button",
    action: buttonName,
    label: buttonName, // Name or identifier of the button
  });
};
