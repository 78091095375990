/**
 * @file deductTrexFromWallet.js
 * @description Deducts Trex credits from the user's wallet in Firestore.
 */

import { db } from "../../config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

/**
 * Deducts Trex from the user's wallet in Firestore.
 * 
 * @param {Object} user - The user object containing the UID.
 * @param {number} toPay - The amount of Trex to deduct.
 * @returns {Promise<boolean>} - Returns true if the deduction is successful, false otherwise.
 */
export const deductTrex = async (user, toPay) => {
    try {
        if (!user?.uid) {
            console.error("Invalid user object");
            return false;
        }

        // Reference to the user's document in Firestore
        const userRef = doc(db, "user", user.uid);

        // Fetch the current user data
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("User not found in Firestore");
            return false;
        }

        // Get current Trex balance
        const userData = userSnap.data();
        const currentTrex = userData.trexCredits || 0;

        if (currentTrex < toPay) {
            console.error("Insufficient Trex balance");
            return false;
        }

        // Deduct Trex and update Firestore
        await updateDoc(userRef, {
            trexCredits: currentTrex - toPay
        });

        console.log(`Successfully deducted ${toPay} Trex. New balance: ${currentTrex - toPay}`);
        return true;
    } catch (error) {
        console.error("Error deducting Trex:", error);
        return false;
    }
};
