import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatName } from "../../helperFunctions/basicHelper";
import StarRating from "../../components/StarRating";
import { GymContext } from "../../hooks/FetchEverythingContext";
import { calculateDistance } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";


/**
 * Page to compare gyms
 * Can comapre upto 3 gyms for now
 * Has a buy button on bottom redirecting to gym page of that gym
 * @returns {JSX.component} showing comparison between gyms in table like format
 */
const CompareGyms = () => {
    const { userLocation }                        = useContext(GymContext)
    const location                                = useLocation();
    const navigate                                = useNavigate();
    const [isFixed          ,         setIsFixed] = useState(false);
    const [comparingGyms    ,                   ] = useState(location?.state || []);
    const [showDifferences  ,                   ] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > window.innerHeight * 0.12);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // List of fields to compare
    const fieldsToCompare = [
        { key: "prices"         , label: "Plan starts from" },
        { key: "rating"         , label: "Rating"           },
        { key: "locality"       , label: "Locality"         },
        { key: "isWomensOnly"   , label: "Women Only"       },
        { key: "distance"       , label: "Distance From You"},

    ];

    const allFacilities = Array.from(new Set(comparingGyms.flatMap(gym => Object.keys(gym.facilities || {}))));
    const equipmentCorrections = {
        "Skiierg"           : "Skierg Machine",
        "ReclineBench"      : "DeclineBench",
        "HexcourtMachine"   : "Hack Squat Machine",
        "AdopterMachine"    : "AdductorMachine",
        "LegPullDown"       : "LatPullDown",
        "ChessPress"        : "ChestPress",
        "PitcherCurlMachine": "PreacherCurlMachine",
        "Butterfly"         : "PeckDeckFly",
        "Dumbells"          : "Dumbbells",
        "CrossTurner"       : "CrossTrainer"
    };
    
    // Normalize equipment names
    const normalizedEquipment = comparingGyms.flatMap(gym =>
        Object.keys(gym.equipmentList || {}).map(equipment =>
            equipmentCorrections[equipment] || equipment
        )
    );
    
    // Remove duplicates and get final list
    const allEquipment = Array.from(new Set(normalizedEquipment));
    
    // Function to check if a field is the same for all gyms
    const isSameAcrossGyms = (key, type) => {
        const values = comparingGyms.map(gym => 
            type === "facility" ? gym.facilities?.[key] :
            type === "equipment" ? gym.equipmentList?.[key] :
            gym[key]
        );
        return new Set(values).size === 1; // If all values are the same, return true
    };

    return (
        <div className="min-h-screen pt-32 pb-12 px-5 lg:px-40">
            <h1 className="text-2xl lg:text-[2vw] text-left text-secondary">Comparing Gyms</h1>

            {/* Toggle Button */}
            {/* <div className="flex justify-end gap-2 mb-4">
                <div onClick={()=> setShowDifferences(!showDifferences)} className={`rounded-full h-6 w-12 flex ${showDifferences ? 'bg-purple' : 'bg-tertiary '} transition-all duration-500 cursor-pointer`}>
                    <div className={`h-6 w-6 rounded-full border-[1px] border-primary ${showDifferences ? 'bg-tertiary translate-x-full' : 'bg-purple'} transition-all duration-500`}></div>
                </div>
                <div>Show Differences Only</div>
            </div> */}

            <div className="overflow-x-auto relative">
                {/* Fixed Header */}
                {isFixed && (
                    <div className="fixed top-[8vh] left-0 w-full bg-[#ffffff] z-50 px-40">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="p-2 w-[12vw]"></th>
                                    {comparingGyms.map((gym, index) => (
                                        <th key={index} className="text-4xl p-2 bg-[#ffffff] text-center">
                                            {gym.gymDisplayName}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </div>
                )}

                <table className="w-full">
                    <thead>
                        <tr>
                            <th className="p-2"></th>
                            {comparingGyms.map((gym, index) => (
                                <th key={index} className="text-6xl p-2">{gym.gymDisplayName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Gym Portfolio Row */}
                        {/* <tr>
                            <td className="text-left p-2 font-semibold w-[12vw]">Images</td>
                            {comparingGyms.map((gym, index) => (
                                <td key={index} className="p-2 text-center">
                                    <GymPortfolio Portfolio={gym.Portfolio} />
                                </td>
                            ))}
                        </tr> */}

                        {/* Basic Gym Info */}
                        {fieldsToCompare
                            .filter(({ key }) => !showDifferences || !isSameAcrossGyms(key, "basic"))
                            .map(({ key, label }) => (
                                <tr key={key}>
                                    <td className="text-left p-2 text-xl font-semibold w-[10vw]">{label}</td>
                                    {comparingGyms.map((gym, index) => (
                                        <td key={index} className="px-2 py-4 text-3xl">
                                            {typeof gym[key] === "boolean"
                                                ? gym[key] ? "Yes" : "No"
                                                : key === 'rating' 
                                                    ? <StarRating rating={gym[key]} color='secondary' showNoOfReviews={false} isComparingPage={true}/> 
                                                    : key === 'distance'
                                                        ? calculateDistance(userLocation, gym.latitude, gym.longitude)
                                                        : key === 'prices' 
                                                            ? gym[key]?.single?.exclusiveYearlyPrice 
                                                                ? `₹${(gym[key].single.exclusiveYearlyPrice / 12).toFixed(2)}/Month`
                                                                : "-"
                                                            : gym[key] || "N/A"
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}

                        <tr className="h-10"></tr> {/* Spacer Row */}

                        {/* Facilities Section */}
                        {!showDifferences || allFacilities.some(facility => !isSameAcrossGyms(facility, "facility")) ? (
                            <>
                                <tr>
                                    <td colSpan={comparingGyms.length + 1} className="text-left text-4xl font-semibold p-2 w-[10vw]">Facilities</td>
                                </tr>
                                {allFacilities
                                    .filter(facility => !showDifferences || !isSameAcrossGyms(facility, "facility"))
                                    .map((facility) => (
                                        <tr key={facility}>
                                            <td className="p-2 font-semibold text-left">{formatName(facility)}</td>
                                            {comparingGyms.map((gym, index) => (
                                                <td key={index} className="p-2 text-center text-purple">
                                                    {gym.facilities?.[facility] ? "✔" : "-"}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                            </>
                        ) : null}

                        <tr className="h-10"></tr> {/* Spacer Row */}

                        {/* Equipment Section */}
                        {!showDifferences || allEquipment.some(equipment => !isSameAcrossGyms(equipment, "equipment")) ? (
                            <>
                                <tr>
                                    <td colSpan={comparingGyms.length + 1} className="text-left text-4xl font-semibold p-2 w-[10vw]">Machines</td>
                                </tr>
                                {allEquipment
                                    .filter(equipment => !showDifferences || !isSameAcrossGyms(equipment, "equipment"))
                                    .map((equipment) => (
                                        <tr key={equipment}>
                                            <td className="p-2 font-semibold text-left">{formatName(equipment)}</td>
                                            {comparingGyms.map((gym, index) => {
                                                // Normalize gym's equipment list
                                                const gymEquipment = Object.keys(gym.equipmentList || {}).reduce((acc, item) => {
                                                    const correctedName = equipmentCorrections[item] || item;
                                                    acc[correctedName] = true; // Store corrected names
                                                    return acc;
                                                }, {});

                                                return (
                                                    <td key={index} className="p-2 text-center">
                                                        {gymEquipment[equipment] ? "✔" : "-"}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                            </>
                        ) : null}

                        <tr className="h-10"></tr> {/* Spacer Row */}

                        {/* Pricing Section */}
                        <tr>
                            <td colSpan={comparingGyms.length + 1} className="text-left text-4xl font-semibold p-2 w-[10vw]">
                                Starts From
                            </td>
                        </tr>
                        {["dailyPrice", "monthlyPrice", "quarterlyPrice", "halfYearlyPrice", "yearlyPrice"]
                            .filter(priceKey => !showDifferences || !isSameAcrossGyms(priceKey, "basic"))
                            .map((priceKey) => (
                                <tr key={priceKey}>
                                    <td className="p-2 font-semibold text-left">
                                        {priceKey.replace(/([A-Z])/g, " $1")}
                                    </td>
                                    {comparingGyms.map((gym, index) => (
                                        <td key={index} className="p-2">
                                            {gym?.prices?.single?.[priceKey] !== ''
                                                ? `₹${gym.prices.single[priceKey]}`
                                                : "-"}
                                        </td>
                                    ))}
                                </tr>
                            ))}

                        <tr className="h-10"></tr> {/* Spacer Row */}

                        <tr className="mt-10">
                            <td></td>
                            {comparingGyms.map((gym, index) => (
                                <td key={index} className="text-center">
                                    <div 
                                        onClick={() => navigate(`/${gym.city}/gyms/${gym.gymName}`, {state : gym})}
                                        className="bg-secondary px-10 py-2 text-4xl font-semibold rounded-full cursor-pointer inline-block">
                                        Buy Now
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CompareGyms;
