export const buyingDietAndFitnessPlan = (userName) => {
    return `
Hi ${userName},

Welcome to Train Rex — your all-in-one destination for achieving your fitness and nutrition goals!

You’ve just unlocked access to our exclusive **Diet & Fitness Plan**, crafted to help you stay consistent, motivated, and on track. To get started, simply log in to our **app or website** and begin exploring your personalized plan.

Make sure to **log your food intake daily** and follow the routines laid out for you. Every entry brings you closer to a healthier, stronger you.

Looking to pair your plan with a gym or fitness center? You can **directly book memberships** through our website: [trainrexofficial.com](https://trainrexofficial.com)

Let’s make your transformation smooth, empowering, and truly sustainable.

If you need any help along the way, our team is here for you at info@trainrex.org.

Stay consistent, stay powerful.  
The Train Rex Team
`;
};
