import { useState } from 'react';
import Alert from '../../components/customComponents/Alert';
import { addReview } from '../gymDatabaseHelper';

// const ratingDescriptions = ['', 'Bad', 'Not Bad', 'Average', 'Good', 'Awesome'];
const ratingDescriptions = ['', '', '', '', '', ''];
// const ratingCategories = ['Ambience', 'Staff', 'Equipments', 'Management', 'Vibe'];

/**
 * AddReviewModal Component
 * 
 * This component displays a modal that allows users to submit a review for a specific gym.
 * The review includes the user's name (pre-filled), a rating, and a written review.
 * The review is saved to the Firebase Firestore database and updates the gym's total reviews 
 * and average rating accordingly.
 * 
 * @param {Function} setShowAddReviewModal - Function to control the visibility of the modal
 * @param {string} gymLocation          - The location of the gym being reviewed (used for Firestore path)
 * @param {string} gymName              - The name of the gym being reviewed (used for Firestore path)
 * @param {boolean} isRecentGymUser     - Boolean indicating if the user has recently used the gym (for verification)
 * @param {Object} user                 - user details
 * @param {boolean} isGymReview         - Boolean indicating if the review is of a gym or a studio
 */
const AddReviewModal = ({
    currGymData,
    setShowAddReviewModal,
    gymLocation,
    gymName,
    isRecentGymUser,
    user,
    isGymReview
}) => {
    // Component State
    // const [name       ,        setName] = useState('');
    const [reviewText ,  setReviewText] = useState('');
    const [submitting ,  setSubmitting] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false); // State for anonymous review
    const [ratings    ,     setRatings] = useState({
        Ambience    : 0,
        Staff       : 0,
        Equipments  : 0,
        Management  : 0,
        Vibe        : 0
    });

    const handleStarClick = (category, value) => {
        setRatings(prev => ({ ...prev, [category]: value }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.values(ratings).some(rating => rating < 1)) {
            Alert("error", "Please rate all categories");
            return;
        }
        setSubmitting(true); // Set submission state to true to disable the submit button

        await addReview(isGymReview, currGymData, ratings, reviewText, setSubmitting, user, isRecentGymUser, isAnonymous, setShowAddReviewModal);
    
        setSubmitting(false);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-tertiary p-6 rounded-lg w-[90vw] md:w-[80vw] lg:w-2/5">
                <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold mb-4 text-primary">Add a Review</h2>
                    {/* 
                    <label className="block mb-2 text-secondary text-xl font-bold text-left">Your Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 placeholder:text-primary font-bold bg-secondary"
                        placeholder={isAnonymous ? 'Anonymous' : user.displayName} // Show 'Anonymous' if the checkbox is checked
                        readOnly // Make the input read-only
                        required
                    /> */}

                    {/* <label className="block mb-2 text-secondary text-xl font-bold text-left">Rating</label> */}
                    <div className='my-8'>
                        {Object.keys(ratings).map((category) => (
                            <div key={category} className="mb-4 w-full flex items-center justify-between border-b-2 border-lightPurple">
                                <div className="flex justify-around space-x-2 w-full max-w-[60%]">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <div
                                        key={star}
                                        onClick={() => handleStarClick(category, star)}
                                        className={`text-3xl cursor-pointer ${
                                            star <= ratings[category] ? 'text-secondary' : 'text-gray-300'
                                        }`}
                                        >
                                            <div>★</div>
                                            <div className="text-secondary text-base font-semibold">{ratingDescriptions[star]}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="text-left text-lg font-semibold text-gray-700 mb-2 w-[35%] lg:w-[20%]">{category}</div>
                            </div>
                        ))}
                    </div>

                    <label className="block mb-2 text-secondary text-xl font-bold text-left">Review</label>
                    <textarea
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 bg-tertiary"
                        placeholder="Write your review"
                        required
                    />

                    <div className="flex gap-2 items-center mb-4">
                        <input
                            type="checkbox"
                            id="isAnonymous"
                            checked={isAnonymous}
                            onChange={() => setIsAnonymous(!isAnonymous)} // Toggle the anonymous state
                            className="mr-2"
                        />
                        <label htmlFor="isAnonymous" className="text-secondary text-lg font-semibold">
                            Submit anonymously
                        </label>
                    </div>

                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setShowAddReviewModal(false)}
                            className="bg-primary text-tertiary px-4 py-2 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-secondary text-tertiary px-4 py-2 rounded-md"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddReviewModal;
