import React, { useContext, useState }  from "react";
import { UserLoginModal }   from "../../helperFunctions/ModalHelpers/UserCreds";
import { deductTrex }       from "../../helperFunctions/UserProfile/deductTrexFromWallet";
import { useNavigate }      from "react-router-dom";
import { GymContext } from "../../hooks/FetchEverythingContext";

/**
 * @file PayViaTrex.js
 * @description Allows users to pay using Trex credits and deducts from their wallet.
 */

/**
 * PayViaTrex Component
 * Displays available Trex balance and allows the user to enter an amount for deduction.
 * If the user is not logged in, it prompts them to log in.
 * 
 * @component
 * @returns {JSX.Element} - The rendered PayViaTrex component.
 */
export const PayViaTrex = () => {
    const { user }                             = useContext(GymContext);;
    const [modalIsOpen, setModalIsOpen]        = useState(false);
    const [trexAmount, setTrexAmount]          = useState("");    // Amount to be deducted
    const [isDialogOpen, setIsDialogOpen]      = useState(false); // Controls dialog visibility
    const navigate                             = useNavigate()

    /**
     * Handles the deduction of Trex credits from the user's account.
     * Ensures valid input and sufficient balance before deducting.
     */
    const handleTrexDeduction = async () => {
        if (!trexAmount || isNaN(trexAmount) || trexAmount <= 0) {
            alert("Please enter a valid Trex amount.");
            return;
        }

        if (trexAmount > user.trexCredits) {
            alert("Insufficient Trex balance. Please add more");
            navigate('/profile');
            return;
        }

        const success = await deductTrex(user, Number(trexAmount));

        if (success) {
            alert(`Successfully deducted ${trexAmount} Trex.`);
            setIsDialogOpen(false); // Close dialog after successful deduction
        } else {
            alert("Trex deduction failed. Please try again.");
        }
    };

    if (user) {
        return (
            <div className="flex flex-col justify-center items-center h-[90vh] lg:h-[100vh] bg-tertiary text-left p-4 relative">
                <div className="text-3xl font-semibold text-secondary mb-4">
                    Available Trex: {user.trexCredits}
                </div>
                <button 
                    onClick={() => setIsDialogOpen(true)}
                    className="px-6 py-3 bg-purple text-white rounded-lg hover:bg-opacity-80 transition"
                >
                    Pay with Trex
                </button>

                {/* Trex Deduction Dialog */}
                {isDialogOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg text-center w-[300px]">
                            <h2 className="text-xl font-semibold mb-4 text-secondary">Enter Trex Amount</h2>
                            <input
                                type="number"
                                value={trexAmount}
                                onChange={(e) => setTrexAmount(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg text-center"
                                placeholder="Enter amount"
                                min="1"
                            />
                            <div className="flex justify-between mt-4">
                                <button
                                    onClick={() => setIsDialogOpen(false)}
                                    className="px-4 py-2 bg-secondary text-white rounded-lg hover:bg-opacity-80 transition"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleTrexDeduction}
                                    className="px-4 py-2 bg-purple text-white rounded-lg hover:bg-opacity-80 transition"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div className="flex flex-col justify-center items-center h-[90vh] lg:h-[100vh] bg-tertiary text-left p-4 relative">
                <UserLoginModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            </div>
        );
    }
};
