import React from "react";
import appleWalletButton from "./AddToAppleWallet.svg"; // Import Apple Wallet Button SVG
import { generatePass } from "./generatePass";

/**
 * AddToAppleWalletButton Component
 * 
 * @param   {Object} billInfo   -   The bill information required to generate the Apple Wallet pass
 * @returns {JSX.Element}       -   A button that allows users to add a pass to Apple Wallet
 */
const AddToAppleWalletButton = ({ billInfo }) => {
    /**
     * Handles the button click event and triggers the pass generation.
     */
    const handleClick = () => {
        generatePass(billInfo); // Calls function to generate Apple Wallet pass
    };

    return (
        <button
            onClick={handleClick}
            style={{
                background      :   "none", // No background to maintain Apple's official design
                border          :   "none", // No borders as per Apple Wallet guidelines
                cursor          :   "pointer", // Ensures the button is clickable
                padding         :   0, // Prevents extra spacing
                display         :   "flex", // Aligns button properly
                alignItems      :   "center",
                justifyContent  :   "center",
                outline         :   "none", // Removes browser focus outline
                userSelect      :   "none",
                width           :   "auto",
                height          :   "auto",
            }}
            aria-label="Add to Apple Wallet"
        >
            {/* Apple Wallet button image - Must not be altered per Apple's Guidelines */}
            <img
                src={appleWalletButton}
                alt="Add to Apple Wallet"
                style={{
                    height      : "60px", // Apple's recommended minimum height
                    width       : "189px", // Apple's recommended minimum width
                    objectFit   : "contain", // Ensures correct scaling
                }}
            />
        </button>
    );
};

// Define PropTypes for validation
// AddToAppleWalletButton.propTypes = {
//     billInfo: PropTypes.object.isRequired, // Ensures billInfo is required
// };

export default AddToAppleWalletButton;
