import React from "react";
/**
 *    DaySelector Component
 *
 *    Renders a horizontal list of days for selecting a specific day in the fitness plan.
 *    Automatically reorders the days based on the user's plan start date, if provided.
 *
 *    @component
 *    @param {Object} props
 *    @param {string} props.selectedDay - The currently selected day (e.g., "Monday").
 *    @param {Function} props.setSelectedDay - Function to update the selected day.
 *    @param {string} [props.startDate] - Optional start date of the user's fitness plan used to reorder days.
 *
 *    @returns {JSX.Element} A horizontal scrollable day selector UI.
 */
const DaySelector = ({ selectedDay, setSelectedDay, startDate }) => {
    // Default order of days
    const defaultDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Reorder days based on the startDate, if provided
    let reorderedDays = defaultDays;
    if (startDate) {
        const startDay = new Date(startDate).toLocaleDateString("en-US", { weekday: "long" });
        const startIndex = defaultDays.indexOf(startDay);
        if (startIndex > -1) {
            reorderedDays = [...defaultDays.slice(startIndex), ...defaultDays.slice(0, startIndex)];
        }
    }

    return (
        <div className="flex overflow-x-auto px-4 py-2 mb-4 gap-2 lg:justify-center lg:max-w-[90vw]">
            {reorderedDays.map((day, index) => (
                <button
                    key={index}
                    onClick={() => setSelectedDay(day)}
                    className={`px-4 py-2 rounded-xl ${
                        selectedDay === day ? "bg-secondary text-white" : "bg-gray-200 text-black"
                    } transition hover:bg-secondary hover:text-white`}
                >
                    {day}
                </button>
            ))}
        </div>
    );
};

export default DaySelector;
