import { ArrowRight } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { GymContext } from "../../../hooks/FetchEverythingContext";

/**
 * 
 * @param {bool}      showfreezingPolicy    - true: modal is visible, fasle: modal is not visible 
 * @param {function}  setShowfreezingPolicy - toggling between showfreezingPolicy as true and false
 * @returns 
 */
const FreezingPolicyModal = ({ showFreezingPolicy, setShowFreezingPolicy }) => {
    const { USR } = useContext(GymContext)
    const [isRendered       ,        setIsRendered] = useState(false);


    // Handle the transition effect
    useEffect(() => {
        if (showFreezingPolicy) {
            setIsRendered(true);
        } else {
            const timer = setTimeout(() => setIsRendered(false), 300); // Match fade-out duration
            return () => clearTimeout(timer);
        }
    }, [showFreezingPolicy]);

    // Do not render the modal until the transition is completed for closing
    if (!isRendered) return null;

    const isProfilePage = window.location.href.includes('profile');

    return (
        <div className={`fixed ${isProfilePage ? 'inset-0' : '-inset-0'} left-0 z-[80] w-full h-screen bg-black bg-opacity-70 flex justify-center items-center`}>
            <div className={`bg-gradient-to-b from-secondary to-white rounded-lg p-6 w-11/12 md:w-1/2 ${USR === 1 ? 'lg:w-1/3' : USR === 1.25 ? 'lg:w-7/12' : USR === 1.5 ? 'lg:w-2/3' : 'lg:w-2/5'} text-center shadow-lg transform transition-transform duration-300 scale-100`}>
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Freezing Policy</h2>
                <div className="pb-6 h-96 no-scrollbar overflow-y-scroll text-left">
                    <p className="font-semibold">At Train Rex, we strive to provide flexibility and transparency to our members.</p>
                    <p className="mt-1">Below are the number of days for every membership plan that you can freeze (pause) for the respective duration:</p>

                    <div className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <p className="mt-4 font-semibold flex items-center gap-2">
                            <div>● 1-Month Membership</div> 
                            <div className='ml-1'><ArrowRight /></div>
                            <div>2 Days</div>
                        </p>
                    </div>

                    <div className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <p className="mt-4 font-semibold flex items-center gap-2">
                            <div>● 3-Month Membership</div> 
                            <div className='ml-1'><ArrowRight /></div>
                            <div>7 Days</div>
                        </p>                       
                    </div>

                    <div className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <p className="mt-4 font-semibold flex items-center gap-2">
                            <div>● 6-Month Membership</div>
                            <div className='ml-1'><ArrowRight /></div>
                            <div>14 Days</div>
                        </p>
                    </div>

                    <div className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <p className="mt-4 font-semibold flex items-center gap-2">
                            <div>● 12-Month Membership</div>
                            <div><ArrowRight /></div>
                            <div>28 Days</div>
                        </p>
                    </div>

                    <p className="mt-4 font-semibold">For any questions or to request a freezing: </p>
                    <p className="">Please contact us at +91-7827145068 or +91-9810060918</p>
                    <p className="">or email us at info@trainrex.org.</p>

                    <p className="mt-4 text-center">T&C apply.</p>
                </div>
                <button
                    onClick={() => setShowFreezingPolicy(false)}
                    className="bg-secondary text-white font-semibold px-6 py-2 rounded-lg mt-2"
                >
                    Close
                </button>
            </div>
        </div>

    );
};

export default FreezingPolicyModal;