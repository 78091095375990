import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import { getFinalImage } from "../ButtonsLibrary";
import { useNavigate } from "react-router-dom";
// import TRLogo from "../../../assets/Website_content/GYMcover_placeholder.webp";
// import Raasta from "../../../assets/Website_content/dinosaur-fossil.png";
import { calculateDistance } from "../MapHelpers/DistanceAndLocationHelpers";
// import { LocationIcon } from "../../../assets/WelcomeScreenImage";
import { createGymIcon, createUserLocationMarker } from "../MapHelpers/MapIcons";
import { mapBoxKey } from "../../../config/SecretKeys";
import { getCoordinatesAndStoreThemInDB } from "../MapHelpers/MapHelper";
// import { Star } from "../../../assets/Website_content/svg";
// import convertDriveUrl from "../../imgUrlRecontuct";
import { ChevronRight } from "lucide-react";
import { GymContext } from "../../../hooks/FetchEverythingContext";

// Set your Mapbox access token for authentication with Mapbox services
mapboxgl.accessToken = mapBoxKey;

// Coordinates for fallback if user denies location access
const cityCoordinates = {
    delhi       : { lat: 28.673209384783952, lng: 77.27820607424384, zoom: 11 },
    ghaziabad   : { lat: 28.655716394582665, lng: 77.37594194658801, zoom: 12 },
    noida       : { lat: 28.5355           , lng: 77.391           , zoom: 10 },
    ncr         : { lat: 28.637210308178965, lng: 77.32793923598803, zoom: 11 },
};

/**
 * Map Component
 * Displays a map with gym locations, user location, and gym details.
 * @param {Object} props - Component props.
 * @param {string} props.selectedCity - City selected by the user.
 * @param {string} props.currentPage - Page context (used to conditionally render details).
 * @param {Array} props.gymArray - Array of gym details including location data.
 */
const Map = ({ selectedCity, currentPage, gymArray, USR }) => {
    const { userLocation } = useContext(GymContext)
    const [map, setMap] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    // const [userLocation, setUserLocation] = useState(null);
    const [locationDenied] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const isMobile = window.innerWidth <= 440;

    const navigate = useNavigate();
    
    // Centers the map on the selected city's coordinates.
    useEffect(() => {
        if (map && selectedCity) {
            const cityCenter = cityCoordinates[selectedCity.toLowerCase()] || cityCoordinates.delhi;
            map.flyTo({
                center: [cityCenter.lng, cityCenter.lat],
                zoom: cityCenter.zoom,
                essential: true, 
            });
        }
    }, [selectedCity, map]);
    
    // Initialize the map
    useEffect(() => {
        const cityCenter = cityCoordinates[selectedCity.toLowerCase()] || cityCoordinates.delhi;
        const mapInstance = new mapboxgl.Map({
            container: "map-container",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [cityCenter.lng, cityCenter.lat],
            zoom: cityCenter.zoom,
        });

        setMap(mapInstance);
        mapInstance.on("load", () => mapInstance.resize());

        if (userLocation) {
            mapInstance.flyTo({ center: [userLocation.lng, userLocation.lat], zoom: isMobile ? 9 : 10});
            new mapboxgl.Marker({
                element: createUserLocationMarker(),
                anchor: "bottom",
            })
                .setLngLat([userLocation.lng, userLocation.lat])
                .addTo(mapInstance);
        }
    }, [selectedCity, userLocation, isMobile]);

    /**
     * Renders gym markers on the map based on the gym array and user location.
     * If a gym does not have valid latitude or longitude, the coordinates are retrieved using the Mapbox Geocoding API.
     *
     * @param {mapboxgl.Map} map - The Mapbox map instance where the markers will be rendered.
     * @param {Array} gymArray - Array of gym objects containing location data (latitude, longitude, address, etc.).
     * @param {Object} selectedLocation - Currently selected gym location to highlight on the map.
     *
     * @throws {Error} Logs warnings for invalid coordinates and continues execution for other gyms.
     */
    useEffect(() => {
        if (map && gymArray.length) {
            gymArray.forEach(async (loc) => {
                let lat = parseFloat(loc.latitude);
                let lng = parseFloat(loc.longitude);

                // Fetch coordinates if they are missing or invalid
                if (isNaN(lat) || isNaN(lng)) {
                    // console.warn("Invalid coordinates for location:", loc.gymDisplayName);

                    let latAndLng = await getCoordinatesAndStoreThemInDB(
                        {gymName : loc.gymName,     city : loc.city},
                        `${loc.address}, ${loc.cityDisplayName}, ${loc.pincode || ""}`
                    );

                    if (latAndLng === null) {
                        console.warn("Unable to retrieve coordinates for:", loc.gymDisplayName, loc.city);
                        return; // Skip marker creation for invalid locations
                    }

                    lat = latAndLng.lat;
                    lng = latAndLng.lng;
                }

                // Create a Mapbox marker for the gym
                const gymMarker = new mapboxgl.Marker({
                    element: createGymIcon(loc === selectedLocation), // Highlight selected gym
                    anchor: "bottom",
                })
                    .setLngLat([lng, lat]) // Set marker position
                    .addTo(map);

                // Add click event listener to highlight the selected gym
                gymMarker.getElement().addEventListener("click", () => {
                    setSelectedLocation(loc);
                    if (map) {
                      const pixelPos = map.project([lng, lat]);
                      // Adjust the Y position to show the tooltip above the marker (e.g., subtract 50 pixels)
                      setTooltipPosition({ x: pixelPos.x, y: pixelPos.y - 50 });
                    }
                  });
            });
        }
    }, [map, gymArray, selectedLocation, userLocation, locationDenied]);


    // useEffect(() => {
    //     if (selectedLocation) setFadeIn(true);
    // }, [selectedLocation]);

    const tooltipStyle = isMobile
        ? { left: tooltipPosition.x - 15, top: tooltipPosition.y - 105 }
        : { left: tooltipPosition.x - 20, top: tooltipPosition.y - 110 };

    return (
        <>
            <div className={`${currentPage !== 'homePage' ? 'hidden' : ''} flex justify-between font-semibold mt-16 px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-48' : USR === 1.25 ? 'lg:px-20' : 'lg:px-44'} `}>
                <div className="px-4 lg:px-8 pt-4 pb-3 w-fit lg:w-[80%] whitespace-nowrap bg-lightOlive text-purple rounded-t-3xl text-sm lg:text-4xl">
                    Navigate to your fitness
                </div>

                <div className="w-full lg:w-[20%] relative"> 
                    <div className="mapbox absolute bottom-[-0.5px] left-[-0.5px] lg:bottom-[-1px] lg:left-[-1px] bg-lightOlive w-[39px] h-[39px] lg:w-10 lg:h-10 flex justify-center items-center "></div>
                    <div className="absolute top-0 left-0  h-full w-full flex gap-[1vw] pl-4 justify-center lg:items-center  text-tertiary">
                        <button onClick={() => navigate(`/${selectedCity}/gyms`, {state : gymArray})} className="rounded-full bg-purple px-6 py-2 w-full max-h-[10vw] lg:h-fit overflow-ellipsis whitespace-nowrap flex justify-center items-center gap-2 text-[3.5vw] lg:text-xl">
                            <div>{selectedCity?.length > 9 ? selectedCity.charAt(0).toUpperCase() + selectedCity.slice(1,9)  + '...' : selectedCity.charAt(0).toUpperCase() + selectedCity.slice(1)}</div>
                            <ChevronRight className="scale-150 lg:scale-100 lg:h-8 font-bold"/>
                        </button>
                    </div>
                </div>
            </div>


            <div className={`${currentPage === "homePage" ? `flex flex-col lg:flex-row h-full justify-between rounded-b-3xl rounded-tr-3xl gap-4 mx-6 ${USR >= 1.5 ? 'lg:mx-6 lg:h-[460px]' : USR <= 1 ? 'lg:mx-48 lg:h-[540px]' : USR === 1.25 ? 'lg:mx-20 lg:h-[540px]' : 'lg:mx-44 lg:h-[540px]'} py-4 bg-lightOlive` : 'flex flex-row h-[300px] md:h-[420px] justify-center py-8 bg-transparent w-full bg-secondary'}`}>
                <div className="relative w-full h-[250px] lg:h-full rounded-2xl pl-5 ">
                    <div id="map-container" className="h-full w-[95%] lg:w-full" />
                    {/* Map will be rendered here */}
                    {selectedLocation && (
                        <GymTooltip 
                        gym={selectedLocation} 
                        userLocation={userLocation} 
                        onClose={() => setSelectedLocation(null)}
                        style={tooltipStyle} />
                    )}
                    </div>
                    <div className={`hidden lg:block text-left text-lg lg:text-2xl font-semibold text-primary pb-4 px-2`}>
                        <div className="text-[5.7vw] lg:text-6xl font-bold text-primary mb-1 md:mb-2">
                            Found {gymArray?.length} gyms near you!!
                        </div>
                        <span>Find the perfect gym for you!</span>
                    </div>
            </div>
        </>
    );
};

export default Map;

const GymTooltip = ({ gym, userLocation, onClose, style }) => {
    const tooltipRef = useRef(null);
    const navigate  = useNavigate();

    useEffect(() => {
        function handleClickOutside(event) {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        // Also handle touch events for mobile
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [onClose]);

  if (!gym) return null;
  
    const distanceText = userLocation 
        ? `Distance: ${calculateDistance(userLocation, parseFloat(gym.latitude), parseFloat(gym.longitude), false)}`
        : "";

    return (
        <div 
            ref={tooltipRef} 
            onClick={() => navigate(`/${gym.city}/gyms/${gym.gymName}`, {state : gym})} 
            className="absolute z-50"
            style={style}>
            <div className="bubble speech">
                {/* <button onClick={onClose} className="absolute top-0 right-2 text-xl">&times;</button> */}
                <h3 className="font-bold text-sm lg:text-xl">{gym.gymDisplayName}</h3>
                {distanceText && <p className="text-xs lg:text-sm">{distanceText}</p>}
            </div>
        </div>
    );
};


// Old map


// import React, { useContext, useEffect, useState } from "react";
// import mapboxgl from "mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
// // import { getFinalImage } from "../ButtonsLibrary";
// import { useNavigate } from "react-router-dom";
// import TRLogo from "../../../assets/Website_content/GYMcover_placeholder.webp";
// import Raasta from "../../../assets/Website_content/dinosaur-fossil.png";
// import { getNavigationLink, calculateDistance } from "../MapHelpers/DistanceAndLocationHelpers";
// import { LocationIcon } from "../../../assets/WelcomeScreenImage";
// import { createGymIcon, createUserLocationMarker } from "../MapHelpers/MapIcons";
// import { mapBoxKey } from "../../../config/SecretKeys";
// import { themeColors } from "../../../theme";
// import { getCoordinatesAndStoreThemInDB } from "../MapHelpers/MapHelper";
// import { Star } from "../../../assets/Website_content/svg";
// import convertDriveUrl from "../../imgUrlRecontuct";
// import { getSellingPrice } from "../gymDataHelpers/PriceHelpers";
// import { ChevronRight } from "lucide-react";
// import { GymContext } from "../../../hooks/FetchEverythingContext";

// // Set your Mapbox access token for authentication with Mapbox services
// mapboxgl.accessToken = mapBoxKey;

// // Coordinates for fallback if user denies location access
// const cityCoordinates = {
//     delhi       : { lat: 28.673209384783952, lng: 77.27820607424384, zoom: 11 },
//     ghaziabad   : { lat: 28.655716394582665, lng: 77.37594194658801, zoom: 12 },
//     noida       : { lat: 28.5355           , lng: 77.391           , zoom: 10 },
//     ncr         : { lat: 28.637210308178965, lng: 77.32793923598803, zoom: 11 },
// };

// /**
//  * Map Component
//  * Displays a map with gym locations, user location, and gym details.
//  * @param {Object} props - Component props.
//  * @param {string} props.selectedCity - City selected by the user.
//  * @param {string} props.currentPage - Page context (used to conditionally render details).
//  * @param {Array} props.gymArray - Array of gym details including location data.
//  */
// const Map = ({ selectedCity, currentPage, gymArray, USR }) => {
//     const { userLocation } = useContext(GymContext)
//     const [map, setMap] = useState(null);
//     const [selectedLocation, setSelectedLocation] = useState(null);
//     // const [userLocation, setUserLocation] = useState(null);
//     const [locationDenied] = useState(false);
//     const [fadeIn, setFadeIn] = useState(false);
//     const isMobile = window.innerWidth <= 440;

//     const navigate = useNavigate();
    
//     // Centers the map on the selected city's coordinates.
//     useEffect(() => {
//         if (map && selectedCity) {
//             const cityCenter = cityCoordinates[selectedCity.toLowerCase()] || cityCoordinates.delhi;
//             map.flyTo({
//                 center: [cityCenter.lng, cityCenter.lat],
//                 zoom: cityCenter.zoom,
//                 essential: true, 
//             });
//         }
//     }, [selectedCity, map]);
    
//     // Initialize the map
//     useEffect(() => {
//         const cityCenter = cityCoordinates[selectedCity.toLowerCase()] || cityCoordinates.delhi;
//         const mapInstance = new mapboxgl.Map({
//             container: "map-container",
//             style: "mapbox://styles/mapbox/streets-v11",
//             center: [cityCenter.lng, cityCenter.lat],
//             zoom: cityCenter.zoom,
//         });

//         setMap(mapInstance);
//         mapInstance.on("load", () => mapInstance.resize());

//         if (userLocation) {
//             mapInstance.flyTo({ center: [userLocation.lng, userLocation.lat], zoom: isMobile ? 9 : 10});
//             new mapboxgl.Marker({
//                 element: createUserLocationMarker(),
//                 anchor: "bottom",
//             })
//                 .setLngLat([userLocation.lng, userLocation.lat])
//                 .addTo(mapInstance);
//         }
//     }, [selectedCity, userLocation, isMobile]);

//     /**
//      * Renders gym markers on the map based on the gym array and user location.
//      * If a gym does not have valid latitude or longitude, the coordinates are retrieved using the Mapbox Geocoding API.
//      *
//      * @param {mapboxgl.Map} map - The Mapbox map instance where the markers will be rendered.
//      * @param {Array} gymArray - Array of gym objects containing location data (latitude, longitude, address, etc.).
//      * @param {Object} selectedLocation - Currently selected gym location to highlight on the map.
//      *
//      * @throws {Error} Logs warnings for invalid coordinates and continues execution for other gyms.
//      */
//     useEffect(() => {
//         if (map && gymArray.length) {
//             gymArray.forEach(async (loc) => {
//                 let lat = parseFloat(loc.latitude);
//                 let lng = parseFloat(loc.longitude);

//                 // Fetch coordinates if they are missing or invalid
//                 if (isNaN(lat) || isNaN(lng)) {
//                     // console.warn("Invalid coordinates for location:", loc.gymDisplayName);

//                     let latAndLng = await getCoordinatesAndStoreThemInDB(
//                         {gymName : loc.gymName,     city : loc.city},
//                         `${loc.address}, ${loc.cityDisplayName}, ${loc.pincode || ""}`
//                     );

//                     if (latAndLng === null) {
//                         console.warn("Unable to retrieve coordinates for:", loc.gymDisplayName, loc.city);
//                         return; // Skip marker creation for invalid locations
//                     }

//                     lat = latAndLng.lat;
//                     lng = latAndLng.lng;
//                 }

//                 // Create a Mapbox marker for the gym
//                 const gymMarker = new mapboxgl.Marker({
//                     element: createGymIcon(loc === selectedLocation), // Highlight selected gym
//                     anchor: "bottom",
//                 })
//                     .setLngLat([lng, lat]) // Set marker position
//                     .addTo(map);

//                 // Add click event listener to highlight the selected gym
//                 gymMarker.getElement().addEventListener("click", () => setSelectedLocation(loc));
//             });
//         }
//     }, [map, gymArray, selectedLocation]);


//     useEffect(() => {
//         if (selectedLocation) setFadeIn(true);
//     }, [selectedLocation]);

//     return (
//         <>
//             <div className={`${currentPage !== 'homePage' ? 'hidden' : ''} flex justify-between font-semibold mt-16 px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-48' : USR === 1.25 ? 'lg:px-20' : 'lg:px-44'} `}>
//                 <div className="px-4 lg:px-8 pt-4 pb-3 w-fit lg:w-[80%] whitespace-nowrap bg-lightOlive text-purple rounded-t-3xl text-sm lg:text-4xl">
//                     Navigate to your fitness
//                 </div>

//                 <div className="w-full lg:w-[20%] relative"> 
//                     <div className="mapbox absolute bottom-[-0.5px] left-[-0.5px] lg:bottom-[-1px] lg:left-[-1px] bg-lightOlive w-[39px] h-[39px] lg:w-10 lg:h-10 flex justify-center items-center "></div>
//                     <div className="absolute top-0 left-0  h-full w-full flex gap-[1vw] pl-4 justify-center lg:items-center  text-tertiary">
//                         <button onClick={() => navigate(`/${selectedCity}/gyms`, {state : gymArray})} className="rounded-full bg-purple px-6 py-2 w-full max-h-[10vw] lg:h-fit overflow-ellipsis whitespace-nowrap flex justify-center items-center gap-2 text-[3.5vw] lg:text-xl">
//                             <div>{selectedCity?.length > 9 ? selectedCity.charAt(0).toUpperCase() + selectedCity.slice(1,9)  + '...' : selectedCity.charAt(0).toUpperCase() + selectedCity.slice(1)}</div>
//                             <ChevronRight className="scale-150 lg:scale-100 lg:h-8 font-bold"/>
//                         </button>
//                     </div>
//                 </div>
//             </div>


//             <div className={`${currentPage === "homePage" ? `flex flex-col lg:flex-row h-full justify-between rounded-b-3xl rounded-tr-3xl gap-4 mx-6 ${USR >= 1.5 ? 'lg:mx-6 lg:h-[460px]' : USR <= 1 ? 'lg:mx-48 lg:h-[540px]' : USR === 1.25 ? 'lg:mx-20 lg:h-[540px]' : 'lg:mx-44 lg:h-[540px]'} py-4 bg-lightOlive` : 'flex flex-row h-[300px] md:h-[420px] justify-center py-8 bg-transparent w-full bg-secondary'}`}>
//                 <div className={`${currentPage === "homePage" ? "px-6 w-full lg:w-2/3"  : "w-[100%] lg:w-[79%] rounded-3xl overflow-hidden z-10"}`} >
//                     <div id="map-container" className="w-full h-[250px] lg:h-full rounded-2xl  " /></div>

//                 {currentPage === "homePage" && (
//                     <div className="w-full  lg:w-1/3 flex flex-col justify-start px-4 lg:p-4 h-full transition-opacity">
//                         {selectedLocation ? (
//                             <div className={`flex flex-col justify-between w-full gap-4 ${fadeIn ? "duration-1000 opacity-100" : "duration-0 opacity-0"}`}>
//                                 <img
//                                     src={(selectedLocation?.image !== "" && selectedLocation?.image?.[0].length > 0) ? convertDriveUrl(selectedLocation?.image?.[0]) : TRLogo}
//                                     alt="Gym-Image"
//                                     className={`rounded-2xl w-full h-52 ${USR >= 1.5 ? 'lg:h-40' : USR <= 1 ? 'lg:h-52' : USR === 1.25 ? 'lg:h-44' : 'lg:h-48'} object-cover`}
//                                 />
//                                 <div className="flex justify-between items-left w-full">
//                                     <div className={`text-left font-bold  text-primary w-7/12 text-2xl ${USR >= 1.5 ? 'lg:text-2xl lg:w-7/12' : USR <= 1 ? 'lg:text-4xl lg:w-2/3' : USR === 1.25 ? 'lg:text-3xl lg:w-7/12' : 'lg:text-3xl lg:w-7/12'}`}>
//                                         {selectedLocation?.gymDisplayName?.length > 15? `${selectedLocation?.gymDisplayName?.slice(0, 15)}...`: selectedLocation?.gymDisplayName}
//                                     </div>
//                                     <div className="md:text-lg  rounded-md font-bold scale-125 lg:pr-5 lg:pt-2 flex items-center gap-1">
//                                         {/* <StarRating rating={selectedLocation?.rating} color="secondary" showNoOfReviews={false} /> */}
//                                         <Star height={isMobile ? '12px' : '14px'} color='var(--primary)' /><div className="mt-[2px] lg:mt-0">{selectedLocation?.rating.toFixed(1)}</div>
//                                     </div>
//                                 </div>
//                                 <div className="text-primary flex items-left gap-1">
//                                     <span className="w-5 lg:w-7 h-4 lg:h-6">
//                                         <LocationIcon color={themeColors.primary} />
//                                     </span>
//                                     <p className="text-sm -mt-0.5 lg:text-xl text-primary font-bold">
//                                         {selectedLocation?.locality || "Locality"}
//                                     </p>
//                                 </div>
//                                 <div className="flex items-left gap-2 mb-2">
//                                     <p className="text-lg lg:text-xl font-semibold text-primary text-left">
//                                         Distance: {calculateDistance(userLocation, parseFloat(selectedLocation.latitude), parseFloat(selectedLocation.longitude), locationDenied)}
//                                     </p>
//                                     <a
//                                         href={getNavigationLink(parseFloat(selectedLocation.latitude), parseFloat(selectedLocation.longitude))}
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                         className="group relative flex items-center bg-transparent -mt-2 px-2 rounded-xl text-logoGray font-bold hover:scale-110 transition-all duration-300"
//                                     >
//                                         {/* Image */}
//                                         <div className="bg-transparent">
//                                             <img src={Raasta} alt="Navigate" className="max-h-8 object-cover" />
//                                         </div>

//                                         {/* Hover Text */}
//                                         <span className="absolute -right-2 -top-8 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-complementPurple text-primary font-bold py-1 px-3 rounded-lg whitespace-nowrap">
//                                             Get Directions
//                                         </span>
//                                     </a>

//                                 </div>
//                                 <div className="flex flex-col items-start justify-between space-y-4">
//                                     <div className="flex items-end space-x-4">
//                                         <div className="text-primary line-through text-sm lg:text-lg">
//                                             ₹{selectedLocation?.prices?.single?.yearlyPrice ? `${selectedLocation?.prices?.single?.yearlyPrice - 1}/Year` : `${selectedLocation?.prices?.single?.monthlyPrice}/Month`}
//                                         </div>
//                                         <div className="font-bold text-xl lg:text-3xl text-primary">
//                                             ₹{
//                                                 getSellingPrice(
//                                                     selectedLocation?.prices?.single?.exclusiveYearlyPrice ? selectedLocation?.prices?.single?.exclusiveYearlyPrice : selectedLocation?.prices?.single?.exclusiveMonthlyPrice, 
//                                                     selectedLocation?.isExclusive, 
//                                                     selectedLocation?.gymName, 
//                                                     selectedLocation?.city
//                                                 )
//                                             }
//                                             {selectedLocation?.prices?.single?.exclusiveYearlyPrice ? '/Year' : '/Month'}
//                                         </div>
//                                     </div>
//                                     <button
//                                         onClick={() => navigate(`/${selectedLocation?.city}/gyms/${selectedLocation?.gymName}`, {state : selectedLocation})}
//                                         className="bg-lightPurple rounded-xl py-2 px-4 text-primary font-semibold hover:scale-105 hover:shadow-primary hover:shadow-2xl transition-all duration-300"
//                                     >
//                                         Buy Membership
//                                     </button>
//                                 </div>
//                             </div>
//                         ) : (
//                             <div className={`text-left text-lg lg:text-2xl font-semibold text-primary pb-4 px-2 ${selectedLocation ? "opacity-0" : "opacity-100"}`}>
//                                 <div className="text-[5.7vw] lg:text-6xl font-bold text-primary mb-1 md:mb-2">
//                                     Found {gymArray?.length} gyms near you!!
//                                 </div>
//                                 <span>Find the perfect gym for you!</span>
//                             </div>
//                         )}
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };

// export default Map;
