import { getTrackBackground, Range } from "react-range";
import { themeColors } from "../../theme";
import { requestLocationAccess } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { FilterSVG, SortSVG } from "../../assets/Website_content/svg";

export const ListingFilter = ({filtersOpen, setFiltersOpen, budget, setBudget, filterType, setFilterType, userLocation, distance, setDistance, handleFilterAndSort, setType}) => {
    return (
        <div onClick={() => setFiltersOpen(false)} className={`fixed inset-0 z-50 h-[100vh] w-[100vw] flex justify-center lg:justify-end items-end transition-opacity duration-500 ${filtersOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} backdrop-blur-xl`}>
            <div onClick={(e) => e.stopPropagation()} className={`relative h-2/3 w-full lg:h-full lg:w-1/3 right-0 bg-tertiary rounded-tl-[40px] rounded-tr-[40px] lg:rounded-tr-none lg:rounded-bl-[40px] flex flex-col text-lg p-8 transition-transform duration-700 transform ${filtersOpen ? 'translate-y-0 lg:translate-x-0' : 'translate-y-0 lg:translate-x-full'}`} style={{boxShadow: '0px 0px 10px gray'}}>
                <div onClick={() => setFiltersOpen(false)} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">×</div>
                
                <div className="font-semibold text-purple text-left mt-4  flex items-center gap-1">
                    <FilterSVG color='#876eaf' height='18px' />
                    <div>FILTER</div>
                </div>

                <div className={`max-h-[85%] overflow-y-scroll mt-8 ml-4 pr-8`}>
                    {/* Price Filter */}
                    <div>
                        <label className="block font-semibold text-2xl text-primary mb-2 text-left">
                            Price
                        </label>
                        <div className="flex justify-between text-md text-primary mb-2">
                            <span>Min: <span className="font-extrabold">₹{budget[0]}</span></span>
                            <span>Max: <span className="font-extrabold">₹{budget[1]}</span></span>
                        </div>

                        <Range
                            step={100}
                            min={0}
                            max={10000}
                            values={budget.map((value) => Math.max(0, Math.min(10000, Math.round(value / 100) * 100)))}
                            onChange={setBudget}
                            onFinalChange={() => {
                                setFilterType(prev => {
                                    const newFilterType = "budget"; // Or "distance"
                                    return newFilterType;
                                });
                            }}
                            // onFinalChange={() => {
                            //     setFilterType(prev => {
                            //         const newFilterType = "budget"; // Or "distance"
                            //         handleFilterAndSort("filter", newFilterType); // Pass new filterType to ensure correct value
                            //         return newFilterType;
                            //     });
                            // }}
                            renderTrack={({ props, children }) => (
                                <div
                                    key="range-track"
                                    {...props}
                                    className="h-2 w-full rounded-md"
                                    style={{
                                        background: getTrackBackground({
                                        values: budget,
                                        colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                        min: 1,
                                        max: 10000,
                                        }),
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                key="range-thumb"
                                {...props}
                                className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                ></div>
                            )}
                        />
                    </div>

                    {/* Distance Filter */}
                    <div>
                        <label className="block font-semibold text-2xl text-primary mb-2 mt-12 text-left">
                            Distance
                        </label>

                        {/* Show warning if location is unavailable */}
                        {!userLocation && (
                            <p 
                                onClick={() => {requestLocationAccess()}} 
                                className="text-sm text-red-500 mb-2">
                                Click to enable location to filter by distance.
                            </p>
                        )}

                        <div className={`${!userLocation ? 'scale-75' : ''} flex flex-col transition-all duration-500`}>
                            <div className={`flex justify-between text-md text-primary mb-2`}>
                                <span className="font-extrabold">{distance[0]}KM</span>
                                <span className="font-extrabold">{distance[1]}KM</span>
                            </div>

                            <Range
                                key={userLocation ? "enabled" : "disabled"} // Forces re-render when `userLocation` changes
                                step={0.1}
                                min={0}
                                max={10}
                                values={distance}
                                onChange={setDistance}
                                onFinalChange={() => {
                                    if (userLocation) {
                                        setFilterType(prev => {
                                            const newFilterType = "distance";
                                            return newFilterType;
                                        });
                                    }
                                }}
                                // onFinalChange={() => {
                                //     if (userLocation) {
                                //         setFilterType(prev => {
                                //             const newFilterType = "distance";
                                //             handleFilterAndSort("filter", newFilterType);
                                //             return newFilterType;
                                //         });
                                //     }
                                // }}
                                disabled={!userLocation} // Disable when userLocation is undefined/null/false
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className={`h-2 w-full rounded-md ${!userLocation ? 'opacity-50' : ''}`}
                                        style={{
                                            background: getTrackBackground({
                                                values: distance,
                                                colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                                min: 0.1,
                                                max: 10,
                                            }),
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className={`w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center ${!userLocation ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    ></div>
                                )}
                            />

                        </div>
                    </div>
                </div>

                <div onClick={() => {setFiltersOpen(false); handleFilterAndSort('filter', filterType, null, distance, budget)}} className="cursor-pointer px-6 py-2 rounded-xl text-black mt-14 border-2 border-purple hover:text-white hover:bg-purple transition-all duration-500">
                    Apply
                </div>
            </div>
        </div>
    )
}

// A small dropdown component for Price sorting
export const PriceSortDropdown = ({ sort, setSort, handleFilterAndSort }) => {
    // If `sort` isn't "LtoH" or "HtoL", fallback to an empty string in the dropdown
    const dropdownValue = sort === "LtoH" || sort === "HtoL" ? sort : "";
  
    const handleChange = (e) => {
        const newSort = e.target.value;
        setSort(newSort);
        handleFilterAndSort("sort", null, newSort);
    };
  
    // We'll conditionally style the dropdown based on whether a price sort is active
    const isActive = dropdownValue !== "";
  
    return (
        <div className={`relative flex items-center gap-2 pl-2 rounded-lg ${isActive ? "bg-purple text-white" : "bg-lightPurple text-purple"}`}>
            {/* Icon color changes based on isActive */}
            <SortSVG color={isActive ? "#ffffff" : "#876eaf"} height="18px" />
    
            <select
            className={`p-2 rounded-lg cursor-pointer appearance-none outline-none ${isActive ? "bg-purple text-white" : "bg-lightPurple text-purple"}`}
            value={dropdownValue}
            onChange={handleChange}
            >
            <option value="">Price Sort</option>
            <option value="LtoH">Low to High</option>
            <option value="HtoL">High to Low</option>
            </select>
        </div>
    );
}

// export const ListingSort = ({setSortingOpen, handleFilterAndSort, sort, setSort, sortingOpen}) => {
//     return (
//         <div onClick={() => setSortingOpen(false)} className={`fixed inset-0 z-50 h-[100vh] w-[100vw] flex justify-center lg:justify-end items-end transition-opacity duration-500 ${sortingOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} backdrop-blur-md`}>
//             <div onClick={(e) => e.stopPropagation()} className={`relative h-[70vh] w-full lg:h-full lg:w-1/3 right-0 bg-tertiary rounded-tl-[40px] rounded-tr-[40px] lg:rounded-tr-none lg:rounded-bl-[40px] flex flex-col text-lg p-8 transition-transform duration-700 transform ${sortingOpen ? 'translate-y-0 lg:translate-x-0' : 'translate-y-0 lg:translate-x-full'}`} style={{boxShadow: '0px 0px 10px gray'}}>
//                 <div onClick={() => setSortingOpen(false)} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">×</div>
                    
//                 <div className="font-semibold text-purple text-left mt-4  flex items-center gap-2">
//                     <SortSVG color='#876eaf' height='18px' />
//                     <div>SORT</div>
//                 </div>

//                     <div className={`mt-10 font-semibold text-2xl text-left pl-4`}>Price</div>
//                     <div className={`flex flex-col items-center`}>
//                         {/* <div onClick={() => {handleFilterAndSort('sort', null, 'LtoH'); sort === 'LtoH' ? setSort('') : setSort('LtoH') }} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'LtoH' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}> */}
//                         <div onClick={() => {sort === 'LtoH' ? setSort('') : setSort('LtoH')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'LtoH' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
//                             Lowest to Highest
//                         </div>
//                         {/* <div onClick={() => {handleFilterAndSort('sort', null, 'HtoL'); sort === 'HtoL' ? setSort('') : setSort('HtoL') }} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'HtoL' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}> */}
//                         <div onClick={() => {sort === 'HtoL' ? setSort('') : setSort('HtoL')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'HtoL' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
//                             Highest to Lowest
//                         </div>
//                     </div>

//                     <div className={`mt-4 font-semibold text-2xl text-left pl-4`}>Name</div>
//                     <div className={`flex flex-col items-center`}>
//                         {/* <div onClick={() => {handleFilterAndSort('sort', null, 'AtoZ') ;sort === 'AtoZ' ? setSort('') : setSort('AtoZ')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'AtoZ' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}> */}
//                         <div onClick={() => {sort === 'AtoZ' ? setSort('') : setSort('AtoZ')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'AtoZ' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
//                             A to Z
//                         </div>
//                         <div onClick={() => {sort === 'ZtoA' ? setSort('') : setSort('ZtoA')}} className={`w-[50vw] lg:w-[70%] rounded-md py-2 border-purple p-[1px] shadow-md mt-4 font-bold ${sort === 'ZtoA' ? 'text-tertiary bg-purple' : 'text-purple bg-tertiary'} cursor-pointer`}>
//                             Z to A
//                         </div>
//                     </div>
                

//                     <div className={`flex justify-end w-full gap-4 mt-10`}>
//                         <div 
//                             onClick={() => {setSortingOpen(false); handleFilterAndSort('sort', null, sort)}} 
//                             className={`px-4 py-2 rounded-md border-[1px] border-purple shadow-md text-lg font-semibold text-purple`}
//                         >
//                             Save
//                         </div>
//                         <div 
//                             onClick={() => {setSort(''); setSortingOpen(false);}} 
//                             className={`px-4 py-2 rounded-md border-[1px] border-purple shadow-md text-lg font-semibold text-purple`}
//                         >
//                             Cancel
//                         </div>
//                     </div>
//             </div>
//         </div>
//     )
// }