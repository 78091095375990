import { useEffect, useState } from "react";
import { availOfferInPrices } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
// import { Prices } from "./Prices";
import MembershipPlanTestA from "./MembershipPlanTestA";
import MembershipPlanTestB from "./MembershipPlanTestB";

const planName = {
    1: 'single',
    2: 'couple',
    3: 'family',
};

/**
  * Displays membership prices of the gym
  * @param {object}   currGymData            -   contains all the information about the gym  
  * @param {function} toggleSummaryModal     -   function to open the modal where we can add coupons, referral code and make payment
  * @param {function} setSelectedCategory    -   to set the category as single, couple, family (maybe unnecessary but currently needed for PaymentConfirmationModal)
  * @param {Object} offer                    -   The special Offer from the gym
  * @param {Object} userGender               -   Gender of the user; for offer purposes
  * @returns {JSX.component} component displaying membership prices
  */
const GymMembershipPrices = ({ USR, currGymData, toggleSummaryModal, setSelectedCategory, offer, userGender }) => {
    const [planType, ] = useState(1); // 1 is single, 2 is couple, 3 is family
    const [, setPrices] = useState({});
    const [testingType, setTestingType] = useState();

    // const hasCouple = currGymData ? currGymData?.prices?.couple && hasData(currGymData?.prices?.couple) : false;
    // const hasFamily = currGymData ? currGymData?.prices?.family && hasData(currGymData?.prices?.family) : false;

    /**
     * Checks whether the gym is offering a special offer
     * If yes, it updates the prices accordingly
     */
    useEffect(() => {
        if (offer) {
            const tempPrices = availOfferInPrices(currGymData.prices, offer, userGender);
            setPrices(tempPrices);
        } else {
            setPrices(currGymData.prices);
        }
    }, [currGymData, offer, userGender]);

    useEffect(() => {
        setSelectedCategory(planName[planType]);
    }, [planType, setSelectedCategory]);


    useEffect(() => {
        const assignTestingType = () => {
            const randomNumber = Math.floor(Math.random() * 100); // Generates a number between 0-99
            // console.log("RAND : ", randomNumber )
            setTestingType(randomNumber < 50 ? "A" : "B");
        };
        assignTestingType();
    }, [])

    return (
        <>
            <div className={`relative w-full h-fit  text-lg flex justify-center items-end mt-8 lg:mt-16`}> 
                {/* {prices?.single && (
                    <Prices 
                        USR={USR}
                        currGymData={{
                            country             : currGymData.country,
                            isExclusive         : currGymData.isExclusive,
                            gymName             : currGymData.gymName,
                            city                : currGymData.city,
                            commissionPercentage: currGymData.commissionPercentage,
                            freeTrial           : currGymData.freeTrial
                        }}
                        prices={prices}
                        planType={planType}
                        setPlanType={setPlanType}
                        hasCouple={hasCouple}
                        hasFamily={hasFamily}
                        toggleSummaryModal={toggleSummaryModal}
                    />
                )} */}

                {/* {console.log("SSSS : ", testingType)} */}
                {testingType === 'A' ?
                <MembershipPlanTestA 
                    currGymData={{
                        country             : currGymData.country,
                        isExclusive         : currGymData.isExclusive,
                        gymName             : currGymData.gymName,
                        city                : currGymData.city,
                        commissionPercentage: currGymData.commissionPercentage,
                        freeTrial           : currGymData.freeTrial,
                        prices              : currGymData.prices,
                    }}
                    toggleSummaryModal={toggleSummaryModal}
                />
                : 
                <MembershipPlanTestB 
                    currGymData={{
                        country             : currGymData.country,
                        isExclusive         : currGymData.isExclusive,
                        gymName             : currGymData.gymName,
                        city                : currGymData.city,
                        commissionPercentage: currGymData.commissionPercentage,
                        freeTrial           : currGymData.freeTrial,
                        prices              : currGymData.prices,
                    }}
                    toggleSummaryModal={toggleSummaryModal}
                />
                }

                {/* <div className={`z-[2] absolute -top-3 lg:-top-2 left-8 rounded-2xl h-[10vh] w-[84%] ${USR >= 1.5 ? 'lg:left-28' : 'lg:left-28'} rotate-6 lg:rotate-[4deg] bg-lightPurple`}></div>
                {hasFamily && <div className={`z-[1] absolute -top-3 lg:-top-2 left-8 rounded-2xl h-[10vh] w-[84%] ${USR >= 1.5 ? 'lg:left-28' : 'lg:left-28'} -rotate-6 lg:-rotate-[4deg] bg-lightSecondary`}></div>}

                <div className={`absolute bottom-0 left-[45%]  lg:left-[48.5%] flex gap-2`}> 
                    {(hasCouple || hasFamily) && <div className={`h-2 w-2 rounded-full ${planType === 1 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                    {hasCouple && <div className={`h-2 w-2 rounded-full ${planType === 2 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                    {hasFamily && <div className={`h-2 w-2 rounded-full ${planType === 3 ? 'bg-primary' : 'bg-secondary'}`}></div>}
                </div> */}
            </div>
        </>
    );
};

export default GymMembershipPrices;

// function hasData(prices) {
//     return Object.values(prices).some(value => value !== "");
// }
