/**
 * Gets The Content of the email which needs to be sent to the user
 */

import { buyingDietAndFitnessPlan } from "../../assets/Messages/FitnessPlanEmail";
import { clientConfirmationEmail, generateContactFormResponse } from "../../assets/Messages/MembershipConfirmationEmail"
import { signUpEmail } from "../../assets/Messages/SignUpEmail";

export const getEmailContent = (type, data= {}, member = {}) => {
    switch (type) {
        case 'getInTouch' :
            return generateContactFormResponse(data.userName);

        case 'signUp' :
            return signUpEmail(data);

        case 'fitnessPlan' :
            return buyingDietAndFitnessPlan(data.userName);

        default : 
            return clientConfirmationEmail(member, data);
    }
}