import { useState } from "react";
import { motion } from "framer-motion";
import TR_Logo from "./../../assets/Website_content/TR_Website_content-14.svg";
import { Cards } from "./Cards";
import { submitForm } from './SubmitForm';
import { useNavigate } from "react-router-dom";

// Plans object defining features available in each plan
const plans = {
    Basic: ["1hr Fitness Consultation", "1% Cashback up to ₹250"],
    Pro: ["1hr Fitness Consultation", "1% Cashback up to ₹250", "Personalized Diet Plans", "Personalized Exercise Plans", "Cancellation", "Freezing"],
    Premium: ["1hr Fitness Consultation", "1% Cashback up to ₹250", "Personalized Diet Plans", "Personalized Exercise Plans", "Wider Cancellation", "Wider Freezing", "Access to Exercise Videos", "Flexible Memberships", "Train Rex Goodies"]
};

const team = [
    'Anjali',
    'Arjun',
    'Arnav',
    'Ashish',
    'Divyansh',
    'Ishita',
    'Kajal',
    'Khushi',
    'Palash',
    'Mukund',
    'Waseem',
    'Yash'
];

// Array of questions for the feedback form
const questions = [
    { label: "Email", type: "text", key: "email" },
    { label: "Gender", type: "select", key: "gender", options: ["Male", "Female", "Other"] },
    { label: "Age", type: "number", key: "age" },
    { label: "Profession / Occupation?", type: "text", key: "profession" },
    { label: "On a scale of 1-5 how much did you like our services?", type: "scale", key: "serviceRating" },
    { label: "On a scale of 1-5 how much did you find them useful?", type: "scale", key: "usefulnessRating" },
    { label: "If you were a customer, which plan would you choose?", type: "plan", key: "plan", options: ["Basic", "Pro", "Premium"] },
    { label: "Which feature of the plan was most useful to you?", type: "select", key: "mostUsefulFeature", options: [] },
    { label: "Which feature of the plan was least useful to you?", type: "select", key: "leastUsefulFeature", options: [] },
    { label: "What else would you like to have on your gym membership?", type: "text", key: "additionalFeature" },
    { label: "Who assisted you at the counter?", type: "select", key: "assistant", options: team },
    { label: "Submit your feedback", type: "submit", key: "submit" }
];

export default function FeedbackForm() {
    const [formData, setFormData] = useState({});
    const [step, setStep] = useState(0);
    const [loadingSubmit , setLoadingSubmit] = useState(false);
    const navigate = useNavigate();

    // Function to handle input change and update state
    const handleChange = (key, value) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    // Function to navigate to the next question
    const nextStep = () => {
        if (formData[questions[step].key]) {
            setStep(step + 1);
        }
    };

    // Function to navigate to the previous question
    const prevStep = () => {
        if (step > 0) setStep(step - 1);
    };

    const resetForm = () => {
        setLoadingSubmit(false);
        setFormData({});
    }

    const handleSubmit = async() => {
        setLoadingSubmit(true);
        if (Object.keys(formData).length === questions.length - 1) {
            const res = await submitForm(formData)
            if (res) {
                alert("Thank you for your feedback!");
                resetForm();
                navigate('/');
            }
            else 
                alert('Error! Please try again later.');
                setLoadingSubmit(false);
        }
    };

    // Get the current question based on step index
    const currentQuestion = questions[step];
    const isAnswered = formData[currentQuestion.key];

    return (
        <div className="flex flex-col justify-center items-center h-[90vh] lg:h-[100vh] bg-tertiary text-left p-4 relative">
            {/* Logo at the top left */}
            <img src={TR_Logo} alt="Train Rex Logo" className="absolute top-5 left-5 w-42 h-fit" />
            
            <motion.div
                key={step}
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -100, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="w-full max-w-2xl"
            >
                {/* Display question number */}
                <h2 className="text-primary text-xl mb-2">{step + 1} → {currentQuestion.label}</h2>
                
                {/* Render input fields based on question type */}
                {currentQuestion.type === "plan" ? (
                    <Cards selectedPlan={formData.plan} selectPlan={(plan) => handleChange("plan", plan)} />
                ) : currentQuestion.type === "text" || currentQuestion.type === "number" ? (
                    <input
                        type={currentQuestion.type}
                        value={formData[currentQuestion.key] || ""}
                        onChange={e => handleChange(currentQuestion.key, e.target.value)}
                        className="w-full p-3 border-b-2 border-purple rounded-3xl bg-transparent shadow-lg text-xl focus:outline-none"
                    />
                ) : currentQuestion.type === "select" ? (
                    <select
                        value={formData[currentQuestion.key] || ""}
                        onChange={e => handleChange(currentQuestion.key, e.target.value)}
                        className="w-full p-3 border-b-2 rounded-3xl border-purple bg-transparent shadow-lg text-xl focus:outline-none"
                    >
                        <option value="" disabled>Select an option</option>
                        {(currentQuestion.key === "mostUsefulFeature" || currentQuestion.key === "leastUsefulFeature") && formData.plan ? (
                            plans[formData.plan].map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))
                        ) : (
                            currentQuestion.options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))
                        )}
                    </select>
                ) : currentQuestion.type === "scale" ? (
                    <div className="flex flex-col space-y-4">
                        <div className="flex justify-between text-sm text-purple">
                            <span>Least Useful</span>
                            <span>Most Useful</span>
                        </div>
                        <div className="flex justify-between">
                            {[...Array(5)].map((_, i) => (
                                <label key={i} className="flex flex-col items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        name={currentQuestion.key}
                                        value={i + 1}
                                        checked={formData[currentQuestion.key] === (i + 1).toString()}
                                        onChange={() => handleChange(currentQuestion.key, (i + 1).toString())}
                                        className="hidden"
                                    />
                                    {i + 1}
                                </label>
                            ))}
                        </div>
                    </div>
                ) : currentQuestion.type === "submit" ? (
                    loadingSubmit ? (
                        <button className="w-full p-3 bg-purple text-white rounded-3xl shadow-lg text-xl focus:outline-none">
                            Submitting
                        </button>
                    ) : (
                        <button onClick={handleSubmit} className="w-full p-3 bg-purple text-white rounded-3xl shadow-lg text-xl focus:outline-none">
                            Submit Feedback
                        </button>
                    )
                ) : null}
            </motion.div>
            
            {/* Navigation buttons */}
            <div className="absolute bottom-10 left-0 right-0 flex justify-between px-10">
                {step > 0 && <button onClick={prevStep} className="text-secondary text-xl">Prev</button>}
                <button onClick={step < questions.length - 1 ? nextStep : handleSubmit} disabled={!isAnswered} className="text-purple text-xl ml-auto">
                    {step < questions.length - 1 ? "Next" : ""}
                </button>
            </div>
        </div>
    );
}
