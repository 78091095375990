import convertDriveUrl from "../../helperFunctions/imgUrlRecontuct";
import { useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

/**
 * Displays images of the gyms
 * @param {array} Portfolio - contains all the images of the gyms as links 
 * @returns {JSX.component} images slide show with user control of slides
 */
const GymPortfolio = ({ Portfolio }) => {
    const containerRef = useRef(null); // To reference the swipe container
    const [dragStart     ,      setDragStart] = useState(null); // To track touch start position
    const [dragOffset    ,     setDragOffset] = useState(0); // To track current drag offset
    const [currImageIndex, setCurrImageIndex] = useState(0);

    if (!Portfolio || Portfolio?.length === 0 || Portfolio === '<url>') {
        return <></>;
    }

    const goToNextImage = () => {
        setCurrImageIndex((prev) => (prev === Portfolio?.length - 1 ? 0 : prev + 1));
    };

    const goToPrevImage = () => {
        setCurrImageIndex((prev) => (prev === 0 ? Portfolio?.length - 1 : prev - 1));
    };

    const handleTouchStart = (e) => {
        setDragStart(e.touches[0].clientX); // Record touch start position
        setDragOffset(0); // Reset drag offset
    };

    const handleTouchMove = (e) => {
        if (dragStart !== null) {
            const currentTouch = e.touches[0].clientX;
            const offset = currentTouch - dragStart;
            setDragOffset(offset); // Update drag offset
        }
    };

    const handleTouchEnd = () => {
        if (dragOffset > 50) {
            // Swipe right (previous image)
            goToPrevImage();
        } else if (dragOffset < -50) {
            // Swipe left (next image)
            goToNextImage();
        }

        setDragStart(null);
        setDragOffset(0); // Reset drag offset
    };

    return (
        <div className="relative w-full overflow-hidden">
            <div
                className="flex items-center transition-transform duration-500 ease-in-out rounded-2xl overflow-hidden"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div 
                    ref={containerRef} 
                    style={{
                        transform: `translateX(calc(${-currImageIndex * 100}% + ${dragOffset}px))`,
                    }}
                    className="bg-gray-900 rounded-2xl h-fit flex items-center transition-transform duration-500 ease-in-out">
                    {Portfolio.map((image, index) => (
                        <img
                            key={index}
                            src={convertDriveUrl(image)}
                            alt="gym"
                            className="h-96 w-full object-contain bg-gray-900 flex-shrink-0"
                            style={{ width: "100%" }}
                        />
                    ))}
                </div>
            </div>

            {/* Left Button */}
            <button
                onClick={goToPrevImage}
                className="hidden lg:flex p-3 justify-center items-center bg-complementPurple text-tertiary text-xl font-bold absolute rounded-full bottom-1 lg:bottom-2 left-2 hover:rotate-[-360deg] hover:lg:scale-[135%] transition-all duration-700"
            >
                <ChevronLeft />
            </button>

            {/* Right Button */}
            <button
                onClick={goToNextImage}
                className="hidden lg:flex p-3 justify-center items-center bg-complementPurple text-tertiary text-xl font-bold absolute rounded-full bottom-1 lg:bottom-2 right-2 hover:rotate-[360deg] hover:lg:scale-[135%] transition-all duration-700"
            >
                <ChevronRight />
            </button>

            {/* Navigation Dots */}
            {Portfolio?.length > 1 && (
                <div className="flex justify-center items-center mt-4 space-x-2 absolute bottom-4 w-full">
                    {Array.from({ length: 5 }).map((_, index) => {
                        let totalImages = Portfolio?.length;
                        let startIndex = Math.max(0, Math.min(currImageIndex - 2, totalImages - 5));

                        let dotIndex = startIndex + index;

                        return (
                            <button
                                key={dotIndex}
                                onClick={() => setCurrImageIndex(dotIndex)}
                                className={`h-2 w-2 lg:h-3 lg:w-3 rounded-full transition-all duration-300 ${
                                    dotIndex === currImageIndex
                                        ? 'bg-complementPurple scale-150'
                                        : 'bg-gray-400'
                                }`}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default GymPortfolio;
