import { CalendarIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import { themeColors } from "../../theme";
import SelectDate from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/SelectDate";
import { convertTo12HourFormat } from "../../helperFunctions/basicHelper";

export const SelectDateAndTimings = ({
    paymentDetails, 
    selectedDate, 
    setSelectedDate, 
    highlightedDates, 
    setAvailableClasses, 
    setIsClassAvailable, 
    setSelectedShift,
    isClassAvailable,
    availableClasses,
    selectedShift,
    showDatePicker,
    setShowDatePicker
}) => {

    /**
     * Handles the selected date from the SelectDate component.
     * @param {string} date - The selected date in 'DD-MM-YYYY' format.
     */
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // Date change for studios
    const handleDateSelect = (date) => {
        console.log(0, date);
        setSelectedDate(date);
    
        // Extract the day name (e.g., "Monday")
        const selectedDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
    
        // Get classes scheduled for the selected day (avoid undefined errors)
        const classesOnDay = paymentDetails?.receiptInfo?.classesSchedule?.[selectedDay] || {};
    
        // Convert classes into an array with className and timings
        const classDetails = Object.entries(classesOnDay).map(([className, timings]) => ({
            className,
            timings,
        }));
    
        // If a className is selected, filter the results
        const filteredClasses = paymentDetails?.receiptInfo?.className 
            ? classDetails.filter(cls => cls.className === paymentDetails.receiptInfo.className) 
            : classDetails; // If no className is selected, show all available classes
    
        // console.log(1, filteredClasses);
        setAvailableClasses(filteredClasses);
        setIsClassAvailable(filteredClasses.length > 0);
        setSelectedShift(''); // Reset shift selection
        setShowDatePicker(false);
    };

    // Closes the date picker modal.
    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    return (
        <div className="flex flex-col rounded-xl px-4 pt-2 pb-8 lg:p-8 shadow-md" style={{boxShadow : '0 0 2px 1px lightGray'}}>
            <div className="lg:hidden text-left text-4xl font-bold text-purple">{paymentDetails?.gymData?.gymDisplayName || paymentDetails?.gymData?.studioDisplayName}</div>
            <div className="lg:hidden text-left font-semibold text-secondary">{paymentDetails?.gymData?.address},</div>
            <div className="lg:hidden text-left font-semibold text-secondary mb-4">{paymentDetails?.gymData?.locality}</div>

            {/* The green box to select start date */}
            <div className={`bg-[#CFE0C4] rounded-full px-3 py-2 lg:p-4 flex justify-between`}>
                <div className='flex items-center gap-2'>
                    <CalendarIcon className="text-[2vw] text-purple"/>
                    <div className={`font-semibold text-sm lg:text-xl text-purple`}>{paymentDetails?.receiptInfo.months > 0 ? `${paymentDetails?.receiptInfo.months} Months` : '1 Day'} | </div>
                    <div className="font-semibold text-sm lg:text-xl text-purple">
                        {paymentDetails?.receiptInfo?.className
                        ? <p>{selectedDate.toLocaleDateString('en-GB')}</p> 
                        : selectedDate}
                    </div>
                    {/* <div className='font-semibold text-sm lg:text-xl text-purple'>Select a Start Date</div> */}
                </div>
                {/* Start Date Select Button */}
                <button onClick={() => setShowDatePicker(!showDatePicker)} className='text-sm text-logoGray font-bold flex items-center gap-1'>
                    <CalendarIcon className="w-4 h-4 lg:w-6 lg:h-6 relative " color={themeColors.purple} />
                </button>

                {/* Date Picker Modal */}
                {showDatePicker && (
                    paymentDetails?.receiptInfo?.className ?
                    <div className='fixed top-[44vw] right-14 lg:top-[17vw] lg:right-[21vw] z-40'>
                        <DatePicker
                            selected={new Date(selectedDate)}
                            onChange={(date) => {
                                handleDateSelect(date); // Update the selected date
                            }}
                            inline
                            calendarClassName="absolute top-0 left-0 bg-secondary text-primary border-complementPurple border-2 rounded-lg shadow-lg"
                            dayClassName={(date) => {
                                const isHighlighted = highlightedDates.some(
                                    (highlightedDate) =>
                                        date.getDate() === highlightedDate.getDate() &&
                                        date.getMonth() === highlightedDate.getMonth() &&
                                        date.getFullYear() === highlightedDate.getFullYear()
                                );
                                    
                                return isHighlighted
                                    ? "custom-day bg-complementPurple text-white rounded-full"
                                    : "custom-day text-gray-800 hover:bg-primary hover:text-white rounded-full";
                            }}
                            headerClassName="custom-header bg-tertiary text-white font-bold py-2 rounded-t-lg"
                        />
                    </div>
                    : 
                    <SelectDate
                        onClose={handleCloseDatePicker}
                        onDateSelect={handleDateChange}
                        initialDate={selectedDate}
                    />
                )}
            </div>

            {/* Timings selection for classes */}
            <div>
                {paymentDetails?.receiptInfo?.className && !isClassAvailable ? (
                <p className="mt-1 text-sm">No {paymentDetails?.receiptInfo?.className} classes are scheduled on this day.</p>
                ) : (
                    availableClasses.map((cls, index) => (
                        <div key={index} className="flex gap-1 mt-1 items-center text-sm ">
                            <p className="font-semibold ">Select timing:</p>
                            <div className="flex gap-2">
                                {cls.timings.map((time, idx) => (
                                    <button
                                        key={idx}
                                        onClick={() => setSelectedShift(convertTo12HourFormat(time))}
                                        className={`px-3 rounded ${
                                            selectedShift === convertTo12HourFormat(time)
                                                ? 'bg-primary text-white'
                                                : 'bg-gray-200'
                                        }`}
                                    >
                                        {convertTo12HourFormat(time)}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div className={`mt-2 text-sm lg:text-xl text-left`}>Click to change your preferred start date</div>
        </div>
    )
}