import React, { useState, useEffect, useCallback } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import Loader from "../../Screens/Marketplace2025/Loader";

/**
 * ExercisePlan Component
 *
 * Displays the user's daily workout routine along with a video carousel for selected exercise types.
 * Allows users to switch between gym and home workout contexts and browse categorized exercises.
 * Fetches exercise videos and thumbnails from Firebase Storage, with caching and lazy-loading support.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.weeklyPlan - The full weekly fitness plan, including workout details.
 * @param {React.Ref} ref1 - Forwarded ref for the section element.
 *
 * @returns {JSX.Element} Section displaying workout table and a scrollable video preview interface.
 */
const ExercisePlan = React.forwardRef(({ weeklyPlan }, ref1) => {
    // State for exercise videos, selected type, location, and currently playing video
    const [exerciseVideos, setExerciseVideos] = useState([]);
    const [selectedExerciseType, setSelectedExerciseType] = useState("cardio");
    const [exerciseLocation, setExerciseLocation] = useState("gym");
    const [playingVideo, setPlayingVideo] = useState("");
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [cachedVideos, setCachedVideos] = useState({});

    // Optimized fetch function with caching
    const fetchExerciseVideos = useCallback(async () => {
        const cacheKey = `${selectedExerciseType}_${exerciseLocation}`;
        
        // Check if we already have this data cached
        if (cachedVideos[cacheKey]) {
            setExerciseVideos(cachedVideos[cacheKey]);
            return;
        }
        
        setIsVideoLoading(true);
        
        try {
            const exerciseRef = ref(storage, `exercises/${selectedExerciseType}/${exerciseLocation}`);

            // List all subfolders
            const result = await listAll(exerciseRef);
            
            // Process folders in parallel but limit concurrency to 3 at a time
            const videoData = [];
            const chunks = [];
            
            // Split into chunks of 3 for processing
            for (let i = 0; i < result.prefixes.length; i += 3) {
                chunks.push(result.prefixes.slice(i, i + 3));
            }
            
            // Process each chunk sequentially
            for (const chunk of chunks) {
                const chunkResults = await Promise.all(chunk.map(async (folderRef) => {
                    // List all files in each exercise folder
                    const files = await listAll(folderRef);

                    // Find video and thumbnail files
                    const videoFile = files.items.find((item) => item.name.endsWith(".mp4"));
                    const thumbnailFile = files.items.find((item) => item.name.endsWith(".png"));

                    // Only fetch the thumbnail URL immediately, defer video URL fetching
                    const thumbnailUrl = thumbnailFile ? await getDownloadURL(thumbnailFile) : null;
                    
                    return {
                        name: folderRef.name,
                        folderRef, // Store reference for lazy loading
                        videoFile, // Store reference for lazy loading
                        videoUrl: null, // Will be fetched on demand
                        thumbnailUrl,
                    };
                }));
                
                videoData.push(...chunkResults);
            }
            
            // Update state and cache
            setExerciseVideos(videoData);
            setCachedVideos(prev => ({
                ...prev,
                [cacheKey]: videoData
            }));
        } catch (error) {
            console.error("Error fetching exercise videos:", error);
        } finally {
            setIsVideoLoading(false);
        }
    }, [selectedExerciseType, exerciseLocation, cachedVideos]);

    // Lazy load video URL when user hovers or clicks
    const loadVideoUrl = useCallback(async (index) => {
        if (exerciseVideos[index] && !exerciseVideos[index].videoUrl && exerciseVideos[index].videoFile) {
            try {
                const videoUrl = await getDownloadURL(exerciseVideos[index].videoFile);
                const updatedVideos = [...exerciseVideos];
                updatedVideos[index] = {
                    ...updatedVideos[index],
                    videoUrl
                };
                setExerciseVideos(updatedVideos);
                
                // Update cache
                const cacheKey = `${selectedExerciseType}_${exerciseLocation}`;
                setCachedVideos(prev => ({
                    ...prev,
                    [cacheKey]: updatedVideos
                }));
            } catch (error) {
                console.error("Error fetching video URL:", error);
            }
        }
    }, [exerciseVideos, selectedExerciseType, exerciseLocation]);

    // Fetch videos whenever exercise type or location changes
    useEffect(() => {
        fetchExerciseVideos();
    }, [fetchExerciseVideos]);
    // If the plan data is not yet available, show the loader.
    if (!weeklyPlan) {
        return <Loader label="Loading your exercise plan" />;
    }
  
    // console.log("Exercise plan page", weeklyPlan);
    const workout = weeklyPlan?.workout || {};
    // console.log("Workout", workout);
    return (
        <section ref={ref1} className="pt-4">
            <h2 className="text-xl font-semibold text-primary">Exercise Plan</h2>
            {Array.isArray(workout) && workout.length > 0 ? (
                <div className="p-4 rounded-lg">
                    {/* Display workout details in a table */}
                    <table className="w-full rounded-2xl border bg-lightTertiary">
                        <thead className="bg-secondary text-white rounded-2xl">
                            <tr>
                                <th className="p-2 text-left border border-gray-300">Activity</th>
                                <th className="p-2 text-left border border-gray-300">Type</th>
                                <th className="p-2 text-left border border-gray-300">Duration</th>
                                <th className="p-2 text-left border border-gray-300">Calories Burned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(workout) ? (
                                workout.map((exercise, index) => (
                                    <tr key={index}>
                                        <td className="p-2 border border-gray-300">{exercise.activity ? exercise.activity : exercise.exercise ? exercise.exercise : "N/A"}</td>
                                        <td className="p-2 border border-gray-300">{exercise.workoutType ? exercise.workoutType : exercise.sets && exercise.reps ? `${exercise.sets} sets for ${exercise.reps} reps` : "N/A"}</td>
                                        <td className="p-2 border border-gray-300">{exercise.duration || "N/A"} </td>
                                        <td className="p-2 border border-gray-300">{exercise.caloriesBurned ? exercise.caloriesBurned : exercise.calories ? exercise.calories : "N/A"} kcal</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="p-2 border border-gray-300">{workout.activity ? workout.activity : workout.exercise ? workout.exercise : "N/A"}</td>
                                    <td className="p-2 border border-gray-300">{workout.workoutType ? workout.workoutType : workout.sets && workout.reps ? `${workout.sets} sets for ${workout.reps} reps` : "N/A"}</td>
                                    <td className="p-2 border border-gray-300">{workout.duration ? workout.duration : "N/A"} </td>
                                    <td className="p-2 border border-gray-300">{workout.caloriesBurned? workout.caloriesBurned : workout.calories ? workout.calories : "N/A"} kcal</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Exercise type selector and location toggle */}
                    <div className="flex justify-between items-center my-4">
                        <select
                            value={selectedExerciseType}
                            onChange={(e) => setSelectedExerciseType(e.target.value)}
                            className="border p-2 rounded-lg"
                        >
                            {[
                                "abs",
                                "back",
                                "biceps",
                                "cardio",
                                "chest",
                                "legs",
                                "pull",
                                "push",
                                "shoulders",
                                "triceps",
                            ].map((type) => (
                                <option key={type} value={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </option>
                            ))}
                        </select>
                        <div>
                            <button
                                onClick={() => setExerciseLocation("home")}
                                className={`px-4 py-2 rounded-lg ${
                                    exerciseLocation === "home" ? "bg-secondary text-white" : "bg-gray-200"
                                }`}
                            >
                                Home
                            </button>
                            <button
                                onClick={() => setExerciseLocation("gym")}
                                className={`ml-2 px-4 py-2 rounded-lg ${
                                    exerciseLocation === "gym" ? "bg-secondary text-white" : "bg-gray-200"
                                }`}
                            >
                                Gym
                            </button>
                        </div>
                    </div>

                    {/* Video carousel */}
                    <div className="relative overflow-x-auto">
                        {isVideoLoading ? (
                            <div className="flex items-center justify-center h-48">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                            </div>
                        ) : exerciseVideos.length > 0 ? (
                            <div className="flex overflow-x-auto space-x-4 w-fit pb-4">
                                {exerciseVideos.map((video, index) => (
                                    <div 
                                        key={index} 
                                        className="relative group min-w-[280px] max-w-[300px]"
                                        onMouseEnter={() => loadVideoUrl(index)}
                                    >
                                        {playingVideo === video.videoUrl ? (
                                            <video
                                                controls
                                                autoPlay
                                                className="w-full h-48 object-cover rounded-lg shadow-md"
                                                src={video.videoUrl}
                                                onEnded={() => setPlayingVideo("")}
                                            />
                                        ) : (
                                            <>
                                                <img
                                                    src={video.thumbnailUrl}
                                                    alt={`Thumbnail for ${video.name}`}
                                                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer"
                                                    loading="lazy"
                                                />
                                                <div
                                                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition"
                                                    onClick={() => {
                                                        if (video.videoUrl) {
                                                            setPlayingVideo(video.videoUrl);
                                                        } else {
                                                            loadVideoUrl(index).then(() => {
                                                                if (exerciseVideos[index].videoUrl) {
                                                                    setPlayingVideo(exerciseVideos[index].videoUrl);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-12 w-12 text-white"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.548-5.106l5.034-3.066a.75.75 0 000-1.268l-5.034-3.066A.75.75 0 007.25 6.75v6.5a.75.75 0 001.202.644z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-600">No videos available for this category and location.</p>
                        )}
                    </div>
                </div>
            ) : (
                <p className="text-gray-600">No exercise plan available for today.</p>
            )}
        </section>
    );
});

export default ExercisePlan;
