import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { getDate } from "../../basicHelper";

/**
 * SelectDate Component
 * Displays only the calendar for date selection.
 *
 * @param {Function} onDateSelect - Callback function to handle the selected date.
 * @param {Function} onClose - Function to close the calendar.
 * @param {string} initialDate - Initial date in 'DD-MM-YYYY' format.
 */
const SelectDate = ({ onDateSelect, onClose, initialDate = getDate() }) => {
    /**
     * Handles the selection of a date.
     * Passes the selected date to the parent component and closes the calendar.
     *
     * @param {Date} date - Selected date.
     */
    const handleDateChange = (date) => {
        const formattedDate = format(date, "dd-MM-yyyy"); // Format the selected date
        onDateSelect(formattedDate); // Pass the formatted date to the parent
        onClose(); // Close the calendar
    };

    return (
        <div className="absolute bg-white shadow-lg rounded-lg p-2 z-50" style={{ maxHeight: '80vh', overflowY: 'auto', top: '27%', left: '43%', transform: 'translateX(-50%)' }}>
            {/* Calendar Component */}
            <DatePicker
                selected={parse(initialDate, "dd-MM-yyyy", new Date())}
                onChange={handleDateChange}
                inline
                calendarClassName="custom-calendar bg-secondary text-primary border-complementPurple border-2 rounded-lg shadow-lg"
                dayClassName={(date) => "custom-day text-gray-800 hover:bg-primary hover:text-white rounded-full"}
                headerClassName="custom-header bg-tertiary text-white font-bold py-2 rounded-t-lg"
            />
        </div>
    );
};

export default SelectDate;
