/**
 * displayPriceFormat.js
 * 
 * This file formats the prices of the fitness centers, so
 * There is a difference between mrp and selling price
 */

import { roundOffLogic } from "../gymDataHelpers/PriceHelpers"

/**
 * Formats Fitness Center Prices
 * 
 * @param {Object} prices       -   The prices to be checked and formatted : sellingPrice and mrp fields
 * @returns {Object}            -   New Formatted prices
 */
export const formatPrices = (prices) => {
    // Check if there is a difference of 10% within 
    // sellingPrice and mrp
    if (
        prices.sellingPrice * 1.1   >   prices.mrp
    ) // If yes, increase the mrp by 10%
        return ({
            sellingPrice    :   prices.sellingPrice,
            mrp             :   roundOffLogic(prices.mrp * 1.1)
        })
    else 
        return ({
            sellingPrice    :   prices.sellingPrice,
            mrp             :   roundOffLogic(prices.mrp)
        })
}