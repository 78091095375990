import TickForPlanPrimary from '../../assets/Website_content/TickForPlanPrimary.svg';
import TickForPlanPurple from '../../assets/Website_content/TickForPlanPurple.svg';
import TickForPlanWhite from '../../assets/Website_content/TickForPlanWhite.svg';
import Basic from '../../assets/Website_content/Basic.svg';
import Pro from '../../assets/Website_content/Pro.svg';
import Premium from '../../assets/Website_content/premium.svg';

export const plans = [
    {
        id: 0,
        name: "Basic",
        image: Basic,
        benefits: ["Gym Membership", "1hr Fitness Consultation", "1% Cashback up to ₹250"],
        textColor: "primary"
    },
    {
        id: 1,
        name: "Pro",
        image: Pro,
        benefits: ["Basic Plan Perks", "Personalized Diet Plans", "Personalized Exercise Plans", "Cancellation", "Freezing"],
        textColor: "purple"
    },
    {
        id: 2,
        name: "Premium",
        image: Premium,
        benefits: ["Medium Plan Perks", "Wider Cancellation", "Wider Freezing", "Access to Exercise Videos", "Flexible Memberships", "Train Rex Goodies"],
        textColor: "white"
    }
];

export const Cards = ({ selectedPlan, selectPlan }) => {
    return (
        <div className="flex gap-2 lg:gap-[4vw] overflow-x-scroll overflow-y-visible no-scrollbar lg:overflow-visible max-w-[100vw] px-5 lg:px-0 lg:mt-0 ">
            {plans.map((plan) => (
                <div 
                    key={plan.id}
                    onClick={() => selectPlan(plan.name)}
                    className={`relative font-bold lg:mt-0 px-4 py-8 text-${plan.textColor} rounded-3xl overflow-hidden flex justify-center cursor-pointer min-h-[50vh] min-w-[80vw] lg:min-h-[48vh] lg:min-w-[20vw] transition-all duration-500 border-4 ${
                        selectedPlan === plan.name ? 'border-purple' : 'border-transparent'
                    }`}
                >
                    <img src={plan.image} alt={plan.name} className="absolute top-0 left-0 min-h-full min-w-full object-cover"/>
                    <div className="flex flex-col justify-between mt-2 lg:mt-[8%] relative">
                        <div className="text-4xl lg:text-[3vw]">{plan.name}</div>
                        <div className="flex flex-col gap-4 mt-8 lg:mt-[2vw]">
                            {plan.benefits.map((benefit, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <img src={plan.id === 0 ? TickForPlanPrimary : plan.id === 1 ? TickForPlanPurple : TickForPlanWhite} alt='tick' className='h-6 w-6 lg:h-[1.8vw] lg:w-[1.8vw]' />
                                    <div className="font-semibold text-[4.6vw] lg:text-[1vw]">{benefit}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};