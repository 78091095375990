import { useContext, useEffect, useState } from "react";
import { fetchAllBlogs } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { removeSpacesAndConvertLowercase } from "../../helperFunctions/basicHelper";
import { useNavigate } from "react-router-dom";
import { GymContext } from "../../hooks/FetchEverythingContext";
const TRLogo = 'https://media.istockphoto.com/id/1313456479/photo/man-and-soul-yoga-lotus-pose-meditation-on-nebula-galaxy-background.jpg?s=612x612&w=0&k=20&c=jJ0pVed-sHjDBtomrO7KmR4qtIfH8OaNhjmEsXvJmAI=';


export const NewBlogs = ({USR}) => {
    const {isMobile} = useContext(GymContext)
    const [blogList, setBlogList] = useState([]);
    const [loading ,  setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchBlogs = async () => {
            const blogsList = await fetchAllBlogs();
            setBlogList(blogsList);
            setLoading(false);
        };
        fetchBlogs();
    }, []);

    if(loading) {
        return (
            <div className="px-6 flex w-full h-60 justify-center items-center">
                <div className="text-purple font-bold text-center text-3xl">Scroll through easy reads, clear your fitness doubts!</div>
            </div>
        )
    }
    
    return (
        <div className="flex flex-col">
            <div className="text-3xl font-semibold text-secondary px-6">Blogs</div>

            <div className={`flex gap-4 lg:gap-6 w-[100vw] lg:w-full overflow-x-scroll ${isMobile ? 'no-scrollbar' : ''} px-6 mt-8`}>
                {blogList.map((blog, index) => (
                    <BlogCard key={index} blog={blog}/>
                ))}
            </div>
        </div>
    )
}

const BlogCard = ({index, blog}) => {
    const navigate = useNavigate();
    const handleNavigate = (blog) => {
        const blogSlug = removeSpacesAndConvertLowercase(blog?.gymDisplayName);
        navigate(`/blogs/${blogSlug}`, { state: blog });
    };

    return (
        <div onClick={() => handleNavigate(blog)} key={index} className="flex flex-col min-w-60 lg:min-w-[24vw] cursor-pointer">
            <img src={blog?.image || TRLogo} alt='blog' className="h-80 lg:h-[30vw] w-full rounded-3xl overflow-hidden object-cover" />
            <div className="font-semibold mt-8 lg:text-lg">{blog.locality}</div>
            <div className="mt-2 lg:text-lg" style={{ fontFamily: '"League Spartan", sans-serif' }}>{`${blog.gymDisplayName.slice(0, 58)} ${blog.gymDisplayName?.length > 58 ? '...' : ''}`}</div>
        </div>
    )
}