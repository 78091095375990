import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { ListCard } from "../../helperFunctions/MarketplaceHelpers/ButtonsLibrary";
import "../../index.css";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
// import Map from "../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Mapbox";
// import Sidebar from "../../components/resultPage/Sidebar";
import { getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import { calculateDistance } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { GymContext } from "../../hooks/FetchEverythingContext";
import { ArrowLeft } from "lucide-react";
import Alert from "../../components/customComponents/Alert";
import { trackNavigation } from "../../helperFunctions/Analytics/AnalyticsHelper";
import { ListingFilter, PriceSortDropdown } from "../../components/ListingPageComponents.js/Components";
import { FilterSVG, SortSVG } from "../../assets/Website_content/svg";

/**
 * Listing Component
 * 
 * @component
 * 
 * This component is responsible for displaying a list of gyms for a selected city. 
 * It fetches gym data either from the navigation state (if available) or from 
 * the database using the `fetchAllGyms` function. It includes pagination for gym listings, 
 * a map view, and SEO using `Helmet`.
 * 
 * @returns {JSX.Element} The rendered Listing component.
 */

// <<<<<<< ErrorsPizza
// const Listing = ({showUnlockGym, setShowUnlockGym}) => {
//     const navigate = useNavigate()
//     const {allGyms, allStudios, userLocation, USR        } = useContext(GymContext)
// =======

const Listing = ({showUnlockGym, setShowUnlockGym, selectedCity = 'ghaziabad'}) => {
    const {allGyms, allStudios, userLocation, selectedCity: contextCity, setSelectedCity, USR } = useContext(GymContext)
    const location                                         = useLocation();
    const { city }                                         = useParams();
    const [searchParams         ,         setSearchParams] = useSearchParams();
    const navigate                                         = useNavigate();
    const currentPageFromUrl                               = parseInt(searchParams.get("page")) || 1; // Get current page from query parameters, default to 1
    const itemsPerPage                                     = 10;
    const [baseGyms             ,             setBaseGyms] = useState([]);    
    const [currentPage          ,          setCurrentPage] = useState(currentPageFromUrl);
    
    const [loading              ,              setLoading] = useState(true);
    const [filteredGyms         ,         setFilteredGyms] = useState([]);
    // const [paginatedGyms        ,        setPaginatedGyms] = useState([]);   
    // const [userLocation         ,         setUserLocation] = useState(null);
    
    const [sort                 ,                 setSort] = useState('');
    
    const [budget               ,               setBudget] = useState([1,10000]);
    const [distance             ,             setDistance] = useState([0.1,10]);
    
    const [filtersOpen          ,          setFiltersOpen] = useState(false);
    const [filterType           ,           setFilterType] = useState(); // to check if the user used the price or budegt filter
    const [                     ,          setSortingOpen] = useState(false);
    const [locationDenied       ,                        ] = useState(false);
    const [isFromSearch         ,         setIsFromSearch] = useState(false);
    const [noResults            ,            setNoResults] = useState(false);
    const [comparingGyms        ,        setComparingGyms] = useState([]);


    
    // Update selected city in context if different from current
    useEffect(() => {
        if (selectedCity !== contextCity) {
          setSelectedCity(selectedCity);
        }
    }, [selectedCity, contextCity, setSelectedCity]);

    /**
     * Handles search query persistence and state updates.
     * - Retrieves query from state.
     * - Loads stored search results and "no results" state.
     * - Updates base gyms list if results exist.
     */
    // Handle search state and pagination
    useEffect(() => {
        const searchQuery = searchParams.get('search');
        const searchResults = location?.state?.searchResults;
        const fromSearch = location?.state?.fromSearch;
        const noResults = location?.state?.noResults;
        setIsFromSearch(Boolean(fromSearch));
        setNoResults(Boolean(noResults));
        
        if (searchQuery && searchResults) {
            setBaseGyms(searchResults);
            setFilteredGyms(searchResults);
        }
    }, [location?.state, searchParams, setBaseGyms, setFilteredGyms]);
    
    // Handle page navigation
    useEffect(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        const currentPage = parseInt(searchParams.get('page')) || 1;
        
        if (currentPage > 1) {
            newSearchParams.set('page', currentPage.toString());
        } else {
            newSearchParams.delete('page');
        }
    
        // Preserve search query in params
        const searchQuery = searchParams.get('search');
        if (searchQuery) {
            newSearchParams.set('search', searchQuery);
        } else {
            newSearchParams.delete('search');
        }
    
        setSearchParams(newSearchParams, { replace: true });
    }, [searchParams, setSearchParams]);
    
    // Get search status message
    const getSearchStatusMessage = () => {
        const searchQuery = searchParams.get('search');
        if (isFromSearch) {
            return noResults 
                ? (
                    <>
                        <div>{`No Gyms Found for "${searchQuery}"`}</div>
                        <div className="mt-4">You might like</div>
                    </>
                )
                : `Showing Search Results for "${searchQuery}"`;
        }
        return 'Showing Gyms In Your Area';
    };

    // Fetch user's location
    // useEffect(() => {
    //     // Check if location is already stored
    //     const storedLocation = localStorage.getItem("userLocation");
    //     const locationDenied = localStorage.getItem("locationDenied");
        
    //     if (storedLocation) {
    //         // If location is already stored, use it
    //         setUserLocation(JSON.parse(storedLocation));
    //     } else if (!locationDenied) {
    //         // If no stored location and location access not denied, request access
    //         requestLocationAccess(
    //             (location) => {
    //                 // Successfully obtained location
    //                 setUserLocation(location);
    
    //                 // Store location in localStorage
    //                 localStorage.setItem("userLocation", JSON.stringify(location));
    //             },
    //             (error) => {
    //                 console.warn("Location access denied:", error);
    
    //                 // Set a flag indicating location access was denied
    //                 localStorage.setItem("locationDenied", "true");
    //             }
    //         );
    //     }
    // }, []);

    // Puts the gym in comparing gym state for comparison
    const onGymSelectForComparison = (isSelected, gymData) => {
        setComparingGyms((prevGyms) => {
            if (isSelected) {
                if (prevGyms.length >= 3) return prevGyms; // Prevent adding more than 3 gyms
    
                // Remove `onGymSelectForComparison` and `comparingGyms` fields
                const { onGymSelectForComparison, comparingGyms, ...cleanGymData } = gymData;
    
                return [...prevGyms, cleanGymData]; // Add cleaned gym data to the list
            } else {
                return prevGyms.filter(gym => gym.gymId !== gymData.gymId); // Remove gym from list
            }
        });
    };

    /**
     * useEffect to log a page view event to Firebase Analytics when the location changes.
     * The log includes the current path to track user navigation.
     * 
     * @fires logEvent
     * @param {Object} location - The location object from React Router.
     */
    useEffect(() => {
        logEvent(analytics, "Marketplace Listing", {
            page_path: location.pathname,
        });
    }, [location]);

    // Fetch gyms only if not present in navigation
    useEffect(() => {
        const fetchGyms = async () => {
            setLoading(true);
            try {
                const searchQuery = searchParams.get('search');
                const searchResults = location?.state?.searchResults;
    
                if (searchQuery && searchResults) {
                    // If there's a search query and results in navigation state, use them
                    setBaseGyms(searchResults);
                    setFilteredGyms(searchResults);
                } else if (allGyms?.length > 0) {
                    // If no search, use all gyms and studios
                    setBaseGyms([...allGyms, ...allStudios]);
                    setFilteredGyms(allGyms);
                } else {
                    // If no gyms data available yet, update only if empty
                    setBaseGyms((prevBaseGyms) => {
                        if (prevBaseGyms.length === 0) {
                            return [...allGyms, ...allStudios];
                        }
                        return prevBaseGyms;
                    });
    
                    setFilteredGyms((prevFilteredGyms) => {
                        if (prevFilteredGyms.length === 0) {
                            return [...allGyms, ...allStudios];
                        }
                        return prevFilteredGyms;
                    });
                }
            } catch (error) {
                console.error("Error fetching gyms:", error);
            } finally {
                // setLoading(false)
                setTimeout(()=> setLoading(false), 1000)
            }
        };
    
        fetchGyms();
    }, [selectedCity, searchParams, location?.state?.searchResults, allGyms, allStudios]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll
        });
    }, [currentPage])

    /** 
     * @const totalPages 
     * @description Calculates the total number of pages for pagination, based on the length of the filteredGyms.
     * @returns {number} - Total pages.
     */
    const totalPages = filteredGyms ? Math.ceil(filteredGyms ? filteredGyms?.length / itemsPerPage : filteredGyms?.length / itemsPerPage) : 0;

    /**
     * Handles filtering and sorting based on user interactions
     */
    const handleFilterAndSort = (
        type = 'filter',
        filterType,  // (We may not need this anymore if we check each condition)
        sortParam,   // may be null when filtering
        distance,
        budget
      ) => {
        // Use the existing sort state if no sort parameter is provided
        const currentSort = sortParam !== null ? sortParam : sort;
        let gyms = [...baseGyms];
      
        if (type === 'filter') {
            // Apply the distance filter if the user has changed the default values
            if (userLocation && (distance[0] !== 0 || distance[1] !== 10)) {
                gyms = gyms.filter((gym) => {
                const distanceInMeters = calculateDistance(
                    { lat: userLocation.lat, lng: userLocation.lng },
                    parseFloat(gym.latitude),
                    parseFloat(gym.longitude),
                    locationDenied
                );
                // Convert meters to kilometers:
                const distanceInKm = distanceInMeters / 1000;
                return distanceInKm >= distance[0] && distanceInKm <= distance[1];
                });
            }
        
            // Apply the budget filter if the user has changed the default values
            if (budget[0] !== 0 || budget[1] !== 10000) {
                gyms = gyms.filter((gym) => {
                const pricePerMonth = getSellingPrice(
                    parseFloat(gym?.prices?.single?.exclusiveMonthlyPrice),
                    gym?.isExclusive,
                    gym?.gymName,
                    gym?.city
                );
                return pricePerMonth >= budget[0] && pricePerMonth <= budget[1];
                });
            }
        
            setFiltersOpen(false);
        } else {
            // Sorting branch: start from the currently filtered gyms if any, or baseGyms otherwise
            setSortingOpen(false);
            gyms = filteredGyms.length ? [...filteredGyms] : [...baseGyms];
        }
      
        // Apply sorting based on the currentSort
        if (currentSort === 'LtoH') {
            gyms.sort(
                (a, b) =>
                (a?.prices?.single?.exclusiveMonthlyPrice || 0) -
                (b?.prices?.single?.exclusiveMonthlyPrice || 0)
            );
        } else if (currentSort === 'HtoL') {
            gyms.sort(
                (a, b) =>
                (b?.prices?.single?.exclusiveMonthlyPrice || 0) -
                (a?.prices?.single?.exclusiveMonthlyPrice || 0)
            );
        } else if (currentSort === 'topRated') {
            if (userLocation) {
                gyms.sort((a, b) => {
                const ratingA = a?.rating || 0;
                const ratingB = b?.rating || 0;
                if (ratingB !== ratingA) {
                    return ratingB - ratingA;
                }
                // If ratings are equal, sort by distance ascending
                const distanceA = calculateDistance(
                    userLocation,
                    parseFloat(a.latitude),
                    parseFloat(a.longitude),
                    locationDenied
                );
                const distanceB = calculateDistance(
                    userLocation,
                    parseFloat(b.latitude),
                    parseFloat(b.longitude),
                    locationDenied
                );
                return distanceA - distanceB;
                });
            } else {
                gyms.sort((a, b) => (b?.rating || 0) - (a?.rating || 0));
            }
        }
      
        setFilteredGyms(gyms);
        setCurrentPage(1); // Reset page to 1
    };
          
    // const handleFilterAndSort = () => {
    //     let gyms = [...baseGyms];

    //     // console.log("type : ", type)
    //     // console.log("FILTER Type : ", filterType)
    
    //     if (type === 'filter') {
    //         // Distance Filter
    //         if(filterType === 'distance') {
    //             gyms = gyms.filter((gym) => {
    //                 if (userLocation) {
    //                     const distanceInKm = calculateDistance(
    //                         { lat: userLocation.lat, lng: userLocation.lng },
    //                         parseFloat(gym.latitude),
    //                         parseFloat(gym.longitude),
    //                         locationDenied, 
    //                         // () => requestLocationAccess(setUserLocation, setLocationDenied) 
    //                     );
    
    //                     // console.log("Distance in KM :", distanceInKm)
    //                     // console.log("less : ", distanceInKm <= distance[1])
    //                     // console.log("More : ", distanceInKm >= distance[0] )
    //                     // console.log("return : ",gym.gymDisplayName, distanceInKm >= distance[0] && distanceInKm <= distance[1])
        
    //                     return distanceInKm >= distance[0] && distanceInKm <= distance[1];
    //                 }
    //                 return true; // If user location is not available, don't filter by distance
    //             });
    //         }
    
    //         // Budget Filter
    //         if(filterType === 'budget') {
    //             gyms = gyms.filter((gym) => {
    //                 const pricePerMonth = getSellingPrice
    //                 (parseFloat(gym?.prices?.single?.exclusiveMonthlyPrice), 
    //                 gym?.isExclusive, 
    //                 gym?.gymName, 
    //                 gym?.city)
    
    //                 console.log("prices per month :", pricePerMonth)
    //                 console.log("budget  low :", budget[0])
    //                 console.log("budget high :", budget[1])
    //                 console.log("First :", pricePerMonth >= budget[0])
    //                 console.log("Second :", pricePerMonth <= budget[1])
    //                 console.log("ीाेहतू :", pricePerMonth >= budget[0] && pricePerMonth <= budget[1])

    //                 return pricePerMonth >= budget[0] && pricePerMonth <= budget[1];
    //             });
    //         }    
    //     } else {
    //         // Sorting Logic    
    //         gyms = [...filteredGyms];
    
    //         if (sort === 'LtoH') {
    //             gyms.sort((a, b) => (a?.prices?.single?.exclusiveMonthlyPrice || 0) - (b?.prices?.single?.exclusiveMonthlyPrice || 0));
    //         } else if (sort === 'HtoL') {
    //             gyms.sort((a, b) => (b?.prices?.single?.exclusiveMonthlyPrice || 0) - (a?.prices?.single?.exclusiveMonthlyPrice || 0));
    //         } else if (sort === 'AtoZ') {
    //             gyms.sort((a, b) => (a?.gymDisplayName || '').localeCompare(b?.gymDisplayName || ''));
    //         } else if (sort === 'ZtoA') {
    //             gyms.sort((a, b) => (b?.gymDisplayName || '').localeCompare(a?.gymDisplayName || ''));
    //         } else if (sort === '') {
    //             gyms = [...baseGyms];
    //         }
    //     }

    //     setFilteredGyms(gyms);
    //     setCurrentPage(1); // Reset page to 1
    // };

    useEffect(() => {
        if(filteredGyms?.length === 0 && filterType) {
            // console.log(999999)
            setTimeout(() => {
                // console.log(911111111199999)
                setFilteredGyms(baseGyms);
                setFilterType();
                setSort('')
                setDistance([0.1, 10]);
                setBudget([1, 10000])
            }, 5000)
        }
    }, [filteredGyms, baseGyms, filterType])

    const renderPaginationButtons = () => {
        const maxPagesToShow = 5;
        let pages = [];
    
        if (totalPages <= maxPagesToShow) {
            // Show all pages if total pages are ≤ 5
            pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        } else {
            if (currentPage <= 3) {
                // Case: First few pages (1,2,3)
                pages = [1, 2, 3, 4, "...", totalPages];
            } else if (currentPage >= totalPages - 2) {
                // Case: Last few pages (last 3)
                pages = [1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                // Case: Middle pages
                pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
            }
        }
    
        return pages.map((page, index) =>
            typeof page === "number" ? (
                <button
                    key={index}
                    onClick={() => setCurrentPage(page)}
                    className={`px-3 py-1 rounded ${
                        currentPage === page ? "bg-secondary text-tertiary" : "bg-tertiary text-secondary border-2 border-secondary"
                    }`}
                >
                    {page}
                </button>
            ) : (
                <span key={index} className="px-2">...</span>
            )
        );
    };
    

    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Find Gyms in {city} | Top Fitness Centers | Train Rex</title>
                <meta
                    name="description"
                    content={`Explore top-rated gyms and fitness centers in ${city}. Discover membership deals, free trials, and expert trainers. Start your fitness journey today with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`gyms in ${city}, fitness centers, free trials, membership deals, personal trainers,
                    affordable gym memberships near me, best gyms like gold's gym near me,
                    budget friendly gym options in ${city}, gym india, gym workout near me,
                    fitness & gym, gym near me, fitness clubs near me, gyms, nearby gym,
                    pilates, near gym, best health club near me, women gym near me,
                    nearby gyms, gold gym near me, gym workout, nearest gym, fitness, female gym near me,
                    gym photos, gym near me with fees under 600, fit india, fitness places near me,
                    gym nearby
                    `}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/${city}/gyms`} />
            </Helmet>

            {/* Main Listing Section */}
            <div className={`bg-[#ffffff] flex flex-col px-4 lg:px-6 min-h-screen pt-28 ${USR <= 1 ? 'lg:pt-28' : USR === 1.25 ? 'lg:pt-28' : USR === 1.5 ? 'lg:pt-24' : 'lg:pt-28'}`}>
                {loading ? (
                    <div className="h-[80vh] flex justify-center items-center flex-col">
                        <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                        <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
                    </div>
                ) : (filteredGyms && 
                    <div className={`flex gap-12 my-8 ${USR >= 1.5 ? 'lg:mx-6' : USR === 1 ? 'lg:mx-44' : USR === 1.25 ? 'lg:mx-20' : 'lg:mx-40'} min-h-screen`}>

                        <div className="flex flex-col w-full">
                            {/* Title */}
                            <h1 className="fade-in-slide-up-delayed flex items-center w-full justify-between gap-1 text-secondary text-xl lg:text-4xl font-bold text-left pl-2">
                                <div className="flex items-center gap-1">
                                    {window.ReactNativeWebView && 
                                        // only visible on app, takes user to the last page before the current one
                                        <button onClick={() => navigate(-1)}> 
                                            <ArrowLeft  className='text-secondary'/>
                                        </button>}
                                    <div>
                                        {getSearchStatusMessage()}
                                    </div>
                                </div>
                                <button onClick={() => setFiltersOpen(true)} className={`p-2 text-sm text-purple font-semibold bg-lightPurple rounded-lg flex items-center gap-1`}><FilterSVG color='#876eaf' height='18px' /><div></div></button>
                            </h1>

                            <div className="w-full flex justify-between gap-2 mt-4 font-semibold text-sm"> 
                                <div className="flex gap-2">
                                    {/* Rating Button */}
                                    <button 
                                    onClick={() => { 
                                        setSort('rating'); 
                                        handleFilterAndSort('sort', null, 'topRated'); 
                                    }}
                                    className={`${sort === 'rating' ? 'bg-purple text-white' : 'bg-lightPurple text-purple'} p-2 rounded-lg flex items-center gap-1`}
                                    >
                                    <SortSVG color={sort === 'rating' ? '#ffffff' : '#876eaf'} height="18px" />
                                    <div>Top Rated</div>  
                                    </button>

                                    {/* Price Dropdown */}
                                    <PriceSortDropdown sort={sort} setSort={setSort} handleFilterAndSort={handleFilterAndSort} />
                                </div>
                            </div>
                            {/* List of Gyms with Fade and Slide Animation */}
                            <div className="fade-in-slide-up-delayed">
                                <div className="flex flex-wrap">
                                    {filteredGyms?.length !== 0 && filteredGyms?.slice((currentPage-1)*itemsPerPage, currentPage*itemsPerPage).map((card, index) => (
                                        <ListCard key={index} {...card} buttonText="Explore" USR={USR} comparingGyms={comparingGyms} onGymSelectForComparison={onGymSelectForComparison}/>
                                    ))}

                                    {filteredGyms?.length === 0 && (
                                        <div className="font-bold text-purple mt-40 text-2xl mx-12">
                                            Currently there are<br />no gyms available that meet your requirements
                                        </div>
                                    )}

                                    {/* Recommended Gyms Section */}
                                    {isFromSearch && !noResults && allGyms.length > 0 && filteredGyms?.length > 0 && (
                                        <div className="mt-8">
                                            <h2 className="text-secondary text-xl lg:text-3xl font-bold text-left pl-2">You might also like</h2>
                                            <div className="flex flex-wrap mt-4">
                                                {allGyms.slice(0, 10).map((gym, index) => ( // Show top 10 gyms from allGyms
                                                    <ListCard key={index} {...gym} buttonText="Explore" USR={USR} comparingGyms={comparingGyms} onGymSelectForComparison={onGymSelectForComparison}/>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {/* Pagination */}
                                    <div className="flex justify-center mt-8 space-x-2 w-full">
                                        {renderPaginationButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            
            {/* Button showing status for comparigng gyms */}
            {comparingGyms?.length !== 0 && 
            <div
                onClick={() => {
                    if (comparingGyms.length < 2) {
                        Alert('error', 'Please select at least 2 gyms to compare');
                    } else {
                        // Clone and remove functions before navigating
                        const serializableGyms = comparingGyms.map(({ someFunction, ...gym }) => gym);
                        trackNavigation(`/${city}/gyms`, '/compareGyms',"Page Navigation for Compare Gyms");
                        navigate('/compareGyms', { state: serializableGyms });
                    }
                }}                
                className='fixed bottom-4 right-4 px-8 py-2 bg-purple text-white font-semibold rounded-full'>
                {comparingGyms?.length}/3 Compare 
            </div>}

            {/* Filter Component */}
            <ListingFilter 
                filtersOpen         ={filtersOpen}
                setFiltersOpen      ={setFiltersOpen}
                budget              ={budget}
                setBudget           ={setBudget}
                filterType          ={filterType}
                setFilterType       ={setFilterType}
                userLocation        ={userLocation}
                distance            ={distance}
                setDistance         ={setDistance}
                handleFilterAndSort ={handleFilterAndSort}
            />


            {/* Sort Component */}
            {/* <ListingSort 
                setSortingOpen      ={setSortingOpen}
                handleFilterAndSort ={handleFilterAndSort}
                sort                ={sort}
                setSort             ={setSort}
                sortingOpen         ={sortingOpen}
            /> */}
        </>
    );
}

export default Listing;