import { useNavigate } from "react-router-dom";
import { PlanCard } from "./PlanCard";
import { getMediumPlanPrices, getPremiumPlanPrices } from "../../helperFunctions/PaymentPlans/getPlanPricing";
import { ChevronLeft } from "lucide-react";
import { plans } from "../../assets/Plans";
import { trackButtonClick } from "../../helperFunctions/Analytics/AnalyticsHelper";

/**
 * If membership is for atleast 1 month, then this is the first screen that the user will see in the payment process
 * 
 * @param {function} setCurrentPage    -  used to go the next page
 * @param {integeer} selectedPlan      -  the plan selected by the user
 * @param {function} setSelectedPlan   -  Selects the plan number
 * @param {object}   paymentDetails    -  Contains information about the payment, gym data, receiptInfo, months, etc
 * @param {function} setPlanPricing    -  This sets the mrp and selling price of the plan that the user selects
 * @returns 
 */
export const SelectPlans = ({ setCurrentPage, selectedPlan, setSelectedPlan, paymentDetails, setPlanPricing }) => {
    const navigate = useNavigate();    
    
    // Base Plan Pricing
    const basePlan = {
        sellingPrice : Math.floor(parseFloat(paymentDetails?.receiptInfo?.amountToPay)),
        mrp          : Math.floor(parseFloat(paymentDetails?.receiptInfo?.mrp)),
    };


    // Calculate Medium Plan Pricing
    const mediumPlan = getMediumPlanPrices(basePlan);
    // Calculate Premium Plan Pricing using Medium Plan as reference
    const premiumPlan = getPremiumPlanPrices(basePlan, mediumPlan);
    
    return (
        <div className="min-h-[80vh] lg:min-h-screen flex flex-col justify-center items-center">

            <div className="fixed top-0 left-0 w-full shadow-md p-4 px-4 flex items-center justify-between bg-[#ffffff] z-40">
                <div className="flex gap-4 items-center">
                    <button className="cursor-pointer" onClick={() => navigate(-1)}><ChevronLeft /></button>
                    <div className="text-purple font-semibold text-lg lg:text-[1.5vw] whitespace-nowrap">{paymentDetails?.gymData?.gymDisplayName || paymentDetails?.gymData?.studioDisplayName}</div>
                    <div className="text-purple font-semibold text-lg lg:text-[1.5vw] whitespace-nowrap">• {paymentDetails?.receiptInfo?.months === 0 ? '1' : paymentDetails?.receiptInfo?.months} {paymentDetails?.receiptInfo?.months === 0 ? 'Day' : 'Months'}</div>
                </div>
            </div>

            <div className="flex gap-6 py-24 lg:gap-[4vw]  overflow-x-scroll no-scrollbar lg:overflow-visible max-w-[100vw] px-5 lg:px-0 mt-28 lg:mt-0 ">
            {plans(basePlan, mediumPlan, premiumPlan).map((plan) => (
                <PlanCard plan={plan} selectedPlan={selectedPlan} currGymData={paymentDetails?.gymData} setSelectedPlan={setSelectedPlan} setPlanPricing={setPlanPricing} MembershipPlanTest='B'/>
            ))}
            </div>

            <button
                onClick={() => {trackButtonClick("Navigation for Plan B"); setCurrentPage(2)}}
                disabled={selectedPlan === null}
                className={`text-xs lg:text-[1.5vw] px-8 py-2 lg:py-4 lg:mr-8 text-white bg-purple w-fit rounded-full ${selectedPlan === null ? 'hidden' : ''}`}>
                Next
            </button>
        </div>
    )
}