import Modal from "react-modal";
import { useContext, useState } from "react";
import icon from '../../assets/CTA_01.webp';
import { addNewUserToDatabase } from "../userDatabaseHelper";
import toast from "react-hot-toast";
import { sendEmailToUserRoute } from "../MessagesAndEmails/messageHelper";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import Alert from "../../components/customComponents/Alert";
import { GymContext } from "../../hooks/FetchEverythingContext";

/**
 * SignUpPopup Component
 * 
 * A modal component that provides a sign-up form for new users.
 * Users can enter their email, gender, fitness level, and date of birth.
 * 
 * @param {Function} onClose - Callback function to close the modal.
 * @param {Object} formInfo 
 * @returns {JSX.Element} - Rendered SignUp modal.
 */
const SignUpPopup = ({ onClose, formInfo }) => {
    // State hooks to manage form inputs
    const {setUser                       } = useContext(GymContext);
    const [email        ,        setEmail] = useState('');
    const [gender       ,       setGender] = useState('');
    const [fitnessLevel , setFitnessLevel] = useState('');
    const [dob          ,          setDob] = useState('');

    /**
     * handleSubmit
     * 
     * Handles the form submission process.
     * Gathers input data from the form and logs it to the console.
     * Calls the onClose function to close the modal after submission.
     * 
     * @param {Event} e - Form submission event.
     */
    const handleSubmit = async (e) => {
        e?.preventDefault();
        const dataAndSuccess = await addNewUserToDatabase(formInfo.userID, {
            email,
            gender,
            fitnessLevel,
            dob,
            name            :   formInfo.name,
            displayName     :   formInfo.name,
            phoneNumber     :   formInfo.phoneNumber
        });

        if (dataAndSuccess?.success) {
            Alert("success", "Sign Up Successful!");
            setUser({uid : formInfo.userID, ...dataAndSuccess?.data});
            sendEmailToUserRoute({email : email}, formInfo.name, 'signUp');
        }
        else toast.error("Sign Up Failure!");
        onClose();  // Close modal after submission
        resetForm();
        // setTimeout(() => {
        //     window.location.reload();
        // }, 2000)
    };

    /**
     * Resets the form
     */
    const resetForm = () => {
        setEmail('');
        setDob('');
        setFitnessLevel('');
        setGender('');
    }

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            contentLabel="Sign Up"
            className="fixed inset-0 flex items-center justify-center z-50 text-black"
            overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
        >
            <div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] md:max-w-[85%] lg:max-w-[52%] max-h-[600px] flex flex-col md:flex-row p-6 md:p-10 lg:p-14">
                {/* Left segment for displaying the image */}
                <div className="w-full md:w-1/2 flex items-center justify-center p-4 rounded-l-3xl overflow-hidden">
                    <img
                        src={icon}
                        alt="Sign Up Illustration"
                        className="w-full h-auto object-contain"
                    />
                </div>

                {/* Right segment containing the form */}
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center space-y-4 p-6">
                    <div className="text-lg md:text-xl lg:text-2xl text-primary font-semibold text-center">
                        Create Account
                    </div>

                    {/* Form Section */}
                    <form onSubmit={handleSubmit} className="w-full space-y-4">
                        {/* Email Input */}
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email"
                            required
                            className="text-xs lg:text-base py-2 px-4 border rounded-3xl w-full outline-none"
                        />

                        {/* Gender Dropdown */}
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                            className="text-xs lg:text-base py-2 px-4 border rounded-3xl w-full outline-none"
                        >
                            <option value="" disabled>Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>

                        {/* Fitness Level Dropdown */}
                        <select
                            value={fitnessLevel}
                            onChange={(e) => setFitnessLevel(e.target.value)}
                            required
                            className="text-xs lg:text-base py-2 px-4 border rounded-3xl w-full outline-none"
                        >
                            <option value="" disabled>Fitness Level</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                        </select>

                        {/* Date of Birth Input */}
                        <DatePicker
                            selected={dob ? new Date(dob.split("-").reverse().join("-")) : null}
                            onChange={(date) => setDob(format(date, "dd-MM-yyyy"))}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date of Birth"
                            className="text-xs lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                            showYearDropdown       // Enables year dropdown
                            scrollableYearDropdown // Allows scrolling through years
                            yearDropdownItemNumber={100} // Number of years to show in dropdown
                        />

                        {/* Submit Button */}
                        <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="bg-tertiary text-sm lg:text-base text-black py-2 px-6 rounded-full hover:bg-primary hover:text-tertiary transition-all duration-300 focus:scale-105 w-full"
                        >
                            Sign Up
                        </button>
                    </form>
                </div>

                {/* Close button at the top-right corner */}
                <button
                    onClick={onClose}
                    className="absolute top-2 md:top-4 right-4 md:right-6 text-tertiary hover:text-black transition text-4xl"
                >
                    &times;
                </button>
            </div>
        </Modal>
    );
};

export default SignUpPopup;
