import React, { useContext, useRef, useState } from "react";
// import { useLocation } from "react-router-dom";
import Header from "../../helperFunctions/ResultsPageHelper/Header";
import DaySelector from "../../helperFunctions/ResultsPageHelper/DaySelector";
import SectionNav from "../../helperFunctions/ResultsPageHelper/SectionNav";
import DietPlan from "../../helperFunctions/ResultsPageHelper/DietPlan";
import ExercisePlan from "../../helperFunctions/ResultsPageHelper/ExercisePlan";
// import Recommendations from "../../helperFunctions/ResultsPageHelper/Recommendations";
import RegeneratePopup from "../../helperFunctions/ResultsPageHelper/RegeneratePopup";
// import CalorieRegeneratePopup from "../../helperFunctions/ResultsPageHelper/CalorieRegeneratePopup";
import { useEffect } from "react";
// import { fetchAllGyms } from "../../helperFunctions/GymInfoHelpers/FetchGymsList";
import FitnessMetrics from "../../helperFunctions/ResultsPageHelper/FitnessMetrics";
import { cloud_url } from "../../config/cloudURL";
import { collection, doc, getDocs, updateDoc } from "@firebase/firestore";
import { auth, db } from "../../config/firebase";
// import UserFitnessForm from "../../helperFunctions/AIandMore/UserFitnessForm/UserFitnessForm";
import Alert from "../../components/customComponents/Alert";
// import SkeletonLoader from "../../helperFunctions/AIandMore/UserFitnessForm/UIComponents/SkeletonLoader";
import { GymContext } from "../../hooks/FetchEverythingContext";
// import { checkIfUserHasPlan, getUserDetails, updatePlanSeenToTrue } from "../../helperFunctions/userDatabaseHelper";
import { PlanContext } from "../../helperFunctions/ResultsPageHelper/PlanContext";
import Loader from "./Loader";

/**
 * ResultsPage Component
 *
 * Central dashboard displaying the user's personalized weekly fitness plan.
 * Renders diet and exercise plans, allows meal regeneration or calorie adjustment,
 * and manages navigation across different sections (diet, exercise, recommendations).
 * Handles dynamic data from PlanContext and user state from GymContext.
 * Also manages popups for meal regeneration and calorie overrides.
 *
 * Features:
 * - Diet and exercise plans displayed per selected day
 * - Dynamic section scrolling and tracking
 * - Firestore updates for plan changes
 * - Handles plan expiration logic
 * - Uses Firebase Cloud Functions for calorie adjustment and plan regeneration
 *
 * @component
 * @returns {JSX.Element} Main layout containing the user's fitness plan with all interactive controls.
 */

const ResultsPage = () => {
    const { user, USR }                                     = useContext(GymContext);
    // const [                     ,               setLoading] = useState(false);     
    // State for weekly plan, selected day, active section, and regeneration popup
    // const [weeklyPlan           ,            setWeeklyPlan] = useState([]);
    // const [listOfGyms           ,            setListOfGyms] = useState([]);
    // const [userDetails          ,           setUserDetails] = useState(null);
    const [activeSection        ,         setActiveSection] = useState("diet");
    // const [selectedDayIndex     ,                         ] = useState(0);
    const [isPlanExpired        ,         setIsPlanExpired] = useState(null);
    // const [tempFormData         ,                         ] = useState(null);
    // Component loading states - all default to false
    // const [metricsLoaded        ,         setMetricsLoaded] = useState(false);
    // const [daySelectorLoaded    ,     setDaySelectorLoaded] = useState(false);
    // const [dietPlanLoaded       ,        setDietPlanLoaded] = useState(false);
    // const [exercisePlanLoaded   ,    setExercisePlanLoaded] = useState(false);
    // const [recommendationsLoaded, setRecommendationsLoaded] = useState(false);
    // const [entirePlanData       ,        setEntirePlanData] = useState(null)
    // const selectedCity                                      = userDetails?.area;
    // Destructure the new context values
    const { weeklyPlan, setWeeklyPlan, dailyPlan, userDetails, selectedDay, setSelectedDay } = useContext(PlanContext);

    // Use the day plan fetched for the selected day.
    // If weekly data is not fully available, dailyPlan is used.

    // console.log("Weekly Plan", weeklyPlan[selectedDay])
    const planToDisplay = weeklyPlan ? weeklyPlan[selectedDay] : dailyPlan;
    // Flag to track if we're in loading state initiated by form submission
    const [isFormSubmitLoading,                       ] = useState(false);
    const [regeneratePopup    ,     setRegeneratePopup] = useState({
        visible: false,
        itemType: null,
        mealKey: null,
        userPrompt: "",
    });

    // const [caloriePopup ,  setCaloriePopup] = useState({
    //     visible: false,
    //     itemType: null,
    //     mealKey: null,
    //     userPrompt: "",
    // });

    // References for scrolling to specific sections
    const dietRef            = useRef(null);
    const exerciseRef        = useRef(null);
    // const recommendationsRef = useRef(null);
    const refs = {
        diet: dietRef,
        exercise: exerciseRef,
        // recommendations: recommendationsRef,
    };

    // Function to handle when diet plan is loaded
    // const handleDietPlanLoaded = () => {
    //     setDietPlanLoaded(true);
    // };

    // updates plan's seen field to true
    // useEffect(() => {
    //     const updatePlanDoc = async () => {
    //         if(entirePlanData && entirePlanData?.seen === false && user?.uid)
    //             await updatePlanSeenToTrue(user?.uid, entirePlanData?.id)
    //     }
    //     updatePlanDoc();
    // }, [user, entirePlanData])

    // Function to handle calorie regeneration
    // const handleCalorieRegenerate = async (itemType, mealKey, foodDetails) => {
    //     const dayName = weeklyPlan[selectedDayIndex]?.day;
        
    //     try {
    //         // Calculate calories for the consumed food
    //         const calorieCalculationPrompt = `
    //             Calculate the total calories in the following meal. Be precise and consider standard portions.
    //             Only return a number representing the total calories without any explanation or text.
    //             Meal: ${foodDetails}
    //         `;
            
    //         // API call to calculate calories
    //         const calorieResponse = await fetch(`${cloud_url}/calculate_calories`, {
    //             method: "POST",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({
    //                 prompt: calorieCalculationPrompt
    //             }),
    //         }).catch(error => {
    //             console.error("Error calling calculate_calories:", error);
    //             return { ok: false, error };
    //         });
            
    //         let foodCalories = 0;
            
    //         if (calorieResponse && calorieResponse.ok) {
    //             const calorieData = await calorieResponse.json();
    //             foodCalories = parseInt(calorieData.calories) || 0;
    //             // console.log("Calculated calories:", foodCalories);
    //         } else {
    //             // Use a fallback temporary estimation based on meal type
    //             if (mealKey === "breakfast") foodCalories = 400;
    //             else if (mealKey === "lunch") foodCalories = 600;
    //             else if (mealKey === "dinner") foodCalories = 500;
    //             else foodCalories = 300;
    //         }
            
    //         // Create a deep copy of the current weekly plan
    //         const updatedPlan = JSON.parse(JSON.stringify(weeklyPlan));
            
    //         // Ensure the day and meals object exists for the selected day
    //         if (!updatedPlan[selectedDayIndex]) {
    //             updatedPlan[selectedDayIndex] = { day: dayName, meals: {} };
    //         }
            
    //         if (!updatedPlan[selectedDayIndex].meals) {
    //             updatedPlan[selectedDayIndex].meals = {};
    //         }
            
    //         // Update the specified meal with the consumed food details
    //         if (itemType === "meal" && mealKey) {
    //             if (!updatedPlan[selectedDayIndex].meals[mealKey]) {
    //                 updatedPlan[selectedDayIndex].meals[mealKey] = {};
    //             }
                
    //             // Create a custom item structure that matches the expected format in DietPlan component
    //             updatedPlan[selectedDayIndex].meals[mealKey] = {
    //                 items: [{
    //                     item: foodDetails,
    //                     calories: foodCalories,
    //                     amount: "custom"
    //                 }],
    //                 actualCalories: foodCalories,
    //                 consumptionStatus: "consumed",
    //                 foodDetails: foodDetails
    //             };
                
    //             // Identify remaining meals for the current day based on which meal was adjusted
    //             const mealOrder = ["breakfast", "lunch", "dinner"];
    //             const currentMealIndex = mealOrder.indexOf(mealKey);
    //             let remainingMealKeys = [];
    //             let consumedMeals = [];
                
    //             // Determine which meals to regenerate based on the current meal
    //             if (currentMealIndex !== -1) {
    //                 // Collect consumed meals (including the current one)
    //                 for (let i = 0; i <= currentMealIndex; i++) {
    //                     const meal = mealOrder[i];
    //                     if (updatedPlan[selectedDayIndex].meals[meal]) {
    //                         consumedMeals.push({
    //                             mealKey: meal,
    //                             actualCalories: updatedPlan[selectedDayIndex].meals[meal].actualCalories || 0
    //                         });
    //                     }
    //                 }
                    
    //                 // Collect remaining meals to be regenerated
    //                 if (currentMealIndex < mealOrder.length - 1) {
    //                     remainingMealKeys = mealOrder.slice(currentMealIndex + 1);
    //                 }
    //             }
                                
    //             // If we have remaining meals for the current day, regenerate them
    //             if (remainingMealKeys.length > 0) {
    //                 try {
    //                     const adjustResponse = await fetch(`${cloud_url}/adjust_daily_calories`, {
    //                         method: "POST",
    //                         headers: { "Content-Type": "application/json" },
    //                         body: JSON.stringify({
    //                             dayName,
    //                             consumedMeals,
    //                             remainingMealKeys,
    //                             userData: userDetails,
    //                             weeklyPlan: updatedPlan,
    //                             userPrompt: "Adjust remaining meals based on what I've already eaten today."
    //                         }),
    //                     });
                        
    //                     if (adjustResponse.ok) {
    //                         const adjustResult = await adjustResponse.json();
                            
    //                         if (adjustResult.status === "success") {
    //                             // Update the remaining meals with the adjusted meal plans
    //                             for (const meal of remainingMealKeys) {
    //                                 if (adjustResult.updatedMeals[meal]) {
    //                                     updatedPlan[selectedDayIndex].meals[meal] = {
    //                                         ...updatedPlan[selectedDayIndex].meals[meal],
    //                                         items: adjustResult.updatedMeals[meal],
    //                                         targetCalories: adjustResult.adjustedMealAllocations[meal] || 0,
    //                                         isAdjusted: true
    //                                     };
    //                                 }
    //                             }
                                
    //                             // console.log("Meals adjusted successfully:", adjustResult);
    //                         } else {
    //                             console.error("Error from adjust_daily_calories API:", adjustResult.message);
    //                         }
    //                     } else {
    //                         console.error("Failed to call adjust_daily_calories API:", adjustResponse.statusText);
    //                     }
    //                 } catch (error) {
    //                     console.error("Error adjusting meals:", error);
    //                 }
    //             } else if (mealKey === "dinner") {
    //                 // If dinner was adjusted, we should update the next day's plan
    //                 const nextDayIndex = (selectedDayIndex + 1) % 7;
    //                 const nextDayName = weeklyPlan[nextDayIndex]?.day;
                    
    //                 if (nextDayName) {
    //                     // Calculate how far off the user was from their target for the current day
    //                     let totalConsumedCalories = 0;
                        
    //                     // Sum up all consumed calories for the day
    //                     for (const meal of mealOrder) {
    //                         if (updatedPlan[selectedDayIndex].meals[meal]?.consumptionStatus === "consumed") {
    //                             totalConsumedCalories += updatedPlan[selectedDayIndex].meals[meal].actualCalories || 0;
    //                         }
    //                     }
                        
    //                     // Calculate daily target from user data
    //                     const weight_kg = parseFloat(userDetails?.weight || 0);
    //                     const height_cm = parseFloat(userDetails?.height || 0);
    //                     const age = parseFloat(userDetails?.age || 0);
    //                     const gender = userDetails?.gender || 'Male';
    //                     const gender_offset = gender.toLowerCase() === 'male' ? 5 : -161;
    //                     const maintenanceCalories = Math.round(10 * weight_kg + 6.25 * height_cm - 5 * age + gender_offset);
                        
    //                     // Calculate the deviation from target
    //                     const calorieDeviation = maintenanceCalories - totalConsumedCalories;
                        
    //                     // Store this information for the next day regeneration
    //                     updatedPlan[selectedDayIndex].nextDayAdjustment = {
    //                         calorieDeviation,
    //                         maintenanceCalories,
    //                         consumedCalories: totalConsumedCalories,
    //                         message: calorieDeviation > 0 
    //                             ? `Under by ${calorieDeviation} calories. Consider adjusting tomorrow's meals.`
    //                             : `Over by ${Math.abs(calorieDeviation)} calories. Consider adjusting tomorrow's meals.`
    //                     };
                        
    //                     // console.log("Next day adjustment info:", updatedPlan[selectedDayIndex].nextDayAdjustment);
    //                 }
    //             }
                
    //             // Store calorie adjustment metadata for this day
    //             updatedPlan[selectedDayIndex].calorieAdjustments = {
    //                 lastUpdated: new Date().toISOString(),
    //                 mealUpdated: mealKey,
    //                 foodDetails: foodDetails,
    //                 caloriesConsumed: foodCalories
    //             };
                
    //             // console.log("Updated plan:", updatedPlan);
                
    //             // Update state with the new plan
    //             // setWeeklyPlan(updatedPlan);
                
    //             // FIXED: Update only the specific day in Firestore, not the entire weeklyPlan
    //             const userPlanRef = doc(db, "user", auth.currentUser.uid, "plan", "details");
                
    //             // Create an object to update only the specific field in Firestore
    //             // This uses dot notation to update a specific array element without replacing the entire array
    //             const updateData = {};
    //             updateData[`weeklyPlan.${selectedDayIndex}`] = updatedPlan[selectedDayIndex];
                
    //             await updateDoc(userPlanRef, updateData);
                
    //             Alert('success', `Meal recorded: ${foodCalories} calories. Remaining meals adjusted.`);
    //         }
    //     } catch (error) {
    //         console.error("Error adjusting calories:", error);
    //         Alert('error', "Error adjusting calories: " + error.message);
    //     } finally {
    //         setCaloriePopup({ visible: false, itemType: null, mealKey: null, userPrompt: "" });
    //     }
    // };    

    // Fetch user details from Firestore
    // useEffect(() => {
    //     // Skip this effect if we're already loading from form submission
    //     if (isFormSubmitLoading) return;
        
    //     const fetchUserDetails = async (userId) => {
    //         try {
    //             // const userData = userId ? await getUserDetails(userId) : null;
    //             // const planData = userId ? await checkIfUserHasPlan(userId) : null;
    //             // console.log("User Data: ", userData)
    //             // console.log("Plan Data: ", planData)
    //             if (userData) {
    //                 // setUserDetails(userData);
    //                 // setWeeklyPlan(planData || []);
    //                 setEntirePlanData(planData);
                    
    //                 // Start the progressive loading
    //                 simulateProgressiveLoading();
                    
    //                 // IMPORTANT: Also set dietPlanLoaded to true since we already have the data
    //                 // Set a short timeout to show the loading state briefly for consistency
    //                 setTimeout(() => setDietPlanLoaded(true), 1000);
    //             } else {
    //                 console.warn("No user details found.");
    //                 setUserDetails(null);
                    
    //                 // Make sure we're not showing loading states when there's no data
    //                 setMetricsLoaded(true);
    //                 setDaySelectorLoaded(true);
    //                 setDietPlanLoaded(true);
    //                 setExercisePlanLoaded(true);
    //                 setRecommendationsLoaded(true);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching user details:", error);
    //             setUserDetails(null);
                
    //             // Make sure we're not showing loading states when there's an error
    //             setMetricsLoaded(true);
    //             setDaySelectorLoaded(true);
    //             setDietPlanLoaded(true);
    //             setExercisePlanLoaded(true);
    //             setRecommendationsLoaded(true);
    //         }
    //     };

    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         if (user) {
    //             // Reset loading states first
    //             setMetricsLoaded(false);
    //             setDaySelectorLoaded(false); 
    //             setDietPlanLoaded(false);
    //             setExercisePlanLoaded(false);
    //             setRecommendationsLoaded(false);
                
    //             // Then fetch data
    //             fetchUserDetails(user.uid);
    //         } else {
    //             setUserDetails(null);
                
    //             // Make sure we're not showing loading states when not logged in
    //             setMetricsLoaded(true);
    //             setDaySelectorLoaded(true);
    //             setDietPlanLoaded(true);
    //             setExercisePlanLoaded(true);
    //             setRecommendationsLoaded(true);
    //         }
    //     });

    //     return () => unsubscribe();
    // }, [isFormSubmitLoading, user]);

    // Function to simulate progressive loading of components
    // const simulateProgressiveLoading = () => {
    //     setTimeout(() => setMetricsLoaded(true), 300);
    //     setTimeout(() => setDaySelectorLoaded(true), 600);
    //     // setTimeout(() => setDietPlanLoaded(true), 900);
    //     setTimeout(() => setExercisePlanLoaded(true), 1200);
    //     setTimeout(() => setRecommendationsLoaded(true), 1500);
    // };

    // Function to handle form submission loading
    // const handleFormLoadingStart = (formData) => {
    //     // Reset all loading states
    //     setMetricsLoaded(false);
    //     setDaySelectorLoaded(false);
    //     setDietPlanLoaded(false);
    //     setExercisePlanLoaded(false);
    //     setRecommendationsLoaded(false);
        
    //     // Set loading flag and store form data for immediate display
    //     setIsFormSubmitLoading(true);
    //     setTempFormData(formData);
        
    //     // Simulate progressive loading of components
    //     simulateProgressiveLoading();
    // };
    
    // Determine what data to display

    // const displayData = tempFormData ? tempFormData : userDetails;
    
    // Function to close form 
    // const handleCloseForm = () => {
    //     setShowUnlockGym(false);
    // };

    // Fteches Gyms and classes
    // useEffect(() => {
    //     setLoading(true);
    //     const fetchGymsAndClasses = async () => {
    //         // const classes = await getAllClasses(selectedCity);
    //         // setClassList(classes);
    //         if (userDetails) {
    //             const allGyms = await fetchAllGyms(userDetails?.area);
    //             setListOfGyms(allGyms);
    //             setLoading(false);
    //         }
    //     };
    //     fetchGymsAndClasses();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [userDetails?.area]);

    // Function to call the backend API to regenerate a specific plan item (e.g., meal or workout)
    const regeneratePlanItem = async ({
        dayName,
        itemType,
        mealKey,
        userData,
        weeklyPlan,
        userPrompt,
    }) => {
        try {
            const response = await fetch(`${cloud_url}/regenerate_plan_item`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    dayName,
                    itemType,
                    mealKey,
                    userData,
                    weeklyPlan,
                    userPrompt,
                }),
            });

            if (!response.ok) {
                return { status: "error", message: "Network error or server returned an error." };
            }

            return await response.json();
        } catch (error) {
            console.error("Regenerate error:", error);
            return { status: "error", message: "Failed to call the server." };
        }
    };

    // Handles UI and Firestore update after regenerating a plan item
    const handleRegenerate = async (itemType, userPrompt, mealKey) => {
        // Get the day name from the current weeklyPlan state (e.g., "Monday")
        const dayName = selectedDay;
        try {
            const result = await regeneratePlanItem({
                dayName,
                itemType,
                mealKey,
                userData: userDetails,
                weeklyPlan,
                userPrompt,
            });
            if (result) {
                if (itemType === "meal" && mealKey) {
                    // Construct the Firestore reference to the specific meal document.
                    // New path: users/{userId}/planDetails/plan/Details/{dayName}/diet/{mealKey}
                    const mealDocRef = doc(
                        db,
                        "user",
                        auth.currentUser.uid,
                        "planDetails",
                        "plan",
                        "Details",
                        dayName,
                        "diet",
                        mealKey
                    );
                    // Update the Firestore document with the regenerated result
                    await updateDoc(mealDocRef, { items: result });
                    // Update the context state immediately:
                    setWeeklyPlan(prevPlan => ({
                        ...prevPlan,
                        [dayName]: {
                            ...prevPlan[dayName],
                            diet: {
                                ...prevPlan[dayName].diet,
                                [mealKey]: {
                                    ...prevPlan[dayName].diet[mealKey],
                                    items: result,
                                },
                            },
                        },
                    }));
                }
            } else {
                Alert('error', "Failed to regenerate: " + result.message);
            }
        } catch (error) {
            console.error("Error during regeneration:", error);
        } finally {
            setRegeneratePopup({ visible: false, itemType: null, mealKey: null, userPrompt: "" });
        }
    };

    // Smoothly scrolls to a specific section on the page and optionally updates the active UI section
    const handleScrollToSection = (sectionRef, sectionName) => {
        if (sectionRef?.current) {
            // Get the element's position relative to the viewport
            const sectionTop = sectionRef.current.getBoundingClientRect().top + window.scrollY;
            
            // Scroll smoothly to 300px above the section
            window.scrollTo({
                top: sectionTop - 160,
                behavior: "smooth",
            });
    
            // Optionally update active section in parent component
            setActiveSection(sectionName);
        }
    };

    // console.log("USER in RP : ", user?.endDate)

    // Checks if user plan is expired or not
    useEffect(() => {

        const checkIfUserHasFitnessPlan = async () => {
            if (user && user.uid) {
                const planTypeRef = collection(db, "user", user.uid, "planType");
                const planTypeSnap = await getDocs(planTypeRef);
    
                let hasActivePlan = false;
                const now = new Date();

                if (planTypeSnap.empty) {
                    // Update your state based on the active plan
                    setIsPlanExpired(!hasActivePlan);
                    return null;
                }
            
                // For each day document (e.g., Monday, Tuesday, etc.)
                for (const planData of planTypeSnap.docs) {
                    if (planData.data()) {
                        const plan = planData.data();
    
                        if (plan.endDate >= now) {
                            // Update your state based on the active plan
                            setIsPlanExpired(hasActivePlan);
                            break; // stop checking once an active plan is found
                        }
                    }
                }
            }
        };

        checkIfUserHasFitnessPlan();
    }, [user])

    return (
        <div className='relative'>
            <div className={`fixed z-[20] top-0 left-0 bg-gradient-to-t h-[100vh] w-full from-[#AF9FCA] to-transparent ${USR >= 1.5 ? 'lg:px-6 ' : USR <= 1 ? 'lg:px-44 ' : USR === 1.25 ? 'lg:px-20 ' : 'lg:px-40 '}`}></div>

            <div className={`relative z-[20] mt-32 ${USR >= 1.5 ? 'lg:px-6' : USR === 1 ? 'lg:px-44' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'}`}>
                {/* Render Header */}
                <Header />

                {/* {console.log("USer Details", userDetails)} */}
                
                {/* Show empty state only if no data is available and not in loading state */}
                {!userDetails ? (
                    <Loader/>
                )
                : isPlanExpired ? 
                    <div className="flex justify-center items-center h-screen">
                        <p>Your Plan is expired please renew the plan!</p>
                    </div>
                : (
                    <>
                        {/* Fitness Metrics */}
                        <FitnessMetrics userDetails={userDetails} />                        

                        {/* Render Day Selector */}
                         
                        <DaySelector
                            // weeklyPlan={weeklyPlan}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            startDate={user?.startDate}
                        />
                        

                        {/* Render Section Navigation - always show this */}
                        <SectionNav
                            activeSection={activeSection}
                            handleScrollToSection={handleScrollToSection}
                            refs={refs}
                        />

                        {/* Render Diet Plan */}
                        <div ref={dietRef}>
                            
                            <DietPlan
                                ref={dietRef}
                                // weeklyPlan={weeklyPlan}
                                weeklyPlan={planToDisplay}
                                // selectedDayIndex={selectedDayIndex}
                                setRegeneratePopup={setRegeneratePopup}
                                // setCaloriePopup={setCaloriePopup}
                            />
                            
                        </div>

                        {/* Render Exercise Plan */}
                        
                        <ExercisePlan
                            ref={exerciseRef}
                            // weeklyPlan={weeklyPlan}
                            // selectedDayIndex={selectedDayIndex}
                            weeklyPlan={planToDisplay}
                        />
                        

                        {/* Render Recommendations */}
                        {/* {recommendationsLoaded ? (
                            <Recommendations 
                                ref={recommendationsRef} 
                                listOfGyms={listOfGyms} 
                                USR={USR}
                                city = {selectedCity}
                            />
                        ) : (
                            <SkeletonLoader type="recommendations" ref={recommendationsRef} />
                        )} */}

                        {/* Render Popups - only show when not in loading state */}
                        {!isFormSubmitLoading && regeneratePopup.visible && (
                            <RegeneratePopup
                                regeneratePopup={regeneratePopup}
                                setRegeneratePopup={setRegeneratePopup}
                                handleRegenerate={handleRegenerate}
                            />
                        )}
                        {/* {!isFormSubmitLoading && caloriePopup.visible && (
                            <CalorieRegeneratePopup
                                caloriePopup={caloriePopup}
                                setCaloriePopup={setCaloriePopup}
                                handleCalorieRegenerate={handleCalorieRegenerate}
                            />
                        )} */}
                    </>
                )}
            </div>


            {/* Fitness Form Modal */}
            {/* <UserFitnessForm
                isModalOpen={showUnlockGym}
                onClose={handleCloseForm}
                onLoadingStart={handleFormLoadingStart}
                onDietPlanLoaded={handleDietPlanLoaded} 
            /> */}
        </div>
    );
};

export default ResultsPage;
