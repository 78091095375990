import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JSConfetti from "js-confetti";
import { generatePdf } from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/BillGeneration";
// import AeMoorkh from "../../assets/AeMoorkhSuccess.webp";
import { convertISOToDDMMYYYY, formattedClassName, getAvailableDates } from "../../helperFunctions/basicHelper";
import Alert from "../../components/customComponents/Alert";
import AddToAppleWalletButton from "../../components/PassComponents/AddToWalletButton";
import paymentPageCard from '../../assets/Website_content/PaymentPageCard.svg'
import paymentPageCardLG from '../../assets/Website_content/PaymentPageCardLG.svg'
import { Calendar } from "lucide-react";
import { GymContext } from "../../hooks/FetchEverythingContext";
import match from '../../assets/Website_content/TR_Website_content-05.svg'
import track from '../../assets/Website_content/TR_Website_content-07.svg'
import consult from '../../assets/Website_content/TR_Website_content-09.svg'

/**
 * PaymentSuccessPage Component
 * 
 * This component displays a confirmation page after a successful payment. 
 * It includes a celebratory confetti animation, payment summary, and options to print the receipt or return to the homepage.
 * 
 * @returns {JSX.Element} Renders the payment success confirmation page.
 */
const PaymentSuccessPage = () => {
    const {isMobile} = useContext(GymContext)
    const confettiRef = useRef(null); // Reference for the JSConfetti instance
    const location = useLocation(); // Access the state passed via navigate
    const navigate = useNavigate(); // Navigation hook for redirection
    const billInfo = location.state?.billInfo; // Extract billInfo from state if available
    const classesSchedule = billInfo?.[0]?.orderType === 'studio' ? location.state?.classesSchedule : null;

    //returns an array of weekdays of classes schedule like zumba on [monday, wednesday]
    const availableWeekDays = classesSchedule ?  getAvailableDates(billInfo[0]?.className, classesSchedule) : null;

    const [isAppleDevice, setIsAppleDevice] = useState(false);

    /**
     * Checks if the user is using an apple device or not
     */
    useEffect(() => {
        // Check if user is on an Apple device
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
            setIsAppleDevice(true);
        }
    }, []);
    
    /**
     * Redirects to the homepage if no valid bill information is available.
     * Ensures the component is not rendered without necessary data.
     */
    useEffect(() => {
        if (!billInfo || !billInfo?.[0]?.startDate) {
            Alert('error', "No Bill Information!");
            navigate("/");
        }
    }, [billInfo, navigate]);

    /**
     * Triggers confetti animation on component load using JSConfetti library.
     */
    useEffect(() => {
        const jsConfetti = new JSConfetti();
        confettiRef.current = jsConfetti;

        jsConfetti.addConfetti({
            emojis: ["🎉", "💪", "🔥", "🏋️‍♂️"], // Fun emojis for fitness theme
            confettiRadius: 6,
            confettiNumber: 100,
        });
    }, []);

    // Scroll to the top when the gymName changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [billInfo]);

    // Return null to prevent rendering if billInfo is undefined
    if (!billInfo) return null;

    return (
        <>
            {/* Container for the Success Message */}
            <div className="pt-14 lg:pt-[4vw] min-h-[100vh] flex ">

                {/* Left Side */}
                <div className="hidden lg:w-[50%] lg:flex flex-col mt-[2vw]">
                    <div 
                        style={{ fontFamily: "'Libre Baskerville', serif" }}
                        className="text-purple text-[5.5vw] font-semibold">
                        Congratulations
                    </div>
                    <div className="ml-[7vw] mt-[3vw]">
                        <img 
                        src="https://media.giphy.com/media/DXskm2gUlhFHbGQKvb/giphy.gif?cid=790b76116oi5kjmmz6weqxk0qegnves7uxf7nkfcw5xf16yf&ep=v1_gifs_search&rid=giphy.gif&ct=g" 
                        alt="Loading animation" 
                        className="w-[80%] h-[80%]" />
                    </div>
                </div>



                {/* right side */}
                <div className="w-full lg:w-[50%] bg-[#70955D] lg:bg-transparent lg:bg-gradient-to-l lg:from-[#70955D] lg:to-transparent relative flex flex-col items-center pt-8 lg:pt-[1vw]">
                    <img src={isMobile ? paymentPageCard : paymentPageCardLG} alt='bg' className="absolute left-5 lg:left-[20%] w-[90vw] lg:w-[60%] scale-y-105 lg:scale-y-100 mt-6 lg:mt-2" />
                    <div className="relative z-[1] px-8 mt-12 lg:mt-[12%] flex flex-col items-center">
                        <div 
                            style={{ fontFamily: "'Libre Baskerville', serif" }} 
                            className="lg:hidden text-[11vw] font-semibold text-purple">
                            Congratulations!
                        </div>
                        <div className="text-secondary text-3xl lg:text-[3.5vw] font-semibold mt-2 lg:mt-[0.5vw]">
                            {billInfo?.[0]?.gymDisplayName || 'GymName'}
                        </div>
                        <div className="text-secondary lg:text-[2vw] font-semibold mt-2 lg:mt-[1vw]">
                            {billInfo?.[0]?.address || 'Address'}
                        </div>
                        <div className="flex justify-center text-purple mt-2 lg:mt-[1vw] lg:text-[1.5vw] lg:gap-1">
                            <Calendar className="lg:w-[1.8vw] lg:h-[1.8vw]"/>
                            <div>{convertISOToDDMMYYYY(billInfo?.[0]?.startDate || 'Start date')}</div>
                        </div>
                        {/* Additional Users' Membership Information */}
                        {billInfo?.length > 1 &&
                            billInfo.slice(1).map((bill, index) => (
                                <div key={index} className="mt-4">
                                    {bill.user.displayName}'s {billInfo?.[0]?.orderType === 'studio' ? `${formattedClassName(billInfo?.[0]?.className)}` : 'Gym'} Membership starts on:
                                    <span className="block font-bold">{convertISOToDDMMYYYY(bill.startDate)}</span>
                                </div>
                        ))}
                        <div className="mt-6 lg:mt-[2vw] lg:text-[1.5vw]">
                            Thank you for trusting us. Let’s<br />{billInfo?.[0]?.orderType === 'studio' ? `start your ${formattedClassName(billInfo?.[0]?.className)} classes and smash your fitness goals` : 'hit the gym and smash your goals'}!
                        </div>
                        {billInfo?.[0]?.payAtTheCounter && (
                            <div className="text-lg md:text-xl font-semibold mb-4 text-complementPurple">
                                Train Rex has you covered – just focus on {billInfo?.[0]?.orderType === 'studio' ? `smashing your ${formattedClassName(billInfo?.[0]?.className)} classes` : 'hitting the gym'}! We'll handle the rest and give you a call when it's time to collect the payment.
                            </div>
                        )}

                        <div className="lg:hidden h-[30%] flex justify-center ">
                            <img 
                            src="https://media.giphy.com/media/DXskm2gUlhFHbGQKvb/giphy.gif?cid=790b76116oi5kjmmz6weqxk0qegnves7uxf7nkfcw5xf16yf&ep=v1_gifs_search&rid=giphy.gif&ct=g" 
                            alt="Loading animation" 
                            className="w-[80%] h-[100%]" />
                        </div>
                        <div className="text-purple font-semibold text-xl lg:text-[2.5vw] lg:mt-[4vw]">Booking Successful</div>
                        <div className="flex flex-col w-[80%] mt-4 lg:mt-[1.8vw] text-primary font-semibold gap-1 justify-between">
                            <div className="w-full flex flex-row justify-between lg:text-[1.2vw]">
                                <div>Amount Paid</div>
                                <div>₹{(billInfo?.[0].amount / 100).toFixed(2)}</div>
                            </div>
                            <div className="w-full flex justify-between lg:text-[1.2vw]">
                                <div>Total Savings</div>
                                <div>₹{(billInfo?.[0].mrp - billInfo?.[0].amount / 100).toFixed(2)}</div>
                            </div>
                        </div>

                        {/* Print Receipt Button */}
                        <button
                            onClick={() => generateAllBills(billInfo, availableWeekDays)}
                            className="mt-5 lg:mt-[2vw] px-8 py-2 lg:py-[0.5vw] lg:text-[1vw] bg-primary  text-white rounded-lg font-semibold hover:bg-secondary hover:text-primary"
                        >
                            Print Receipt
                        </button>

                        <div className="flex justify-center gap-[2px] mt-5 lg:mt-[2vw]">
                            <img src={match} alt='match' className="h-3 w-fit lg:h-[1.5vw] "/>
                            <img src={track} alt='track' className="h-3 w-fit lg:h-[1.5vw] "/>
                            <img src={consult} alt='consult' className="h-3 w-fit lg:h-[1.5vw] "/>
                        </div>
                    </div>
                    <div className="flex mt-10">
                        {isAppleDevice && <AddToAppleWalletButton billInfo={billInfo[0]} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentSuccessPage;


/**
 * Handles the generation of all bills by iterating through the billInfo array.
 * 
 * @param {Array} billInfo - Array of bill details for each user
 */
const generateAllBills = async (billInfo, weekDays) => {
    for (const bill of billInfo) {
        await generatePdf(bill, weekDays, bill.response ? bill.response : {
            razorpay_payment_id : 'N/A'
        });
    }
};