import { useContext } from "react"
import { GymContext } from "../../hooks/FetchEverythingContext"
import match from '../../assets/Website_content/TR_Website_content-05.svg'
import track from '../../assets/Website_content/TR_Website_content-07.svg'
import consult from '../../assets/Website_content/TR_Website_content-09.svg'

export const OrderSummary = ({
    selectedPlan,
    paymentDetails,
    trex,
    toggleTrexToBeUsed,
    trexToBeUsed,
    payAtTheCounter,
    setPayAtTheCounter,
    mrp,
    baseAmount,
    selectedCoupon,
    referralGiver,
    appliedTrexAmount,
    totalAmount,
    handleNextStep
}) => {
    const {USR} = useContext(GymContext);


    const showSelectedPlan = (plan = 0) => {
        if (plan === 0 || plan === null) return 'Basic'
        if (plan === 1) return 'Pro'
        return 'Premium'
    }

    return (
        <div className={`lg:w-[45%] flex flex-col justify-between ${USR === 1 ? 'lg:max-h-[59vh]' : USR === 1.25 ? 'lg:max-h-[75vh]' : USR === 1.5 ? 'lg:max-h-[90vh]' : 'lg:max-h-[60vh]'}`}>
            <div className="shadow-lg rounded-xl flex flex-col pt-2 lg:pt-[1vw]" style={{boxShadow : '0 1px 2px 1px lightGray'}}>
                {/* <p className="text-xl mb-2 font-bold">{receiptInfo.amountToPay > 1000 ? `${receiptInfo.months} ${receiptInfo.months > 1 ? 'Months' : 'Month'}` : `1 Day`}</p> */}
                <div className="hidden lg:block px-8 lg:px-[2.5vw] text-left text-[3vw] font-bold text-purple">{paymentDetails?.gymData?.gymDisplayName}</div>
                <div className="hidden lg:block px-8 lg:px-[2.5vw] text-left text-[1.4vw] font-bold text-secondary pt-[0.8vw]">{paymentDetails?.gymData?.address},</div>
                <div className="hidden lg:block px-8 lg:px-[2.5vw] text-left text-[1.4vw] font-bold text-secondary mb-[1vw]">{paymentDetails?.gymData?.locality}</div>

                <div className="font-semibold text-xl lg:text-[1.6vw] text-gray-700 scale-y-110">Order Summary</div>
                {trex > 0 && (
                    <div className="flex justify-between items-center pt-3 lg:pt-[1vw] px-8 lg:px-[2.5vw] mt-[0.5vw]">
                        <div className='flex gap-2'>
                            <div className="flex text-lg lg:text-[1.1vw] font-bold text-gray-700 gap-2 items-center">
                                <label>Use Trex (Available: {trex})</label>
                            </div>
                        </div>
                        <div onClick={toggleTrexToBeUsed} className={`rounded-full h-5 w-10 lg:h-[1.26vw] lg:w-[2.52vw] flex ${trexToBeUsed ? 'bg-purple' : 'bg-tertiary '} transition-all duration-500 cursor-pointer`}>
                            <div className={`h-5 w-5 lg:h-[1.25vw] lg:w-[1.25vw] rounded-full border-[1px] border-purple ${trexToBeUsed ? 'bg-tertiary translate-x-full' : 'bg-purple'} transition-all duration-500`}></div>
                        </div>
                    </div>
                )}
                {/* Pay At The Counter Option */}
                <div className="flex justify-between items-center pb-4 lg:pb-[1vw] mb-4 px-8 lg:px-[2.5vw] border-b-[1px] border-gray-400 mt-[0.5vw]">
                    <div className='flex gap-2'>
                        <div className="flex text-lg lg:text-[1.1vw] font-bold text-gray-700 gap-2 items-center">
                            <label>Cash Payment?</label>
                        </div>
                    </div>
                    <div onClick={()=> setPayAtTheCounter(!payAtTheCounter)} className={`rounded-full h-5 w-10 lg:h-[1.26vw] lg:w-[2.52vw] flex ${payAtTheCounter ? 'bg-purple' : 'bg-tertiary '} transition-all duration-500 cursor-pointer`}>
                        <div className={`h-5 w-5 lg:h-[1.25vw] lg:w-[1.25vw] rounded-full border-[1px] border-purple ${payAtTheCounter ? 'bg-tertiary translate-x-full' : 'bg-purple'} transition-all duration-500`}></div>
                    </div>
                </div>

                {selectedPlan !== null && <div className="flex justify-between px-8 lg:px-[2.5vw] lg:text-[1.3vw] text-gray-700" >
                    <span>Plan</span>
                    <span>{showSelectedPlan(selectedPlan)}</span>
                </div> }
                <div className="flex justify-between mt-1 px-8 lg:px-[2.5vw] lg:text-[1.3vw] text-gray-700" >
                    <span>Base Price</span>
                    <span>₹{mrp.toFixed(2)}</span>
                </div>
                {trexToBeUsed && (
                    <div className="flex justify-between mt-1 lg:mt-[0.7vw] px-8 lg:px-[2.5vw] text-secondary font-normal lg:text-[1.3vw] ">
                        <span>Trex</span>
                        <span>- ₹{appliedTrexAmount.toFixed(2)}</span>
                    </div>
                )}
                <div className="flex justify-between mt-1 lg:mt-[0.7vw] px-8 lg:px-[2.5vw] text-secondary font-normal  lg:text-[1.3vw] ">
                    <span>Discount</span>
                    <span>- ₹{((mrp) - (baseAmount - (selectedCoupon ? selectedCoupon.discountAmount.toFixed(2) : 0))).toFixed(2)}</span>
                </div>
                <div className="flex justify-between mt-1 lg:mt-[0.7vw] px-8 lg:px-[2.5vw] font-normal  lg:text-[1.3vw] text-gray-700">
                    <span>Members</span>
                    <span>{paymentDetails?.totalMemberships}</span>
                </div>
                {referralGiver && (
                    <div className="flex justify-between mt-1 lg:mt-[0.7vw] px-8 lg:px-[2.5vw] text-secondary font-normal  lg:text-[1.3vw]">
                        <span>Referrer Code </span>
                        <span>{referralGiver.code}</span>
                    </div>
                )}
                <div className="flex justify-between text-2xl lg:text-[1.4vw] mt-[0.7vw] px-8 lg:px-[2.5vw] py-4 lg:py-[1vw] bg-lightSecondary text-purple font-semibold border-t-[1px] border-gray-500 rounded-b-xl">
                    <span>Total</span>
                    <span>₹{totalAmount.toFixed(2)}</span>
                </div>
            </div>
            
            <div className='flex justify-center items-center gap-8 mt-4 '>
                {/* <button
                    onClick={handleNextStep}
                    className='w-full py-2 text-sm lg:text-base border-[1px] lg:border-[2px] border-purple text-purple font-semibold rounded-xl'>
                    Cash Payment
                </button> */}
                <button
                    onClick={handleNextStep}
                    className='w-full py-2 lg:py-4 lg:text-[1.5vw] rounded-xl bg-purple text-white font-semibold'>
                    Pay ₹{totalAmount.toFixed(2)}
                </button>
            </div>
            <div className="flex flex-col items-center">
                <div className="text-sm text-gray-700 mt-4">Secured by RazorPay</div>
                <div className="lg:hidden flex gap-[2px] mt-2 w-[10vw] lg:w-[2vw]">
                    <img src={match} alt='match' className="h-3 w-fit"/>
                    <img src={track} alt='track' className="h-3 w-fit"/>
                    <img src={consult} alt='consult' className="h-3 w-fit"/>
                </div>
            </div>
        </div>
    )
}