export const CouponCard = ({coupon, selectedCoupon}) => {
    return (
        <div className={`relative rounded-2xl flex w-full h-40 overflow-hidden ${selectedCoupon?.code === coupon?.code ? 'shadow-lg' : ''}`}>
            <div className="bg-lightPurple w-[20%] flex justify-center items-center">
                <div className="relative text-white text-2xl whitespace-nowrap -rotate-90">
                    {coupon?.type === 'percentage' ? `${coupon?.value}%` : `₹${coupon?.value}`} OFF
                </div>
                <div className="absolute -left-[2px] top-10 flex flex-col gap-4">
                    <div className="w-2 h-2 rounded-full bg-[#ffffff]"></div>
                    <div className="w-2 h-2 rounded-full bg-[#ffffff]"></div>
                    <div className="w-2 h-2 rounded-full bg-[#ffffff]"></div>
                    <div className="w-2 h-2 rounded-full bg-[#ffffff]"></div>
                </div>
                <div className="absolute left-[19%] top-3 flex flex-col gap-1">
                    <div className="text-[#ffffff] font-semibold rotate-90 scale-x-[300%]">-</div>
                    <div className="text-[#ffffff] font-semibold rotate-90 scale-x-[300%]">-</div>
                    <div className="text-[#ffffff] font-semibold rotate-90 scale-x-[300%]">-</div>
                    <div className="text-[#ffffff] font-semibold rotate-90 scale-x-[300%]">-</div>
                    <div className="text-[#ffffff] font-semibold rotate-90 scale-x-[300%]">-</div>
                </div>
            </div>
            <div className="bg-[#F8FFED] w-[80%] flex flex-col justify-center items-center pl-4">
                <div className="text-[125%] font-semibold text-purple">{coupon?.description}</div>
                <div className="text-2xl font-semibold text-secondary">Code: {coupon?.code}</div>
            </div>
        </div>
    )
}