import "react-datepicker/dist/react-datepicker.css";
import { useContext, useEffect, useState } from "react";
import Alert from "../components/customComponents/Alert";
import { updateUserDetails } from "../helperFunctions/userDatabaseHelper";
import { GymContext } from "../hooks/FetchEverythingContext";
import { emailChecker, phoneNumberChecker } from "../helperFunctions/ConstraintsChecker";
import { getCountryCode } from "../config/countryCodes";
import { AllMissingFields } from "../helperFunctions/AIandMore/UserFitnessForm/UIComponents/DietAndFitnessPlanQuestions";
import { checkIfUserHasMissingDetails } from "../helperFunctions/basicHelper";


/**
 * a modal asking for user missing details
 * @param {function} setShowGetUserEmailModal - to open or close the modal 
 * @returns a modal asking for user missing details
 */
const NewGetUserMissingDetails = ({ showGetUserEmailModal, setShowGetUserEmailModal }) => {
    const { user, setUser                } = useContext(GymContext)
    const [displayName  ,  setDisplayName] = useState(user?.displayName === 'User' ? '' : (user?.displayName && user?.displayName !== '') ? user.displayName : '');
    const [email        ,        setEmail] = useState((user?.email       && user?.email       !== '') ? user.email       : '');
    const [phoneNumber  ,  setPhoneNumber] = useState((user?.phoneNumber && user?.phoneNumber !== '') ? user.phoneNumber : '');
    const [dob          ,          setDob] = useState((user?.dob         && user?.dob         !== '') ? user.dob         : '');
    const [gender       ,       setGender] = useState((user?.gender      && user?.gender      !== '') ? user.dob         : '');
    const [submitting   ,   setSubmitting] = useState(false);

    useEffect(() => {
        if (user) {
            setDisplayName(user.displayName === 'User' ? '' : user.displayName || '');
            setEmail(user.email || '');
            setPhoneNumber(user.phoneNumber || '');
            setDob(user.dob || '');
            setGender(user.gender || '');
        }
    }, [user]);

    useEffect(() => {
        const doesUserHasIncompleteData = checkIfUserHasMissingDetails(user !== null, user);
        if(!doesUserHasIncompleteData) setShowGetUserEmailModal(false);
    }, [user, setShowGetUserEmailModal])

    // Keep these for future use
    // console.log(0)
    // console.log('0: user       :',user       ) 
    // console.log('0: email      :',email      )
    // console.log('0: displaNamw :',displayName)
    // console.log('0: Phone      :',phoneNumber)
    // console.log('0: DOB        :',dob        )
    // console.log('0: gender     :',gender     )
    // console.log('0: submitting :',submitting )
    // console.log(0)

    const handleEmailSubmit = async () => {
        if(email === '' || displayName === '' || phoneNumber === '' || dob === '' || gender === '') {
            Alert('error', 'Please enter the missing details');
            return ;
        }

        const phoneNumberWithCountryCode = phoneNumber?.length === 13 ? phoneNumber : `${getCountryCode()}${phoneNumber}`

        
        if(!phoneNumberChecker(phoneNumberWithCountryCode)) {
            Alert('error', 'Please enter a 10 digit phone number')
            return;
        }

        if(!emailChecker(email)) {
            Alert('error', 'Please enter a valid email address')
            return;
        }
        setSubmitting(true);
        
        const newUser = {
            ...user,
            email,
            displayName,
            phoneNumber : phoneNumberWithCountryCode,
            dob,
            gender,
        };

        await updateUserDetails(user.uid, {email, displayName, phoneNumber : phoneNumberWithCountryCode, dob, gender})
        setTimeout(() => {
            setUser(newUser);
            localStorage.setItem("user", JSON.stringify(newUser));
            setSubmitting(false);
            setShowGetUserEmailModal(false)
        }, 2500)
    }

    // in your component:
    const [showDelayedModal, setShowDelayedModal] = useState(false);

    useEffect(() => {
    const timer = setTimeout(() => {
        setShowDelayedModal(true);
    }, 8000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // cleanup timer
    }, []);

    if (!showDelayedModal) {
        return null
    }

    return (
        <div className={`${showGetUserEmailModal ? '' : 'hidden pointer-events-none'} fixed inset-0 h-[100vh] w-[100vw] flex justify-center items-center bg-black bg-opacity-50 z-[50]`}>
            <div className={`relative h-fit w-fit bg-[#ffffff] flex flex-col items-center rounded-3xl p-4 mx-4`}>
                {submitting ? (
                    <div className="text-3xl font-semibold text-purple min-h-[30vh] flex flex-col items-center justify-center">
                        <div class="success-checkmark scale-100 mt-10">
                            <div class="check-icon">
                                <span class="icon-line line-tip"></span>
                                <span class="icon-line line-long"></span>
                                <div class="icon-circle"></div>
                                <div class="icon-fix"></div>
                            </div>
                        </div>
                        <div className="mt-10">Thanks {displayName} for providing the details!</div>
                    </div>
                ) : (
                <div className="flex flex-col bg-">
                    <button onClick={() => setShowGetUserEmailModal(false)} className="absolute top-2 right-3 text-gray-700 text-2xl hover:text-black">
                        &times;
                    </button>
                    
                    {/* Why we need email? */}
                    <div className="text-center mb-8 mt-[2vh]">
                        <div className="text-2xl font-semibold">Could you please provide the missing details?</div>
                        <div className="">This will really help us personalize your journey on Train Rex and tailor your experience.</div>
                    </div>
                    <div className="w-full flex flex-col">

                        <AllMissingFields 
                            user           ={user}
                            displayName    ={displayName}
                            setDisplayName ={setDisplayName}
                            email          ={email}
                            setEmail       ={setEmail}
                            phoneNumber    ={phoneNumber}
                            setPhoneNumber ={setPhoneNumber}
                            dob            ={dob}
                            setDob         ={setDob}
                            gender         ={gender}
                            setGender      ={setGender}
                        />

                        {/* Submit Button */}
                        <div className="col-span-2 flex justify-between mt-4">                            
                            <button type='button' onClick={() => handleEmailSubmit()} className="bg-secondary text-white px-6 py-2 rounded-2xl">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>            
    );
}

export default NewGetUserMissingDetails;