export const ChooseYourFitnessPlan = ({ 
    resetForm, 
    handleSelectPlan, 
    trex, 
    isUserUsingTrex, 
    setIsUserUsingTrex,
    isPaymentProcessing,
    selectedPlan,
}) => {
    return (
        <>
        <button
            className="absolute top-2 right-4 text-gray-500 text-2xl hover:text-black"
            onClick={resetForm}
        >
            &times;
        </button>
        <h2 className="text-2xl font-bold mb-6 text-primary">
            Choose Your Fitness Plan
        </h2>

        <div className="h-[82vh] lg:h-fit overflow-y-auto">
            <div className="w-full px-6 mb-6 space-y-4">
                <h2 className="text-2xl font-semibold text-gray-800">What you get?</h2>
                <div className="flex flex-col ">
                    {[
                    "A fully customizable diet plan – swap meals in one tap",
                    "Step‑by‑step workout routines with video guidance",
                    "Daily checklists to keep you accountable",
                    "Real results — reach your goal faster",
                    ].map((item) => (
                    <div key={item} className="flex items-start">
                        <div className="mr-3 text-">✓</div>
                        <div className="text-sm text-left leading-relaxed text-gray-700">{item}</div>
                    </div>
                    ))}
                </div>
            </div>

        
            <div className="flex flex-col md:flex-row gap-6 w-full">
                {/* Weekly Plan on the left */}
                <div className="bg-gray-50 rounded-xl p-6 flex flex-col border-2 border-gray-200 flex-1 relative">
                    <div className="absolute -top-4 right-6 bg-[#ffffff] text-gray-600 px-2 py-1 rounded-full text-sm">
                        40% OFF
                    </div>
                    <h3 className="text-xl font-bold mb-2">Trial Week</h3>
                    <div className="mb-4">
                        <span className="text-gray-500 line-through mr-2">₹499</span>
                        <span className="text-3xl font-bold">₹299</span>
                        <span className="text-sm font-normal">/week</span>
                    </div>

                    <button 
                        className="mt-auto w-full py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors font-bold"
                        onClick={() => handleSelectPlan('Weekly')}
                    >
                        {isPaymentProcessing && selectedPlan === 'Weekly' ? 'Processing...' : 'Try For 7 Days'}
                    </button>
                    <p className="text-center mt-2 text-xs text-gray-500">One-time payment</p>
                </div>
            
                {/* Monthly Plan on the right */}
                <div className="bg-gray-50 rounded-xl p-6 flex flex-col border-2 border-gray-200 flex-1 relative">
                    <div className="absolute -top-4 right-6 bg-[#ffffff] text-gray-600 px-2 py-1 rounded-full text-sm">
                        43% OFF
                    </div>
                    <h3 className="text-xl font-bold mb-2">Monthly Plan</h3>
                    <div className="mb-4">
                        <span className="text-gray-500 line-through mr-2">₹1399</span>
                        <span className="text-3xl font-bold">₹799</span>
                        <span className="text-sm font-normal">/month</span>
                    </div>
                    <button 
                        className="mt-auto w-full py-3 bg-secondary text-white rounded-lg hover:bg-lightSecondary transition-colors font-bold"
                        onClick={() => handleSelectPlan('Monthly')}
                        disabled={isPaymentProcessing}
                    >
                        {isPaymentProcessing && selectedPlan === 'Monthly'? 'Processing...' : 'Get Started Now'}
                    </button>
                    <p className="text-center mt-2 text-xs text-gray-500">Billed monthly</p>
                </div>
            </div>

            {/* {console.log("TREX ; ", trex)} */}
            {trex > 0 && (
                <div className="flex w-full justify-between items-center pt-2 lg:pt-[1vw] lg:px-[2.5vw] mt-[0.5vw]">
                    <div className='flex gap-2'>
                        <div className="flex  lg:text-[1.1vw] font-bold text-gray-700 gap-2 items-center">
                            <label className="whitespace-nowrap">Use Trex (Available: {trex/100})</label>
                        </div>
                    </div>
                    <div onClick={() => setIsUserUsingTrex(!isUserUsingTrex)} className={`rounded-full h-5 w-10 lg:h-[1.26vw] lg:w-[2.52vw] flex ${isUserUsingTrex ? 'bg-purple' : 'bg-lightPurple '} transition-all duration-500 cursor-pointer`}>
                        <div className={`h-5 w-5 lg:h-[1.25vw] lg:w-[1.25vw] rounded-full border-[1px] border-purple ${isUserUsingTrex ? 'bg-tertiary translate-x-full' : 'bg-purple'} transition-all duration-500`}></div>
                    </div>
                </div>
            )}
        </div>
    
        <div className="mt-6 text-sm text-gray-500">
            <p className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
                Secure payment via Razorpay
            </p>
        </div>
        </>
    )
}