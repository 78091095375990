/**
 * BillGeneration.js
 * 
 * Renders the PrintView of the Receipts in a modal and allows the user to generate a PDF or send the receipt details to a phone number.
 */

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toWords } from "number-to-words";
import { convertISOToDDMMYYYY, formattedClassName, formatWeekDays, getDate } from "../../basicHelper";
import Alert from "../../../components/customComponents/Alert";

// Initialize pdfMake with fonts
// The library handled it differently than trainrex_website
pdfMake.vfs = pdfFonts;

/**
 * Converts the total amount to words using the number-to-words library.
 * 
 * @param {number} amount - The total amount to convert to words.
 * @returns {string} - The total amount in words.
 */
const getTotalAmountInWords = (amount) => {
    return toWords(amount).toUpperCase();
};

/**
 * Creates the PDF document definition.
 * 
 * @param {Object} receipt  - The receipt details.
 * @param {Object} response - The Razorpay object
 * @returns {Object} - The document definition for the PDF.
 */
const createDocDefinition = (receipt, weekdays, response) => {
    const totalAmount = receipt.amount / 100 || 0;
    const totalAmountInWords = getTotalAmountInWords(totalAmount);

    const netPrice = parseFloat((totalAmount / 1.18).toFixed(2));
    const cgst = parseFloat((0.09 * netPrice).toFixed(2));
    const sgst = parseFloat((0.09 * netPrice).toFixed(2));

    // Create content array
    const content = [];

    // Only include the image if it exists and is valid
    if (receipt.image && typeof receipt.image === 'string' && receipt.image.trim() !== '') {
        content.push({
            image: receipt.image,
            fit: [85, 85],
            alignment: 'left',
            margin: [0, 0, 0, 10]
        });
    }

    // Add the rest of the content
    content.push(
        {
            text: receipt.gymDisplayName || 'Gym Name',
            fontSize: 20,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10]
        },
        {
            text: [
                `${receipt.address || 'Address'}, ${receipt.cityDisplayName || 'City'}`,
                `Phone: ${receipt.gymContactNo || 'Phone'} | Email: ${receipt.gymEmail || 'Email'}`,
                `GSTIN: ${receipt.gstin || 'N/A'}`
            ].join('\n'),
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 20]
        },
        {
            text: [
                `Name: ${receipt.user? receipt.user.displayName : 'N/A'}`,
                `Phone: ${receipt.user? receipt.user.phoneNumber : 'N/A'}`
            ].join('\n'),
            margin: [0, 0, 0, 10]
        },
        {
            text: [
                `Transaction No: ${receipt?.razorpay_payment_id || response?.razorpay_payment_id || 'N/A'}`,
                'Payment Status: Paid',
                // Start and End Date
                receipt?.months !== 0 ? `Start Date: ${Date.parse(receipt.startDate) ? convertISOToDDMMYYYY(receipt.startDate) : receipt.startDate}` : '', 
                receipt?.months !== 0 ? `End Date: ${Date.parse(receipt.endDate) ? convertISOToDDMMYYYY(receipt.endDate) : receipt.endDate}` : '', 
                // Class Timings and Days
                receipt?.orderType === 'studio' ? receipt?.months === 0 ? 
                `${formattedClassName(receipt?.className)} Class on ${Date.parse(receipt.startDate) ? convertISOToDDMMYYYY(receipt.startDate) : receipt.startDate} at ${receipt?.selectedShift}` : 
                `${formattedClassName(receipt?.className)} Classes on ${formatWeekDays(weekdays)} at ${receipt?.selectedShift}` : 
                '',
            ].join('\n'),
            margin: [0, 0, 0, 10]
        },
        {
            table: {
                headerRows: 1,
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    [
                        { text: 'Qty', bold: true },
                        { text: 'Start Date', bold: true, alignment: 'right' },
                        { text: 'End Date', bold: true, alignment: 'right' },
                        { text: 'Net Amount', bold: true, alignment: 'right' },
                        { text: 'CGST (9%)', bold: true, alignment: 'right' },
                        { text: 'SGST (9%)', bold: true, alignment: 'right' },
                        { text: 'Total Amount', bold: true, alignment: 'right' },
                    ],
                    [
                        { text: `${receipt.months > 0 ? `${receipt.months} Months` : receipt.months === 0 ? '1 Day' : 'Free Trial'}`, fontSize: 8  },
                        { text: Date.parse(receipt.startDate) ? convertISOToDDMMYYYY(receipt.startDate) : receipt.startDate || getDate(), alignment: 'right', fontSize: 8 },
                        { text: Date.parse(receipt.endDate) ? convertISOToDDMMYYYY(receipt.endDate) : receipt.endDate, alignment: 'right', fontSize: 8 },
                        { text: `₹${netPrice}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${cgst}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${sgst}`, alignment: 'right', fontSize: 8 },
                        { text: `₹${totalAmount}`, alignment: 'right', fontSize: 8 },
                    ],
                ]
            },
            layout: {
                fillColor: '#f3f3f3',
                hLineColor: (i, node) => (i === 0 || i === node.table.body.length) ? 'black' : '#ccc',
                vLineColor: (i, node) => (i === 0 || i === node.table.widths.length) ? 'black' : '#ccc',
                hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 1 : 0.5,
                vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length) ? 1 : 0.5,
                paddingLeft: () => 4,
                paddingRight: () => 4,
                paddingTop: () => 2,
                paddingBottom: () => 2,
            }
        }
    );

    // Handle member information
    if (Array.isArray(receipt.allMembers) && receipt.allMembers.length > 0) {
        receipt.allMembers.forEach((member, index) => {
            content.push({
                text: [
                    `Member ${index + 1}: ${member.name || 'N/A'}`,
                    `Age: ${member.age || 'N/A'}`,
                    `Gender: ${member.gender || 'N/A'}`,
                    `Phone: ${member.phone || 'N/A'}`
                ].join('\n'),
                fontSize: 10,
                margin: [0, 0, 0, 10]
            });
        });
    } 
    // else {
    //     content.push({
    //         text: 'No additional members',
    //         fontSize: 10,
    //         margin: [0, 0, 0, 10]
    //     });
    // }

    // Add the remaining content items
    content.push(
        {
            columns: [
                {
                    width: '*',
                    text: [
                        `Payment Date and Time: ${formatDateTime(new Date())}`,
                        `Receipt No: ${receipt?.razorpay_payment_id || response?.razorpay_payment_id || 'N/A'}`,
                        `Personal Trainer: ${receipt.personalTrainer ? 'Yes' : 'No'}`
                    ].join('\n'),
                    margin: [0, 0, 10, 0]
                },
                {
                    width: 'auto',
                    text: [
                        `Total: ₹${totalAmount}`,
                        `Paid: ₹${totalAmount}`
                    ].join('\n'),
                    alignment: 'right'
                }
            ]
        },
        {
            text: `Total Amount in Words: Rupees ${totalAmountInWords} Only`,
            alignment: 'right',
            margin: [0, 10, 0, 10]
        },
        {
            text: `${receipt.gymDisplayName || 'Gym Name'} Terms & Conditions:`,
            bold: true,
            margin: [0, 20, 0, 5]
        },
        {
            ul: [
                'Membership rates can be revised by the management and/or Train Rex.',
                {
                    text: [
                        'Get instant updates on Train Rex, download the app: ',
                        {
                            text: 'iOS',
                            color: '#0071e3',
                            link: 'https://apps.apple.com/gb/app/train-rex/id6476014470',
                            decoration: 'underline',
                        },
                        ' or ',
                        {
                            text: 'Android',
                            color: '#34A853',
                            link: 'https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro',
                            decoration: 'underline',
                        },
                        '.'
                    ],
                }
                
                // 'No membership is refundable.'
            ]
        }
    );

    // Return the document definition
    return {
        content,
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black'
            },
            tableBody: {
                fontSize: 10
            }
        }
    };
};

const createMemberDocDefinition = (receipt, weekdays, response) => {    
    const totalAmount = receipt.amount / 100 || 0;
    const totalAmountInWords = getTotalAmountInWords(totalAmount);
    const netPrice = parseFloat((totalAmount / 1.18).toFixed(2));
    const cgst = parseFloat((0.09 * netPrice).toFixed(2));
    const sgst = parseFloat((0.09 * netPrice).toFixed(2));

    const content = [];

    if (receipt.image && typeof receipt.image === 'string' && receipt.image.trim() !== '') {
        content.push({
            image: receipt.image,
            fit: [85, 85],
            alignment: 'left',
            margin: [0, 0, 0, 10]
        });
    }

    content.push(
        {
            text: receipt.gymDisplayName || 'Gym Name',
            fontSize: 20,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10]
        },
        {
            text: [
                `${receipt.address || 'Address'}, ${receipt.cityDisplayName || 'City'}`,
                `Phone: ${receipt.gymContactNo || 'Phone'} | Email: ${receipt.gymEmail || 'Email'}`,
                `GSTIN: ${receipt.gstin || 'N/A'}`
            ].join('\n'),
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 20]
        },
        {
            text: [
                `Name: ${receipt.user? receipt.user.displayName : 'N/A'}`,
                `Phone: ${receipt.user? receipt.user.phoneNumber : 'N/A'}`
            ].join('\n'),
            margin: [0, 0, 0, 10]
        },
        {
            text: [
                `Transaction No: ${receipt?.razorpay_payment_id || response?.razorpay_payment_id || 'N/A'}`,
                'Payment Status: Paid',
                receipt?.months !== 0 ? `Start Date: ${Date.parse(receipt.startDate) ? convertISOToDDMMYYYY(receipt.startDate) : receipt.startDate}` : '', 
                receipt?.months !== 0 ? `End Date: ${Date.parse(receipt.endDate) ? convertISOToDDMMYYYY(receipt.endDate) : receipt.endDate}` : '', 
                receipt?.orderType === 'studio' ? receipt?.months === 0 ? 
                `Class on ${convertISOToDDMMYYYY(receipt?.startDate)} at ${receipt?.selectedShift}` : 
                `Classes on ${formatWeekDays(weekdays)} at ${receipt?.selectedShift}` : 
                '',
            ].join('\n'),
            margin: [0, 0, 0, 10]
        }
    );

    content.push({
        table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
                [
                    { text: 'Qty', bold: true },
                    { text: 'Start Date', bold: true, alignment: 'right' },
                    { text: 'End Date', bold: true, alignment: 'right' },
                    { text: 'Net Amount', bold: true, alignment: 'right' },
                    { text: 'CGST (9%)', bold: true, alignment: 'right' },
                    { text: 'SGST (9%)', bold: true, alignment: 'right' },
                    { text: 'Total Amount', bold: true, alignment: 'right' },
                ],
                [
                    { text: `${receipt.months || '0'} Months`, fontSize: 8 },
                    { text: Date.parse(receipt.startDate) ? convertISOToDDMMYYYY(receipt.startDate) : receipt.startDate || getDate(), alignment: 'right', fontSize: 8 },
                    { text: Date.parse(receipt.endDate) ? convertISOToDDMMYYYY(receipt.endDate) : receipt.endDate || 'N/A', alignment: 'right', fontSize: 8 },
                    { text: `₹${netPrice}`, alignment: 'right', fontSize: 8 },
                    { text: `₹${cgst}`, alignment: 'right', fontSize: 8 },
                    { text: `₹${sgst}`, alignment: 'right', fontSize: 8 },
                    { text: `₹${totalAmount}`, alignment: 'right', fontSize: 8 },
                ]
            ]
        },
        layout: {
            fillColor: '#f3f3f3',
            hLineColor: (i, node) => (i === 0 || i === node.table.body.length) ? 'black' : '#ccc',
            vLineColor: (i, node) => (i === 0 || i === node.table.widths.length) ? 'black' : '#ccc',
            hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 1 : 0.5,
            vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length) ? 1 : 0.5,
            paddingLeft: () => 4,
            paddingRight: () => 4,
            paddingTop: () => 2,
            paddingBottom: () => 2,
        }
    });

    // Fixed: Handle allMembers as an object instead of an array
    if (receipt.allMembers) {
        content.push({
            text: 'Member Information',
            bold: true,
            margin: [0, 10, 0, 5]
        });

        Object.entries(receipt.allMembers).forEach(([key, member]) => {
            content.push({
                text: [
                    `${key}: ${member.name || 'N/A'}`,
                    `Age: ${member.age || 'N/A'}`,
                    `Gender: ${member.gender || 'N/A'}`,
                    `Phone: ${member.phone || 'N/A'}`,
                    `Email: ${member.email || 'N/A'}`
                ].join('\n'),
                fontSize: 10,
                margin: [0, 0, 0, 10]
            });
        });
    }

    content.push(
        {
            columns: [
                {
                    width: '*',
                    text: [
                        `Payment Date and Time: ${formatDateTime(receipt.timestamp)}`,
                        `Receipt No: ${receipt.razorpay_payment_id ? receipt.razorpay_payment_id : 'N/A'}`,
                        `Personal Trainer: ${receipt.personalTrainer ? 'Yes' : 'No'}`
                    ].join('\n'),
                    margin: [0, 0, 10, 0]
                },
                {
                    width: 'auto',
                    text: [
                        `Total: ₹${totalAmount}`,
                        `Paid: ₹${totalAmount}`
                    ].join('\n'),
                    alignment: 'right'
                }
            ]
        },
        {
            text: `Total Amount in Words: Rupees ${totalAmountInWords} Only`,
            alignment: 'right',
            margin: [0, 10, 0, 10]
        },
        {
            text: `${receipt.gymDisplayName || 'Gym Name'} Terms & Conditions:`,
            bold: true,
            margin: [0, 20, 0, 5]
        },
        {
            ul: [
                'Membership rates can be revised by the management and/or Train Rex.',
                {
                    text: [
                        'Get instant updates on Train Rex, download the app: ',
                        {
                            text: 'iOS',
                            color: '#0071e3',
                            link: 'https://apps.apple.com/gb/app/train-rex/id6476014470',
                            decoration: 'underline',
                        },
                        ' or ',
                        {
                            text: 'Android',
                            color: '#34A853',
                            link: 'https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro',
                            decoration: 'underline',
                        },
                        '.'
                    ],
                }
            ]
        }
    );

    return {
        content,
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black'
            },
            tableBody: {
                fontSize: 10
            }
        }
    };
};


/**
 * Generates and opens the PDF.
 * 
 * @param {Object} receipt  - The receipt details.
 * @param {Object} response - The razorpay payment id.
 */
export const generatePdf = (receipt, weekdays, response) => {

    var docDefinition 
    try {
        
        if(receipt.allMembers){
            // using createMemberDocDefinition if Couple/Family Pricing
            docDefinition = createMemberDocDefinition(receipt, weekdays, response);
        } else {
            // using createDocDefinition if User is present
            docDefinition = createDocDefinition(receipt, weekdays, response);
        }
        
        // Generating PDF
        const generatedPDF = pdfMake.createPdf(docDefinition);

        // Instead of directly opening the PDF, provide options to open or download it
        generatedPDF.getBlob((blob) => {
            const blobURL = URL.createObjectURL(blob);

            // Open PDF in a new tab (within user gesture to avoid browser popup block)
            const newWindow = window.open(blobURL, '_blank');

            if (!newWindow) {
                // console.error('Failed to open new window, browser may have blocked it.');
                Alert('error', 'Popup blocked. Please allow popups for this site to view the PDF.');
            }
        });

        return generatedPDF;
    } catch (error) {
        // console.log('Error in Generating Bill PDF:', error);
    }
};


/**
 * Formats the ISO date string to a user-friendly date and time format.
 * 
 * @param {String} isoString - The ISO date string (e.g., '2024-09-14T10:44:10.581Z').
 * @returns {String} - Formatted date and time string.
 */
const formatDateTime = (isoString) => {
    const dateObj = new Date(isoString);

    // Format the date as 'DD/MM/YYYY'
    const formattedDate = dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    // Format the time as 'HH:MM AM/PM'
    const formattedTime = dateObj.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    // Return the formatted date and time
    return `${formattedDate} at ${formattedTime}`;
};


/**
     * Helper function to update bill information for a specific member.
     * @param {number} index - Index of the member's bill info to update in `billInfoArray`.
     * @param {Object} newBillInfo - New bill information for the member.
     * @param {Function} setBillInfoArray
     */
export const updateBillInfo = (index, newBillInfo, setBillInfoArray) => {
    setBillInfoArray(prev => {
        const updatedBills = [...prev];
        updatedBills[index] = newBillInfo;
        return updatedBills;
    });
};