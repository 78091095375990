import { useContext } from "react";
// import { FilterSVG, SortSVG } from "../../assets/Website_content/svg";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { Helmet } from "react-helmet-async";
// import { getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
// import { requestLocationAccess } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import StudioCard from "../../components/StudioListComponents/StudioCard";
import { ArrowLeft } from "lucide-react";
import { GymContext } from "../../hooks/FetchEverythingContext";

const StudioList = ({showUnlockGym, setShowUnlockGym}) => {
    const {allStudios : studios, USR, loading} = useContext(GymContext)
    const navigate = useNavigate();
    const { city } = useParams();
    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Find Gyms in {city} | Top Fitness Centers | Train Rex</title>
                <meta
                    name="description"
                    content={`Explore top-rated gyms and fitness centers in ${city}. Discover membership deals, free trials, and expert trainers. Start your fitness journey today with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`gyms in ${city}, fitness centers, free trials, membership deals, personal trainers`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/${city}/gyms`} />
            </Helmet>

            {/* Main Listing Section */}
            <div className={`bg-tertiary flex flex-col px-4 lg:px-6 min-h-screen pt-28 ${USR <= 1 ? 'lg:pt-28' : USR === 1.25 ? 'lg:pt-28' : USR === 1.5 ? 'lg:pt-24' : 'lg:pt-28'}`}>
                {loading ? (
                    <div className="h-[80vh] flex justify-center items-center flex-col">
                        <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                        <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>;
                    </div>
                ) : (
                    <div className={`flex gap-12 my-8 ${USR >= 1.5 ? 'lg:mx-6' : USR === 1 ? 'lg:mx-44' : USR === 1.25 ? 'lg:mx-20' : 'lg:mx-40'} min-h-screen`}>

                        <div className="flex flex-col w-full">

                            <div className="flex gap-1">
                                {window.ReactNativeWebView && 
                                    // only visible on app, takes user to the last page before the current one
                                    <div 
                                        onClick={() => navigate(-1)}>
                                        <ArrowLeft  className='text-secondary'/>
                                    </div>
                                }
                                {/* Title */}
                                <h1 className="fade-in-slide-up-delayed flex text-secondary text-xl lg:text-4xl font-bold text-left pl-2">
                                    SHOWING STUDIOS IN YOUR AREA
                                </h1>
                            </div>

                            {/* <div className={`w-full flex justify-end gap-2 -ml-2 mt-4`}>
                                <button onClick={() => setFiltersOpen(true)} className={`p-2 text-sm text-purple font-semibold bg-lightPurple rounded-lg flex items-center gap-1`}><FilterSVG color='#876eaf' height='18px' /><div>FILTER BY</div></button>
                                <button onClick={() => setSortingOpen(true)} className={`p-2 text-sm text-purple font-semibold bg-lightPurple rounded-lg flex items-center gap-1`}><SortSVG color='#876eaf' height='18px' /><div>SORT BY</div>  </button>
                            </div> */}

                            {/* List of Gyms with Fade and Slide Animation */}
                            <div className="fade-in-slide-up-delayed">
                                <div className="flex flex-wrap">
                                    {studios?.length !== 0 && studios.map((card, index) => (
                                        <StudioCard key={index} {...card} buttonText="Explore" USR={USR}/>
                                    ))}

                                    {studios?.length === 0 && (
                                        <div className="font-bold text-purple mt-40 text-2xl mx-12">
                                            Currently there are<br />no studios available that meet your requirements
                                        </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                {/* <div className="flex justify-center mt-8">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentPage(index+1)}
                                            className={`mx-1 px-3 py-1 rounded ${index + 1 === currentPage
                                                    ? "bg-secondary text-tertiary"
                                                    : "bg-tertiary text-secondary border-2 border-secondary"
                                                }`}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div> */}
                            </div>
                        </div>
                    </div>
                )
                
            }
            </div>
        </>
    )
}


export default StudioList;