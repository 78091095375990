import { useState, useEffect, useRef } from "react";

/**
 * Navigation Bar on the gym page
 * Has tabs - Prices, Ammenities, Classes and Reviews
 * 
 * @param {Object}    currGymData        -    the data of the gym
 * @param {Function}  handleTabClick     -    the function to handle the tab which the user clicks on
 * @param {Ref}       membershipPriceRef -    page ref for prices
 * @param {Ref}       amenitiesRef       -    page ref for amenities
 * @param {Ref}       reviewsRef         -    page ref for reviews
 * @param {Ref}       classesRef         -    page ref for classes
 */
const GymPageNav = ({ currGymData, handleTabClick, membershipPricesRef, amenitiesRef, reviewsRef, classesRef }) => {
    const [activeTab, setActiveTab] = useState("Prices");
    const [isSticky, setIsSticky] = useState(false);
    const [hideNav, setHideNav] = useState(false);
    const navRef = useRef(null);
    const placeholderRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current && placeholderRef.current) {
                const navTop = placeholderRef.current.getBoundingClientRect().top - 130;
                const bottomReached = window.innerHeight + window.scrollY >= document.body.offsetHeight - 130;
                setHideNav(bottomReached);
                setIsSticky(navTop <= 0 && !bottomReached);

                // Check which section is in view
                const sections = [
                    { ref: membershipPricesRef, tab: "Prices" },
                    { ref: reviewsRef, tab: "Reviews" },
                    { ref: amenitiesRef, tab: "Amenities" },
                    { ref: classesRef, tab: "Classes" }
                ];

                // Find the section that's currently most visible in the viewport
                let mostVisibleSection = null;
                let maxVisibility = -Infinity;

                sections.forEach(({ ref, tab }) => {
                    if (ref?.current) {
                        const rect = ref.current.getBoundingClientRect();
                        const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
                        
                        if (visibleHeight > maxVisibility) {
                            maxVisibility = visibleHeight;
                            mostVisibleSection = tab;
                        }
                    }
                });

                if (mostVisibleSection && mostVisibleSection !== activeTab) {
                    setActiveTab(mostVisibleSection);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        // Trigger initial check
        handleScroll();
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [activeTab, membershipPricesRef, reviewsRef, amenitiesRef, classesRef]);

    const handleClick = (tab) => {
        setActiveTab(tab);
        handleTabClick(tab);
    };

    return (
        <>
            <div 
                className="mt-8 lg:hidden" 
                ref={placeholderRef} 
                style={{ height: isSticky ? navRef.current?.offsetHeight : 0 }}
            />
            <div
                ref={navRef}
                className={`lg:hidden w-full bg-white p-1 flex mb-4 transition-all duration-300 ${
                    isSticky ? "fixed top-[14.5vh] left-0 z-20" : hideNav ? 'hidden' : ''
                }`}
            >
                <div className={`flex w-full bg-lightPurple justify-between rounded-3xl p-1 transition-all duration-500 ${isSticky ? 'scale-90' : ''}`}>
                    <div onClick={() => handleClick("Prices")} className={`font-bold px-3 py-2 text-xs ${activeTab === 'Prices' ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Prices</div>
                    <div onClick={() => handleClick('Reviews')} className={`font-bold px-3 py-2 text-xs ${activeTab === 'Reviews' ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Reviews</div>
                    <div onClick={() => handleClick('Amenities')} className={`font-bold px-3 py-2 text-xs ${activeTab === 'Amenities' ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Amenities</div>
                    <div onClick={() => handleClick('Classes')} className={`font-bold px-3 py-2 text-xs ${activeTab === 'Classes' ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Classes</div>
                </div>
            </div>
        </>
    );
};

export default GymPageNav;
