import React, { useState, useEffect } from "react";
import { calculateAge, parseHeight, parseWeight } from "../basicHelper";

const FitnessMetrics = ({ userDetails }) => {
    
    const initialAge = userDetails.dob ? calculateAge(userDetails.dob) : "";
    console.log("AGE", initialAge)

    // State to manage recalculated metrics
    const [calculatedMetrics, setCalculatedMetrics] = useState({
        bmi: "",
        bmr: "",
        tdee: "",
        idealWeight: "",
    });

    const [tooltipText, setTooltipText] = useState("");
    const [activeMetric, setActiveMetric] = useState(null); // Track the active metric

    const handleMetricClick = (metric) => {
        const tooltips = {
            bmi: "BMI is a measure of body fat based on height and weight.",
            bmr: "BMR is the number of calories your body needs to maintain basic functions at rest.",
            tdee: "TDEE is the number of calories you burn daily based on your activity level.",
            idealWeight: "Ideal weight is an estimate of the optimal weight based on height.",
        };

        if (activeMetric === metric) {
            setTooltipText("");
        } else {
            setTooltipText(tooltips[metric]);
        }

        // setTooltipText(tooltips[metric]);
        setActiveMetric(metric); // Update the active metric
    };

    // Function to calculate metrics
    const recalculateMetrics = () => {
        
        // Assume these are provided from your data (could be strings or numbers)
        const height = userDetails.height; // or "150 cm"
        const weight = userDetails.weight;    // or "50 kg"
        const age = initialAge;             // age as a number
        const gender = userDetails.gender;    // "male" or "female"
        
        // Parse height and weight into consistent units
        const parsedHeight = parseHeight(height); // in cm
        const parsedWeight = parseWeight(weight); // in kg
        console.log("Height", parsedHeight)
        console.log("Weight", parsedWeight)

        // Calculate BMI using height in meters
        const calculateBMI = () => {
            if (!parsedHeight || !parsedWeight) return "N/A";
            const heightInMeters = parsedHeight / 100;
            return (parsedWeight / (heightInMeters * heightInMeters)).toFixed(2);
        };
        
        // Calculate BMR using the Mifflin-St Jeor Equation
        const calculateBMR = () => {
            if (!parsedHeight || !parsedWeight || age === 0) return "N/A";

            return gender === "male"
            ? Math.round(88.362 + 13.397 * parsedWeight + 4.799 * parsedHeight - 5.677 * age)
            : Math.round(447.593 + 9.247 * parsedWeight + 3.098 * parsedHeight - 4.330 * age);
        };
        
        // Calculate ideal weight (using the Devine formula adjusted for metric height)
        const calculateIdealWeight = () => {
            if (!parsedHeight) return "N/A";
            // The formula uses height in cm and converts the difference from 152.4 cm (5ft) to inches.
            return gender === "male"
            ? Math.round(50 + 2.3 * ((parsedHeight - 152.4) / 2.54))
            : Math.round(45.5 + 2.3 * ((parsedHeight - 152.4) / 2.54));
        };
        
        // Calculate TDEE (Total Daily Energy Expenditure) using an activity multiplier
        const calculateTDEE = () => {
            const bmr = calculateBMR();
            const activityLevel = 1.55; // Moderate activity
            return bmr === "N/A" ? "N/A" : Math.round(bmr * activityLevel);
        };
        
        // Set the calculated metrics (this example assumes a React state setter)
        setCalculatedMetrics({
            bmi: calculateBMI(),
            bmr: calculateBMR(),
            tdee: calculateTDEE(),
            idealWeight: calculateIdealWeight(),
        });
        
    };

    // Automatically calculate metrics when component mounts
    useEffect(() => {
        recalculateMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // bg-gradient-to-b from-[#AF9FCA] to-[#8A6BBE] 
    return (
        <section className="text-purple px-4 rounded-2xl mb-6">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between space-y-6 lg:gap-x-12">
                {/* Left Section: Editable User Details */}
                <div className="lg:w-1/2">
                    {/* <h2 className="text-2xl font-bold mb-6">Your Fitness Metrics</h2> */}
                    <form className="grid grid-cols-4 gap-2">
                        <div>
                            <label className="block text-sm font-medium">Height</label>
                            <input
                            type="text"
                            value={userDetails.height}
                            disabled
                            className="w-full border-none p-2 rounded-md text-black text-center bg-gray-200"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Weight</label>
                            <input
                            type="text"
                            value={userDetails.weight}
                            disabled
                            className="w-full border-none p-2 rounded-md text-black text-center bg-gray-200"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Age</label>
                            <input
                            type="number"
                            value={initialAge}
                            disabled
                            className="w-full border-none p-2 rounded-md text-black text-center bg-gray-200"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Gender</label>
                            <input
                            type="text"
                            value={userDetails.gender}
                            disabled
                            className="w-full border-none p-2 rounded-md text-black text-center bg-gray-200"
                            />
                        </div>
                    </form>

                    {/* <button
                        onClick={recalculateMetrics}
                        className="mt-6 w-full bg-lightSecondary text-primary p-2 rounded-lg hover:bg-secondary hover:text-white"
                    >
                        Recalculate Metrics
                    </button> */}
                </div>

                {/* Right Section: Calculated Metrics */}
                <div className="lg:w-1/2">
                    {/* Tooltip Section */}
                    {tooltipText && (
                        <div className="p-4 mb-4 bg-gray-100 text-black rounded-lg shadow-md">
                            {tooltipText}
                        </div>
                    )}

                    {/* Table */}
                    <table className="w-full bg-white text-black rounded-lg shadow-md border-collapse">
                        <thead>
                            <tr className="bg-gray-100">
                                {[
                                    { label: "BMI", key: "bmi" },
                                    { label: "BMR", key: "bmr" },
                                    { label: "TDEE", key: "tdee" },
                                    { label: "Ideal Weight", key: "idealWeight" },
                                ].map((metric, idx) => (
                                    <th
                                    key={idx}
                                    className={`p-4 text-center font-semibold cursor-pointer hover:underline bg-secondary ${
                                            activeMetric === metric.key ? "text-primary" : "text-black"
                                        }`}
                                        onClick={() => handleMetricClick(metric.key)}
                                    >
                                        {metric.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-4 border-b">{calculatedMetrics.bmi}</td>
                                <td className="p-4 border-b">{calculatedMetrics.bmr}</td>
                                <td className="p-4 border-b">{calculatedMetrics.tdee}</td>
                                <td className="p-4 border-b">{`${calculatedMetrics.idealWeight} kg`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default FitnessMetrics;
