import { ChevronDown } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import PriceCard from '../../assets/Website_content/PriceCard.svg'
import { fillMissingPrices, getSellingPrice } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import { GymContext } from "../../hooks/FetchEverythingContext";
import { trackButtonClick, trackNavigation } from "../../helperFunctions/Analytics/AnalyticsHelper";
import { getBasePlanPrices } from "../../helperFunctions/PaymentPlans/getPlanPricing";

/**
 * Shows Tiles for duration of membership leading to plan (basic pro premium) selection page
 * @param {object}   currGymData        - data related to prices of the gym 
 * @param {function} toggleSummaryModal - function to go to plan selection page 
 * @param {bool}     isGymPage          - bool to check if this is gym page or studio page 
 * @returns three plan cards with duration and membershipType (single couple family) selection 
 */
const MembershipPlanTestB = ({currGymData, toggleSummaryModal, isGymPage = true}) => {
    const {USR}                                     = useContext(GymContext)
    const [dropdownOpen     ,      setDropdownOpen] = useState(false);  // For toggling dropdown
    const [membershipType   ,    setMembershipType] = useState(1);      // 1 is Single, 2 is Couple, more than 3 is family 
    const [duration         ,          setDuration] = useState(null);   // 0 means 1 day and 1, 3, 6, 12 are months
    const [classDropdownOpen, setClassDropdownOpen] = useState(false);
    const [className        ,         setClassName] = useState('');     // Zumba, etc

    const showPlanType = () => {
        if      (membershipType === 1) return 'Single'
        else if (membershipType === 2) return 'Couple'
        else if (membershipType >= 3)  return 'Family'
    }

    const getSingleCoupleOrFamily = () => {
        if     (membershipType === 1) return 'single'
        else if(membershipType === 2) {
            trackButtonClick("Couple Plan Click B");
            return 'couple'
        }
        else if(membershipType >=  3) {
            trackButtonClick("Family Plan Click B");
            return 'family'
        }
    }

    const getExclusiveName = () => {
        if      (duration === 1)  return 'exclusiveMonthlyPrice'
        else if (duration === 3)  return 'exclusiveQuarterlyPrice'
        else if (duration === 6)  return 'exclusiveHalfYearlyPrice'
        else if (duration === 12) return 'exclusiveYearlyPrice'
    }
    const getMRPName = () => {
        if      (duration === 1)  return 'monthlyPrice'
        else if (duration === 3)  return 'quarterlyPrice'
        else if (duration === 6)  return 'halfYearlyPrice'
        else if (duration === 12) return 'yearlyPrice'
    }

    function hasData(prices) {
        return Object.values(prices).some(value => value !== "");
    }

    // These are for gyms only, not studios
    const newPrices = fillMissingPrices(currGymData?.prices, isGymPage)
    
    // Checks if prices have couple and family prices
    const hasCouple = currGymData && isGymPage ? newPrices?.couple && hasData(newPrices?.couple) : false;
    const hasFamily = currGymData && isGymPage ? newPrices?.family && hasData(newPrices?.family) : false;
    
    
    const oneDayPriceExclusive = getSellingPrice(isGymPage ? newPrices?.[getSingleCoupleOrFamily()]?.exclusiveDailyPrice : currGymData?.prices?.single?.[className]?.exclusiveDailyPrice, currGymData?.isExcluive, currGymData?.gymName, currGymData?.city);
    const oneDayPriceMRP       = isGymPage ? newPrices?.[getSingleCoupleOrFamily()]?.dailyPrice : currGymData?.prices?.single?.[className]?.dailyPrice

    const fallbackPlanKey = 'single';

    // Calculates the price to pay gym for internal dashboards
    const priceToPayGym = currGymData?.isExclusive === false
    ? currGymData.commissionPercentage
    ? newPrices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] * ((100 - currGymData.commissionPercentage) / 100) || newPrices?.[fallbackPlanKey]?.[getExclusiveName()] * ((100 - currGymData.commissionPercentage) / 100)
    : newPrices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] * ((100 - 20) / 100) || newPrices?.[fallbackPlanKey]?.[getExclusiveName()] * ((100 - 20) / 100)
    : newPrices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] || newPrices?.[fallbackPlanKey]?.[getExclusiveName()];

    // Zumba, etc
    const availableClasses = Object.keys(currGymData?.prices?.single || {});

    // When user selects duration then it redirects to Payments pagr yo select the plans
    useEffect(() => {
        const basePlan = getBasePlanPrices(
            isGymPage,
            currGymData,
            getSingleCoupleOrFamily(),
            getExclusiveName(),
            getMRPName(),
            className,
        )
        
        if(basePlan?.mrp) {
            if(isGymPage) 
                toggleSummaryModal(duration, basePlan.sellingPrice, basePlan.mrp, getSingleCoupleOrFamily(), priceToPayGym, 'B') 
            else
                toggleSummaryModal(duration, basePlan.sellingPrice, basePlan.mrp, className, getSingleCoupleOrFamily(), priceToPayGym, 'B')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration])

    return (
        <>
            <div className={`flex flex-col w-full ${isGymPage ? 'px-0' : USR >= 1.5 ? 'px-4 lg:px-10' : USR === 1 ? 'px-4 lg:px-44' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'}`}>
                {(className !== '' || isGymPage) && oneDayPriceExclusive > 0 && 
                <button
                    onClick={() => isGymPage ? toggleSummaryModal(0, oneDayPriceExclusive, oneDayPriceMRP, 'single', priceToPayGym, 'B') : toggleSummaryModal(0, oneDayPriceExclusive, oneDayPriceMRP, className, 'single', priceToPayGym, 'B')}
                    className="w-full flex justify-between bg-gradient-to-tr from-white to-secondary rounded-full p-3 shadow-lg">
                    <div className="text-purple text-xl font-semibold ml-10">1 Day Pass</div>
                    <div className="rounded-full bg-secondary text-sm text-white px-6 py-1">₹{oneDayPriceExclusive} / Day</div>
                </button>}
                
                {/* Heading */}
                <div className={`w-full flex justify-between items-center mt-4 lg:mt-8`}>
                    <div className='font-semibold text-xl whitespace-nowrap'>Monthly Membership</div>

                    {/* Dropdown Button */}
                    <div className="relative">
                        {isGymPage && 
                        <div onClick={() => setDropdownOpen(!dropdownOpen)} className="flex gap-1 items-center px-6 py-1 rounded-full text-white bg-purple text-sm cursor-pointer" >
                            <div>{showPlanType()}</div>
                            {(hasCouple || hasFamily) && <ChevronDown className={`text-white transition-transform ${dropdownOpen ? "rotate-180" : ""}`} />}
                        </div>}

                        {/* Gym Dropdown Menu */}
                        {dropdownOpen && (
                            <div className="absolute right-0 mt-1 w-32 bg-white text-black shadow-md rounded-lg overflow-hidden z-10">
                                <div onClick={() => {setMembershipType(1); setDropdownOpen(false); }} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer" >
                                    Single
                                </div>
                                <div onClick={() => {setMembershipType(2); setDropdownOpen(false);}} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer" >
                                    Couple
                                </div>
                                {hasFamily && <div onClick={() => {setMembershipType(3); setDropdownOpen(false); }} className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer" >
                                    Family
                                </div>}
                            </div>
                        )}
                    </div>

                    {/* Class Type Dropdown */}
                    {!isGymPage && 
                    <div className="relative">
                        <div
                            className="flex gap-1 items-center px-6 py-1 rounded-full bg-lightSecondary text-black text-sm cursor-pointer"
                            onClick={() => setClassDropdownOpen(!classDropdownOpen)}
                        >
                            <div>{className ? className.charAt(0).toUpperCase() + className.slice(1) : "Select Class"}</div>
                            <ChevronDown className={`text-black transition-transform ${classDropdownOpen ? "rotate-180" : ""}`} />
                        </div>
                        {classDropdownOpen && <div className="absolute right-0 mt-1 w-40 bg-white text-black shadow-md rounded-lg overflow-hidden z-10">
                                {availableClasses.map((cls) => (
                                    <div
                                        key={cls}
                                        onClick={() => { setClassName(cls); setClassDropdownOpen(false); setDuration(null); }}
                                        className="px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer"
                                    >
                                        {cls.charAt(0).toUpperCase() + cls.slice(1)}
                                    </div>
                                ))}
                        </div>}
                    </div>}
                </div>

                {/* Gym Duration */}
                {isGymPage && (
                <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 mt-4">
                    {[
                    { duration: 1, priceKey: "monthlyPrice", label: "1 Month", clickMessage: "1 month Gym Button Click B", navPlan: "Navigation for 1 month Plan B", },
                    { duration: 3, priceKey: "quarterlyPrice", label: "3 Months", clickMessage: "3 months Gym Button Click B", navPlan: "Navigation for 3 months Plan B", },
                    { duration: 6, priceKey: "halfYearlyPrice", label: "6 Months", clickMessage: "6 months Gym Button Click B", navPlan: "Navigation for 6 months Plan B", },
                    { duration: 12, priceKey: "yearlyPrice", label: "12 Months", clickMessage: "12 months Gym Button Click B", navPlan: "Navigation for 12 months Plan B", },
                    ].map((plan) =>
                    currGymData.prices?.[getSingleCoupleOrFamily()]?.[plan.priceKey] ? (
                        <div
                            key={plan.duration}
                            onClick={() => {
                                trackButtonClick(plan.clickMessage);
                                trackNavigation(`/${currGymData?.city.toLowerCase()}/gyms/${currGymData?.gymName}`,`/${currGymData?.city.toLowerCase()}/${currGymData?.gymName}/paymentDetails`,plan.navPlan);
                                setDuration(plan.duration);
                            }}
                            className="cursor-pointer relative rounded-2xl w-full h-[40vw] lg:w-[20vw] lg:h-[20vw] flex justify-center items-center overflow-hidden"
                        >
                            <img src={PriceCard} alt="month" className="absolute w-full h-full left-0 top-0 z-[1]" />
                            <div className="text-white font-semibold whitespace-nowrap text-xl relative z-[2]">
                                {plan.label}
                            </div>
                        </div>
                    ) : null
                    )}
                </div>
                )}

                {/* Studio Duration */}
                {!isGymPage && className !== '' && (
                <div className="w-full grid grid-cols-2 gap-8 mt-4">
                    {[
                    { duration: 1,  priceKey: "monthlyPrice",    label: "1 Month", tracking: "1 month Studio Button Click B", },
                    { duration: 3,  priceKey: "quarterlyPrice",  label: "3 Months", },
                    { duration: 6,  priceKey: "halfYearlyPrice", label: "6 Months", },
                    { duration: 12, priceKey: "yearlyPrice",     label: "12 Months", },
                    ].map((plan) =>
                    currGymData.prices.single?.[className]?.[plan.priceKey] ? (
                        <div
                            key={plan.duration}
                            onClick={() => {
                                if (plan.tracking) trackButtonClick(plan.tracking);
                                setDuration(plan.duration);
                            }}
                            className="relative rounded-2xl w-full h-[40vw] lg:w-[35vw] lg:h-[35vw] flex justify-center items-center overflow-hidden"
                        >
                            <img src={PriceCard} alt="month" className="absolute w-full h-full left-0 top-0 z-[1]"/>
                            <div className="text-white font-semibold whitespace-nowrap text-xl relative z-[2]">
                                {plan.label}
                            </div>
                        </div>
                    ) : null
                    )}
                </div>
                )}
            </div>
        </>
    )
}

export default MembershipPlanTestB;