import React, { useContext, useEffect, useState } from "react";
import { GymContext } from "../../../hooks/FetchEverythingContext";

/**
 * 
 * @param {bool} showCancellationPolicy         - true: modal is visible, fasle: modal is not visible 
 * @param {function}  setShowCancellationPolicy - toggling between showCancellationPolicy as true and false
 * @returns 
 */
const CancellationPolicyModal = ({ showCancellationPolicy, setShowCancellationPolicy }) => {
    const { USR } = useContext(GymContext)
    const [isRendered, setIsRendered] = useState(false);
    const [show1MonthPolicy ,  setShow1MonthPolicy] = useState(false);
    const [show3MonthPolicy ,  setShow3MonthPolicy] = useState(false);
    const [show6MonthPolicy ,  setShow6MonthPolicy] = useState(false);
    const [show12MonthPolicy, setShow12MonthPolicy] = useState(false);

    // Handle the transition effect
    useEffect(() => {
        if (showCancellationPolicy) {
            setIsRendered(true);
        } else {
            const timer = setTimeout(() => setIsRendered(false), 300); // Match fade-out duration
            return () => clearTimeout(timer);
        }
    }, [showCancellationPolicy]);

    // Do not render the modal until the transition is completed for closing
    if (!isRendered) return null;

    const isProfilePage = window.location.href.includes('profile');

    return (
        <div className={`fixed ${isProfilePage ? 'inset-0' : '-inset-0'} left-0 z-[80] w-full h-screen bg-black bg-opacity-70 flex justify-center items-center`}>
            <div className={`bg-gradient-to-b from-secondary to-white rounded-lg p-6 w-11/12 md:w-1/2 ${USR === 1 ? 'lg:w-1/3' : USR === 1.25 ? 'lg:w-7/12' : USR === 1.5 ? 'lg:w-2/3' : 'lg:w-2/5'} text-center shadow-lg transform transition-transform duration-300 scale-100`}>
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Cancellation Policy</h2>
                <div className="pb-6 h-96 no-scrollbar overflow-y-scroll text-left">
                    <p className="font-semibold">At Train Rex, we strive to provide flexibility and transparency to our members.</p>
                    <p className="mt-1">Below are the refund terms for cancellations based on your membership duration:</p>

                    <div onClick={() => setShow1MonthPolicy(!show1MonthPolicy)} className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <div className="flex items-center justify-between">
                            <p className="mt-4 font-semibold">1-Month Membership</p>
                            <div className={`${show1MonthPolicy ? '' : 'rotate-180'}`}>^</div>
                        </div>
                        {show1MonthPolicy && <hr className={`my-1`}/>}
                        <p className={`${show1MonthPolicy ? 'block' : 'hidden'}`}>- Cancel on or before 1 week: Receive up to 33% refund.</p>
                    </div>

                    <div onClick={() => setShow3MonthPolicy(!show3MonthPolicy)} className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <div className="flex items-center justify-between">
                            <p className="mt-4 font-semibold">3-Month Membership</p>
                            <div className={`${show3MonthPolicy ? '' : 'rotate-180'}`}>^</div>
                        </div>
                        {show3MonthPolicy && <hr className={`my-1`}/>}
                        <p className={`${show3MonthPolicy ? 'block' : 'hidden'}`}>- Cancel within 2 weeks: Receive up to 66% refund.</p>
                        <p className={`${show3MonthPolicy ? 'block' : 'hidden'}`}>- ⁠Cancel within 4 weeks: Receive up to 33% refund.</p>
                    </div>

                    <div onClick={() => setShow6MonthPolicy(!show6MonthPolicy)} className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <div className="flex items-center justify-between">
                            <p className="mt-4 font-semibold">6-Month Membership</p>
                            <div className={`${show6MonthPolicy ? '' : 'rotate-180'}`}>^</div>
                        </div>
                        {show6MonthPolicy && <hr className={`my-1`}/>}
                        <p className={`${show6MonthPolicy ? 'block' : 'hidden'}`}>- Cancel within 1 month: Receive up to 66% refund.</p>
                        <p className={`${show6MonthPolicy ? 'block' : 'hidden'}`}>- Cancel within 2 months: Receive up to 33% refund.</p>
                        <p className={`${show6MonthPolicy ? 'block' : 'hidden'}`}>- ⁠Cancel within 3 months: Receive up to 10% refund.</p>
                    </div>

                    <div onClick={() => setShow12MonthPolicy(!show12MonthPolicy)} className="bg-secondary rounded-xl text-white px-3 py- flex flex-col mt-1 pb-2">
                        <div className="flex items-center justify-between">
                            <p className="mt-4 font-semibold">12-Month Membership</p>
                            <div className={`${show12MonthPolicy ? '' : 'rotate-180'}`}>^</div>
                        </div>
                        {show12MonthPolicy && <hr className={`my-1`}/>}
                        <p className={`${show12MonthPolicy ? 'block' : 'hidden'}`}>- Cancel within 1 month: Receive up to 66% refund.</p>
                        <p className={`${show12MonthPolicy ? 'block' : 'hidden'}`}>- ⁠Cancel within 3 months: Receive up to 33% refund.</p>
                        <p className={`${show12MonthPolicy ? 'block' : 'hidden'}`}>- ⁠Cancel within 6 months: Receive up to 10% refund.</p>
                    </div>

                    <p className="mt-4 font-semibold">Additional Notes:</p>
                    <p className="">- Refunds are calculated based on the time of cancellation relative to your membership start date.</p>
                    <p className="">- “Up to" indicates the maximum refund amount, which may vary based on specific circumstances or usage.</p>
                    <p className="">- ⁠Refunds will be processed within 7 business days from the cancellation date.</p>

                    <p className="mt-4 font-semibold">For any questions or to request a cancellation: </p>
                    <p className="">Please contact us at +91-7827145068 or +91-9810060918 </p>
                    <p className="">or email us at info@trainrex.org.</p>

                    <p className="mt-4 text-center">T&C apply.</p>
                </div>
                <button
                    onClick={() => setShowCancellationPolicy(false)}
                    className="bg-secondary text-white font-semibold px-6 py-2 rounded-lg mt-2"
                >
                    Close
                </button>
            </div>
        </div>

    );
};

export default CancellationPolicyModal;