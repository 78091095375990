import { calculateDistance } from "../MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";

/**
 * Maximum scores for normalization of gym attributes.
 * Modify these values to adjust the scaling of attributes.
 */
const maxScores = {
    MAX_DISTANCE            : 2,    // Maximum relevant distance in km
    MAX_RATING              : 5,     // Maximum gym rating
    MAX_REVIEWS             : 10,   // Maximum relevant review count
    MAX_FACILITIES          : 20,    // Maximum number of facilities
    MAX_EQUIPMENT           : 20,    // Maximum number of equipment
    MAX_TOTAL_VIEWS         : 500,   // Maximum relevant total views
    MAX_WEEKLY_VIEWS        : 80,    // Maximum relevant weekly views
    MAX_TOTAL_MEMBERSHIP    : 5,   // Maximum relevant total memberships
    MAX_WEEKLY_MEMBERSHIP   : 2,    // Maximum relevant weekly memberships
};

/**
 * Weights for each scoring attribute.
 * Adjust these weights to prioritize specific attributes.
 */
const WEIGHTS = {
    budget              : 0.1,
    distance            : 0.25,
    rating              : 0.15,
    reviews             : 0.1,
    facilities          : 0.1,
    equipment           : 0.1,
    totalViews          : 0.05,
    thisWeekViews       : 0.05,
    totalMembership     : 0.05,
    thisWeekMembership  : 0.05,
};

/**
 * Smart sorting for gyms based on multiple criteria like budget, distance, facilities, etc.
 *
 * @param   {Array} gymList       - List of gyms to sort.
 * @param   {Object} formDeets    - User preferences including budget, timing, etc.
 * @param   {Object} userLocation - User's location with latitude and longitude.
 * @returns {Array}               - Sorted list of gyms based on composite score.
 */
export const sortBySmartCriteria = (gymList, formDeets, userLocation) => {
    const { budget } = formDeets;

    // Score each gym
    const scoredGyms = gymList.map((gym) => {
        let price = gym.prices?.single?.monthlyPrice ? gym.prices?.single?.monthlyPrice : Infinity
        const budgetScore       = getBudgetScore(price, budget);
        const distanceAndScore  = getDistanceScore(gym.latitude, gym.longitude, userLocation);
        const ratingScore       = getRatingScore(gym.rating);
        const reviewsScore      = getReviewsScore(gym.NoOfReview);
        const facilitiesScore   = getFacilitiesScore(gym.facilities);
        const equipmentScore    = getEquipmentScore(gym.equipmentList);
        const viewsScore        = getViewsScore(gym.totalViews, gym.thisWeekViews);
        const membershipScore   = getMembershipScore(gym.totalMembershipSold, gym.thisWeekMembershipSold);

        // Composite score
        const compositeScore =
            WEIGHTS.budget              * budgetScore                                               +
            WEIGHTS.distance            * (distanceAndScore.score? distanceAndScore.score : 0)      +
            WEIGHTS.rating              * ratingScore                                               +
            WEIGHTS.reviews             * reviewsScore                                              +
            WEIGHTS.facilities          * facilitiesScore                                           +
            WEIGHTS.equipment           * equipmentScore                                            +
            WEIGHTS.totalViews          * viewsScore.total                                          +
            WEIGHTS.thisWeekViews       * viewsScore.weekly                                         +
            WEIGHTS.totalMembership     * membershipScore.total                                     +
            WEIGHTS.thisWeekMembership  * membershipScore.weekly                                    ;

        return { ...gym, compositeScore, distance: distanceAndScore.distance };
    });

    // Sort gyms by composite score (descending)
    let sortedGyms = scoredGyms.sort((a, b) => b.compositeScore - a.compositeScore);

    // Remove gyms with distance > maxScores.MAX_DISTANCE + 1 until the list has at least 3 gyms
    // const maxDistance = maxScores.MAX_DISTANCE + 1;
    // while (sortedGyms.length > 3 && sortedGyms[sortedGyms.length - 1].distance > maxDistance) {
    //     sortedGyms.pop(); // Remove the last gym
    // }

    return sortedGyms;
};


/**
 * Scores the gym based on its distance from the user.
 * 
 * @param   {String} latitude       - Latitude of the gym.
 * @param   {String} longitude      - Longitude of the gym.
 * @param   {Object} userLocation   - User's location with latitude and longitude.
 * @returns {Dict}   score          - Normalized score (0 to 1).
 *                   distance       - Distance from user location to gym in km
 */
const getDistanceScore = (latitude, longitude, userLocation) => {
    const distance = parseFloat(calculateDistance(userLocation, latitude, longitude));
    return {
        score       :   1 - Math.min(distance / maxScores.MAX_DISTANCE, 1), // Closer gyms get higher scores
        distance    :   distance,
    }; 
};

/**
 * Scores the gym based on its alignment with the user's budget.
 * 
 * @param   {Number} monthlyPrice   - Monthly price of the gym.
 * @param   {Array} budget          - User's budget range [min, max].
 * @returns {Number}                - Normalized score (0 to 1).
 */
const getBudgetScore = (monthlyPrice, budget) => {
    const price = monthlyPrice || Infinity;
    if (price >= budget[0] && price <= budget[1]) return 1; // Perfect match
    if (price < budget[0]) return price / budget[0]; // Normalize for below range
    return budget[1] / price; // Normalize for above range
};

/**
 * Scores the gym based on its average rating.
 * 
 * @param   {Number} rating - Average rating of the gym.
 * @returns {Number}        - Normalized score (0 to 1).
 */
const getRatingScore = (rating) => {
    return Math.min((rating || 0) / maxScores.MAX_RATING, 1);
};

/**
 * Scores the gym based on the number of reviews.
 * 
 * @param   {Number} reviews - Number of reviews for the gym.
 * @returns {Number}         - Normalized score (0 to 1).
 */
const getReviewsScore = (reviews) => {
    return Math.min((reviews || 0) / maxScores.MAX_REVIEWS, 1);
};

/**
 * Scores the gym based on the number of facilities.
 * 
 * @param   {Object} facilities - Facilities available at the gym.
 * @returns {Number}            - Normalized score (0 to 1).
 */
const getFacilitiesScore = (facilities) => {
    const facilityCount = Object.values(facilities || {}).filter(Boolean).length;
    return Math.min(facilityCount / maxScores.MAX_FACILITIES, 1);
};

/**
 * Scores the gym based on the number of equipment.
 * 
 * @param   {Object} equipmentList - Equipment available at the gym.
 * @returns {Number}               - Normalized score (0 to 1).
 */
const getEquipmentScore = (equipmentList) => {
    const equipmentCount = Object.values(equipmentList || {}).filter(Boolean).length;
    return Math.min(equipmentCount / maxScores.MAX_EQUIPMENT, 1);
};

/**
 * Scores the gym based on total and weekly views.
 * 
 * @param   {Number} totalViews     - Total number of views for the gym.
 * @param   {Number} thisWeekViews  - Views for the gym this week.
 * @returns {Object}                - Object with `total` and `weekly` scores.
 */
const getViewsScore = (totalViews, thisWeekViews) => {
    return {
        total   : Math.min((totalViews || 0) / maxScores.MAX_TOTAL_VIEWS, 1),
        weekly  : Math.min((thisWeekViews || 0) / maxScores.MAX_WEEKLY_VIEWS, 1),
    };
};

/**
 * Scores the gym based on total and weekly membership sales.
 * 
 * @param   {Number} totalMembership     - Total memberships sold by the gym.
 * @param   {Number} thisWeekMembership  - Memberships sold by the gym this week.
 * @returns {Object}                     - Object with `total` and `weekly` scores.
 */
const getMembershipScore = (totalMembership, thisWeekMembership) => {
    return {
        total   : Math.min((totalMembership || 0) / maxScores.MAX_TOTAL_MEMBERSHIP, 1),
        weekly  : Math.min((thisWeekMembership || 0) / maxScores.MAX_WEEKLY_MEMBERSHIP, 1),
    };
};
