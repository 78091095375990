import toast from "react-hot-toast";
import { checkDiscountCodeValidity } from "./DiscountHelpers"

/**
 * Checks if the coupon is valid or not
 * @param {String} discountCode     -   Discount code added
 * @param {Object} paymentDetails   -   Details required to validate the coupon
 */
export const checkIfCouponIsValid = async(discountCode, paymentDetails) => {
    console.log(paymentDetails);
    // Fetch Coupon from the database
    const discountCodeData = await checkDiscountCodeValidity(discountCode); // Also checks if the code still exists

    if (!discountCodeData) {
        toast.error('This code doesn\'t exist or is expired.');
        return {};
    }

    // Check if the discount coupon matches the min and max duration
    if (
        // The code should return false, if minDuration required is greater than payment duration
        discountCodeData.minDuration    >   paymentDetails.months    
        || // OR
        // The code should return false, if maxDuration required is lesser than payment duration
        discountCode.maxDuration        <   paymentDetails.months
    ) {
        toast.error(`The code supports duration between ${discountCodeData.minDuration} - ${discountCodeData.maxDuration} months`);
        return {};
    }

    // Check if this plan is acceptable for the discount to work
    const planMap = ["basic", "pro", "premium"];
    // Only proceed if plan is a valid number within the range
    if (
        typeof paymentDetails.plan === "number" &&
        paymentDetails.plan >= 0 &&
        paymentDetails.plan < planMap.length
    ) {
        const plan = planMap[paymentDetails.plan];

        if (
            Array.isArray(discountCodeData.plansIncluded) &&
            !discountCodeData.plansIncluded.map(p => p.toLowerCase()).includes(plan)
        ) {
            toast.error(`This code is only valid for plans: ${discountCodeData.plansIncluded.join(", ")}`);
            return {};
        }
    }

    return discountCodeData;

}