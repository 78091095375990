import { storeUserData } from "./userDatabaseHelper";
import { storeGymData, storeUnknownUserInGym } from "./gymDatabaseHelper";
import { db } from "../config/firebase";
import { cityList, gymList, studioList } from "../components/Databases";
import { collection, doc, getDoc, getDocs, increment, limit, orderBy, query, updateDoc, where } from "@firebase/firestore";
import { getCurrentMonthAndYear } from "./basicHelper";
import { updateMemberShipBoughtCount } from "./gymDatabaseHelper";
import Alert from "../components/customComponents/Alert";
import { buyMembershipErrorEmail } from "./CommunicationHelpers/ErrorEmailToTrainRex";

/**
 * updates boughtMembership field in LeadInfo user's latest document
 * @param {Object} myData - user data including amount for membership, city, gym contact number, gym name, image and number of months of membership
 */
const updateBoughtMembership = async (myData) => {
    try {
        // Reference to the collection containing user leads
        const leadsRef = collection(db, cityList, myData.city, myData?.orderType === 'studio' ? studioList : gymList, myData.gymName, 'Leads', getCurrentMonthAndYear(), 'LeadInfo');
        const convertedLeadsRef = doc(db, cityList, myData.city, myData?.orderType === 'studio' ? studioList : gymList, myData.gymName, 'Leads', getCurrentMonthAndYear());

        // Get a reference to the user document
        const userRef = doc(db, "user", myData.user.uid);
        const userSnap = await getDoc(userRef); 
        const userData = userSnap.data(); // Get the document data
        const email = userData.email; // Access the email field

        // Query to find the user's document by email, sorted by the latest timestamp, and limit to 1
        // To configure the latest document of the user
        // BoughtMembership : True
        const leadsQuery = query(
            leadsRef,
            where('email', '==', email),
            orderBy('timestamp', 'desc'),
            limit(1)
        );

        // Execute the query to get the document
        const querySnapshot = await getDocs(leadsQuery);

        if (!querySnapshot.empty) {
            // Get the first (latest) document from the query result
            const userDoc = querySnapshot.docs[0];

            const userData = userDoc.data()

            // Update the boughtMembership field in the document
            await updateDoc(userDoc.ref, {
                boughtMembership: true
            });

            // If boughtMembership was already true then, 
            // Do not increment
            if (!userData.boughtMembership) {
                // If not, 
                // Increment the 'converted' field in the convertedLeads document
                await updateDoc(convertedLeadsRef, {
                    converted: increment(1) // Increments the 'converted' field by 1
                });
            }

        } else {
            // console.log('No document found for the user with email:', myData.user.email);
        }
        return true;
    } catch (error) {
        // console.error('Error updating boughtMembership field:', error);
        // throw new Error('Could not update membership status');
        // console.log('Error in updating leads');
        return false;
    }
}

/**
 * Stores data in firestore
 * Is called when a payment is successfully made
 * @param   {dict} myData - userID, city, gymName, amount, months
 * @param   {dict} response - Razorpay Receipt
 * @param   {Function} setBillInfo - sets the bill information
 * @returns true; if save successful, false; otherwise
 */
export const paymentSuccessHandler = async (myData, response, setBillInfo) => {
    let userBool            =   false;
    let gymBool             =   false;
    let userResponseData    =   [];
    let gymResonseData      =   [];

    // For debugging purposes only
    // console.log('myData.user.uid : ', myData.user.uid);


    if (myData.user.uid) {
        /**
         * This segment is for users present in Train Rex's Database
         */
        // store data in 'user' database
        userResponseData    = await storeUserData(myData, response);
        userBool            = userResponseData.status;
        
        // store data in gym's database
        gymResonseData      = await storeGymData(myData, response, userResponseData);
        gymBool             = gymResonseData.status;
    }
    else {
        /** 
         * This segment is for users not present in Train Rex's Database
         */
        userResponseData    =   await storeUnknownUserInGym(myData);
        gymBool             =   userResponseData.status;
        userBool            =   true;
    }

    // For debugging purposes only
    // console.log('gymBool : ', gymBool, ', userBool : ', userBool);

    // Update the user's membership status after both user and gym data have been successfully stored
    if (userBool && gymBool) {
        try {
            // Update Leads if uid exists
            if (myData.user.uid)
                await updateBoughtMembership(myData); // Call function to update membership status in the database
            
            // Bill now is generated from the success modal
            const billInfo = {
                ...myData,
                startDate   : userResponseData.startDate,
                endDate     : userResponseData.endDate,
                response    : response,
            };
            
            // Update the bill info state
            setBillInfo(billInfo);
            
            await updateMemberShipBoughtCount(myData.city, myData.gymName, myData.orderType);
            // alert(`Payment Successful! \nw/ Payment ID: ${response.razorpay_payment_id}`);
            return ({
                status      :   true,
                userName    :   myData.user.displayName,
            });
        } catch (error) {
            Alert('error', 'Membership status update failed. Please contact support.');
            buyMembershipErrorEmail(myData, error);
        }
    } else {
        Alert('error', 'There was an issue completing your membership. Please try again.');
        buyMembershipErrorEmail(myData, `userBool : ${userBool ? userBool : userResponseData.errorMessage ? userResponseData.errorMessage : 'false'}, gymBool : ${gymBool ? gymBool : gymResonseData.errorMessage ? gymResonseData.errorMessage : 'false'}`);
    }
    return ({
        status      :   false,
        userName    :   myData.user.displayName,
    });
};


/**
 * Function to handle payment failure
 */
export const handlePaymentFailure = (setIsPaymentProcessing) => {
    // Implement your logic for handling payment failures here
    // For example, you could log the failure, alert the user, or retry the payment
    setIsPaymentProcessing(false);
    Alert('error', 'Payment failed or was cancelled. Please try again.');
};