/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { ReactComponent as TR_MainLogo } from '../assets/TR_Written.svg'; // Logo
import { CalendarIcon } from '../assets/Website_content/svg'
import FamilyModal from '../components/FamilyModal';
import SelectedCoupon from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/SelectedCoupon';
import CouponModal from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/CouponModal';
import { themeColors } from '../theme';
import { Calendar } from 'lucide-react';
import { convertDDMMYYYYToTimestampMilliSeconds, convertMilliSecondsTimestampToISOFormat, convertTo12HourFormat, getAvailableDates, getDate, getDate2, getDatesForDays } from '../helperFunctions/basicHelper';
import SelectDate from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/SelectDate';
import DatePicker from 'react-datepicker';
import Alert from '../components/customComponents/Alert';
import GetUserMissingDetailsModal from './GetUserMissingDetailsModal';
import { checkIfUserHasNecessaryData } from '../helperFunctions/userHelper';

/**
 * PaymentConfirmationModal
 * Displays the payment confirmation details, such as gym name, membership duration, and pricing.
 * Allows users to select and apply a coupon, shows total savings, and applies Trex credits.
 * 
 * @param {object} receiptInfo             - Contains gym name, base price, membership duration, and MRP.
 * @param {function} onClose               - Function to close the modal.
 * @param {function} handleMembershipButton- Function to handle payment after confirmation.
 * @param {number} totalMemberships        - The total number of memberships selected by the user.
 * @param {string} selectedCategory        - Category of membership selected, such as "family" or "couple".
 * @param {boolean} isUserPresent          - Boolean indicating if the user is logged in.
 * @param {object} user                    - User information, including UID for fetching Trex balance.
 * @param {function} setSignInModal        - Function to open the sign-in modal when needed.
 * @returns {JSX.Element} The payment confirmation modal.
 */
const PaymentConfirmationModal = ({
    receiptInfo,
    onClose,
    handleMembershipButton,
    totalMemberships,
    selectedCategory,
    isUserPresent,
    user,
    setSignInModal,
    gymData,
    USR
}) => {
    const [selectedCoupon                ,                 setSelectedCoupon] = useState(null);                       // Stores the currently selected coupon
    const [totalAmount                   ,                    setTotalAmount] = useState(receiptInfo.amountToPay * totalMemberships);    // Stores the total amount after coupon
    const [showCouponModal               ,                setShowCouponModal] = useState(false);                      // Manages the visibility of the coupon modal
    const [trex                          ,                           setTrex] = useState(0);                          // Stores the user's Trex
    const [trexToBeUsed                  ,                   setTrexToBeUsed] = useState(false);                      // Tracks if Trex will be used
    const [appliedTrexAmount             ,              setAppliedTrexAmount] = useState(0);                          // Amount of Trex used in the discount
    const [showFamilyModal               ,                setShowFamilyModal] = useState(false);                      // Toggles displaying Family Modal
    const [totalMembers                  ,                   setTotalMembers] = useState(1);                          // Number of members buying the gym membership
    const [referralGiver                 ,                  setReferralGiver] = useState('');                           // Stores referral giver details
    // Important: selected date for gym and studios are of different formats because the calendar used in them uses different date formats
    // Have to send selectedDate as timestamp ahead, initially studio uses date object, gyms use 'DD-MM-YYYY'
    const [selectedDate                  ,                   setSelectedDate] = useState(receiptInfo?.className ? new Date(getDate2()) : getDate());
    const [showDatePicker                ,                 setShowDatePicker] = useState(false);
    const [payAtTheCounter               ,                setPayAtTheCounter] = useState(false);
    const [availableClasses              ,              setAvailableClasses ] = useState([]);
    const [selectedShift                 ,              setSelectedShift    ] = useState('');
    const [isClassAvailable              ,              setIsClassAvailable ] = useState(false);
    const [doesUserHasNecessaryData      ,       setDoesUserHasNecessaryData] = useState();
    const [showGetUserMissingDetailsModal, setShowGetUserMissingDetailsModal] = useState(false);

    
    const baseAmount = receiptInfo.amountToPay * totalMemberships;
    // let isMac = false;
    const mrp = parseInt(receiptInfo.mrp * totalMemberships, 10);
    
    const [totalMoneySaved      ,   setTotalMoneySaved]     = useState(mrp - baseAmount);  // Starts as MRP - initial amount to pay
    
    // returns array of classes days ['monday', 'thursday' ]
    const availableWeekDays = receiptInfo?.className ? getAvailableDates(
        receiptInfo?.className,
        receiptInfo.classesSchedule
    ) : null;


    // return array of all the available dates for the class from now to 30 days ahead
    const highlightedDates = receiptInfo?.className ? getDatesForDays(availableWeekDays) : null;

    
    useEffect(() => {
        if (selectedCategory === 'family') setTotalMembers(4);
        else if (selectedCategory === 'couple') setTotalMembers(2);        
    }, [selectedCategory]);

    // Fetch Trex on component mount or when user changes
    useEffect(() => {
        const fetchTrex = async () => {
            if (user?.uid) {
                setTrex(user.trexCredits);  
            }
        };

        if (user?.uid) {
            fetchTrex();
        }
    }, [user]);

    /**
     * handleCouponSelection
     * Applies the selected coupon and adjusts the total amount accordingly.
     * 
     * @param {object} coupon - The coupon object containing discount details.
     */
    const handleCouponSelection = (coupon) => {
        const discount = coupon.type === 'percentage'
            ? Math.min((coupon.value / 100) * baseAmount, coupon.maxDiscount)
            : coupon.value;

        const discountedAmount = baseAmount - discount;
        setTotalAmount(discountedAmount - appliedTrexAmount);
        setTotalMoneySaved(mrp - discountedAmount);
        setSelectedCoupon({ ...coupon, discountAmount: discount });
        // Do not close the modal
        // setShowCouponModal(false);
    };

    /**
     * handleReferralCode
     * Fetches details of the referral giver based on the referral code provided.
     * 
     * @param {object} referralCodeDetails - Details of the referral code used.
     */
    const handleReferralCode = async (referralCodeDetails) => {
        setReferralGiver({
            userID  :   referralCodeDetails.userID,
            code    :   referralCodeDetails.code
        });
    };

    /**
     * removeCoupon
     * Removes the applied coupon and resets the total amount to the original base amount.
     */
    const removeCoupon = () => {
        setTotalAmount(baseAmount - appliedTrexAmount);
        setSelectedCoupon(null);
        setTotalMoneySaved(mrp - baseAmount);
    };

    /**
     * toggleTrexToBeUsed
     * Toggles the usage of Trex credits, applying or removing Trex discounts as needed.
     * Ensures that Trex usage doesn’t reduce `totalAmount` below zero.
     */
    const toggleTrexToBeUsed = () => {
        const discount          = selectedCoupon ? selectedCoupon.discountAmount : 0;
        const amountAfterCoupon = baseAmount - discount;
        // Problem :  Razorpay doesn't accept 0 as an amount, 
        // thus, the max trex user can use takes down the amount to minimum Rs1 and not Rs0
        const maxTrexUsage    = Math.min(trex, amountAfterCoupon - 1); // -1 because razorpay doesn't accept 0 as an amount

        if (trexToBeUsed) {
            // Remove Trex discount
            setTotalAmount(amountAfterCoupon);
            setTotalMoneySaved(totalMoneySaved - appliedTrexAmount);
            setAppliedTrexAmount(0);
        } else {
            // Apply Trex discount
            setTotalAmount(amountAfterCoupon - maxTrexUsage);
            setTotalMoneySaved(totalMoneySaved + maxTrexUsage);
            setAppliedTrexAmount(maxTrexUsage);
        }

        setTrexToBeUsed(!trexToBeUsed);
    };

    const closeModal = () => setShowFamilyModal(false);

    useEffect(() => {
        const result = checkIfUserHasNecessaryData(user)
        setDoesUserHasNecessaryData(result);
      }, [user])

    // console.log("Selected Date : " , selectedDate )
    // console.log("Selected DAte : ", convertDDMMYYYYToTimestampMilliSeconds(selectedDate)
      
    /**
     * handleNextStep
     * Handles the payment process and opens the family modal if multiple memberships are selected.
     */
    const handleNextStep = async () => {
        if(doesUserHasNecessaryData?.showGetUserMissingDetailsModal) {
            setShowGetUserMissingDetailsModal(true);
            return ;
        }

        if (totalMemberships > 1) {
            setShowFamilyModal(true);
            return;
        }

        if(receiptInfo?.className && !isClassAvailable) {
            Alert('error', `${receiptInfo?.className} classes are not scheduled on ${selectedDate?.toLocaleDateString('en-GB')}`)
            return;
        } else if (receiptInfo?.className && selectedShift === '') {
            Alert('error', 'Please select a shift for your class')
            return;
        }


        // if the modal is for studio 1st function, else 2nd function
        receiptInfo?.className ?
        await handleMembershipButton({
            months              :   receiptInfo.months,
            amount              :   Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp                 :   mrp,
            className           :   receiptInfo?.className,
            totalMemberships    :   totalMemberships,
            referralGiver       :   referralGiver,
            trex                :   appliedTrexAmount,
            startDate           :   convertMilliSecondsTimestampToISOFormat(selectedDate.getTime()),  // timeStamp from date object (ISO format)
            payAtTheCounter     :   payAtTheCounter,
            selectedShift       :   selectedShift,
            class               :   receiptInfo?.className,
        })
        : 
        await handleMembershipButton({
            months              :   receiptInfo.months, 
            amount              :   Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp                 :   mrp, 
            className           :   receiptInfo?.className,
            totalMemberships    :   totalMemberships, 
            referralGiver       :   referralGiver, 
            trex                :   appliedTrexAmount,
            startDate           :   convertMilliSecondsTimestampToISOFormat(convertDDMMYYYYToTimestampMilliSeconds(selectedDate)), // sending ahead in timestamp format (ISO format)
            payAtTheCounter     :   payAtTheCounter,
        });
    };

    /**
     * Opens the date picker modal.
     */
    // const handleOpenDatePicker = () => {
    //     setShowDatePicker(true);
    // };

    /**
     * Closes the date picker modal.
     */
    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    /**
     * Handles the selected date from the SelectDate component.
     * @param {string} date - The selected date in 'DD-MM-YYYY' format.
     */
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // Date change for studios
    const handleDateSelect = (date) => {
        setSelectedDate(date);
   
        const selectedDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date).toLowerCase();
        const classesOnDay = receiptInfo.classesSchedule[selectedDay] || {};
   
        // Filter classes based on the selected className (if any)
        const classDetails = Object.entries(classesOnDay)
            .map(([className, timings]) => ({
                className,
                timings,
            }))
            .filter(cls => cls.className === receiptInfo?.className); // Only show the selected class
   
        setAvailableClasses(classDetails);
        setIsClassAvailable(classDetails.length > 0);
        setSelectedShift(''); // Reset shift selection
        setShowDatePicker(false);
    };

    // const isPayButtonDisabled = !isClassAvailable || !selectedShift;

    return (
        <>
            <div className="fixed inset-0 -top-8 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className={`relative bg-tertiary md:bg-tertiary pt-6 md:p-6 lg:p-10 sm:p-12 rounded-3xl shadow-xl w-[90%] ${USR === 1.5 ? 'lg:w-[95vw] lg:scale-[80%]' : 'lg:w-9/12'} mx-auto`}>
                    {/* Close Button */}
                    <button onClick={onClose} className="z-50 absolute top-4 right-4 text-xl h-7 w-7 flex items-center justify-center rounded-full bg-secondary text-tertiary transition">
                        &times;
                    </button>

                    <div className="flex flex-col lg:flex-row justify-between">
                        {/* Left Side: Coupon Display Section */}
                        <div className="relative w-full lg:w-2/3 bg-tertiary p-6 rounded-b-none rounded-t-3xl lg:rounded-b-3xl mr-4">
                            <div className="absolute top-0 left-7">
                                <TR_MainLogo className="w-60 md:w-80 lg:w-[30vw] text-secondary fill-current" />
                            </div>
                            <div className="mt-2 text-center lg:pt-14 leading-4 lg:leading-none">
                                <p className="md:text-2xl lg:text-5xl font-bold text-primary">Congratulations</p>
                                <p className="md:text-2xl lg:text-5xl font-semibold text-primary">You saved <span className='font-bold text-xl lg:text-6xl'>₹{(totalMoneySaved).toFixed(2)}!</span></p>
                            </div>
                            <div className="flex items-center justify-center h-fit w-full lg:mt-20">
                                {selectedCoupon ? (
                                    <div className="h-32 md:h-40 lg:h-72 mt-4 md:mt-20 lg:mt-0 w-full">
                                        <SelectedCoupon selected={true} coupon={selectedCoupon} />
                                    </div>
                                ) : (
                                    <div className='h-32 md:h-40 lg:h-72 mt-4 md:mt-20 lg:mt-0 w-full'>
                                        <SelectedCoupon selected={false} coupon={{}} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="w-full lg:w-1/3 bg-tertiary p-6 rounded-t-none rounded-b-3xl lg:rounded-t-3xl h-[37vh] overflow-y-scroll lg:overflow-y-auto lg:h-full -mt-6 md:-mt-4 lg:mt-0 flex flex-col justify-between">
                            <div>
                                <h3 className="text-xl md:text-3xl lg:text-xl font-bold md:font-bold lg:font-semibold  text-primary">
                                    Order Summary
                                </h3>
                                <div className="bg-tertiary pt-3 pb-2 font-semibold text-primary">
                                    {/* The green box to select start date */}
                                    <div className={`bg-[#CFE0C4] rounded-xl px-4 py-3 lg:p-4`}>
                                        <div className={`font-semibold text-lg text-left`}>{receiptInfo?.gymName}</div>
                                        <div className={`py-2 px-4 mt-2 bg-tertiary flex items-center justify-between rounded-lg`}>
                                            <div className='flex items-center gap-2'>
                                                <CalendarIcon height='20px'/>
                                                <div className={`text-sm`}>{receiptInfo.months > 0 ? `${receiptInfo.months} Months` : '1 Day'} | </div>
                                                <div className="font-semibold text-sm">{receiptInfo?.className ? <p>{selectedDate.toLocaleDateString('en-GB')}</p> : selectedDate}</div>
                                            </div>
                                            <div>
                                                {/* Start Date Select Button */}
                                                <button onClick={() => setShowDatePicker(!showDatePicker)} className='text-sm text-logoGray font-bold flex items-center gap-1'>
                                                    <Calendar className="w-5 h-5 relative -top-0.5" color={themeColors.logoGray} />
                                                </button>
                                            </div>


                                            {/* Date Picker Modal */}
                                            {showDatePicker && (
                                                receiptInfo?.className ?
                                                <div className='fixed top-[44vw] right-14 lg:top-[17vw] lg:right-[21vw] z-40'>
                                                    <DatePicker
                                                        selected={new Date(selectedDate)}
                                                        onChange={(date) => {
                                                            handleDateSelect(date); // Update the selected date
                                                        }}
                                                        inline
                                                        calendarClassName="absolute top-0 left-0 bg-secondary text-primary border-complementPurple border-2 rounded-lg shadow-lg"
                                                        dayClassName={(date) => {
                                                            const isHighlighted = highlightedDates.some(
                                                                (highlightedDate) =>
                                                                    date.getDate() === highlightedDate.getDate() &&
                                                                    date.getMonth() === highlightedDate.getMonth() &&
                                                                    date.getFullYear() === highlightedDate.getFullYear()
                                                            );
                                                   
                                                            return isHighlighted
                                                                ? "custom-day bg-complementPurple text-white rounded-full"
                                                                : "custom-day text-gray-800 hover:bg-primary hover:text-white rounded-full";
                                                        }}
                                                        headerClassName="custom-header bg-tertiary text-white font-bold py-2 rounded-t-lg"
                                                    />
                                                </div>
                                                :
                                                <SelectDate
                                                    onClose={handleCloseDatePicker}
                                                    onDateSelect={handleDateChange}
                                                    initialDate={selectedDate}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {receiptInfo?.className && !isClassAvailable ? (
                                                <p className="mt-1 text-sm">No {receiptInfo?.className} classes are scheduled on this day.</p>
                                            ) : (
                                                availableClasses.map((cls, index) => (
                                                    <div key={index} className="flex gap-1 mt-1 items-center text-sm ">
                                                        <p className="font-semibold ">Select timing:</p>
                                                        <div className="flex gap-2">
                                                            {cls.timings.map((time, idx) => (
                                                                <button
                                                                    key={idx}
                                                                    onClick={() => setSelectedShift(convertTo12HourFormat(time))}
                                                                    className={`px-3 rounded ${
                                                                        selectedShift === convertTo12HourFormat(time)
                                                                            ? 'bg-primary text-white'
                                                                            : 'bg-gray-200'
                                                                    }`}
                                                                >
                                                                    {convertTo12HourFormat(time)}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        <div className={`mt-2 italic font-normal text-sm`}>Click to change your preferred start date</div>
                                    </div>

                                    {/* <p className="text-xl mb-2 font-bold">{receiptInfo.amountToPay > 1000 ? `${receiptInfo.months} ${receiptInfo.months > 1 ? 'Months' : 'Month'}` : `1 Day`}</p> */}
                                    <div className="flex justify-between mt-4 font-normal  lg:text-base lg:font-semibold">
                                        <span>Base Price</span>
                                        <span>₹{mrp.toFixed(2)}</span>
                                    </div>
                                    {trexToBeUsed && (
                                        <div className="flex justify-between mt-1 text-secondary font-normal  lg:text-base lg:font-semibold">
                                            <span>Trex</span>
                                            <span>- ₹{appliedTrexAmount.toFixed(2)}</span>
                                        </div>
                                    )}
                                    <div className="flex justify-between mt-1 text-secondary font-normal  lg:text-base lg:font-semibold">
                                        <span>Discount</span>
                                        <span>- ₹{((mrp) - (baseAmount - (selectedCoupon ? selectedCoupon.discountAmount.toFixed(2) : 0))).toFixed(2)}</span>
                                    </div>
                                    <div className="flex justify-between mt-1 font-normal  lg:text-base lg:font-semibold">
                                        <span>Members</span>
                                        <span>{totalMemberships}</span>
                                    </div>
                                    {referralGiver && (
                                        <div className="flex justify-between mt-1 text-secondary font-normal  lg:text-base lg:font-semibold">
                                            <span>Referrer Code </span>
                                            <span>{referralGiver.code}</span>
                                        </div>
                                    )}
                                    <div className="bg-gray-700 mt-2 w-full h-[1px]"></div>
                                    <div className="flex justify-between text-2xl mt-2  font-semibold">
                                        <span>Total</span>
                                        <span>₹{totalAmount.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>

                            {trex > 0 && (
                                <div className="px-3 py-2 flex justify-between bg-[#CFE0C4] rounded-md">
                                    <div className='flex gap-2'>
                                        <div className="flex text-sm font-bold text-primary gap-2 items-center">
                                            <label>Use Trex (Available: {trex})</label>
                                        </div>
                                    </div>
                                    <div onClick={toggleTrexToBeUsed} className={`rounded-full h-6 w-12 flex ${trexToBeUsed ? 'bg-secondary' : 'bg-tertiary '} transition-all duration-500 cursor-pointer`}>
                                        <div className={`h-6 w-6 rounded-full border-[1px] border-primary ${trexToBeUsed ? 'bg-tertiary translate-x-full' : 'bg-lightSecondary'} transition-all duration-500`}></div>
                                    </div>
                                </div>
                            )}
                            {/* Pay At The Counter Option */}
                            <div className="px-3 py-2 flex justify-between bg-[#CFE0C4] rounded-md mt-2 lg:mt-4">
                                <div className='flex gap-2'>
                                    <div className="flex text-sm font-bold text-primary gap-2 items-center">
                                        <label>Cash Payment?</label>
                                    </div>
                                </div>
                                <div onClick={()=> setPayAtTheCounter(!payAtTheCounter)} className={`rounded-full h-6 w-12 flex ${payAtTheCounter ? 'bg-secondary' : 'bg-tertiary '} transition-all duration-500 cursor-pointer`}>
                                    <div className={`h-6 w-6 rounded-full border-[1px] border-primary ${payAtTheCounter ? 'bg-tertiary translate-x-full' : 'bg-lightSecondary'} transition-all duration-500`}></div>
                                </div>
                            </div>

                            <div className='hidden lg:flex flex-row lg:flex-col justify-between md:justify-around lg:justify-center my-4 h-fit gap-2 lg:gap-5 '>
                                {!selectedCoupon && (
                                    <button onClick={() => setShowCouponModal(true)} className="w-6/12 lg:w-full text-sm md:text-xl bg-tertiary text-primary border-[1px] border-gray-500 font-semibold py-2 px-3 md:px-6 rounded-md hover:font-bold hover:scale-105 duration-500 transition-all">
                                        {conditionToDisplayCoupons(gymData, receiptInfo) ? 'Select Coupon' : 'Enter Referral Code' }
                                    </button>
                                )}
                                {selectedCoupon && conditionToDisplayCoupons(gymData, receiptInfo) && (
                                    <button onClick={removeCoupon} className="w-6/12 lg:w-full text-sm md:text-xl bg-tertiary text-primary border-[1px] border-gray-500 font-semibold py-2 px-3 md:px-6 rounded-md hover:font-bold hover:scale-105 duration-500 transition-all">
                                        Remove Coupon
                                    </button>
                                )}
                                <button
                                    onClick={isUserPresent ? handleNextStep : () => setSignInModal(true)}
                                    className={`w-5/12 md:w-2/5 lg:w-full text-sm md:text-xl bg-primary text-tertiary font-semibold py-2 px-4 md:px-6 rounded-md`}
                                >
                                    Pay ₹{totalAmount.toFixed(2)}
                                </button>
                            </div>
                            <p className="hidden lg:block text-xs sm:text-sm text-center text-primary mb-0">Secured by RazorPay</p>
                        </div>
                        <div className='px-4 lg:hidden'>
                            <div className='flex flex-row lg:flex-col justify-between md:justify-around lg:justify-center my-4 h-fit gap-2 lg:gap-5 '>
                                {!selectedCoupon && conditionToDisplayCoupons(gymData, receiptInfo) && (
                                    <button onClick={() => setShowCouponModal(true)} className="w-6/12 lg:w-full text-sm md:text-xl bg-tertiary text-primary border-[1px] border-gray-500 font-semibold py-2 px-3 md:px-6 rounded-md hover:font-bold hover:scale-105 duration-500 transition-all">
                                        {conditionToDisplayCoupons(gymData, receiptInfo) ? 'Select Coupon' : 'Enter Referral Code' }
                                    </button>
                                )}
                                {selectedCoupon && conditionToDisplayCoupons(gymData, receiptInfo) > 0 && (
                                    <button onClick={removeCoupon} className="w-6/12 lg:w-full text-sm md:text-xl bg-tertiary text-primary border-[1px] border-gray-500 font-semibold py-2 px-3 md:px-6 rounded-md hover:font-bold hover:scale-105 duration-500 transition-all">
                                        Remove Coupon
                                    </button>
                                )}
                                <button onClick={isUserPresent ? handleNextStep : () => setSignInModal(true)} className="w-5/12 md:w-2/5 lg:w-full text-sm md:text-xl bg-primary text-tertiary font-semibold py-2 px-4 md:px-6 rounded-md hover:font-bold hover:scale-105 duration-500 transition-all">
                                    Pay ₹{totalAmount.toFixed(2)}
                                </button>
                            </div>
                            <p className="text-xs sm:text-sm text-center text-primary mb-4">Secured by RazorPay</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Family Modal */}
            {showFamilyModal && (
                <FamilyModal
                    totalMemberships={totalMembers}
                    isOpen={FamilyModal}
                    onClose={closeModal}
                    receiptInfo={{
                        ...receiptInfo,
                        mrp             :   mrp,
                        referralGiver   :   referralGiver, 
                        trex            :   appliedTrexAmount,
                        startDate       :   selectedDate,
                        payAtTheCounter :   payAtTheCounter,
                    }}
                    totalAmount={totalAmount}
                    handleMembershipButton={handleMembershipButton}
                    user={user}
                    gymData={gymData}
                />
            )}

            {/* Coupon Selection Modal */}
            {showCouponModal && (
                <CouponModal
                    handleReferralCode={handleReferralCode}
                    handleCouponSelection={handleCouponSelection}
                    setShowCouponModal={setShowCouponModal}
                    duration={receiptInfo.months}
                    user={user}
                    conditionForCoupons={conditionToDisplayCoupons(gymData, receiptInfo)}
                />
            )}

            {/* Getting User's missing details */}
            {showGetUserMissingDetailsModal && 
            <GetUserMissingDetailsModal 
                missingDetails={doesUserHasNecessaryData}
                setShowGetUserMissingDetailsModal={setShowGetUserMissingDetailsModal}
                userId={user?.uid}
            />}
        </>
    );
};

export default PaymentConfirmationModal;


// -------------------------------------------------------------------------
// ----------------------------- CONDITIONS --------------------------------
// -------------------------------------------------------------------------

/**
 * Decides whether a user can choose/enter coupons or not
 * @param   {Object} gymData        -   Data of the gym
 * @param   {Object} receiptInfo    -   Receipt Information
 * @returns {Boolean}               -   True, if coupons can be selected; false, otherwise
 */
export const conditionToDisplayCoupons = (gymData, receiptInfo) => {
    if (gymData.isExclusive)
        return true;
    return false;
}