/**
 * updateUserDoc.js
 * 
 * Updates the user doc according to the event
 */

import { Timestamp, getDoc, setDoc } from "@firebase/firestore";

/**
 * Updates user doc
 * 
 * @param {String} type     -   pageView
 * @param {Ref} userRef     -   user's document reference
 * @param {Object} deets    -   Details of the page view
 * @returns {Boolean}       -   true if updated successfully; false otherwise
 */
export const updateUserDoc = async (type, userRef, deets) => {
    try {
        switch (type) {
            case 'pageView':
                if (deets.isGymPage) {
                    await setDoc(userRef, {
                        mostRecentGym       :   deets.gymData.gymDisplayName,
                        gymPageRecentVisit  :   Timestamp.now(),
                    }, { merge: true });

                    return true;
                }
                break;

            default:
                break;
        }

        return false;
    } catch (error) {
        console.error('Error in updateUserDoc:', error);
        return false;
    }
};

/**
 * Add a userDoc if it doesn't exist
 * @param {Object} user     -   details of the user
 * @param {Ref} userRef     -   Reference to the collection
 */
export const addUserDoc = async (user, userRef) => {
    try {
        const existingDoc = await getDoc(userRef);

        if (!existingDoc.exists())
            await setDoc(userRef, {
                userName            :   user.displayName,
                email               :   user.email,
                phoneNumber         :   user.phoneNumber,
            })

    } catch (error) {
        console.log('Error adding user doc : ', error);
    }
}