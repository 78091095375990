import { plans } from "../../assets/Plans";
import { PlanCard } from "./PlanCard";
import { getBasePlanPrices, getMediumPlanPrices, getPremiumPlanPrices } from "../../helperFunctions/PaymentPlans/getPlanPricing";

/**
 * Displays basic pro premium cards for plans
 * @param {bool}     isGymPage          - to check if this is from gym page or studio page
 * @param {object}   currGymData        - information about gym prices
 * @param {int}      duration           - 1 month, 12 months, etc
 * @param {string}   membershipType     - single couple or family
 * @param {string}   className          - if studio membership is being bought then classname 'Zumba', etc
 * @param {function} toggleSummaryModal - function to go to final payment's page  
 * @param {bool}     hasCouple          - to check if gym or studio has couple pricing
 * @returns 
 */
const PlanCards = ({ isGymPage, currGymData, duration, membershipType, className, toggleSummaryModal, hasCouple }) => {

    const getSingleCoupleOrFamily = () => {
        if     (membershipType === 1 || !hasCouple) return 'single'
        else if(membershipType === 2) return 'couple'
        else if(membershipType >=  3) return 'family'
    }

    const getExclusiveName = () => {
        if      (duration === 1)  return 'exclusiveMonthlyPrice'
        else if (duration === 3)  return 'exclusiveQuarterlyPrice'
        else if (duration === 6)  return 'exclusiveHalfYearlyPrice'
        else if (duration === 12) return 'exclusiveYearlyPrice'
    }
    const getMRPName = () => {
        if      (duration === 1)  return 'monthlyPrice'
        else if (duration === 3)  return 'quarterlyPrice'
        else if (duration === 6)  return 'halfYearlyPrice'
        else if (duration === 12) return 'yearlyPrice'
    }
    
    // Base Plan Pricing
    const basePlan = getBasePlanPrices(
        isGymPage,
        currGymData,
        getSingleCoupleOrFamily(),
        getExclusiveName(),
        getMRPName(),
        className,
    )

    // Calculate Medium Plan Pricing
    const mediumPlan = getMediumPlanPrices(basePlan);
    // Calculate Premium Plan Pricing using Medium Plan as reference
    const premiumPlan = getPremiumPlanPrices(basePlan, mediumPlan);

    const fallbackPlanKey = 'single';

    // Calculates the price to pay gym for internal dashboards
    const priceToPayGym = currGymData?.isExclusive === false
    ? currGymData.commissionPercentage
    ? currGymData?.prices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] * ((100 - currGymData?.commissionPercentage) / 100) || currGymData?.prices?.[fallbackPlanKey]?.[getExclusiveName()] * ((100 - currGymData.commissionPercentage) / 100)
    : currGymData?.prices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] * ((100 - 20) / 100) || currGymData?.prices?.[fallbackPlanKey]?.[getExclusiveName()] * ((100 - 20) / 100)
    : currGymData?.prices?.[getSingleCoupleOrFamily()]?.[getExclusiveName()] || currGymData?.prices?.[fallbackPlanKey]?.[getExclusiveName()];

    const handlePlanSelect = (selectedPlan) => {
        if(isGymPage) toggleSummaryModal(duration, selectedPlan === 0 ? basePlan.sellingPrice : selectedPlan === 1 ? mediumPlan.sellingPrice : premiumPlan.sellingPrice, selectedPlan === 0 ? basePlan.mrp : selectedPlan === 1 ? mediumPlan.mrp : premiumPlan.mrp, getSingleCoupleOrFamily(), priceToPayGym, 'A', selectedPlan) 
        else          toggleSummaryModal(duration, selectedPlan === 0 ? basePlan.sellingPrice : selectedPlan === 1 ? mediumPlan.sellingPrice : premiumPlan.sellingPrice, selectedPlan === 0 ? basePlan.mrp : selectedPlan === 1 ? mediumPlan.mrp : premiumPlan.mrp, className, 'single', priceToPayGym, 'A', selectedPlan)
    }

    return (
        <>
        {className || isGymPage ? 
            <div className="flex gap-6 pt-11 lg:gap-[4vw] overflow-x-scroll overflow-y-none no-scrollbar lg:overflow-visible max-w-[100vw] px-5 lg:px-0 lg:mt-0 ">
            {plans(basePlan, mediumPlan, premiumPlan).map((plan) => (
                <PlanCard plan={plan} currGymData={currGymData} handlePlanSelect={handlePlanSelect} MembershipPlanTest='A' />
            ))}

            </div> 
        : 
            <div className="text-purple font-semibold text-2xl">Please Select a class to see the plans</div>
        }
        </>
    )
}

export default PlanCards;