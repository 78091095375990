import { useNavigate } from "react-router-dom";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import match from '../assets/Website_content/TR_Website_content-05.svg'
import consult from '../assets/Website_content/TR_Website_content-09.svg'
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";
import { ChevronRight } from "lucide-react";

/**
 * SideNavbar Component
 * 
 * A responsive slide-in sidebar menu for navigation on smaller screens.
 * Displays user-specific options when a user is signed in, and allows navigation to key app sections.
 * Includes external links to download the Train Rex app and access the control panel.
 * Logs user interactions (menu clicks and app store clicks) using Firebase Analytics.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.menuOpen - Controls whether the side menu is visible.
 * @param {function} props.toggleMenu - Function to toggle the side menu open/closed.
 * @param {function} props.setProfileMenuOpen - Function to control visibility of profile menu.
 * @param {string} props.selectedCity - Current selected city for gym/studio listings.
 * @param {Object} props.user - Firebase user object of the currently logged-in user.
 * @param {boolean} props.isUserPresent - Whether a user is currently logged in.
 * @param {boolean} props.showSignIn - Flag indicating if the sign-in modal is shown.
 * @param {function} props.setShowSignIn - Function to toggle the sign-in modal.
 * @param {boolean} props.planDetailsExist - Whether the user has an active fitness plan.
 * @param {Array} props.allStudios - List of all studios, used to show studio navigation in dev mode.
 * 
 * @returns {JSX.Element} A slide-in sidebar menu with dynamic links and actions.
 */
const SideNavbar = ({
    menuOpen            ,
    toggleMenu          ,
    setProfileMenuOpen  ,
    selectedCity        ,
    user                ,
    isUserPresent       ,
    showSignIn          ,
    setShowSignIn       ,
    planDetailsExist    ,
    allStudios          ,
}) => {
    // console.log("user", user)
    // console.log("plandetailsexist", planDetailsExist)
    // console.log("isuserpre", isUserPresent)
    const navigate = useNavigate();

    const logAppStoreClick = (store) => {
        logEvent(analytics, `Store, ${store}`);
    };

    const logMenuItemClick = (itemName) => {
            logEvent(analytics, `menuClick, ${itemName}`);
        };
    
    return (
        <div className={`fixed inset-0 z-50 h-[100vh] w-[100vw] bg-black bg-opacity-50 flex justify-end transition-opacity duration-500 ${menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            <div
                className={`relative w-3/4 right-0 bg-tertiary h-[100vh] rounded-l-2xl flex flex-col text-lg p-4 transition-transform duration-700 transform ${
                    menuOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <div onClick={toggleMenu} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">
                    ×
                </div>

                {isUserPresent ? 
                <div onClick={() => {setProfileMenuOpen(false); navigate('/profile', {state : {user}}); toggleMenu();}} className="w-full flex justify-between items-center mt-10 px-2">
                    <div className="text-lg font-semibold">
                        Hi, <span className="md:hidden">{user?.displayName?.split(" ")[0] || "User"}</span>
                    </div>
                    <div><ChevronRight className='h-full' /></div>
                </div> 
                :
                <div onClick={() => {toggleMenu(); setShowSignIn(!showSignIn);}} className="text-left mt-10 font-semibold text-secondary">Login for a better experience</div>
                }

                <div
                    onClick={() => {
                        logMenuItemClick("Gyms/Classes");
                        navigate(`/${selectedCity.toLowerCase()}/gyms`);
                        toggleMenu();
                    }}
                    className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-4 sm:mt-8"
                >
                    <div className="font-semibold text-sm sm:text-base">Gyms/Studios</div>
                    <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>
                {isUserPresent && planDetailsExist && 
                <div
                    onClick={() => {
                    logMenuItemClick("My Fitness Plan");
                    navigate('/myFitnessPlan');
                    toggleMenu();
                    }}
                    className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-2"
                >
                    <div className="font-semibold text-sm sm:text-base">My Fitness Plan</div>
                    <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>
                }

                {process.env.NODE_ENV === 'development' && allStudios?.length !== 0 && (
                <div
                    onClick={() => {
                    logMenuItemClick("Studios");
                    navigate(`/${selectedCity.toLowerCase()}/studios`);
                    toggleMenu();
                    }}
                    className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-2"
                >
                    <div className="font-semibold text-sm sm:text-base">Studios/Fitness Centers</div>
                    <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>
                )}

                <div
                onClick={() => {
                    logMenuItemClick("Train Rex App");
                    navigate('/AppDescription');
                    toggleMenu();
                }}
                className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-2"
                >
                <div className="font-semibold text-sm sm:text-base">App Description</div>
                <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>

                <div
                onClick={() => {
                    logMenuItemClick("Our Blog");
                    navigate('/blogs');
                    toggleMenu();
                }}
                className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-2"
                >
                <div className="font-semibold text-sm sm:text-base">Blogs</div>
                <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>

                <div
                    onClick={() => {
                        logMenuItemClick("Forums");
                        navigate('/forum');
                        toggleMenu();
                    }}
                    className="w-full flex justify-between items-center px-2 pr-2 sm:pr-4 mt-2"
                >
                    <div className="font-semibold text-sm sm:text-base">Forums</div>
                    <div className="text-lg sm:text-xl"><ChevronRight className='h-full' /></div>
                </div>

                <div className="text-left flex items-center flex-nowrap text-sm font-semibold mt-16">
                <img src={match} alt="match" className="h-3 w-fit" />
                <div className="whitespace-nowrap ml-2">Train Rex App</div>
                <div className="flex items-center gap-2 ml-4">
                    <a
                    href="https://apps.apple.com/gb/app/train-rex/id6476014470"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-tertiary bg-secondary p-1 px-2 scale-[70%] rounded-3xl text-center text-lg border overflow-hidden transition duration-300 ease-in-out focus:outline-none focus:shadow-outline flex"
                    onClick={logAppStoreClick('Apple')}
                    >
                    <span style={{ fontSize: '0.2rem' }}>
                        <AppleIcon />
                    </span>
                    </a>
                    <a
                    href="https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-tertiary bg-secondary p-1 px-2 -ml-2 scale-[70%] rounded-3xl text-center text-lg border transition duration-300 ease-in-out focus:outline-none focus:shadow-outline flex"
                    onClick={logAppStoreClick('Android')}
                    >
                    <span style={{ fontSize: '0.2rem' }}>
                        <AndroidIcon />
                    </span>
                    </a>
                </div>
                </div>

                <a
                href="https://trainrex.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-left w-full sm:w-[40%] flex items-center gap-2 text-sm font-semibold mt-2"
                >
                <img src={consult} alt="consult" className="h-3 w-fit" />
                <div className="whitespace-nowrap text-xs sm:text-sm">Train Rex Smart Control Panel</div>
                </a>

                {/* <div className="text-left flex items-center gap-2 text-base font-bold mt-12">
                    <div></div>
                    <div>Contact Us</div>
                </div>
                <div className="text-left flex items-center gap-2 text-base font-bold mt-2">
                    <div></div>
                    <div>Help</div>
                </div> */}
            </div>
        </div>
    )
}

export default SideNavbar;