import React from "react";
// import { TrexIcon } from "../../assets/Website_content/svg";
import Loader from "../../Screens/Marketplace2025/Loader";

/**
 * DietPlan Component
 * 
 * Displays a user's daily diet plan with details for breakfast, lunch, and dinner.
 * Handles different meal formats and renders meal items with calorie info.
 * Provides actions to adjust the meal (e.g., "Ate something else", "Regenerate").
 * Visually indicates meal consumption status and calorie breakdown.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.weeklyPlan - The full weekly plan object containing diet data.
 * @param {Function} props.setRegeneratePopup - Function to trigger a popup for regenerating meals.
 * @param {Function} props.setCaloriePopup - Function to trigger a popup for calorie replacement.
 * @param {React.Ref} ref - Forwarded ref for the section element.
 * 
 * @returns {JSX.Element} A section with meal details, actions, and consumption tracking.
 */

const DietPlan = React.forwardRef(({ weeklyPlan, setRegeneratePopup, setCaloriePopup }, ref) => {
    // console.log("Diet plan page", weeklyPlan);
    // If the plan data is not yet available, show the loader.
    if (!weeklyPlan) {
        return <Loader label="Loading your diet plan" />;
    }
  
    const diet = weeklyPlan?.diet || {};

    // const [status, setStatus] = useState({});

    // const handleStatusChange = (mealType, newStatus) => {
    //     setStatus((prev) => ({ ...prev, [mealType]: newStatus }));
    // };

    // Helper function to extract meal items from different data structures
    // const getMealItems = (mealData) => {
    //     if (Array.isArray(mealData)) {
    //         // Original format - direct array
    //         return mealData;
    //     } else if (mealData?.items && Array.isArray(mealData.items)) {
    //         // Format after adjustment - object with items array
    //         return mealData.items;
    //     } else if (mealData?.foodDetails) {
    //         // Format after "ate something else" - create pseudo item
    //         return [{
    //             item: mealData.foodDetails,
    //             calories: mealData.actualCalories || 0,
    //             amount: "custom"
    //         }];
    //     }
    //     return [];
    // };

    // Helper function to extract meal items from different data structures
    const getMealItems = (mealData) => {
        // console.log("Meal DATA", Array.isArray(mealData), mealData?.items, Array.isArray(mealData.items), mealData?.foodDetails, typeof mealData === "object")
        if (Array.isArray(mealData)) {
            // console.log(1)
            // Original format - direct array
            return mealData;
        } else if (mealData?.items && Array.isArray(mealData.items)) {
            // console.log(2)
            // Format after adjustment - object with items array
            return mealData.items;
        } else if (mealData?.foodDetails) {
            // console.log(3)
            // Format after "ate something else" - create pseudo item
            return [{
                item: mealData.foodDetails,
                calories: mealData.actualCalories || 0,
                amount: "custom"
            }];
        } else if (mealData && typeof mealData === "object") {
            // console.log(4, [mealData.items])
            // Plain meal object (e.g., {calories, amount, item, imageUrl})
            return [mealData.items];
        }
        return [];
    };

    // Helper function to calculate target calories for a meal
    const calculateTargetCalories = (mealItems) => {
        return mealItems.reduce((total, item) => total + (item.calories || 0), 0);
    };

    return (
        <section ref={ref} className="pt-4 px-4">
            <h2 className="text-xl font-semibold mb-4 text-primary">Diet Plan</h2>
            {["breakfast", "lunch", "dinner"].map((mealType) => {
                // console.log("CHeck", diet[mealType])
                const mealData = diet[mealType];
                const mealItems = getMealItems(mealData);
                const consumptionStatus = mealData?.consumptionStatus;
                const targetCalories = calculateTargetCalories(mealItems);
                return (
                    <div 
                        key={mealType} 
                        className={`mb-4 p-4 rounded-lg shadow-md ${
                            consumptionStatus === 'eaten' || consumptionStatus === 'consumed' 
                                ? 'bg-green-200' 
                                : consumptionStatus === 'notEaten' 
                                    ? 'bg-red-200' 
                                    : 'bg-gray-100'
                        }`}
                    >
                        <div className="flex justify-between items-center">
                            <h3 className="text-xl font-semibold capitalize">{mealType}</h3>
                            {mealData?.isAdjusted && (
                                <span className="text-xs text-blue-600 font-medium ml-2">Adjusted</span>
                            )}
                            <div className="flex flex-col items-end gap-2">

                                {/* <button 
                                    onClick={() => handleStatusChange(mealType, 'eaten')} 
                                    className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple to-primaryComplement bg-green-500 rounded-xl">
                                    Eaten
                                </button> */}
                                {/* <button
                                    onClick={() =>
                                        setCaloriePopup({ visible: true, itemType: "meal", mealKey: mealType })
                                    }
                                    className="gap-x-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple to-primaryComplement rounded-xl"
                                >
                                    <div>Ate something else</div>
                                </button> */}
                                <button
                                    onClick={() =>
                                        setRegeneratePopup({ visible: true, itemType: "meal", mealKey: mealType })
                                    }
                                    className="gap-x-2 flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple to-primaryComplement rounded-xl"
                                >
                                    <div className="flex gap-1">
                                        {/* <span className="font-semibold">10</span> */}
                                        {/* <TrexIcon height='16px' color='#ffffff'/> */}
                                    </div>
                                    <div>Did not like it? Regenerate</div>
                                </button>
                            </div>
                        </div>
                        <div className="mt-2">
                            {mealItems.length > 0 ? (
                                <ul className="gap-4">
                                    {mealItems.map((item, idx) => (
                                    <li 
                                        key={idx} 
                                        className="text-lg text-gray-700 border-b py-2 flex flex-col items-center text-center"
                                    >
                                        <span className="mb-1">{item.item}</span>
                                        <span className="text-sm">{item.amount} - {item.calories} kcal</span>
                                    </li>
                                    ))}
                                </ul>                                                        
                            ) : (
                                <p className="text-sm text-gray-500">No meals available</p>
                            )}
                            
                            {mealData?.targetCalories && (
                                <div className="text-sm text-blue-600 mt-1">
                                    Target: {targetCalories} kcal
                                </div>
                            )}
                            
                            {mealData?.actualCalories && mealData.consumptionStatus === "consumed" && (
                                <div className="text-sm text-green-600 mt-1">
                                    Consumed: {mealData.actualCalories} kcal
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </section>
    );
});

export default DietPlan;