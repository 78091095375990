import { createContext, useContext, useState } from "react";

const PersonalizedPlanContext = createContext();

export const PersonalizedPlanProvider = ({ children }) => {
    const [triggerPersonalizedPlans, setTriggerPersonalizedPlans] = useState(false);

    return (
        <PersonalizedPlanContext.Provider value={{ triggerPersonalizedPlans, setTriggerPersonalizedPlans }}>
            {children}
        </PersonalizedPlanContext.Provider>
    );
};

export const usePersonalizedPlan = () => useContext(PersonalizedPlanContext);
