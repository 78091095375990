import React, { useState } from 'react';

/**
 * WeeklyUpdateDialog component renders a dialog for users to update their weekly fitness plan.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Determines if the dialog is open
 * @param {function} props.onClose - Function to call when the dialog is closed
 * @param {function} props.onSubmit - Function to call when the form is submitted
 * @returns {JSX.Element|null} The rendered component or null if not open
 *
 * @example
 * <WeeklyUpdateDialog
 *   isOpen={true}
 *   onClose={() => console.log('Dialog closed')}
 *   onSubmit={(changes) => console.log('Form submitted', changes)}
 * />
 */
const WeeklyUpdateDialog = ({ isOpen, onClose, onSubmit }) => {
  const [changes, setChanges] = useState({
    dietChanges: "",
    workoutChanges: "",
    meditationChanges: "",
    healthChanges: "",
    otherChanges: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChanges(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(changes);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl shadow-lg max-w-[90%] w-[550px] max-h-[80vh] overflow-y-auto relative flex flex-col p-6">
        <button
          className="absolute top-2 right-4 text-gray-500 text-2xl hover:text-black"
          onClick={onClose}
        >
          &times;
        </button>
        
        <h2 className="text-2xl font-bold mb-4 text-primary">
          Update Your Weekly Plan
        </h2>
        
        <p className="text-gray-600 mb-4">
          Let us know what changes you'd like to make to your fitness plan for next week.
        </p>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Diet Changes
            </label>
            <textarea
              name="dietChanges"
              value={changes.dietChanges}
              onChange={handleInputChange}
              placeholder="e.g., I want to reduce carbs, add more protein, include more vegetarian options..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              rows="2"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Workout Changes
            </label>
            <textarea
              name="workoutChanges"
              value={changes.workoutChanges}
              onChange={handleInputChange}
              placeholder="e.g., I want more cardio, less strength training, focus on lower body..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              rows="2"
            />
          </div>
          
          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Meditation Changes
            </label>
            <textarea
              name="meditationChanges"
              value={changes.meditationChanges}
              onChange={handleInputChange}
              placeholder="e.g., I want shorter sessions, focus on sleep meditation..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              rows="2"
            />
          </div> */}
          
          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Health Changes
            </label>
            <textarea
              name="healthChanges"
              value={changes.healthChanges}
              onChange={handleInputChange}
              placeholder="e.g., I've developed a knee pain, I'm having digestive issues..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              rows="2"
            />
          </div> */}
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Other Changes or Comments
            </label>
            <textarea
              name="otherChanges"
              value={changes.otherChanges}
              onChange={handleInputChange}
              placeholder="Any other preferences or feedback about your previous plan..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
              rows="2"
            />
          </div>
          
          <div className="flex justify-center space-x-3 pt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark"
            >
              Update Plan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WeeklyUpdateDialog;