/**
 * getPlanPricing.js
 * 
 * Consists of functions to get the pricing for plans
 */

import { getSellingPrice, roundOffLogic } from "../MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import { formatPrices } from "../MarketplaceHelpers/Pricing/displayPriceFormat";


/**
 * Gets Base Plan Pricing   –   Selling Price and MRP
 * 
 * @param {Boolean} isGymPage               -   If the functon is being called from the gymPage
 * @param {Object} currGymData              -   Information of the gym
 * @param {String} membershipTypeName       -   Single / Couple /Family
 * @param {String} exclusiveName            -   exclusive price type (monthly, quarterly, etc.)
 * @param {String} mrpName                  -   gym selling price (monthly, quarterly, etc.)
 * @param {String} className                -   name of the class if this is for class
 * @returns {Object}                        -   Prices : selling and mrp
 */
export const getBasePlanPrices = (isGymPage, currGymData, membershipTypeName = '', exclusiveName = '', mrpName = '', className = '') => {
    return (
        formatPrices({
            sellingPrice : getSellingPrice(
                isGymPage 
                    ?   currGymData?.prices?.[membershipTypeName]?.[exclusiveName] 
                    :   currGymData?.prices?.single?.[className]?.[exclusiveName] , 
                currGymData?.isExcluive, 
                currGymData?.gymName, 
                currGymData?.city
            ) ,
            mrp          : 
                isGymPage 
                    ?   currGymData.prices[membershipTypeName][mrpName] 
                    :   currGymData?.prices?.single?.[className]?.[mrpName],
        })
    )
}

/**
 * Function to get Medium Plan Pricing, mrp and selling price
 * @param {object} basePlan - containing mrp and selling price of the base plan
 * @returns mrp and selling orice of the medium plan
 */
export const getMediumPlanPrices = (basePlan) => {
    let sellingPrice = basePlan.sellingPrice * 1.2;
    let mrp = basePlan.mrp * 1.2;

    // Ensure Medium Plan selling price is greater than Base Plan
    if (sellingPrice <= basePlan.sellingPrice) sellingPrice += 100;

    // Ensure MRP is at least the selling price
    if (mrp <= sellingPrice) mrp = sellingPrice + 100;

    return {
        sellingPrice    :   roundOffLogic(Math.floor(sellingPrice.toFixed(2))),
        mrp             :   roundOffLogic(Math.floor(mrp.toFixed(2))),
    };
}

/**
 * Function to get premium Plan Pricing, mrp and selling price
 * Ensures premium pricing is always greater than the medium plan's
 * @param {object} basePlan     - containing mrp and selling price of the base plan
 * @param {object} mediumPlan   - containing mrp and selling price of the medium plan
 * @returns mrp and selling orice of the premium plan
 */
export const getPremiumPlanPrices = (basePlan, mediumPlan) => {
    let sellingPrice = basePlan.sellingPrice * 1.4;
    let mrp = basePlan.mrp * 1.4;

    // Ensure Premium Plan selling price is greater than Medium Plan
    if (sellingPrice <= mediumPlan.sellingPrice) sellingPrice = mediumPlan.sellingPrice + 100;

    // Ensure MRP is at least the selling price
    if (mrp <= sellingPrice) mrp = sellingPrice + 100;

    return {
        sellingPrice    :   roundOffLogic(Math.floor(sellingPrice.toFixed(2))),
        mrp             :   roundOffLogic(Math.floor(mrp.toFixed(2))),
    };
};