import React, { useState } from 'react';
import { coupons } from '../../../assets/Coupons';
// import { checkReferralCodeValidity } from './ReferralCodeHelpers';
import Alert from '../../../components/customComponents/Alert';
import { ChevronLeft } from 'lucide-react';
import coupon from '../../../assets/Website_content/Coupon.svg'
import { CouponCard } from '../../../components/CouponCard';
import Star from '../../../assets/Website_content/Star.png'
import { checkIfCouponIsValid } from './DiscountCoupons/checkIfCouponIsValid';

/**
 * CouponModal
 * Displays a modal for users to enter a coupon or referral code, or select an available coupon.
 * Includes functionality to validate codes, show success marks, and unselect conflicting selections.
 * 
 * @param 
 */
const CouponModal = ({ handleCouponSelection, setShowCouponModal, duration, isLargerScreen, setExpandCouponSection, conditionForCoupons, selectedPlan }) => {
    // States
    const [discountCode       ,        setDiscountCode] = useState(''); // User-entered discount code
    const [selectedCoupon       ,    setSelectedCoupon] = useState(null); // Selected coupon from the list
    const [                    , setDiscountCodeDetails] = useState({}); // Valid discount code details

    // Filter available coupons based on membership duration
    const filteredCoupons = coupons.filter(coupon => {
        return (
            coupon.minDuration <= duration &&
            (coupon.maxDuration === undefined || duration <= coupon.maxDuration)
        );
    });

    /**
     * Handles the submission of a discount code by validating it.
     * If valid, applies the discount and unselects any selected coupon.
     */
    const handleDiscountCodeSubmit = async () => {
        if(discountCode.trim() === '') {
            Alert('error', 'Please enter a discount code.')
            return;
        }

        const discountCoupon = await checkIfCouponIsValid(discountCode.toUpperCase(), {months : duration, plan : selectedPlan});
        if (Object.keys(discountCoupon).length > 0) {
            setDiscountCodeDetails(discountCoupon); // Store valid coupon details
            setSelectedCoupon(null); // Unselect any selected coupon
            handleCouponSelection(discountCoupon); // Apply the coupon
            setExpandCouponSection(false);
        }
    };

    return (
        <div className={`${isLargerScreen ? '' : 'fixed inset-0 top-0 flex items-center justify-center bg-[#ffffff] z-50'}`}>
            <div className={`relative ${isLargerScreen ? '' : 'p-4'} h-full w-full`}>
                {/* Close Button */}
                <button
                    onClick={() => setShowCouponModal(false)}
                    className={`${isLargerScreen ? 'hidden' : ''} absolute top-4 left-3 text-gray-600 text-3xl`}
                >
                    <ChevronLeft />
                </button>

                <div className={`flex flex-col ${!isLargerScreen ? 'mt-8' : ''}`}>
                    {/* Coupon Code Input */}
                    {conditionForCoupons && 
                    <div className="flex gap-2 rounded-2xl shadow-md mt-4 pl-4 pr-8 w-full">
                        {/* <label htmlFor="couponCode" className="text-base lg:text-xl whitespace-nowrap">
                            Have a coupon code?
                        </label> */}
                        <img src={coupon} alt='coupon' className='h-16 w-fit'/>
                        <div className="flex items-center gap-1 md:gap-2 w-full">
                            <input
                                type="text"
                                id="couponCode"
                                placeholder='Enter Coupon Code'
                                className="w-full rounded-full bg-gray-100 px-3 py-2 text-sm md:text-xl text-black placeholder:text-purple placeholder:font-semibold font-semibold outline-none"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                            />
                            
                                <button
                                    onClick={handleDiscountCodeSubmit}
                                    className="relative text-purple font-semibold text-sm lg:text-lg"
                                >
                                    Apply
                                    <img src={Star} alt='star' className="absolute -bottom-1 right-0 h-2 " />
                                    <img src={Star} alt='star' className="absolute -bottom-3 right-2 h-3 " />
                                </button>
                            
                        </div>
                    </div>
                    }

                    {/* Referral Code Input
                    <div className="flex gap-2 rounded-2xl shadow-md mt-4 lg:mt-10 pl-2 pr-4 w-full max-h-fit">
                        {/* <label htmlFor="referralCode" className="text-base lg:text-xl whitespace-nowrap">
                            Have a Referral code?
                        </label> *
                        <img src={coupon} alt='coupon' className='h-16 w-fit'/>
                        <div className="flex items-center gap-1 md:gap-2 w-full">
                            <input
                                type="text"
                                id="referralCode"
                                placeholder='Enter Referral Code'
                                className="w-full rounded-2xl px-3 py-2 text-sm md:text-xl text-black placeholder:text-purple placeholder:font-semibold font-semibold outline-none"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                            />
                            {Object.keys(referralCodeDetails).length > 0 ? (
                                <div className="flex items-center gap-1 -ml-4 md:ml-0">
                                    Success Checkmark
                                    <div className="success-checkmark scale-[30%] md:scale-[40%] pt-4">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    Remove Referral Button
                                    <button
                                        onClick={() => {
                                            setReferralCode('');
                                            setReferralCodeDetails({});
                                        }}
                                        className="text-purple rounded-full -ml-4 md:ml-0 lg:px-1 text-lg md:text-3xl hover:bg-red-600 transition"
                                    >
                                        ×
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleReferralCodeSubmit}
                                    className='relative text-purple font-semibold text-sm lg:text-lg'
                                >
                                    Apply
                                    <img src={Star} alt='star' className="absolute -bottom-1 right-0 h-2 " />
                                    <img src={Star} alt='star' className="absolute -bottom-3 right-2 h-3 " />
                                </button>
                                )}
                        </div>
                    </div> */}
                </div>

                {/* Coupons List */}
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6 mt-4 lg:mt-8">
                    {conditionForCoupons && 'Available Coupons'}
                </h2>
                {conditionForCoupons && (
                    <div className="space-y-3 sm:space-y-4 md:space-y-6">
                        {filteredCoupons.length > 0 ? (
                            filteredCoupons.map((coupon) => (
                                <div
                                    key={coupon.code}
                                    onClick={() => {
                                        setSelectedCoupon(coupon); // Highlight the selected coupon
                                        setDiscountCodeDetails({}); // Unset discount code if a coupon is selected
                                        handleCouponSelection(coupon);
                                        setExpandCouponSection(false);
                                    }}
                                >
                                    <CouponCard coupon={coupon} selectedCoupon={selectedCoupon}/>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-logoGray text-sm sm:text-base md:text-lg">
                                No coupons available for this duration.
                            </p>
                        )}
                    </div>
                )}

                {/* Done Button */}
                {/* {shouldShowDoneButton && !isLargerScreen && (
                    <div className="mt-6 flex justify-center">
                        <button
                            onClick={() => setShowCouponModal(false)}
                            className="bg-primary text-tertiary rounded-2xl px-6 py-2 text-base sm:text-lg md:text-xl hover:bg-tertiary hover:text-primary transition"
                        >
                            Done
                        </button>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default CouponModal;
