import React, { useState } from "react";
// import { GymContext } from "../../hooks/FetchEverythingContext";

/**
 * A popup component that allows users to regenerate a meal or item plan using Trex points.
 * 
 * @param   {Object} regeneratePopup         -    State object containing popup details.
 * @param   {Function} setRegeneratePopup    -    Function to update the popup state.
 * @param   {Function} handleRegenerate      -    Function to trigger the regeneration process.
 * @returns {JSX.Element}                    -    The RegeneratePopup component.
 */
const RegeneratePopup = ({
    regeneratePopup,
    setRegeneratePopup,
    handleRegenerate, // Pass the handleRegenerate function from the parent component
}) => {
    const { itemType, mealKey, userPrompt } = regeneratePopup; // Extracting properties from regeneratePopup state
    // const { user } = useContext(GymContext);; // Get the authenticated user details
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage the loading state of the button

    /**
     * Handles the regeneration process when the button is clicked.
     * Checks if the user has enough Trex points, and if so, triggers regeneration.
     * @async
     * @function handleRegenerateClick
     */
    const handleRegenerateClick = async () => {
        setIsSubmitting(true);

        // Check if the user has enough Trex to regenerate the plan
        // const hasEnoughTrex = await usedTrexForPlanRegeneration(user.uid);
        // if (!hasEnoughTrex) {
        //     // If not, close the popup and reset state
        //     setRegeneratePopup({ visible: false, itemType: null, mealKey: null, userPrompt: "" });
        //     setIsSubmitting(false);
        //     return;
        // }

        // Call the handleRegenerate function to regenerate the plan
        await handleRegenerate(itemType, userPrompt, mealKey);
        setIsSubmitting(false);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-8">
            <div className="bg-white rounded-lg shadow-lg w-96 p-3 relative">
                {/* Popup heading displaying the type of plan being regenerated */}
                <h3 className="text-xl font-semibold text-primary mb-4">
                    Regenerate {itemType} Plan
                </h3>

                {/* Textarea for the user to input additional preferences */}
                <textarea
                    rows={4}
                    value={userPrompt}
                    onChange={(e) =>
                        setRegeneratePopup((prev) => ({
                            ...prev,
                            userPrompt: e.target.value,
                        }))
                    }
                    className="w-full border rounded-lg p-2 text-sm text-gray-700 focus:ring-2 focus:ring-secondary outline-none mb-4"
                    placeholder="Add details about your preferences..."
                ></textarea>

                {/* Buttons for canceling or regenerating the plan */}
                <div className="flex justify-end space-x-4">
                    {/* Cancel button to close the popup */}
                    <button
                        onClick={() =>
                            setRegeneratePopup({
                                visible: false,
                                itemType: null,
                                mealKey: null,
                                userPrompt: "",
                            })
                        }
                        className="text-sm px-4 py-2 bg-gray-300 rounded-lg text-gray-800 hover:bg-gray-400 transition"
                        disabled={isSubmitting} // Disable while processing
                    >
                        Cancel
                    </button>

                    {/* Regenerate button to trigger plan regeneration */}
                    <button
                        onClick={handleRegenerateClick}
                        className="text-sm px-4 py-2 bg-secondary text-white rounded-lg hover:bg-lightSecondary transition"
                        disabled={isSubmitting} // Disable while processing
                    >
                        {isSubmitting ? "Processing..." : "Regenerate Now"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegeneratePopup;
