import { passFunction_url } from "../../config/cloudURL";
import { convertISOTOWord } from "../../helperFunctions/basicHelper";
import { addMonthsToISODate } from "../../helperFunctions/userDatabaseHelper";
import { themeColors } from "../../theme";

/**
 * Sends a request at the backend and 
 * Generates an apple pkpass
 * 
 * @param {Object} billInfo     -   Information regarding the payment
 */
export const generatePass = async (billInfo) => {
    try {
        const response = await fetch(passFunction_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                // For a unique Pass
                serialNumber        :   billInfo.response.razorpay_payment_id,
                // Important Dates
                relevantDate        :   billInfo.startDate,
                expirationDate      :   addMonthsToISODate(billInfo.startDate, billInfo.months),
                foregroundColor     :   themeColors.tertiary,
                backgroundColor     :   themeColors.lightGreen,
                labelColor          :   themeColors.secondary,
                barcodeData         :   `https://trainrex.org/receipt/${billInfo.response.razorpay_payment_id.slice(4)}`,

                primary             :   { 
                    value           :   billInfo.user.displayName, 
                    label           :   billInfo.gymName 
                },

                secondary           :   [
                    { 
                        value       :   convertISOTOWord(billInfo.startDate),
                        label       :   "Start Date" 
                    },
                    { 
                        value       :   `${billInfo.months ? `${billInfo.months} Months` : `1 Day`}`, 
                        label       :   "Duration" 
                    },
                ],

                auxiliary           :   [
                    { 
                        value       :   billInfo.response.razorpay_payment_id, 
                        label       :   "ReceiptID" 
                    },
                ]
            })
        });

        if (!response.ok) {
            throw new Error("Failed to download pass");
        }

        const blob = await response.blob();
        const passUrl = window.URL.createObjectURL(blob);

        // Open in Apple Wallet instead of downloading
        window.location.href = passUrl; // This will open "Add to Wallet" on iOS/macOS

        // const blob = await response.blob();
        // const url = window.URL.createObjectURL(blob);

        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "TrainRex.pkpass";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    } catch (error) {
        console.error("Error downloading pass:", error);
    }
};