import { trackNavigation } from "../../helperFunctions/Analytics/AnalyticsHelper";

/**
 * A card which displays Membership plans
 * @param {object}   plan               - contains all the info about the card (texts, background, color, etc) 
 * @param {object}   selectedPlan       - id of the selectedPlan
 * @param {object}   currGymData        - info about the gyms
 * @param {Function} setSelectedPlan    - function for MembershipPlanTest A handling plan select
 * @param {Function} handlePlanSelect   - function for MembershipPlanTest B handling plan select 
 * @param {String}   MembershipPlanTest - Can be "A" or "B" donating through which modal user came to these plan
 * @returns A card which displays Membership plans
 */
export const PlanCard = ({plan, selectedPlan, currGymData, handlePlanSelect, setSelectedPlan, setPlanPricing, MembershipPlanTest}) => {
    return (
        <div 
            key={plan.id}
            onClick={() => {
                // Track navigation based on plan id
                if (plan.id === 0) {
                    trackNavigation(
                        `/${currGymData?.city.toLowerCase()}/gyms`,
                        `/${currGymData?.city.toLowerCase()}/gyms/${currGymData?.gymName}`,
                        `Navigation for Basic Plan ${MembershipPlanTest}`
                    );
                } else if (plan.id === 1) {
                    trackNavigation(
                        `/${currGymData?.city.toLowerCase()}/gyms`,
                        `/${currGymData?.city.toLowerCase()}/gyms/${currGymData?.gymName}`,
                        `Navigation for Pro Plan ${MembershipPlanTest}`
                    );
                } else {
                    trackNavigation(
                        `/${currGymData?.city.toLowerCase()}/gyms`,
                        `/${currGymData?.city.toLowerCase()}/gyms/${currGymData?.gymName}`,
                        `Navigation for Premium Plan ${MembershipPlanTest}`
                    );
                }
              
                // Handle plan selection based on ABTesting flag
                if (MembershipPlanTest === 'B') {
                    setSelectedPlan(plan.id);
                    setPlanPricing(plan?.planData);
                } else {
                    handlePlanSelect(plan.id);
                }
            }}
            // className={`${selectedPlan === plan.id ? 'shadow-googleBlue shadow-2xl' : ''}relative font-bold lg:mt-0 px-4 py-8 text-white rounded-3xl overflow-hidden flex justify-center cursor-pointer min-h-[60vh] min-w-[80vw] lg:min-h-[60vh] lg:min-w-[20vw] transition-all duration-500`}
            className={`relative  font-bold lg:mt-0 p-4 text-${plan.textColor} rounded-3xl ${plan.id === 1 ? 'rounded-tl-none' : ''} flex justify-center cursor-pointer min-h-[56vh] min-w-[80vw] lg:min-h-[32vw] lg:min-w-[18vw] transition-all duration-500`}
            style={{ boxShadow: plan.id === selectedPlan ? '0 0 30px 15px rgba(135, 110, 175, 1)' : 'none' }}
        >
            {/* "Most Popular" Label */}
            <div className={`${plan.id === 1 ? '' : 'hidden'} top-0 left-0 absolute z-[11] text-white font-semibold bg-primary rounded-br-3xl px-8 py-2`}>
                Most Popular
            </div>

            {/* Wrapping Image inside an overflow-hidden div */}
            <div className="absolute top-0 left-0 h-[101%] w-[101%] rounded-3xl overflow-hidden">
                <img src={plan.image} alt={plan.name} className="min-h-[125%] -mt-4 -ml-4 min-w-[125%] object-cover" />
            </div>

            <div className="flex flex-col justify-between mt-8 lg:mt-[8%] relative">
                <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                        <div className="text-4xl lg:text-[3vw] mt-2">{plan.name}</div>
                        {/* Info Icon */}
                        <div className="relative group cursor-pointer">
                            <span className="text-lg lg:text-[1vw] bg-gray-300 text-black rounded-full px-2">i</span>
                            {/* Tooltip */}
                            <div className="z-[12] absolute left-8 -bottom-10 mb-2 w-48 p-2 text-sm text-white bg-black rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {plan.infoText}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 mt-8 lg:mt-[2vw]">
                        {plan.benefits.map((benefit, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <img src={plan.tick} alt='point' className='h-6 w-6 lg:h-[1.8vw] lg:w-[1.8vw]' />
                                <div className="font-semibold text-[4.6vw] lg:text-[100%]">{benefit}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="relative w-full flex gap-2 mt-6 justify-between items-center">
                    <div className={`relative ${plan.id === 0 ? 'text-primary' : 'text-white'} text-lg lg:w-[8vw] whitespace-nowrap lg:text-[1.1vw] font-normal`}>
                        Was ₹{plan.mrp}
                        <div className={`absolute top-[45%] right-4 h-[2px] w-[45%] rotate-6 bg-${plan.textColor}`}></div>
                    </div>
                    <button className="rounded-full px-4 py-2 lg:text-[1.5vw] lg:w-[14vw] text-white bg-black whitespace-nowrap">
                        Now ₹{plan.price}
                    </button>
                </div>
            </div>
        </div>
    )
}