import React from "react";
import { generateIMTPass } from "./generateIMTPass";
import appleWalletButton from "./AddToAppleWallet.svg";
import useAuthStatus from "../../hooks/clientAuth";

export const IDGenerator = () => {
    const { user }                           = useAuthStatus();

    if (user) {
        return (
            <div className="flex flex-col justify-center items-center h-[90vh] lg:h-[100vh] bg-tertiary text-left p-4 relative">
                <AddToAppleWalletButton user={user}/>
            </div>
        )
    } else {
        console.log('No User Present!');
        return (
            <div className="flex flex-col justify-center items-center h-[90vh] lg:h-[100vh] bg-tertiary text-left p-4 relative">
            </div>
        )
    }
}

/**
 * AddToAppleWalletButton Component
 * 
 * @param   {Object} billInfo   -   The bill information required to generate the Apple Wallet pass
 * @returns {JSX.Element}       -   A button that allows users to add a pass to Apple Wallet
 */
const AddToAppleWalletButton = ({ user }) => {
    /**
     * Handles the button click event and triggers the pass generation.
     */
    const handleClick = () => {
        generateIMTPass(user); // Calls function to generate Apple Wallet pass
    };

    return (
        <button
            onClick={handleClick}
            style={{
                background      :   "none", // No background to maintain Apple's official design
                border          :   "none", // No borders as per Apple Wallet guidelines
                cursor          :   "pointer", // Ensures the button is clickable
                padding         :   0, // Prevents extra spacing
                display         :   "flex", // Aligns button properly
                alignItems      :   "center",
                justifyContent  :   "center",
                outline         :   "none", // Removes browser focus outline
                userSelect      :   "none",
                width           :   "auto",
                height          :   "auto",
            }}
            aria-label="Add to Apple Wallet"
        >
            {/* Apple Wallet button image - Must not be altered per Apple's Guidelines */}
            <img
                src={appleWalletButton}
                alt="Add to Apple Wallet"
                style={{
                    height      : "60px", // Apple's recommended minimum height
                    width       : "189px", // Apple's recommended minimum width
                    objectFit   : "contain", // Ensures correct scaling
                }}
            />
        </button>
    );
}
