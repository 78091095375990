import React, {  useContext, useState } from "react";
import Modal from "react-modal";
import { RecaptchaVerifier, signInWithPhoneNumber, updateProfile } from "firebase/auth";
import { auth, db } from "../../config/firebase";
import toast from "react-hot-toast";
import { doc, getDoc, setDoc, Timestamp } from "@firebase/firestore";
import SignUpPopup from "./NewSignUpModal";
import { handleGoogleLogin } from "../../config/GoogleSignIn";
// import GoogleSignUpForm from "../../Modals/GoogleSignIn";
import { getCountryCode } from "../../config/countryCodes";
import GoogleLogo from "../../assets/Logos/GoogleLogo.png";
import Alert from "../../components/customComponents/Alert";
import UserFitnessForm from "../AIandMore/UserFitnessForm/UserFitnessForm";
import { trexToGiveNewUsers } from "../userDatabaseHelper";
import { GymContext } from "../../hooks/FetchEverythingContext";

/**
 * Sign In / Sign Up modal
 * Helps user sign in and up using their name and phoneNumber
 * 
 * @param 	{Boolean} modalIsOpen 		-	state to know the status of the modal
 * @param 	{Function} setModalIsOpen	-	Function to toggle the state of the modal
 * @returns 
 */
export const UserLoginModal = ({ modalIsOpen, setModalIsOpen }) => {
	const {setUser	     								}	= 	useContext(GymContext);
	const [view					, setView				]	=	useState("phoneInput"); // Tracks current view: phoneInput, otpVerification, additionalDetails
	const [phoneNumber			, setPhoneNumber		]	=	useState("");
	const [name					, setName				]	=	useState("");
	const [buttonText			, setButtonText			]	=	useState("Verify Phone Number");
	const [otp					, setOtp				]	=	useState('');
	const [confirmationResult	, setConfirmationResult	]	=	useState(null);
	const [googleDetails 		, setGoogleDetails		] 	=	useState({});
	const [showBasicQuestions   , setShowBasicQuestions ]   =   useState(false);

	// setModalIsOpen(false);

	/**
	 * Resets the Form
	 */
	const resetForm = () => {
		setModalIsOpen(false);
		setPhoneNumber('');
		setName('');
		setOtp('');
		setConfirmationResult(null);
		setView("phoneInput");
	}

	/**
     * Initializes the reCAPTCHA verifier for phone number authentication.
     * Ensures the reCAPTCHA is only initialized once.
     */
	const initializeRecaptcha = async () => {
		if (!window.recaptchaVerifier && document.getElementById('recaptcha-container')) {
			try {
				window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
					'size': 'invisible',
					'callback': (response) => {
						// alert("Congratulations!! You're not a robot");
					},
					'expired-callback': () => {
						// alert("Dude are you a robot??");
					}
				});

				await window.recaptchaVerifier.render();
				// console.log('reCAPTCHA initialized');
			} catch (error) {
				// console.error("Error initializing reCAPTCHA", error);
			}
		}
	};

	const handleInputChange = (e) => {
		let rawValue = e.target.value;
	
		// Remove all non-numeric characters except '+'
		const sanitizedValue = rawValue.replace(/\D/g, "");
	
		// Ensure the country code is always prefixed correctly
		const countryCode = getCountryCode(); // Example: +91
		if (!sanitizedValue.startsWith(countryCode.replace("+", ""))) {
			if (sanitizedValue.length <= 10) {
				setPhoneNumber(`${countryCode}${sanitizedValue}`);
			} else {
				setPhoneNumber(`+${sanitizedValue}`);
			}
		} else {
			setPhoneNumber(`+${sanitizedValue}`);
		}
	};

	const handleOtpInputChange = (value) => {
		// Ensure only numbers are entered
		if (!/^\d*$/.test(value)) return;
	
		// Limit OTP to 6 characters
		if (value.length > 6) return;
	
		// Update OTP state as a single string
		setOtp(value);
	};

	// Send OTP
	const sendOtp = async () => {
		if (!phoneNumber ) {
			toast.error("Please enter your full name and phone number.");
			return;
		}
		try {
			setButtonText("Verifying...");
			initializeRecaptcha();
			const appVerifier = window.recaptchaVerifier;
			if (!appVerifier) {
				Alert('error', "reCAPTCHA is not initialized. Please reload the page and try again.");
				return;
			}

			if (!appVerifier) {
				Alert('error', "reCAPTCHA is not initialized. Please reload the page and try again.");
				return;
			}

			const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
			setConfirmationResult(result);
			setView("otpVerification");
			toast.success("OTP sent successfully!");
			setButtonText("Verify OTP");
		} catch (error) {
			setButtonText("Verify Phone Number");

			// console.error("Error sending OTP:", error);
			toast.error("Failed to send OTP. Please try again.");

			// Refresh the page to reinitialize the process
			setTimeout(() => {
				window.location.reload();
			}, 3000); // Optional: Add a delay before refresh for user to see the toast message
		}
	};

	// Verify OTP
	const verifyOtp = async () => {
		if (!otp) {
			toast.error("Please enter the OTP.");
			return;
		}
		try {
			setButtonText("Verifying...");
			const userCredential = await confirmationResult.confirm(otp);
			const user = userCredential.user;

			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(JSON.stringify(user.uid));
			} else {
				console.warn('ReactNativeWebView is not available');
			}

			// setView("phoneInput");
			setOtp('');
			
			// Update the user's displayName with the provided name
			if (name) {
				// Only Update the name if name is not already present
				if (!auth.currentUser.displayName)
					await updateProfile(auth.currentUser, {
					displayName: name,
					});
			}

			// If a new user, collect additional details
			checkUserData(user);
		} catch (error) {
			setButtonText("Verify OTP");
			console.error("Error verifying OTP:", error);
			toast.error("Invalid OTP. Please try again.");
		}
	};

	/**
	 * Checks whether the user is a returning user or a new user
	 * @param {Object} user 			-	The signed in user
	 * @param {Boolean} isGoogleSignIn 	-	If The user signed in through google
	 */
	const checkUserData = async (user, isGoogleSignIn = false) => {
		try {
			// Reference to the user's Firestore document
			const userDocRef = doc(db, "user", user.uid); // Adjust the collection path as per your setup
			const userDocSnap = await getDoc(userDocRef);
			
			// Check if the user is new or doesn't have Firestore data
			if ( !userDocSnap.exists() ) {
				// Firestore document does not exist
				if(isGoogleSignIn) {
					setShowBasicQuestions(true);
					setView('GoogleSignIn');
				} else {
					// setView("additionalDetails"); // Show additional details view
					setShowBasicQuestions(true);
					setView("BasicQuestions");
				}
				const initialUserDetails = {
					trexCredits    : trexToGiveNewUsers(), 
					timestamp      : Timestamp.now(),
					gender		   : '',
					dob			   : '',
					phoneNumber    : user?.phoneNumber || googleDetails?.phoneNumber || '',
					email		   : user?.email 	   || googleDetails?.email 		 || '',
					displayName	   : user?.displayName || googleDetails?.displayName || '',
					profilePic     : user?.photoURL || '',
					isUpdated	   : true,
				}

				// console.log('User details : ', initialUserDetails)
				// console.log("user : ", user)
			
				await setDoc(userDocRef, initialUserDetails)
				setUser({uid : user.uid, ...initialUserDetails})
			} else {
				setButtonText("Verify Phone Number");
				toast.success("Sign In Successful!");
				resetForm();
			}
		} catch (error) {
			console.error("Error checking user data:", error);
		}
	};
	
	// console.log("USER : ", user)

	/**
	 * Handles pasting the otp
	 * @param {*} e 
	 */
	const handleOtpPaste = (e) => {
		const pastedData = e.clipboardData.getData("text");
		
		// Ensure the pasted data is numeric and of the correct length
		if (/^\d{6}$/.test(pastedData)) {
		  	const otpArray = pastedData.split(""); // Convert string to array
		  	setOtp(otpArray); // Assuming `otp` is the state variable
		} else {
		  e.preventDefault(); // Prevent invalid data from being pasted
		}
	};

	/**
	 * Handles Google Sign In
	 */
	const handleSigninWithGoogle = async() => {
		try {
			const result = await handleGoogleLogin();
			setGoogleDetails(result._tokenResponse);
			// Checks whether user is logging in for the first time ?
			await checkUserData(result.user, true);
		} catch (error) {
			resetForm();
		}
	}

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={() => {
				window.location.reload();
				resetForm();
				setView("phoneInput")
				setButtonText("Verify Phone Number")
			}}
			contentLabel="User Login"
			className="fixed inset-0 flex items-center justify-center z-50 text-black"
			overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
			// ariaHideApp={false} // Use this if running in a React Native-Web environment
		>
			<div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] max-h-[660px] relative flex flex-col items-center p-6">
				{/* <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-primary mb-4"> */}
				<div className="transform mb-2 mx-2">
					<div className="text-2xl lg:text-3xl font-extrabold text-lightTertiary">
						Get <span className="text-primary">₹50</span> on Signing Up!
					</div>
				</div>
				{/* {view === "phoneInput" && "Enter Your Details"}
				{view === "otpVerification" && "Verify OTP"}
				{view === "additionalDetails" && "Complete Your Profile"} */}
				{/* </h2> */}

				{view === "phoneInput" && (
				<>
					{/* <input
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Full Name"
						className="text-xs lg:text-base py-2 px-3 border rounded-3xl w-full mb-3 outline-none focus:ring-2 focus:ring-primary"
					/> */}
					<input
						type="tel"
						value={phoneNumber}
						onChange={handleInputChange}
						placeholder="Enter Phone Number"
						className="text-xs lg:text-base py-2 px-3 border rounded-3xl w-full mb-3 outline-none focus:ring-2 bg-tertiary text-secondary font-semibold placeholder:text-secondary placeholder:font-semibold focus:ring-primary"
					/>
					<button
						onClick={sendOtp}
						className="bg-tertiary text-black py-2 px-4 rounded-full hover:bg-purple hover:text-tertiary transition-all w-full"
					>
						{buttonText}
					</button>

					{/* Google Sign Up button */}
					{!window.ReactNativeWebView && 
					<>
						<div className="flex items-center w-full my-4">
							<hr className="flex-grow border-t border-primary" />
							<span className="mx-4 text-black text-sm font-medium">OR</span>
							<hr className="flex-grow border-t border-primary" />
						</div>

						<button
							onClick={handleSigninWithGoogle}
							className="flex items-center justify-center gap-2 border bg-white border-black rounded-full px-4 py-2 text-black font-medium shadow-md hover:bg-purple transition-all w-full mt-2"
							style={{
								fontFamily: "Roboto, Arial, sans-serif",
								fontSize: "16px",
								fontWeight: 500,
							}}
						>
							<img src={GoogleLogo} alt="Google Logo" className="w-6 h-6" />
							Sign in with Google
						</button>
					</>}
				</>
				)}

				{view === "otpVerification" && (
				<>
					{/* Heading */}
					<h2 className="text-lg lg:text-xl font-bold mb-2">
					Verify <span className="text-primary">{phoneNumber}</span>
					</h2>

					{/* OTP Input Box */}
					<div className="flex flex-col items-center mb-4 w-full">
						<input
							type="text"
							inputMode="numeric" // Ensures numeric keyboard on mobile devices
							pattern="[0-9]*" // Ensures only numeric input
							value={otp} // Single OTP string
							onChange={(e) => handleOtpInputChange(e.target.value)} // Update OTP state
							onPaste={handleOtpPaste} // Handles autofill when OTP is pasted
							className="w-full max-w-sm text-center text-lg border rounded-lg outline-none focus:ring-2 focus:ring-primary py-2"
							maxLength="6" // Restrict input length to 6 digits
							placeholder="Enter OTP"
						/>
					</div>

					{/* Verify Button */}
					<button
					onClick={() => verifyOtp()}
					className="bg-tertiary text-black mb-2 py-2 px-4 rounded-full hover:bg-primary hover:text-tertiary transition-all w-full"
					>
					{buttonText}
					</button>

					{/* Go Back Button */}
					<button
						onClick={() => {
							setView("phoneInput")
							setOtp('')
						}}
						className="text-sm text-primary underline hover:text-secondary transition"
					>
						Wrong Number? Go Back
					</button>
				</>
				)}

				{view === "additionalDetails" && (
					<>
						{/* Form to get all the details */}
						<SignUpPopup onClose={() => setModalIsOpen(false)} formInfo={{
							name, phoneNumber, userID : auth.currentUser ? auth.currentUser.uid : ''
						}}/>
						{/* <UserFitnessForm isModalOpen={true} onClose={() => setModalIsOpen(false)}/> */}
					</>
				)}

				{view === 'BasicQuestions' && 
					<UserFitnessForm isModalOpen={showBasicQuestions} onClose={() => {setShowBasicQuestions(false); setModalIsOpen(false);} }/>
				}

				{view === "GoogleSignIn" && (
					<>
						<UserFitnessForm isModalOpen={showBasicQuestions} onClose={() => {setShowBasicQuestions(false); setModalIsOpen(false);} }/>
						{/* <GoogleSignUpForm 
							isOpen={modalIsOpen}
							onClose={() => setModalIsOpen(false)}
							googleDetails={googleDetails}
							user={auth.currentUser ? auth.currentUser : ''}
							resetForm={resetForm}
						/> */}
					</>
				)}

				<div id="recaptcha-container"></div>

				<button
					onClick={() => resetForm()}
					className="absolute top-2 right-4 text-tertiary hover:text-black text-2xl"
				>
					&times;
				</button>

			</div>
		</Modal>
	);
};
