import { useEffect, useState } from "react";
import useAuthStatus from "../../hooks/clientAuth";
import { UserLoginModal } from "../../helperFunctions/ModalHelpers/UserCreds";
import { QuestionCard } from "../../helperFunctions/DiscussionForumHelpers/QuestionCard";
import AddQuestion from "../../components/Modals/Forum/AddQuestion";
import { getAllForums } from "../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";
import { getAllTags } from "../../helperFunctions/DiscussionForumHelpers/GetAllTags";
import { ArrowLeft, Search } from "lucide-react";
import { themeColors } from "../../theme";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { useLocation, useNavigate } from "react-router-dom";

const DiscussionForums = ({showUnlockGym, setShowUnlockGym}) => {
    const { isUserPresent                       } = useAuthStatus();
    const location                                = useLocation();
    const navigate = useNavigate();
    const [myData                               ] = useState({});
    const [USR             ,              setUSR] = useState(window.devicePixelRatio);  //userScreenResolution
    const [tags            ,             setTags] = useState();
    const [loading         ,          setLoading] = useState(true);
    const [allForums       ,        setAllForums] = useState([]);
    const [searchQuery     ,      setSearchQuery] = useState(location?.state || ""); // State for search query
    const [selectedTags    ,     setSelectedTags] = useState([])
    const [showCredModal   ,    setShowCredModal] = useState(false);
    const [addQuestionModal, setAddQuestionModal] = useState(false);

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        setLoading(true);
        const getForums = async () => {
            const forums = await getAllForums();
            setAllForums(forums);
            setLoading(false);
        };

        getForums();
    }, []);

    // Filter forums based on search query
    const filteredForums = allForums.filter((forum) => {
        const query = searchQuery.toLowerCase();
    
        // Check if the question or any tag name includes the search query
        const isSearchMatch =
            forum.question.toLowerCase().includes(query) ||
            forum.tags?.some((tag) => tag.toLowerCase().includes(query));
    
        // Check if the forum's tags match any of the selected tags
        const isTagMatch =
            selectedTags.length === 0 || // If no tags are selected, include all forums
            selectedTags.some((tag) => forum.tags?.includes(tag));
    
        // Include forums that match both the search query and selected tags
        return isSearchMatch && isTagMatch;
    });

    useEffect(() => {
        setLoading(true);
        const fetchTags = async () => {
            const data = await getAllTags();
            const sortedTags = data
                .sort((a, b) => b.usedCount - a.usedCount) // Sort tags by `usedCount` in descending order
                .slice(0, 5); // Select the top 5 tags
            setTags(sortedTags);
            setLoading(false);
        };

        fetchTags();
    }, []);

    const toggleTagSelection = (tag) => {
        setSelectedTags((prevTags) =>
            prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
        );
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth", // Enables smooth scrolling
        });
    }, []);

    if(loading) {
        return (
            <div className="h-[80vh] flex justify-center items-center flex-col">
                <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
            </div>
        )
    }

    return (
        <div className={`min-h-[76.6vh] mt-36 md:mt-28 ${USR <= 1 ? 'lg:mt-32' : 'lg:mt-24'} flex flex-col my-10 mb-20`}>
            <div className="w-full">
                <div className={`flex justify-between items-center mx-4 md:mx-10  ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'}`}>
                    <div className="flex items-center gap-1">
                        {window.ReactNativeWebView && 
                        // only visible on app, takes user to the last page before the current one
                        <button onClick={() => navigate(-1)}> 
                            <ArrowLeft  className='text-secondary'/>
                        </button>}
                        <div className="text-2xl lg:text-5xl text-secondary">Discussion Forums</div>
                    </div>
                    <div 
                        onClick={() => isUserPresent ? setAddQuestionModal(true) : setShowCredModal(true) }
                        className="text-4xl lg:text-3xl h-12 w-12 rounded-full flex justify-center items-center text-tertiary bg-secondary cursor-pointer hover:scale-125 transition-all duration-500"
                    >
                        <span className="leading-none -mt-[3px]">+</span>
                    </div>
                </div>
            </div>

            {addQuestionModal && <AddQuestion setAddQuestionModal={setAddQuestionModal} />}

            {/* Search Bar */}
            <div className="w-full">
                <div
                    className={`bg-gray-300 rounded-lg py-3 px-4 lg:p-4 mt-4 flex items-center gap-4 mx-4 md:mx-10 ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'}`}
                >
                    <Search color={themeColors.secondary} />
                    <input
                        placeholder="Search discussions..."
                        className={`bg-transparent lg:text-xl placeholder-gray-600 w-full outline-none focus:ring-0`}
                        value={searchQuery} // Bind to search query state
                        onChange={(e) => setSearchQuery(e.target.value)} // Update state on input
                    />
                </div>
            </div>

            {/* Tags */}
            <div className="w-full flex gap-1 lg:gap-4 items-center">
                <div className={`font-semibold ml-4 ${USR <= 1 ? 'lg:ml-40' : USR === 1.25 ? 'lg:ml-20' : USR === 1.5 ? 'lg:ml-4' : 'lg:ml-20'} whitespace-nowrap`}>Hot Topics:</div>
                <div className="flex items-center gap-4 overflow-x-auto no-scrollbar my-4 pr-4">
                    {tags && tags?.length !== 0 && tags.map((tag) => (
                        <div
                            key={tag.tag}
                            onClick={() => toggleTagSelection(tag.tag)}
                            className={`px-4 py-1 rounded-full border-[1px] font-extralight text-sm whitespace-nowrap cursor-pointer transition-all duration-300 ${
                                selectedTags.includes(tag.tag)
                                    ? "bg-secondary text-white"
                                    : "border-gray-500 text-gray-700"
                            }`}
                        >
                            {tag?.tagDisplayName}
                        </div>
                    ))}
                </div>
            </div>

            {/* Render Forums */}
            <div className={`md:mx-10  ${USR <= 1 ? 'lg:mx-40' : USR === 1.25 ? 'lg:mx-20' : USR === 1.5 ? 'lg:mx-4' : 'lg:mx-20'} mt-4`}>
                {filteredForums && filteredForums?.length > 0 ? (
                    filteredForums?.map((forum, index) => (
                        <QuestionCard key={index} forum={forum} />
                    ))
                ) : (
                    <div className="text-center text-gray-500 mt-10">No forums found.</div>
                )}
            </div>

            <UserLoginModal
                modalIsOpen={showCredModal}
                setModalIsOpen={setShowCredModal}
                myData={myData}
                showGuest={false}
            />
        </div>
    );
};

export default DiscussionForums;
