import { useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { cmToFeetIn, feetInToCm, kgToLbs, lbsToKg } from "../../../basicHelper";

// Slider showing steps like Steps 1/9
export const StepsSlider = ({ slide }) => {
    const percent = ((slide) / 10) * 100;
  
    return (
        <div className="mb-6">
            <div className="w-full bg-gray-300 h-2 rounded-full overflow-hidden">
                <div
                className="h-2 bg-secondary transition-width"
                style={{ width: `${percent}%` }}
                />
            </div>
            <p className="text-center text-sm font-medium mt-1">
                Step {slide} of 10
            </p>
        </div>
    );
};

// Slide 0
export const UserGoals = ({ answers, setAnswers, setSlide }) => {
    const goals = ['Fat Loss', 'Bodybuilding', 'Weight Gain', 'Coach Guidance', 'Strength & Conditioning', 'Healthy Lifestyle'];
  
    const toggleGoal = (goal) => {
        setAnswers(prev => {
            const list = prev.selectedGoals || [];
            return {
            ...prev,
            selectedGoals: list.includes(goal)
                ? list.filter(g => g !== goal)
                : [...list, goal]
            };
        });
    };
  
    const handleTermClick = (term) => {
        setAnswers(prev => ({ ...prev, termChoice: term }));
    };
  
    const hasGoals = (answers.selectedGoals?.length > 0);
  
    return (
        <div className="text-black flex flex-col min-w-full">
            <h2 className="text-2xl font-semibold">What is your goal?</h2>
            <div className="grid grid-cols-2 gap-4 mt-4">
            {goals.map(goal => (
                <button
                key={goal}
                onClick={() => toggleGoal(goal)}
                className={`border-2 px-6 py-4 rounded-2xl transition-all duration-500 ${answers.selectedGoals?.includes(goal) ? 'border-purple bg-purple/20' : ''}`}
                >
                {goal}
                </button>
            ))}
            </div>
    
            {hasGoals && (
            <div className="flex flex-col mt-8">
                <h2 className="text-2xl font-semibold">Is this a short‑term or long‑term habit goal?</h2>
                <div className="flex gap-4 mt-2">
                {['Short Term','Long Term'].map(term => {
                    const value = term === 'Short Term' ? 'short' : 'long';
                    return (
                        <button
                        key={term}
                        onClick={() => handleTermClick(value)}
                        className={`border-2 py-4 w-full rounded-2xl transition-all duration-500 ${
                            answers.termChoice === value ? 'border-purple bg-purple/20' : ''
                        }`}
                        >
                        {term === 'Short Term'
                            ? <>Short term<br/>(e.g. summer body)</>
                            : <>Long term<br/>(habit forming)</>}
                        </button>
                    );
                    })}
                </div>
            </div>
            )}

            <div className="w-[100vw] p-4 fixed bottom-0 left-0">
                <button
                    onClick={() => setSlide(prev => prev + 1)}
                    disabled={!hasGoals || !answers.termChoice}
                    className="bg-secondary text-black rounded-2xl w-full py-4 text-2xl mt-8 disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};  

// Slide 1
export const UserHeightWeightDOB = ({ answers, setAnswers, slide, setSlide }) => {
    const [heightUnit, setHeightUnit] = useState("cm");
    const [weightUnit, setWeightUnit] = useState("kg");

    
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            {/* HEIGHT */}
            <div>
                <label className="block mb-1 text-left text-xl font-semibold">What is your height?</label>
                <div className="flex mb-2 gap-4">
                    {["cm","ft & in"].map(unit => (
                    <button
                        key={unit}
                        onClick={() => {
                        if (unit === "cm" && answers.heightFt) {
                            setAnswers(prev => ({
                            ...prev,
                            heightCm: feetInToCm(prev.heightFt, prev.heightIn).toString(),
                            heightFt: "", heightIn: ""
                            }));
                        }
                        if (unit === "ft & in" && answers.heightCm) {
                            const { ft, inch } = cmToFeetIn(Number(answers.heightCm));
                            setAnswers(prev => ({ ...prev, heightFt: ft.toString(), heightIn: inch.toString(), heightCm: "" }));
                        }
                        setHeightUnit(unit);
                        }}
                        className={`px-4 py-2 border ${heightUnit===unit ? "border-purple bg-purple/20" : ""} rounded`}
                    >{unit}</button>
                    ))}
                </div>

                {heightUnit === "cm" ? (
                    <select
                    value={answers.heightCm || ""}
                    onChange={e => setAnswers(prev => ({ ...prev, heightCm: e.target.value, heightFt: "", heightIn: "" }))}
                    className="w-full px-4 py-2 border rounded appearance-none"
                    >
                    <option value="">Select cm</option>
                    {Array.from({ length: 151 }, (_, i) => 100 + i).map(cm => (
                        <option key={cm} value={cm}>{cm} cm</option>
                    ))}
                    </select>
                ) : (
                <div className="flex gap-2">
                    <select
                        value={answers.heightFt || ""}
                        onChange={e => setAnswers(prev => ({ ...prev, heightFt: e.target.value, heightCm: "" }))}
                        className="w-1/2 px-4 py-2 border rounded appearance-none"
                    >
                        <option value="">ft</option>
                        {[3,4,5,6,7].map(ft => <option key={ft} value={ft}>{ft} ft</option>)}
                    </select>
                    <select
                        value={answers.heightIn || ""}
                        onChange={e => setAnswers(prev => ({ ...prev, heightIn: e.target.value, heightCm: "" }))}
                        className="w-1/2 px-4 py-2 border rounded appearance-none"
                    >
                        <option value="">in</option>
                        {Array.from({ length: 12 }, (_, i) => i).map(i => <option key={i} value={i}>{i} in</option>)}
                    </select>
                </div>
            )}
            </div>

            {/* WEIGHT */}
            <div>
                <label className="block mb-1 text-left text-xl font-semibold">What is your weight?</label>
                <div className="flex mb-2 gap-4">
                    {["kg","lbs"].map(unit => (
                    <button
                        key={unit}
                        onClick={() => {
                        if (unit === "kg" && answers.weightLbs) {
                            setAnswers(prev => ({ ...prev, weightKg: lbsToKg(Number(prev.weightLbs)).toFixed(0), weightLbs: "" }));
                        }
                        if (unit === "lbs" && answers.weightKg) {
                            setAnswers(prev => ({ ...prev, weightLbs: kgToLbs(Number(prev.weightKg)).toFixed(0), weightKg: "" }));
                        }
                        setWeightUnit(unit);
                        }}
                        className={`px-4 py-2 border ${weightUnit===unit ? "border-purple bg-purple/20" : ""} rounded`}
                    >{unit}</button>
                    ))}
                </div>

                <select
                    value={weightUnit === "kg" ? answers.weightKg || "" : answers.weightLbs || ""}
                    onChange={e => setAnswers(prev => ({
                    ...prev,
                    ...(weightUnit === "kg"
                        ? { weightKg: e.target.value, weightLbs: "" }
                        : { weightLbs: e.target.value, weightKg: "" })
                    }))}
                    className="w-full px-4 py-2 border rounded appearance-none"
                >
                    <option value="">{weightUnit}</option>
                    {weightUnit === "kg"
                    ? Array.from({ length: 151 }, (_, i) => i + 30).map(val => <option key={val} value={val}>{val} kg</option>)
                    : Array.from({ length: 201 }, (_, i) => i + 30).map(val => <option key={val} value={val}>{val} lbs</option>)
                    }
                </select>
            </div>


    
            {/* Date of Birth */}
            <div className="flex flex-col gap-1 col-span-2">
                <label className=" text-left text-xl font-semibold">Date of Birth*</label>
                <DatePicker
                    selected={answers.dob ? new Date(answers.dob.split("-").reverse().join("-")) : null}
                    onChange={(date) => {
                        if (!date) {
                            setAnswers(prev => ({ ...prev, dob: "" }));
                        } else {
                            setAnswers(prev => ({ ...prev, dob: format(date, "dd-MM-yyyy") }));
                        }
                    }}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DOB: DD-MM-YYYY"
                    className="text-sm text-black lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    renderCustomHeader={({ date, changeYear, changeMonth }) => {
                    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
                    const months = [
                        "January","February","March","April","May","June",
                        "July","August","September","October","November","December"
                    ];

                    return (
                        <div className="flex justify-center space-x-2 p-2">
                        <select
                            value={date.getFullYear()}
                            onChange={({ target }) => changeYear(target.value)}
                            className="p-1 border rounded"
                        >
                            {years.map(year => <option key={year} value={year}>{year}</option>)}
                        </select>
                        <select
                            value={date.getMonth()}
                            onChange={({ target }) => changeMonth(Number(target.value))}
                            className="p-1 border rounded"
                        >
                            {months.map((m,i) => <option key={i} value={i}>{m}</option>)}
                        </select>
                        </div>
                    );
                    }}
                />
            </div>

            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={
                        !(answers.dob &&
                        (heightUnit==="cm" ? answers.heightCm : answers.heightFt && answers.heightIn) &&
                        (weightUnit==="kg" ? answers.weightKg : answers.weightLbs))
                    }
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

// Slide 2
export const UserGender = ({ answers, setAnswers, slide, setSlide }) => {
    const handleGenderChange = (value) => {
        setAnswers(prev => ({
            ...prev,
            gender: value,
            centerPreference: value === "Female" ? prev.centerPreference : "Unisex"
        }));
    };
  
    const handlePrefChange = (value) => {
        setAnswers(prev => ({ ...prev, centerPreference: value }));
    };
  
    const isValid =
        answers.gender &&
        (answers.gender !== "Female" || answers.centerPreference);
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <div>
            <h2 className=" mb-2 text-left text-xl font-semibold">What is your gender?</h2>
            <div className="flex gap-4">
                {["Male", "Female", "Others"].map(opt => (
                <button
                    key={opt}
                    onClick={() => handleGenderChange(opt)}
                    className={`px-4 py-2 border rounded transition-all duration-500 ${
                    answers.gender === opt ? "border-purple bg-purple/20" : ""
                    }`}
                >
                    {opt}
                </button>
                ))}
            </div>
            </div>
    
            {(answers.gender === "Female" || answers.gender === "Others") && (
            <div>
                <h2 className="text-left text-xl font-semibold mb-2">
                    What kind of fitness center do you prefer?
                </h2>
                <div className="flex gap-4">
                {["Unisex", "Women‑only"].map(opt => (
                    <button
                    key={opt}
                    onClick={() => handlePrefChange(opt)}
                    className={`px-4 py-2 border rounded transition-all duration-500 ${
                        answers.centerPreference === opt ? "border-purple bg-purple/20" : ""
                    }`}
                    >
                    {opt}
                    </button>
                ))}
                </div>
            </div>
            )}

            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={!isValid}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

// Slide 3
export const UserActivityLevel = ({ answers, setAnswers, slide, setSlide }) => {
    const handleSelect = (value) =>
        setAnswers(prev => ({ ...prev, activityLevel: value }));

    
    const OPTIONS = [
        { label: "Sedentary (little to no exercise)", value: "sedentary" },
        { label: "Lightly Active (1–2 workouts/week)", value: "lightly_active" },
        { label: "Moderately Active (3–4 workouts/week)", value: "moderately_active" },
        { label: "Very Active (5+ workouts/week)", value: "very_active" },
    ];
  
  
    return (
        <div className="flex flex-col space-y-6 text-black">
            <StepsSlider slide={slide} />
    
            <h2 className="text-left text-xl font-semibold">What is your current activity level?</h2>
            <p className="text-sm text-gray-700 mb-4 text-left">Select only one</p>
    
            <div className="grid grid-cols-2 gap-4">
            {OPTIONS.map(({ label, value }) => (
                <button
                key={value}
                onClick={() => handleSelect(value)}
                className={`flex flex-col items-center p-4 border rounded-lg hover:shadow transition-all duration-500 ${
                    answers.activityLevel === value 
                    ? "border-purple bg-purple/20" 
                    : "border-gray-300"
                }`}
                >
                {/* Replace with your icon */}
                {/* <img src="/placeholder-icon.png" alt="" className="w-10 h-10 mb-2" /> */}
                <span className="text-center">{label}</span>
                </button>
            ))}
            </div>
    
            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button
                    onClick={() => setSlide(prev => prev - 1)}
                    className="px-6 py-3 border rounded-2xl"
                >
                    Back
                </button>
                <button
                    onClick={() => setSlide(prev => prev + 1)}
                    disabled={!answers.activityLevel}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

// Slide 4
export const UserWorkoutPreference = ({ answers, setAnswers, slide, setSlide }) => {
    const toggleOption = (value) => {
        setAnswers(prev => {
            const next = prev.trainingPreference?.includes(value)
            ? prev.trainingPreference.filter(v => v !== value)
            : [...(prev.trainingPreference||[]), value];
    
            return {
            ...prev,
            trainingPreference: next,
            ...(next.length < 2 ? { trainingBalance: "" } : {})
            };
        });
    };
  
    const selectBalance = (value) =>
        setAnswers(prev => ({ ...prev, trainingBalance: value }));
  
    const selected = answers.trainingPreference || [];
  
    const OPTIONS = [
        { label: "Gym Based", value: "gym" },
        { label: "Home‑based Online Classes", value: "online" },
        { label: "Home‑based Self Workout", value: "self" },
        { label: "Offline Classes (Yoga, Zumba)", value: "offline" },
        { label: "Sports‑based", value: "sports" },
    ];
  
    const generateBalanceOptions = (prefs = []) => {
        if (prefs.length < 2) return [];
      
        const [primary, secondary] = prefs;
        const labelPrimary = OPTIONS.find(o => o.value === primary).label;
        const labelSecondary = OPTIONS.find(o => o.value === secondary).label;
      
        return [
            {
                label: `Do ${labelSecondary} at most 2×/week (prioritise ${labelPrimary})`,
                value: "other_limited"
            },
            {
                label: `Balance ${labelPrimary} and ${labelSecondary} equally each week`,
                value: "balanced"
            }
        ];
    };

    const balanceOptions = generateBalanceOptions(selected);
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <h2 className="text-left text-xl font-semibold">What kind of workouts do you prefer?</h2>
            <p className="text-sm text-gray-700 text-left">(Select one or more)</p>
    
            <div className="grid grid-cols-2 gap-4">
            {OPTIONS.map(({ label, value }) => (
                <button
                key={value}
                onClick={() => toggleOption(value)}
                className={`p-4 border rounded-lg text-center transition-all duration-500 ${
                    selected.includes(value)
                    ? "border-purple bg-purple/20"
                    : "border-gray-300"
                }`}
                >
                {label}
                </button>
            ))}
            </div>
    
            {selected.length >= 2 && (
            <div className="mt-6 space-y-2">
                <h3 className="text-left text-xl font-semibold">How do you like to balance these two?</h3>
                {balanceOptions.map(({ label, value }) => (
                <button
                    key={value}
                    onClick={() => selectBalance(value)}
                    className={`w-full px-4 py-2 border rounded ${
                    answers.trainingBalance === value ? "border-purple bg-purple/10" : "border-gray-300"
                    }`}
                >
                    {label}
                </button>
                ))}
            </div>
            )}
    
            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={
                    !selected.length ||
                    (selected.length >= 2 && !answers.trainingBalance)
                    }
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

// Slide 5
export const UserGymOnlinePreferences = ({ answers, setAnswers, slide, setSlide }) => {
    const toggleArray = (key, value) =>
      setAnswers(prev => {
        const arr = prev[key] || [];
        return {
          ...prev,
          [key]: arr.includes(value)
            ? arr.filter(x => x !== value)
            : [...arr, value],
        };
      });
  
    const { trainingPreference = [] } = answers;

    const isValid = trainingPreference.every(pref => {
        switch(pref) {
            case "gym":
                return answers.gymActivities?.length > 0;
            case "online":
                return answers.onlineClasses?.length > 0;
            case "self":
                return answers.selfConfirmed === true;
            case "offline":
                return answers.offlineClasses?.length > 0;
            case "sports":
                return Boolean(answers.sportsType) && Boolean(answers.sportsApproach);
            default:
                return true;
        }
    });
  
    return (
      <div className="flex flex-col space-y-6 text-black min-w-full">
        <StepsSlider slide={slide} />
  
        <div className="max-h-[75vh] overflow-y-auto">
            {trainingPreference.includes("gym") && (
            <section>
                <h2 className="text-left text-xl font-semibold mb-2">What do you like doing in the gym?</h2>
                {["Cardio", "Weight Training", "CrossFit", "Other"].map(opt => (
                <button
                    key={opt}
                    onClick={() => toggleArray("gymActivities", opt)}
                    className={`w-full text-left px-4 py-3 border rounded mb-2 transition-all duration-500 ${
                    answers.gymActivities?.includes(opt) ? "border-purple bg-purple/20" : "border-gray-300"
                    }`}
                >
                    {opt}
                </button>
                ))}
                {answers.gymActivities?.includes("Other") && (
                <input
                    type="text"
                    placeholder="Please specify"
                    value={answers.otherGymActivity || ""}
                    onChange={e => setAnswers(prev => ({ ...prev, otherGymActivity: e.target.value }))}
                    className="w-full px-4 py-2 border rounded text-black"
                />
                )}
            </section>
            )}
    
            {trainingPreference.includes("online") && (
            <section>
                <h2 className="text-left text-xl font-semibold mb-2 mt-6">What kind of online classes do you prefer?</h2>
                {["Live Zoom Sessions", "Pre‑recorded Videos", "Group Classes"].map(opt => (
                <button
                    key={opt}
                    onClick={() => toggleArray("onlineClasses", opt)}
                    className={`w-full text-left px-4 py-3 border rounded mb-2 transition-all duration-500 ${
                    answers.onlineClasses?.includes(opt) ? "border-purple bg-purple/20" : "border-gray-300"
                    }`}
                >
                    {opt}
                </button>
                ))}
            </section>
            )}
    
            {trainingPreference.includes("self") && (
            <section>
                <h2 className="text-left text-xl font-semibold mb-2 mt-6">
                You’ll need to track regularly even if we provide a plan. Is that okay?
                </h2>
                <p className="text-gray-700 text-left mb-4">
                (We’ll show you how to log workouts & progress.)
                </p>

                <label className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    checked={answers.selfConfirmed || false}
                    onChange={e => setAnswers(prev => ({ ...prev, selfConfirmed: e.target.checked }))}
                    className="h-5 w-5 rounded border-white text-black"
                />
                <span className="text-base">Yes, I’m okay tracking regularly</span>
                </label>
            </section>
            )}
    
            {trainingPreference.includes("offline") && (
            <section>
                <h2 className="text-left text-xl font-semibold mb-2 mt-6">Which offline classes do you prefer?</h2>
                {["Yoga","Zumba","HIIT","Dance","Pilates"].map(opt => (
                <button
                    key={opt}
                    onClick={() => toggleArray("offlineClasses", opt)}
                    className={`w-full text-left px-4 py-3 border rounded mb-2 transition-all duration-500 ${
                    answers.offlineClasses?.includes(opt) ? "border-purple bg-purple/20" : "border-gray-300"
                    }`}
                >
                    {opt}
                </button>
                ))}
            </section>
            )}
    
            {trainingPreference.includes("sports") && (
            <section>
                <h2 className="text-left text-xl font-semibold mb-2 mt-6">Which sport would you like?</h2>
                <input
                type="text"
                placeholder="e.g. Football, Cricket..."
                value={answers.sportsType || ""}
                onChange={e => setAnswers(prev => ({ ...prev, sportsType: e.target.value }))}
                className="w-full px-4 py-2 border rounded mb-4 text-black"
                />
                <h2 className="text-left text-xl font-semibold mb-2">Casual or professional?</h2>
                {["casual","professional"].map(opt => (
                <button
                    key={opt}
                    onClick={() => setAnswers(prev => ({ ...prev, sportsApproach: opt }))}
                    className={`w-full px-4 py-3 border rounded mb-2 transition-all duration-500 ${
                    answers.sportsApproach === opt ? "border-purple bg-purple/20" : "border-gray-300"
                    }`}
                >
                    {opt.charAt(0).toUpperCase() + opt.slice(1)}
                </button>
                ))}
            </section>
            )}
        </div>
  
        <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
            <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                Back
            </button>
            <button
                onClick={() => setSlide(s => s + 1)}
                disabled={!isValid}
                className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
            >
                Next
            </button>
        </div>
      </div>
    );
};

// Slide 6
export const UserTimeDedicationToWorkout = ({ answers, setAnswers, slide, setSlide }) => {
    const OPTIONS = [
        { label: "30 minutes", value: "30min" },
        { label: "1 hour", value: "1hr" },
        { label: "2 hours", value: "2hr" },
        { label: "More than 2 hours", value: "2hr+" },
    ];
  
    const select = (value) => {
        setAnswers(prev => ({ ...prev, timeForWorkout: value }));
    };
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <h2 className="text-xl font-semibold text-left">How much time can you dedicate to fitness per day?</h2>
            <p className="text-sm text-gray-600 text-left">(Select one)</p>
    
            <div className="grid grid-cols-2 gap-4 mt-4">
            {OPTIONS.map(({ label, value }) => (
                <button
                    key={value}
                    onClick={() => select(value)}
                    className={`flex justify-center items-center p-4 border rounded-lg transition-all duration-500 ${
                        answers.timeForWorkout === value ? "border-purple bg-purple/20" : "border-gray-300"
                    }`}
                >
                    <span>{label}</span>
                </button>
            ))}
            </div>
    
            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={!answers.timeForWorkout}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};  

// Slide 7
export const UserDietPreference = ({ answers, setAnswers, slide, setSlide }) => {
    const select = (value) => {
        setAnswers(prev => ({
            ...prev,
            dietPreference: value,
            dietPreferenceOther: value === "other" ? prev.dietPreferenceOther : ""  // clear other if switching away
        }));
    };
  
    const OPTIONS = [
        { label: "Veg", value: "veg" },
        { label: "Non Veg", value: "nonveg" },
        { label: "Vegan", value: "vegan" },
        { label: "Other", value: "other" },
    ];
  
    const isValid = 
        answers.dietPreference && 
        (answers.dietPreference !== "other" || Boolean(answers.dietPreferenceOther?.trim()));
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <h2 className="text-left text-xl font-semibold">What do you prefer eating?</h2>
            <p className="text-sm text-gray-700 text-left">(Select only one)</p>
    
            <div className="grid grid-cols-2 gap-4">
            {OPTIONS.map(({ label, value }) => (
                <button
                key={value}
                onClick={() => select(value)}
                className={`flex flex-col items-center p-4 border rounded-lg transition-all duration-500 ${
                    answers.dietPreference === value 
                    ? "border-purple bg-purple/20" 
                    : "border-gray-300"
                }`}
                >
                <span>{label}</span>
                </button>
            ))}
            </div>
    
            {answers.dietPreference === "other" && (
            <textarea
                value={answers.dietPreferenceOther || ""}
                onChange={e => setAnswers(prev => ({ ...prev, dietPreferenceOther: e.target.value }))}
                placeholder="Please specify…"
                className="w-full px-4 py-2 border rounded text-black"
            />
            )}
    
            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={!isValid}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};  

// Slide 8
export const UserDietRestrictions = ({ answers, setAnswers, slide, setSlide }) => {
    const toggle = (value) => {
        setAnswers(prev => {
            let next = prev.dietRestrictions || [];
    
            if (value === "None") {
                next = ["None"];
            } else {
                next = next.includes(value)
                    ? next.filter(v => v !== value)
                    : [...next.filter(v => v !== "None"), value];
            }
    
            return { ...prev, dietRestrictions: next };
        });
    };
  
    const OPTIONS = ["Lactose Intolerant","Gluten‑Free","Peanut Allergy","Egg Allergy","None","Others"];
  
    const hasOther = answers.dietRestrictions?.includes("Others");
    const hasSelection = answers.dietRestrictions?.length > 0;
    const needsOtherText = answers.dietRestrictions?.includes("Others") && !answers.otherDietRestriction?.trim();
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <h2 className="text-left text-xl font-semibold">Do you have any dietary restrictions?</h2>
            <p className="text-sm text-gray-700 mb-4 text-left">(Select any that apply)</p>
    
            <div className="space-y-2">
            {OPTIONS.map(opt => (
                <button
                key={opt}
                onClick={() => toggle(opt)}
                className={`w-full text-left px-4 py-3 border rounded transition-all duration-500 ${
                    answers.dietRestrictions?.includes(opt)
                    ? "border-purple bg-purple/20"
                    : "border-gray-300"
                }`}
                >
                {opt}
                </button>
            ))}
            </div>
    
            {hasOther && (
            <div>
                <label className="block mb-1 text-left font-semibold">Other?</label>
                <input
                type="text"
                value={answers.otherDietRestriction || ""}
                onChange={e => setAnswers(prev => ({ ...prev, otherDietRestriction: e.target.value }))}
                placeholder="e.g. Shellfish allergy, Halal, Kosher"
                className="w-full px-4 py-2 border rounded text-black"
                />
            </div>
            )}
    
            <div className="flex justify-between mt-8 w-full fixed bottom-0 left-0 p-4 bg-white">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => setSlide(s => s + 1)}
                    disabled={!hasSelection || needsOtherText}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};
  

// Slide 9
export const UserHealthConcerns = ({ answers, setAnswers, slide, setSlide }) => {
    const toggle = (value) => {
        setAnswers(prev => {
            let next = prev.healthConcerns || [];
    
            if (value === "None") {
            next = ["None"];
            } else {
            next = next.includes(value)
                ? next.filter(v => v !== value)
                : [...next.filter(v => v !== "None"), value];
            }
    
            return { ...prev, healthConcerns: next };
        });
    };
  
    const OPTIONS = ["Knee Pain","Back Injury","Diabetes","Hypertension","PCOS","None","Others"];
  
    const hasOther = answers.healthConcerns?.includes("Others");
    const hasSelection = answers.healthConcerns?.length > 0;
    const needsOtherText = hasOther && !answers.otherHealthConcern?.trim();
  
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
    
            <h2 className="text-left text-xl font-semibold">
                Any health‑related problems or injuries we should consider?
            </h2>
            <p className="text-sm text-gray-700 text-left mb-4">
                Select any that apply, or specify below
            </p>
    
            <div className="space-y-2">
            {OPTIONS.map(opt => (
                <button
                key={opt}
                onClick={() => toggle(opt)}
                className={`w-full text-left px-4 py-3 border rounded transition-all duration-500 ${
                    answers.healthConcerns?.includes(opt)
                    ? "border-purple bg-purple/20"
                    : "border-gray-300"
                }`}
                >
                {opt}
                </button>
            ))}
            </div>
    
            {hasOther && (
            <div>
                <label className="block mb-1 text-left font-semibold">Other concerns?</label>
                <input
                type="text"
                value={answers.otherHealthConcern || ""}
                onChange={e => setAnswers(prev => ({ ...prev, otherHealthConcern: e.target.value }))}
                placeholder="e.g. heart condition, old fracture…"
                className="w-full px-4 py-2 border rounded text-black"
                />
            </div>
            )}
    
            <div className="flex justify-between mt-8 w-full fixed bottom-0 left-0 p-4 bg-white">
            <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                Back
            </button>
            <button
                onClick={() => setSlide(s => s + 1)}
                disabled={!hasSelection || needsOtherText}
                className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
            >
                Next
            </button>
            </div>
        </div>
    );
};

// Slide 10
export const UserStructuredPrograms = ({ answers, setAnswers, slide, setSlide, handleSubmit, submitting, user, setView, setShowPaymentPlans, isPlanExpired }) => {
    const OPTIONS = [
        "I never tried a structured program before",
        "I tried in the past but gave up",
        "I did a short‑term program for an event",
        "I had a personal trainer",
        "I followed online workouts (YouTube, apps, etc.)",
        "I don’t prefer to answer this"
    ];

    const selectOption = (value) => {
        setAnswers(prev => ({ ...prev, programExperience: prev.programExperience === value ? "" : value }));
    };

    const hasAnswered = answers.programExperience || answers.programDetails;
    const hasMissingDetails = (!user?.email || user?.email === '' || !user?.phoneNumber || user?.phoneNumber === '')

    // console.log("Missing details ? ", hasMissingDetails)
    
    return (
        <div className="flex flex-col space-y-6 text-black min-w-full">
            <StepsSlider slide={slide} />
        
            <h2 className="text-left text-xl font-semibold">Have you tried any structured programs before?</h2>
            <p className="text-sm text-gray-700 text-left">How did it go? Select one that applies, and/or share more below.</p>
        
            <div className="space-y-2">
                {OPTIONS.map(opt => (
                <button
                    key={opt}
                    onClick={() => selectOption(opt)}
                    className={`w-full text-left px-4 py-3 border rounded transition-all duration-500 ${
                    answers.programExperience === opt
                        ? "border-purple bg-purple/20"
                        : "border-gray-300"
                    }`}
                >
                    {opt}
                </button>
                ))}
            </div>
        
            <label className="block mb-1 text-left text-sm">Tell us more about your experience or motivation</label>
            <textarea
                rows={3}
                value={answers.programDetails || ""}
                onChange={e => setAnswers(prev => ({ ...prev, programDetails: e.target.value }))}
                placeholder="Share details if you like…"
                className="w-full px-4 py-2 border rounded text-black"
            />
        
            <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                <button onClick={() => setSlide(s => s - 1)} className="px-6 py-3 border rounded-2xl">
                    Back
                </button>
                <button
                    onClick={() => {
                        if (hasMissingDetails) {
                            setView('emailAndDOB');
                        } else if (isPlanExpired) {
                            setShowPaymentPlans(true); handleSubmit();
                        } else {
                            setShowPaymentPlans(true);  handleSubmit();
                        }
                    }}
                    disabled={!hasAnswered || submitting}
                    className="px-6 py-3 bg-secondary text-black rounded-2xl disabled:opacity-50 transition-all duration-500"
                >
                    {(!user?.email || user?.email === '' || !user?.phoneNumber || user?.phoneNumber === '') ? 'Next' : submitting ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </div>
    );
};

export const AllMissingFields = ({user, displayName, setDisplayName, email, setEmail, phoneNumber, setPhoneNumber, dob, setDob, gender, setGender}) => {
    return (
        <div className="flex flex-col">
            {/* Name */}
            {(!user?.displayName || user?.displayName === '' || user?.displayName === 'User') &&
            <div className="flex flex-col gap-1 col-span-2">
                <label className="font-medium text-left">Name*</label>
                <input
                    type="displayName"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    placeholder="Enter your Name: eg. John Doe"
                    className="text-sm lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                />
            </div>}
            
            {/* Email */}
            {(!user?.email || user?.email === '') && 
            <div className="flex flex-col gap-1 col-span-2 mt-4">
                <label className="font-medium text-left">Email*</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email: eg. abc@gmail.com"
                    className="text-sm lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                />
            </div>}

            {/* Phone Number */} 
            {(!user?.phoneNumber || user?.phoneNumber === '') && 
            <div className="flex flex-col gap-1 col-span-2 mt-4">
                <label className="font-medium text-left">Phone Number*</label>
                <input
                    type="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your Phone Number: eg. 9988776655"
                    className="text-sm lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                />
            </div>}

            {/* Date of Birth */}
            {(!user?.dob || user?.dob === '') && 
            <div className="flex flex-col mt-4">
                <div className="text-left">Date of Birth*</div>
                <DatePicker
                    selected={dob ? new Date(dob.split("-").reverse().join("-")) : null}
                    onChange={(date) => setDob(format(date, "dd-MM-yyyy"))}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DOB: DD-MM-YYYY"
                    className="text-sm text-black lg:text-base py-2 px-4 border rounded-3xl outline-none w-full"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    renderCustomHeader={({ date, changeYear, changeMonth }) => {
                        const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
                        const months = [
                        "January","February","March","April","May","June",
                        "July","August","September","October","November","December"
                        ];

                        return (
                        <div className="flex justify-center space-x-2 p-2">
                            <select
                            value={date.getFullYear()}
                            onChange={({ target }) => changeYear(target.value)}
                            className="p-1 border rounded"
                            >
                            {years.map(year => <option key={year} value={year}>{year}</option>)}
                            </select>
                            <select
                            value={date.getMonth()}
                            onChange={({ target }) => changeMonth(Number(target.value))}
                            className="p-1 border rounded"
                            >
                            {months.map((m, i) => <option key={i} value={i}>{m}</option>)}
                            </select>
                        </div>
                        );
                    }}
                />
                </div>
            }

            {/* Gender */}
            {(!user?.gender || user?.gender === '' )&& 
            <div className="flex flex-col gap-1 col-span-2 mt-4">
                <div className="text-left">Gender*</div>
                <div className="flex gap-4">
                    {["Male", "Female", "Other"].map((opt) => (
                        <button
                            key={opt}
                            onClick={() => setGender(opt)}
                            className={`px-4 py-2 border rounded transition-all duration-500 ${
                                gender === opt ? "border-purple bg-purple/20" : ""
                            }`}
                        >
                        {opt}
                        </button>
                    ))}
                </div>
            </div>}
        </div>
    )
}