import { useContext, useEffect, useRef, useState } from "react";
// import TRLogoWhite from "../assets/Website_content/TR_Website_content-02.svg";
import TRLogoGreen from "../assets/Website_content/TR_Website_content-13.svg";
// import TRLogoGreen from "../assets/Website_content/BirthDayGreenTRLogo.svg";
import TRLogoPurple from "../assets/Website_content/TR_Website_content-14.svg";
// import TRLogoPurple from "../assets/Website_content/BirthDayPurpleTRLogo.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuthStatus from "../hooks/clientAuth";
import { analytics, auth } from "../config/firebase";
import toast from "react-hot-toast";
import { logEvent } from "firebase/analytics";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UserLoginModal } from "../helperFunctions/ModalHelpers/UserCreds";
// import { fetchCities } from "../helperFunctions/MarketplaceHelpers/MarketplxaceHelper";
import { ChevronDown, Search } from 'lucide-react';
import { TrexIcon, UserIcon } from '../assets/Website_content/svg'
import Hamburger from '../assets/Website_content/menu.svg'
import { algoliasearch } from 'algoliasearch';
import { AlgoliaAPIKey, AlgoliaAdminAPIKey } from "../config/SecretKeys"; 
import { capitalizeFirstLetter, checkIfUserHasMissingDetails } from "../helperFunctions/basicHelper";
import { checkIfUserHasPlanDetails } from "../helperFunctions/userDatabaseHelper";
import { GymContext } from "../hooks/FetchEverythingContext";
import LocationWithShadow from '../assets/Website_content/LocationWithShadow.svg'
import AIButtonBG from '../assets/Website_content/AI button bg.svg'
import Profile from '../assets/Website_content/ProfileNew.svg'
import { getDailyTips } from "../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { usePersonalizedPlan } from "../hooks/PersonalizedPlanContext";
import UserFitnessForm from "../helperFunctions/AIandMore/UserFitnessForm/UserFitnessForm";
import SideNavbar from './SideNavbar'
import NewGetUserMissingDetails from "../Modals/NewGetUsermissingDetails";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../config/firebase";
// import { LocationIcon } from "../assets/WelcomeScreenImage";
// import { themeColors } from "../theme";
// import Search from '../assets/Website_content/search.svg'

/**
 * The new navbar for the new design of marketplace
 * @returns {JSX.Element} Navbar for the new marketplace design
 */
function TrainRexNavbar({ selectedCity, onLocationChange, setShowUnlockGym }) {
    const {allCities, allGyms, allStudios, user          } = useContext(GymContext);
    const { isUserPresent      }                           = useAuthStatus();
    const profileMenuRef                                   = useRef(null);
    const dropdownRef                                      = useRef();
    const { setTriggerPersonalizedPlans }                  = usePersonalizedPlan();
    const [userCity             ,                        ] = useState(selectedCity? selectedCity : 'NCR');  // Initialize with citySlug or default
    const [menuOpen             ,             setMenuOpen] = useState(false);
    const [trexCoins            ,            setTrexCoins] = useState(0.00);
    const [showSignIn           ,           setShowSignIn] = useState(false);
    const [                     ,       setSearchDropDown] = useState(false); // For search suggestions dropdown
    const [profileMenuOpen      ,      setProfileMenuOpen] = useState(false);
    const [USR                  ,                  setUSR] = useState(window.devicePixelRatio);  //userScreenResolution
    const [                     , setCategoryDropdownOpen] = useState(false); // For Gym/Spa/Yoga dropdown
    // const [selectedCategory     ,                        ] = useState('Gym'); // Default category
    // State to manage dropdown visibility
    const [dropdownOpen         ,         setDropdownOpen] = useState(false);
    // State to store the selected location, default is Delhi or the provided prop
    const [                     ,     setSelectedLocation] = useState(userCity || 'NCR');
    // State to manage the search query entered by the user
    const [                     ,          setSearchQuery] = useState('');
    const [showProfile          ,          setShowProfile] = useState(true); // to switch between  trex and profile icon when user is logged in
    const [shownOnce            ,            setShownOnce] = useState(false);
    const [                     ,       setIsGlobalSearch] = useState(false);
    const [searchParams                                  ] = useSearchParams();
    const [globalSearchQuery    ,    setGlobalSearchQuery] = useState(searchParams.get('search') || '');
    //const [                     ,  setGlobalSearchResults] = useState([]); // Stores search results
    const [client               ,               setClient] = useState(null);
    const [index                ,                        ] = useState(`${capitalizeFirstLetter(selectedCity)}Gyms`);
    const [planDetailsExist     ,     setPlanDetailsExist] = useState(false);
    const [isLoading            ,            setIsLoading] = useState(false);
    const [searchOpen           ,           setSearchOpen] = useState(false);
    const [showFitnessPlanForm  ,  setShowFitnessPlanForm] = useState(user?.showFitnessPlan || false);
    const [showGetUserEmailModal,setShowGetUserEmailModal] = useState(false);

    // Temporary solution: if plan already present then open results page
    const [isPlanExpired        ,       setIsPlanExpired] = useState(false);

    // console.log("USER in Navbar : ", user)
    const isMobile = window.innerWidth <= 430;
    
    const timeInSecondsToDisplayLoginModal = 10000;

    const searchBarRef = useRef(null);
    const navigate     = useNavigate();
    const link         = useLocation();
    
    /**
     * Initialises the algolia client
     * whenever the city changes, the client re-initialises
     */
    useEffect(() => {
        const fetchClientFunction = async () => {
            try {
                // Initialize the Algolia client
                const fetchClient = algoliasearch(AlgoliaAPIKey, AlgoliaAdminAPIKey);
                setClient(fetchClient);
            } catch (error) {
                console.error("Error initializing Algolia client or fetching data:", error);
            }
        };

        fetchClientFunction();
    }, [selectedCity]);
    
    // Update globalSearchQuery when URL params change
    useEffect(() => {
        const searchQuery = searchParams.get('search');
        if (searchQuery) {
            setGlobalSearchQuery(searchQuery);
        }
        else {
            setGlobalSearchQuery('');
        }
    }, [searchParams]);

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    // const toggleCategoryDropdown = () => {
    //     setCategoryDropdownOpen(!categoryDropdownOpen);
    // };

    const handleLocationChange = (location) => {
        setSelectedLocation(location);
        onLocationChange(location);
    
        if (link.pathname.includes('gyms')) {
            // If on the Listing page, update the URL to /{city}/gyms
            navigate(`/${location}/gyms`);
        } else {
            // If on the Marketplace page, update the URL to /{city}
            navigate(`/${location}`);
        }
    
        setDropdownOpen(false);
    
        // Reload the page after navigation
        // window.location.reload();
    };

    // For city list drop down, close on sroll and outside click
    useEffect(() => {
        // Handle click outside
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setDropdownOpen]);
    

    // const handleCategoryChange = (category) => {
    //     setSelectedCategory(category);
    //     setCategoryDropdownOpen(false); // Close dropdown on selection
    // };

    // Toggling between trex coin and profile logo
    useEffect(() => {
        const interval = setInterval(() => {
        setShowProfile((prev) => !prev); // Toggle between true and false
        }, 3000); // 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchDropDown(false);
                setCategoryDropdownOpen(false); // Close category dropdown on click outside
            }
        };

        // const fetchCity = async () => {
        //     const cities = await fetchCities();
        //     setAllCities(cities);
        // };

        // fetchCity();
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const handleSearch = (searchQuery) => {
    //     setSearchDropDown(false);
    //     navigate(`/${selectedLocation}/result?search=${searchQuery}&category=${selectedCategory}`, {
    //         state: { city: selectedLocation, category: selectedCategory },
    //     });
    // };

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         handleSearch(searchQuery);
    //     }
    // };

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };

    const logMenuItemClick = (itemName) => {
        logEvent(analytics, `menuClick, ${itemName}`);
    };

   

    const handleLogout = async () => {
        try {
            await auth.signOut();
            localStorage.removeItem("user"); // Remove user from local storage
            toast.success("Logged out successfully");
            setProfileMenuOpen(false);
            window.location.reload();
        } catch (error) {
            toast.error("Error logging out. Please try again.");
            console.error("Logout Error:", error);
        }
    };

    // Handle click outside of the profile menu to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target)
            ) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Fetches user plan if available
    useEffect(() => {
        const fetchUserDetails = async (userId) => {
            if(user?.uid) {
                const planDetails = await checkIfUserHasPlanDetails(userId);
                if(planDetails) setPlanDetailsExist(true);
            }
        }
        fetchUserDetails(user?.uid);
        // this is the only component present everywhere on the website, 
        // so whenever user changes, update the local storage alos
    }, [user]);
    
    useEffect(() => {
        // Checks if the user is a guest and if the user is not logged in
        const isGuest = localStorage.getItem("usertype") === "guest";

        // If the user is not a guest and the user is not logged in, show the sign in modal and if it is not shown even once
        if (!isGuest && !user && !shownOnce) {
            // Show the sign in modal after 9 seconds
            const firstTimeout = setTimeout(() => {
                setShowSignIn(true);
                setShownOnce(true);
            }, timeInSecondsToDisplayLoginModal);

            return () => {
                // Clear the timeout and interval when the component unmounts
                clearTimeout(firstTimeout);
                // clearInterval(interval);
            };
        }

        // Function to fetch the trex of the user
        const fetchTrexCoins = async () => {
            setTrexCoins(user?.trexCredits ? user.trexCredits : 0);
        }
        fetchTrexCoins()
    }, [user, shownOnce]); // Only run this effect when the user changes

    // Update search state when URL params change
    useEffect(() => {
        const searchQuery = searchParams.get('search');
        if (searchQuery) {
            setGlobalSearchQuery(searchQuery);
            // Trigger search automatically when URL search param changes
            if (client && index && searchQuery.length > 0) {
                (async () => {
                    setIsLoading(true);
                    setIsGlobalSearch(true);
                    try {
                        const response = await client.search([{
                            indexName: `${capitalizeFirstLetter(selectedCity)}Gyms`,
                            query: searchQuery,
                        }]);
                        const searchResults = response.results[0].hits;
                        const noResults = searchResults?.length === 0;
                        
                        // Navigate with search results in state
                        navigate(`/${selectedCity}/gyms?${searchParams.toString()}`, { 
                            state: { 
                                searchResults: noResults ? allGyms : searchResults,
                                noResults,
                                fromSearch: true,
                                searchQuery
                            },
                            replace: true
                        });
                    } catch (error) {
                        console.error("Search error:", error);
                    } finally {
                        setIsLoading(false);
                    }
                })();
            }
        } else {
            setGlobalSearchQuery('');
            setIsGlobalSearch(false);
        }
    }, [searchParams, client, index, selectedCity, navigate, allGyms]);

    // Handle global search query submission
    const handleGlobalSearch = async () => {
        if (client && index && globalSearchQuery?.length > 0) {
            setIsLoading(true);
            try {
                const response = await client.search([{
                    indexName: `${capitalizeFirstLetter(selectedCity)}Gyms`,
                    query: globalSearchQuery,
                }]);
                const searchResults = response.results[0].hits;
                const noResults = searchResults?.length === 0;
                
                // Construct the URL with search parameters
                const params = new URLSearchParams();
                params.set('search', globalSearchQuery);
                
                // Keep existing params except 'page'
                searchParams.forEach((value, key) => {
                    if (key !== 'page' && key !== 'search') {
                        params.set(key, value);
                    }
                });

                setSearchQuery('');
                setSearchOpen(false);

                // Navigate with search results in state
                navigate(`/${selectedCity}/gyms?${params.toString()}`, { 
                    state: { 
                        searchResults: noResults ? allGyms : searchResults,
                        noResults,
                        fromSearch: true,
                        searchQuery: globalSearchQuery
                    },
                    replace: true
                });
            } catch (error) {
                console.error("Search error:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsGlobalSearch(false);
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete('search');

            setSearchQuery('');
            setSearchOpen(false);
            
            navigate(`/${selectedCity}/gyms?${newSearchParams.toString()}`, {
                state: { searchResults: allGyms },
                replace: true
            });
        }
    };

    // Handle keypress event
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleGlobalSearch();
        }
    }

    // // when navigating from the global search results, reset everthing
    // const handleGlobalResultClick = (result) => {
    //     setGlobalSearchResults([]);
    //     setIsGlobalSearch(false);
    //     setGlobalSearchQuery('');
    //     navigate(`/${selectedLocation}/gyms/${result.gymName}`)
    // }

    // if the search query length is 0 again then remove the results
    // useEffect(() => {
    //     if(globalSearchQuery?.length === 0) {
    //         setGlobalSearchResults([]);
    //     }
    // },[globalSearchQuery])

    // console.log("Gobal : ", globalSearchResults)

    useEffect(() => {
        // Expose the modal control function globally for WebView interaction
        window.setShowSignIn = setShowSignIn;
    }, []);
    const isNotHomepage = (window.location.href.includes('forum') || 
                           window.location.href.includes('gyms')  || 
                           window.location.href.includes('blogs') ||
                           window.location.href.includes('studio') ||
                           window.location.href.includes('profile') 
                        );
    // const isFitnessPlanPage    = window.location.href.includes('myFitnessPlan');
    const isPaymentSuccessPage = window.location.href.includes('PaymentSuccessfulPage')

    useEffect(() => {
        // Check if user is present and if any field is missing or empty.
        const doesUserHasIncompleteData = checkIfUserHasMissingDetails(isUserPresent, user);
        if ( isUserPresent && doesUserHasIncompleteData ) {
            const timer = setTimeout(() => {
                setShowGetUserEmailModal(doesUserHasIncompleteData);
            }, 2000); // 2 seconds delay 
        
            return () => clearTimeout(timer);
        }
    }, [isUserPresent, user]);

    //Checks if user already has a plan or is the plan expired
    useEffect(() => {
        const checkIfUserHasFitnessPlan = async () => {
            if (user && user.uid) {
                const planTypeRef = collection(db, "user", user.uid, "planType");
                const planTypeSnap = await getDocs(planTypeRef);
    
                let hasActivePlan = false;
                const now = new Date();

                if (planTypeSnap.empty) {
                    // Update your state based on the active plan
                    setIsPlanExpired(!hasActivePlan);
                    return null;
                }
            
                // For each day document (e.g., Monday, Tuesday, etc.)
                for (const planData of planTypeSnap.docs) {
                    if (planData.data()) {
                        const plan = planData.data();
    
                        if (plan.endDate >= now) {
                            // Update your state based on the active plan
                            setIsPlanExpired(hasActivePlan);
                            break; // stop checking once an active plan is found
                        }
                    }
                }
            }
        };

        checkIfUserHasFitnessPlan();
    }, [user])

    return (
        <>
            {/* <div className={`z-30 ${isNotHomepage ? 'bg-white shadow-md' : USR === 1 ? 'bg-gradient-to-t from-[#e8e6ec] to-[#ffffff] lg:from-[#eeedf0] lg:to-[#ffffff]' : (USR === 1.25 || USR === 1.5) ? 'bg-gradient-to-t from-[#f1ebf8] to-[#ffffff]' : 'bg-gradient-to-t from-[#f1ebf8] to-[#ffffff]'} max-h-fit fixed inset-0 flex flex-col gap-3  py-3 lg:py-[1vw] `}> */}
            <div className={`z-30 bg-[#ffffff] ${isNotHomepage ? 'shadow-md' : ''} max-h-fit fixed inset-0 flex flex-col gap-3  py-3 lg:py-4 `}>

                {/* First Row */}
                <div className={`flex justify-between items-center w-full px-4 lg:px-[1.5vw] `}>
                    <div onClick={() => navigate("/")} className="cursor-pointer">
                        <img src={isNotHomepage ? TRLogoGreen : TRLogoPurple} alt='TR' className={`h-[5.8vw] ${USR >= 1.5 ? 'lg:h-[2.2vw]' : USR <= 1 ? 'lg:h-[2vw]' : USR === 1.25 ? 'lg:h-[2vw]' : 'lg:h-[2.2vw]'}`} />
                    </div>

                    {/* All page links */}
                    <div className={`hidden lg:flex justify-between h-full items-end -mb-2 font-bold text-lg text-[#866FB0] ${USR >= 1.5 ? 'lg:text-[1vw] gap-[2vw]' : USR === 1 ? 'lg:text-[1vw] gap-[3vw]' : USR === 1.25 ? 'lg:text-[1vw] gap-[2vw]' :  'lg:text-[1vw] gap-[3vw]'}`}>
                        <div onClick={() => {logMenuItemClick("Gyms/Classes"); navigate(`/${selectedCity.toLowerCase()}/gyms`)}} className="flex flex-col items-end">
                            <div className="cursor-pointer">Gyms/Studios</div>

                            <div className={`${window.location.href.includes('gyms') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>

                        {process.env.NODE_ENV === 'development' && allStudios?.length !== 0 && 
                        <div onClick={() => {logMenuItemClick("Studios"); navigate(`/${selectedCity.toLowerCase()}/studios`)}}  className="flex flex-col items-end">
                            <div className="cursor-pointer">Studios</div>
                            <div className={`${window.location.href.includes('studios') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>}
                        
                        {user && planDetailsExist && 
                        <div onClick={(e) => {
                                e.stopPropagation(); // Prevent unintended triggers
                                logMenuItemClick("My Fitness Plan");
                                navigate('/myFitnessPlan')  
                            }} 
                            className="flex flex-col items-end">
                            <div className="cursor-pointer">My Fitness Plan</div>
                            <div className={`${window.location.href.includes('myFitnessPlan') ? '' : 'hidden' } -mr-2 -mb-2 h-2 w-14 rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>}

                        <div onClick={() => {logMenuItemClick("Train Rex App"); navigate('/AppDescription');}}  className="flex flex-col items-end">
                            <div className="cursor-pointer">Train Rex App</div>
                            <div className={`${window.location.href.includes('AppDescription') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>
                        <div onClick={() => {logMenuItemClick("Our Blog"); navigate('/blogs')}}  className="flex flex-col items-end">
                            <div className="cursor-pointer">Blogs</div>
                            <div className={`${window.location.href.includes('blogs') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>
                        <div onClick={() => {logMenuItemClick("Forums"); navigate('/forum')}}  className="flex flex-col items-end">
                            <div className="cursor-pointer">Forums</div>
                            <div className={`${window.location.href.includes('forum') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </div>
                    </div>

                    {/* Location and search bar visible only on larger screens */}
                    {/* <div className={`relative hidden lg:flex gap-4 items-center p-2 ${isNotHomepage ? 'md:bg-lightPurple' : 'md:bg-lightSecondary'} rounded-xl w-fit`}>
                        
                        <div onClick={toggleDropdown} className={`cursor-pointer pl-1 pr-4 py-2 rounded-full text-tertiary ${isNotHomepage ? 'bg-purple' : 'bg-secondary'} shadow-md whitespace-nowrap text-xs font-semibold h-full flex items-center ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? '' : USR === 1.25 ? 'lg:text-sm' : USR > 1.75 ? 'text-sm' : ''}`}>
                            <LocationIcon className="w-6 lg:w-8 h-4 lg:h-4 lg:mt-0" color={themeColors.lightAccent} />  
                            {selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1)}
                        </div>

                        <div className={`absolute top-10 -left-1 lg:top-14 lg:-left-20 rounded-md rounded-b-3xl w-1/3 lg:w-2/5 overflow-hidden ${dropdownOpen ? '' : 'pointer-events-none'}`}>
                            <div className={`${dropdownOpen ? 'translate-y-0' : '-translate-y-[101%]'} transition-all duration-300 bg-tertiary`}>
                                <ul className="flex flex-col h-fit">
                                    {allCities && allCities.map((location) => (
                                        <li
                                            key={location}
                                            className=" text-sm md:text-base px-2 md:px-4 py-1 md:py-2 hover:bg-secondary transition-all duration-300 cursor-pointer"
                                            onClick={() => handleLocationChange(location)}
                                        >
                                            {location.charAt(0).toUpperCase() + location.slice(1)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>



                        <div className={`relative flex items-center gap-2`}>
                            <div className={`flex gap-2 items-center whitespace-nowrap px-4 py-1 rounded-full ${isNotHomepage ? 'text-purple' : 'text-secondary'} font-semibold bg-tertiary text-left shadow-lg text-sm ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:text-sm w-[18.5vw]' : USR === 1.25 ? 'lg:text-sm w-[22.7vw]' : 'text-sm'}`}>
                                {isGlobalSearch ? 
                                    <input
                                        type="text"
                                        placeholder="Type your search query..."
                                        value={globalSearchQuery}
                                        onChange={(event) => setGlobalSearchQuery(event.target.value)}
                                        onKeyDown={handleKeyDown} // Trigger search on Enter
                                        className={`w-full outline-none focus:ring-0 bg-white text-primary`}
                                        autoFocus // Added autoFocus
                                    />
                                    :
                                    <div className={`flex gap-2 items-center whitespace-nowrap px-4 py-1 rounded-full bg-purple text-white font-semibold shadow-lg`}>
                                        <div className="cursor-pointer" onClick={() => setShowUnlockGym(true)}>
                                            UNLOCK YOUR PERFECT GYM MATCH
                                        </div>
                                    </div>
                                }
                                <div onClick={() => globalSearchQuery?.length > 0 ? handleGlobalSearch() : setIsGlobalSearch(!isGlobalSearch)} className="relative cursor-pointer">
                                    <Search className="text-purple"/>
                                    <div className="absolute"></div>
                                </div>

                                {isLoading && (
                                    <div className="fixed inset-0 bg-white h-[100vh] flex justify-center items-center flex-col z-[100]">
                                        <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                                        <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
                                    </div>
                                )}

                                
                            </div>

                            {searchDropDown && (
                                <SearchDropDown
                                    setSearchQuery={setSearchQuery}
                                    searchQuery={searchQuery}
                                    setSearchDropDown={setSearchDropDown}
                                    cityLocation={selectedLocation}
                                />
                            )}
                        </div>
                    </div> */}
                    
                    {/* Sign In/Up */}
                    {/* <div className="flex gap-2 text-sm mt-1">
                        {isUserPresent ? (
                            <div
                                className="relative flex items-center gap-4 cursor-pointer"
                                ref={profileMenuRef}
                            >
                                <div className="flex items-center gap-2">

                                    <button onClick={toggleProfileMenu} className={`rounded-full py-1 lg:py-0 pl-1 pr-4 ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:pl-2 lg:pr-4' : USR === 1.25 ? 'lg:text-xs' : USR > 1.75 ? 'text-xs' : ''} ${isNotHomepage ? 'border-[3px] border-secondary' : ''} lg:py-1 bg-tertiary flex justify-center items-center font-semibold gap-2 text-sm`}>
                                        <div className="relative w-6 h-6 lg:w-8 lg:h-8">
                                            <div className={`absolute inset-0 flex items-center justify-center p- border-2 bg-purple rounded-full text-white transform transition-all duration-1000 ${showProfile ? "scale-x-100 z-[1]" : "-scale-x-100 z-[0]"}`} >
                                                <UserIcon color="#ffffff" height="16px" width="14px" />
                                            </div>
                                            <div className={`absolute inset-0 flex items-center justify-center p- border-2 bg-purple rounded-full text-white transform transition-all duration-1000 ${showProfile ? "-scale-x-100 z-[0]" : "scale-x-100 z-[1]"}`} >
                                                <TrexIcon height='16px' color='#ffffff'/>
                                            </div>
                                        </div>
                                        <div className={`relative whitespace-nowrap text-purple text-[3vw] lg:text-base`}>
                                            <div className={`${showProfile ? 'opacity-100' : 'opacity-0'} -mb-5 lg:-mb-6 lg:h-6 transition-all duration-1000`}><span className="w-10 lg:w-14 overflow-hidden ">
                                                {user?.displayName?.split(" ")[0] || "User"}</span>
                                            </div>
                                            <div className={`${!showProfile? 'opacity-100' : 'opacity-0'} -mt-5 lg:-mt-4 lg:h-6 transition-all duration-1000`}><div className="w-10 lg:w-14 overflow-hidden">
                                                {trexCoins}</div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                {profileMenuOpen && (
                                    <div className="absolute right-[-50px] top-8 mt-2 w-48 border-secondary bg-white border-[1px] rounded-md hover:rounded-md shadow-lg z-50">
                                        <div 
                                            className="block hover:bg-secondary px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                            onClick={() => {setProfileMenuOpen(false); navigate('/profile', {state : {user}})}} // Close the menu on click
                                        >
                                            Profile
                                        </div>

                                        <button 
                                            onClick={() => {
                                                handleLogout();
                                                setProfileMenuOpen(false); // Close the menu on click
                                            }} 
                                            className="block hover:bg-red px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                        >
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button onClick={() => setShowSignIn(!showSignIn)} className={`rounded-full pl-2 pr-4 ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:pl-2 lg:pr-4' : USR === 1.25 ? 'lg:text-xs' : USR > 1.75 ? 'lg:text-xs' : ''} lg:py-2 bg-tertiary flex ${isNotHomepage ? 'border-2 lg:border-4 border-purple' : ''} justify-center items-center font-semibold gap-2 text-sm`}>
                                <div className='p-1 border-2 bg-purple rounded-full'>
                                    <UserIcon color='#ffffff' className={`w-[14px] h-[14px] lg:w-[0.8vw] lg:h-[0.8vw]`}/>
                                </div>
                                <div className={`whitespace-nowrap text-purple text-xs lg:text-[0.8vw] lg:text-base`}>SIGN UP</div>
                            </button>)}
                        <div onClick={toggleMenu} className={`rounded-full bg-tertiary font-bold text-purple ${isNotHomepage ? 'border-[3px] border-purple h-[9.5vw] w-[9.5vw]' : 'h-[7.5vw] w-[7.5vw]'} flex lg:hidden justify-center text-lg items-center`}>
                            <img src={Hamburger} alt='menu' className="h-[20px]" />
                        </div>
                    
                    </div> */}
                    <div className='flex gap-6'>
                        {/* Location and search bar visible only on larger screens */}
                        <div className="relative hidden lg:flex items-center bg-[#DFEFC4] shadow-lg rounded-full justify-between">
                            {/* Left Section - Location */}
                            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center justify-center gap-2 px-[1vw] py-[0.5vw] rounded-full w-[10vw] text-[#95B06B] text-[0.8vw] font-semibold relative outline-none">
                                <img src={LocationWithShadow} alt='location' className="h-[1.2vw]"/>
                                <div>{`${selectedCity.charAt(0).toUpperCase()}${selectedCity.slice(1)}`}</div>
                                <div className="ml-1"><ChevronDown className="text-secondary h-[1.5vw]"/></div>
                            </button>
                            {/* Dropdown menu for cities */}
                            <div className={`absolute top-12 left-8 rounded-md rounded-b-2xl overflow-hidden transition-all duration-500 ${dropdownOpen ? 'shadow-2xl' : 'pointer-events-none'}`}>
                                <div className={`${dropdownOpen ? 'translate-y-0' : '-translate-y-[101%]'} transition-all duration-300 bg-tertiary`}>
                                    <div className="flex flex-col">
                                    {allCities?.filter(city => city.toLowerCase() !== "testcity").map(location => (
                                        <div
                                        key={location}
                                        className="text-sm md:text-base px-2 md:px-8 py-1 hover:bg-secondary cursor-pointer border-t-2"
                                        onClick={() => handleLocationChange(location)}
                                        >
                                        {location.charAt(0).toUpperCase() + location.slice(1)}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            {/* Right Section - AI modal button */}
                            <button
                                onClick={
                                    isUserPresent ? () => {
                                        setTriggerPersonalizedPlans(true); 
                                        setShowUnlockGym(true);  
                                        // eslint-disable-next-line
                                        {isPlanExpired ? setShowFitnessPlanForm(true) : navigate('/myFitnessPlan');}} 
                                        : () => setShowSignIn(!showSignIn)}
                                className="flex items-center justify-center gap-2 px-[1vw] py-[0.5vw] w-[10vw] rounded-full text-secondary text-[0.8vw] font-semibold">
                                <img src={AIButtonBG} alt='bg' className={`absolute max-w-fit -right-2 -top-[10%] z-[1] ${USR === 1 ? 'h-[115%]' : USR === 1.25 ? 'h-[115%]' : USR === 1.5 ? 'h-[117%] scale-x-95' : 'h-[115%]' }`}/>
                            </button>
                        </div>
                        
                        {/* Search, Profile and Hamburger Icon */}
                        <div className={`relative flex gap-2  lg:mt-0 items-center`}>
                            <div onClick={() => setSearchOpen(!searchOpen)}>
                                <Search className={`cursor-pointer ${isNotHomepage ? 'text-secondary' : 'text-purple'} h-[6vw] w-[6vw] lg:h-[1.5vw] lg:w-[1.5vw]`} />
                            </div>
                            {searchOpen && 
                                <div className="absolute top-10 right-0 z-[5] py-2 px-4 w-[70vw] lg:w-[20vw] rounded-full shadow-md bg-white border-2 border-secondary flex items-center">
                                    <input
                                        type="text"
                                        placeholder="Type your search query..."
                                        value={globalSearchQuery}
                                        onChange={(event) => setGlobalSearchQuery(event.target.value)}
                                        onKeyDown={handleKeyDown} // Trigger search on Enter
                                        className={`w-full outline-none focus:ring-0 bg-white text-primary`}
                                        autoFocus // Added autoFocus
                                    />
                                    {/* Add the search icon here */}
                                    <div onClick={handleGlobalSearch}><Search className={`cursor-pointer ${isNotHomepage ? 'text-secondary' : 'text-purple'} h-[6vw] w-[6vw] lg:h-[1.5vw] lg:w-[1.5vw]`} /></div>
                                </div>
                            }
                            {/* Loader when searching */}
                            {isLoading && (
                                <div className="fixed inset-0 bg-white h-[100vh] flex justify-center items-center flex-col z-[100]">
                                    <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">
                                        Train Rex
                                    </div>
                                    <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">
                                        {getDailyTips()}
                                    </div>
                                </div>
                            )}
                            {isUserPresent ? (
                                <div
                                    className="relative flex items-center gap-4 cursor-pointer"
                                    ref={profileMenuRef}
                                >
                                    <div className="flex items-center gap-2">

                                        <button onClick={toggleProfileMenu} className={`rounded-full py-1 pl-1 pr-4 ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:pl-2 lg:pr-4' : USR === 1.25 ? 'lg:text-xs' : USR > 1.75 ? 'text-xs' : ''} ${isNotHomepage ? 'border-[3px] lg:border-[0.2vw] border-secondary' : 'bg-purple'} py-1 lg:py-[0.2vw] flex justify-center items-center font-semibold gap-2 lg:gap-[0.6vw] text-sm`}>
                                            <div className="relative w-6 h-6 lg:w-[1.6vw] lg:h-[1.6vw]">
                                                <div className={`absolute inset-0 flex items-center justify-center border-2 bg-purple rounded-full text-white transform transition-all duration-1000 ${showProfile ? "scale-x-100 z-[1]" : "-scale-x-100 z-[0]"}`} >
                                                    <UserIcon color="#ffffff" height={`${isMobile ? '4vw' : '1vw'}`} width={`${isMobile ? '4vw' : '1vw'}`} />
                                                </div>
                                                <div className={`absolute inset-0 flex items-center justify-center border-2 bg-purple rounded-full text-white transform transition-all duration-1000 ${showProfile ? "-scale-x-100 z-[0]" : "scale-x-100 z-[1]"}`} >
                                                    <TrexIcon height={`${isMobile ? '4vw' : '1vw'}`} color='#ffffff'/>
                                                </div>
                                            </div>
                                            <div className={`relative h-fit whitespace-nowrap ${isNotHomepage ? 'text-secondary' : 'text-white'}`}>
                                                <div className={`${showProfile ? 'opacity-100' : 'opacity-0'} absolute right-0 left-0 transition-all duration-1000`}>
                                                    <span className="overflow-hidden text-sm lg:text-[0.9vw] text-center">{user?.displayName?.split(" ")[0] || "Loading"}</span>
                                                </div>
                                                <div className={`${!showProfile? 'opacity-100' : 'opacity-0'} absolute right-0 left-0 transition-all duration-1000`}>
                                                    <div className="overflow-hidden text-sm lg:text-[0.9vw] text-center">{trexCoins}</div>
                                                </div>
                                                {/* placeholder text for the name and trex */}
                                                <div className="text-transparent text-sm lg:text-[0.9vw]">AAAAAA</div> 
                                            </div>
                                        </button>
                                    </div>
                                    {profileMenuOpen && (
                                        <div className="absolute right-[-50px] top-8 mt-2 w-48 border border-secondary bg-white rounded-md shadow-lg z-50">
                                            <button
                                            onClick={() => {
                                                setProfileMenuOpen(false);
                                                navigate('/profile', { state: { user } });
                                            }}
                                            className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-secondary hover:text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary"
                                            >
                                            Profile
                                            </button>
                                            <button
                                            onClick={() => {
                                                handleLogout();
                                                setProfileMenuOpen(false);
                                            }}
                                            className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-red-500 hover:text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500"
                                            >
                                            Logout
                                            </button>
                                        </div>                                      
                                    )}
                                </div>
                            ) : (
                                <div onClick={() => setShowSignIn(!showSignIn)} className={`cursor-pointer lg:p-1 lg:pr-3  lg:bg-[#CDBBDB] lg:rounded-full flex gap-1 items-center justify-center`}>
                                    <img src={Profile} alt='Profile' className={`h-7 w-7`} />
                                    <div className="hidden lg:block text-white">Sign Up</div>
                                </div>
                            )}
                            <img onClick={toggleMenu} src={Hamburger} alt='menu' className={`lg:hidden h-7 w-7`} />
                        </div>
                    </div>
                </div> 




                {/* Second Row, location and search bar, only for smaller screens*/}
                <div className={`${isPaymentSuccessPage ? 'hidden' : ''} relative lg:hidden flex items-center justify-between rounded-full bg-[#DFEFC4] shadow-lg w-[92vw] mx-4 mt-3 py-[0.8%]`}>
                    {/* Left Section - Location */}
                    <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center justify-center gap-2 w-[50vw] py-1 rounded-full text-[#95B06B] text-sm font-semibold relative outline-none">
                        <img src={LocationWithShadow} alt='location' className="h-6"/>
                        <div>{`${selectedCity.charAt(0).toUpperCase()}${selectedCity.slice(1)}`}</div>
                        <div className="ml-4"><ChevronDown className="text-[#95B06B]"/></div>
                    </button>
                    {/* Dropdown menu for cities */}
                    {/* Dropdown menu for cities */}
                    <div className={`absolute top-12 left-0 rounded-md rounded-b-2xl overflow-hidden transition-all duration-500 ${dropdownOpen ? 'shadow-2xl' : 'pointer-events-none'}`}>
                        <div className={`${dropdownOpen ? 'translate-y-0' : '-translate-y-[101%]'} transition-all duration-300 bg-tertiary`}>
                            <div className="flex flex-col pt-2 px-8">
                            {allCities?.filter(city => city.toLowerCase() !== "testcity").map(location => (
                                <div
                                key={location}
                                className="text-sm md:text-base px-2 md:px-20 py-1 hover:bg-secondary cursor-pointer border-b-2"
                                onClick={() => handleLocationChange(location)}
                                >
                                {location.charAt(0).toUpperCase() + location.slice(1)}
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Section - Logo and Text */}
                    <button
                        onClick={isUserPresent ? () => {
                            setTriggerPersonalizedPlans(true); 
                            setShowUnlockGym(true);  
                            // eslint-disable-next-line
                            {isPlanExpired ? setShowFitnessPlanForm(true) : navigate('/myFitnessPlan');}
                        } : () => setShowSignIn(!showSignIn)}
                        className="flex items-center justify-center gap-2 px-4 py-1 text-sm font-semibold w-[45vw] text-secondary">
                        {/* Magic Wand Icon */}
                        <img src={AIButtonBG} alt='bg' className="absolute -right-[0.5%] -top-1 z-[1] h-[115%]"/>
                    </button>
                </div>
            </div>



            {/* Menu for smaller screens */}
            <SideNavbar 
                menuOpen            ={menuOpen}
                toggleMenu          ={toggleMenu}
                setProfileMenuOpen  ={setProfileMenuOpen}
                selectedCity        ={selectedCity}
                user                ={user}
                isUserPresent       ={isUserPresent}
                showSignIn          ={showSignIn}
                setShowSignIn       ={setShowSignIn}
                planDetailsExist    ={planDetailsExist}
                allStudios          ={allStudios}
            />

            
            {/* When user clicks sign in/up, then this modal opens */}
            <UserLoginModal
                modalIsOpen={showSignIn}
                setModalIsOpen={setShowSignIn}
                myData={{ city: userCity }}
            />

            {/* Inside the modal, we show the multi-step form */}
            <UserFitnessForm
                isModalOpen={showFitnessPlanForm} // modal is present in that page so we will not open it from here
                onClose={() => setShowFitnessPlanForm(false)}   // so we can close the modal from inside the form if needed     
            />

            <NewGetUserMissingDetails showGetUserEmailModal={showGetUserEmailModal} setShowGetUserEmailModal={setShowGetUserEmailModal}/>
        </>
    )
}

export default TrainRexNavbar;