import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa'; // Import star icons from react-icons

/**
 * StarRating component displays a star rating and the number of reviews.
 * The stars can be either full, half, or empty based on the rating.
 * @param {number} rating           - The rating value (e.g., 4.5).
 * @param {number} NoOfReview       - The number of reviews for the gym or product.
 * @param {string} color            - Optional color argument that changes the color of the stars and text. 
 *                                    If 'secondary', the stars and text will use the secondary color, otherwise, the default is tertiary.
 * @param {Boolean} showNoOfReviews - To show The No Of Reviews or not
 */
const StarRating = ({ rating, NoOfReview = 0, color = 'tertiary', showNoOfReviews = true, isComparingPage=false }) => {
    // Calculate full stars and check if there's a half star
    const fullStars = Math.floor(rating); // Number of full stars
    const hasHalfStar = rating % 1 !== 0; // Check if there is a half star
    // const starColorClass = color === 'secondary' ? 'text-black' : 'text-black'; // Determine color class
    const starColorClass = `text-${color}`;

    const starSize = showNoOfReviews ? '4xl' : 'xl';

    return (
        <div className={`flex flex-col ${isComparingPage ? 'items-center' : 'items-end'}`}>
            {/* Star rating display */}
            <div className="flex space-x-1 text-lg lg:text-3xl font-bold">
                {[...Array(5)].map((_, starIndex) => {
                    // Determine star type (full, half, or empty)
                    if (starIndex < fullStars) {
                        // Full star
                        return (
                            <FaStar key={starIndex} className={`${starColorClass} text-sm lg:text-${starSize}`} />
                        );
                    } else if (hasHalfStar && starIndex === fullStars) {
                        // Half star
                        return (
                            <FaStarHalfAlt key={starIndex} className={`${starColorClass} text-sm lg:text-${starSize}`} />
                        );
                    } else {
                        // Empty star
                        return (
                            <FaRegStar key={starIndex} className={`${starColorClass} opacity-40 text-sm lg:text-${starSize}`} />
                        );
                    }
                })}
            </div>

            {/* Number of reviews */}
            {showNoOfReviews ? (
                <p className={`text-sm lg:text-xl font-bold ${starColorClass}`}>
                    {NoOfReview} Reviews
                </p>
            ) : null}
            
        </div>
    );
};

export default StarRating;
