import { addDoc, collection, Timestamp } from "@firebase/firestore"
import { dbGym } from "../../config/firebase"

/**
 * Submit the Feedback Form in the Database
 * @param   {Object} formData   -   The filled up form data
 * @returns {Boolean}           -  true, if save successful; false, otherwise
 */
export const submitForm = async (formData) => {
    try {
        const userRef = collection(dbGym, 'IMTFeedback');
        await addDoc(userRef, {
            ...formData,
            timeStamp : Timestamp.now()
        });
        return true;
    } catch (error) {
        return false;
    }
}