import React, { useContext, useState } from 'react';
import { checkIfUserExists } from '../helperFunctions/userDatabaseHelper';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { emailChecker, phoneNumberChecker, userNameChecker } from '../helperFunctions/ConstraintsChecker';
import Alert from './customComponents/Alert';
import { GymContext } from '../hooks/FetchEverythingContext';

/**
 * GiftModal component for handling gift membership entries.
 * @param {Boolean} isOpen - Boolean to control the modal visibility.
 * @param {Function} onClose - Function to close the modal.
 * @param {Object} receiptInfo - Information regarding receipt details.
 * @param {Number} totalAmount - Total payment amount.
 * @param {Function} handleMembershipButton - Function to handle membership submission.
 */
const GiftModal = ({ isOpen, setShowGiftModal, receiptInfo, totalAmount, handleMembershipButton, gymData }) => {
    const { user } = useContext(GymContext);

    // State to manage gift recipient details
    const [giftRecipient, setGiftRecipient] = useState({
        email: '',
        exists: false,
        displayName: '',
        phoneNumber: '',
        age: '',
        gender: '',
        countryCode: '+91'
    });
    const [collapsed, setCollapsed] = useState(true);
    const [errors,] = useState({});

    // Check if user exists and update recipient data accordingly
    const doesUserExist = async (email) => {
        if (emailChecker(email)) {
            const res = await checkIfUserExists(email);
            setGiftRecipient(prev => ({
                ...prev,
                exists: !!res,
                ...res // Spread existing user data if found
            }));
            setCollapsed(res);
        } else {
            Alert('error', 'Please enter a valid email');
        }
    };

    // Handle input changes for recipient fields
    const handleInputChange = (e, field) => {
        const value = e.target.value;

        if (field === 'gender' && gymData?.isWomensOnly && (value === 'Male' || value === 'male')) {
            Alert('error', 'You cannot gift membership to male members for this gym')
            setGiftRecipient(prev => ({ ...prev, [field]: '' }));
            return;
        }

        setGiftRecipient(prev => ({ ...prev, [field]: value }));
    };

    // Check if the gift recipient email is same as the user's email
    const isEmailSameAsUser = () => {
        return giftRecipient.email === user?.email;
    };

    // Payment handler which includes the gift recipient's details
    const handlePayment = async () => {
        // Check if recipient is male and the gym is for women only
        if (gymData?.isWomensOnly && (giftRecipient.gender === 'Male' || giftRecipient.gender === 'male')) {
            Alert('error', "You cannot gift membership to male members for this gym.");
            return;
        }

        const validRecipient = {
            ...giftRecipient,
            phoneNumber: giftRecipient.phoneNumber.length === 10 ? `${giftRecipient.countryCode}${giftRecipient.phoneNumber}` : giftRecipient.phoneNumber
        };

        if (!userNameChecker(validRecipient.displayName)) {
            Alert("error", "Please enter a valid name")
            return;
        }

        if (!phoneNumberChecker(validRecipient.phoneNumber)) {
            Alert("error", "Please enter a correct phone number")
            return;
        }

        if (validRecipient.age < 1) {
            Alert("error", "Please enter recipient's age correctly")
            return;
        }

        if (validRecipient.gender === '') {
            Alert("error", "Please select recipient's gender")
            return;
        }

        await handleMembershipButton({
            months: receiptInfo.months,
            amount: Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp: receiptInfo.mrp,
            validMembers: [validRecipient],
            referralGiver: receiptInfo.referralGiver,
            trex: receiptInfo.trex,
            startDate: receiptInfo.startDate,
            payAtTheCounter: receiptInfo.payAtTheCounter,
            isThisGift : true
        });
    };

    const clearFields = () => {
        setGiftRecipient({
            email: '',
            exists: false,
            displayName: '',
            phoneNumber: '',
            age: '',
            gender: '',
            countryCode: '+91',
        });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 -top-8 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-secondary rounded-lg shadow-lg p-6 w-[90vw] md:w-[88vw] lg:w-[42vw] max-h-[80vh] overflow-y-scroll md:overflow-y-auto">
                <div className='flex justify-between items-center mb-4'>
                    <div>
                        <h2 className="text-xl font-semibold">
                            Gift Membership
                            <span className="relative group cursor-pointer ml-2">
                                <span className="text-primary text-xl font-bold px-2 bg-tertiary rounded-full">?</span>
                                <div className="absolute z-[100] -top-5 md:-top-6 left-7 md:left-8 mb-1 hidden group-hover:flex items-center justify-center w-32 md:w-40 p-1 md:p-2 text-xs lg:text-sm text-tertiary bg-primary rounded-lg shadow-md">
                                    Please enter the details of the person you want to gift the membership to.
                                </div>
                            </span>
                        </h2>
                    </div>
                    <button className='text-gray-600 hover:text-primary transition-all duration-300' onClick={() => setShowGiftModal(false)}>✖</button>
                </div>
                
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 md:gap-y-14 mt-14 items-start'>
                    <div className='flex flex-col gap-1 md:gap-4 items-center'>
                        <AccountCircleIcon fontSize="large" style={{ color: 'var(--tertiary)' }} className="scale-[150%] md:scale-[250%] mb-4 md:mb-7" />
                        <div className='text-primary font-semibold text-base md:text-xl'>{user?.displayName}</div>
                        <div className='text-primary font-semibold text-base md:text-xl'>{user?.email}</div>
                    </div>
                    
                    <div className="w-full flex flex-col items-center">
                        <div className='flex gap-4 relative'>
                            <AccountCircleIcon fontSize="large" style={{ color: 'var(--tertiary)' }} className="scale-[150%] md:scale-[250%] mb-4 md:mb-7" />
                            {(giftRecipient.exists ? giftRecipient.phoneNumber.length === 13 : giftRecipient.phoneNumber.length === 10) && 
                             userNameChecker(giftRecipient.displayName) && 
                             (giftRecipient.gender !== '') && 
                             (giftRecipient.age > 1) &&
                                <div onClick={clearFields} className='text-primary font-semibold absolute -top-4 -right-24 cursor-pointer'>Clear</div>
                            }
                        </div>

                        <input
                            className="p-2 rounded-lg border mb-2 w-2/3"
                            placeholder="Enter Email"
                            onChange={(e) => handleInputChange(e, 'email')}
                            value={giftRecipient.email}
                        />
                        {errors.email && <div className="text-red-500">{errors.email}</div>}

                        {!collapsed && !giftRecipient.exists && (
                            <div className="flex flex-col gap-2 bg-secondary p-3 rounded-md w-full">
                                <input
                                    type="text"
                                    className="p-2 border rounded-md"
                                    placeholder="Name"
                                    onChange={(e) => handleInputChange(e, 'displayName')}
                                    value={giftRecipient.displayName}
                                />
                                <div className="flex gap-2">
                                    <select
                                        className="p-2 border rounded-md w-1/4"
                                        value={giftRecipient.countryCode}
                                        onChange={(e) => handleInputChange(e, 'countryCode')}
                                    >
                                        <option value="+1">+1</option>
                                        <option value="+91">+91</option>
                                        <option value="+44">+44</option>
                                        <option value="+61">+61</option>
                                    </select>
                                    <input
                                        type="text"
                                        className="p-2 border rounded-md w-3/4"
                                        placeholder="Phone Number"
                                        onChange={(e) => handleInputChange(e, 'phoneNumber')}
                                        value={giftRecipient.phoneNumber}
                                    />
                                </div>
                                <input
                                    type="text"
                                    className="p-2 border rounded-md"
                                    placeholder="Age"
                                    onChange={(e) => handleInputChange(e, 'age')}
                                    value={giftRecipient.age}
                                />
                                <select
                                    className="p-2 border rounded-md"
                                    onChange={(e) => handleInputChange(e, 'gender')}
                                    value={giftRecipient.gender}
                                >
                                    <option value="" disabled>
                                        Select Gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                        )}

                        {emailChecker(giftRecipient.email) && 
                         (giftRecipient.exists ? giftRecipient.phoneNumber.length === 13 : giftRecipient.phoneNumber.length === 10) && 
                         userNameChecker(giftRecipient.displayName) && 
                         (giftRecipient.gender !== '') && 
                         (giftRecipient.age > 1) &&
                            <div className="success-checkmark scale-50">
                                <div className="check-icon">
                                    <span className="icon-line line-tip"></span>
                                    <span className="icon-line line-long"></span>
                                    <div className="icon-circle"></div>
                                    <div className="icon-fix"></div>
                                </div>
                            </div>
                        }

                        {!giftRecipient.exists && 
                         !(emailChecker(giftRecipient.email) && 
                           (giftRecipient.phoneNumber.length === 10) && 
                           userNameChecker(giftRecipient.displayName) && 
                           (giftRecipient.gender !== '') && 
                           (giftRecipient.age > 1)) && (
                            <button
                                onClick={() => doesUserExist(giftRecipient.email)}
                                disabled={isEmailSameAsUser()}
                                className={`bg-primary text-tertiary w-2/3 py-2 rounded-md mb-3 hover:font-semibold hover:scale-105 ${isEmailSameAsUser() ? 'opacity-50 cursor-not-allowed' : ''} transition-all duration-500`}
                            >
                                Add Recipient
                            </button>
                        )}
                    </div>
                </div>

                <button
                    onClick={handlePayment}
                    disabled={!giftRecipient.displayName}
                    className={`mt-5 px-5 py-2 rounded-lg hover:scale-110 hover:font-bold hover:shadow-2xl hover:shadow-primary transition-all duration-500 ${!giftRecipient.displayName ? 'bg-gray-300 cursor-not-allowed text-gray-500' : 'bg-tertiary text-primary'}`}
                >
                    Pay Now
                </button>
            </div>
        </div>
    );
};

export default GiftModal;