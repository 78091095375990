import React, { useState, useEffect } from "react";

// Loader for fitness plan
const Loader = ({ label }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + 1;
        if (nextProgress >= 95) {
          clearInterval(interval);
          return 95;
        }
        return nextProgress;
      });
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      <div
        style={{
          backgroundColor: "#eee",
          height: "10px",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: "#007bff",
            height: "10px",
            borderRadius: "5px",
            transition: "width 0.2s ease",
          }}
        />
      </div>
      <p style={{ textAlign: "center", marginTop: "8px" }}>
        {label} ({progress}%)
      </p>
    </div>
  );
};

export default Loader;
