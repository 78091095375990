import { BigButton } from "../MarketplaceHelpers/ButtonsLibrary";
import AddReviewModal from "./AddReviewModal";

/**
 * ReviewActions Component
 * 
 * This component renders the actions available for reviews, including options to
 * view more reviews, view fewer reviews, or add a new review. It manages the 
 * display of buttons based on the number of visible reviews versus the total number
 * of reviews.
 * 
 * @param {Function} setShowAddReviewModal - Function to control the visibility of the "Add Review" modal
 * @param {number} visibleReviews       - The number of currently visible reviews
 * @param {Function} showMoreReviews    - Function to show more reviews
 * @param {Function} showLessReviews    - Function to show fewer reviews
 * @param {number} NoOfReview           - The total number of reviews for the gym
 */
export const ReviewActions = ({
    setSignInModal,
    showAddReviewModal, 
    setShowAddReviewModal,  
    isUserPresent,
    currGymData,
    user, 
    isUserRecent,
    visibleReviews,
    showMoreReviews,                               
    showLessReviews,                               
    NoOfReview
}) => (
    <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
        {visibleReviews < NoOfReview ? (
            <BigButton
                label       = "View More"
                bg          = "bg-secondary"
                textColor   = "text-tertiary"
                width       = "lg:w-1/7"
                onClick     = {showMoreReviews}
            />
        ) : (
            <BigButton
                label       = "View Less"
                bg          = "bg-secondary"
                textColor   = "text-tertiary"
                width       = "lg:w-1/7"
                onClick     = {showLessReviews}
            />
        )}
        <BigButton
            label       = "Add Review"
            bg          = "bg-secondary"
            textColor   = "text-tertiary"
            width       = "w-1/7 flex justify-center"
            onClick     = {() => isUserPresent ? setShowAddReviewModal(true) : setSignInModal(true)} // Open modal on button click
        />
    </div>
);

/**
 * NoReviews Component
 * 
 * This component displays a message when no reviews are available for the gym and
 * provides the user with an option to add the first review. It also conditionally renders
 * the AddReviewModal if the user is present and wants to add a review.
 * 
 * @param {boolean} showAddReviewModal  - State indicating whether the "Add Review" modal is visible
 * @param {Function} setShowAddReviewModal - Function to control the visibility of the "Add Review" modal
 * @param {boolean} isUserPresent       - Boolean indicating if the user is logged in
 * @param {object} currGymData          - Object containing the current gym's data (city and gymName)
 * @param {object} user                 - Object containing the logged-in user's information (displayName)
 * @param {boolean} isUserRecent        - Boolean indicating if the user has recently visited the gym
 */
export const NoReviews = ({
    showAddReviewModal,
    setShowAddReviewModal,
    isUserPresent,
    currGymData,
    user,
    isUserRecent,
    isGymReview,
}) => (
    <div className="relative h-32">
        <div className="absolute inset-0 bg-tertiary bg-opacity-100 items-center justify-center text-secondary text-lg lg:text-2xl font-bold flex flex-col space-y-4 rounded-xl">
            <p>Be the first one to review</p>
            <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
                <BigButton
                    label       = "Add Review"
                    bg          = "bg-secondary"
                    textColor   = "text-tertiary"
                    width       = "w-1/7 flex justify-center"
                    onClick     = {() => setShowAddReviewModal(true)} // Open modal on button click
                />
            </div>
        </div>

        {/* Conditionally render the AddReviewModal if the user is logged in and wants to add a review */}
        {showAddReviewModal && isUserPresent ? (
            <AddReviewModal 
                currGymData            =   {currGymData}
                setShowAddReviewModal  =   {setShowAddReviewModal} 
                gymLocation            =   {currGymData.city} 
                gymName                =   {isGymReview ? currGymData.gymName : currGymData.studioName} 
                isRecentGymUser        =   {isUserRecent} 
                user                   =   {user}
            />
        ) : null}
    </div>
);

/**
 * getRatingCounts Function
 * 
 * This function calculates and returns the count of reviews for each star rating (1 to 5 stars).
 * It loops through the list of reviews and increments the count of each rating.
 * 
 * @param {Array} reviews - Array of review objects, each containing a "rating" property
 * @returns {Object}      - An object with keys for each star rating (1 to 5) and values for the number of reviews for each rating
 */
export const getRatingCounts = (reviews) => {
    const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    reviews.forEach(review => {
        const rating = review.rating;
        if (rating >= 1 && rating <= 5) {
            counts[rating]++; // Increment the count for the respective rating
        }
    });
    return counts;
};
