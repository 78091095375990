import Icon from '../../assets/Icon.webp'
import { MapPin, BadgeIndianRupee, Star } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getClassWithLowestMonthlyPrice, getFacilityNamesForSmallerScreens, getTopFacilities } from '../../helperFunctions/basicHelper';
import { getSellingPrice } from '../../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers';
import { Arrow } from '../../assets/Website_content/svg';
import StarRating from "../../components/StarRating";

const StudioCard = (props) => {

    // const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMobile] = useState(window.innerWidth <= 440)

    const lowestMonthlyPriceClass = getClassWithLowestMonthlyPrice(props?.prices);
    const priceToDisplay = getSellingPrice(
        props?.prices?.single?.[lowestMonthlyPriceClass]?.exclusiveMonthlyPrice,
        props?.isExclusive,
        props?.studioName,
        props?.city
    );
    
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/${props.city}/studios/${props.studioName}`, {
            state: { props },
        });
    };

    // Capitalize first letter
    const formatName = (name) =>
        name.replace(/([a-z])([A-Z])/g, "$1 $2");

    // Get the unique or best facilities
    const topFacilities = getTopFacilities(props.facilities);

    // returns the facility for mobile or larger screens differently
    const finalFacilityRender = (index) => {
        return !isMobile ? topFacilities[index] && formatName(topFacilities[index]) : topFacilities[index] && getFacilityNamesForSmallerScreens(formatName(topFacilities[index]))
    }

    // return bool : true if the number of characters of 1 and 2 facility can allow the rendering of the 3rd facility due to screen width restriction
    const canRenderThis = (facility1, facility2, facility3) => {
        // Combine the lengths of all facility strings
        const totalLength = (facility1?.length || 0) + (facility2?.length || 0) + (facility3?.length || 0);
    
        // Check if the total length is less than 30
        return totalLength < 35;
    };

    return (
        <>
            <div onClick={() => handleNavigation()} className={`relative rounded-2xl rounded-br-3xl rounded-bl-none p-3 lg:p-4   flex flex-col lg:flex-row items-start w-full mt-4 border-t-2 border-x-2  border-gray-300 lg:h-[13vw] ${props?.USR <= 1 ? 'lg:h-[13vw]' : props?.USR === 1.25 ? 'lg:h-[14vw]' : props?.USR === 1.5 ? 'lg:h-[17vw]' : 'lg:h-[15vw]' } cursor-pointer`}>
                <img src={props?.Portfolio?.[0] ? props.Portfolio[0] : Icon} alt='gym' className={`rounded-2xl w-full lg:min-w-[32%] lg:max-w-[32%] h-52 lg:h-[125%] object-cover`} />
                <div className={`flex flex-col w-full lg:pl-8 lg:h-full lg:justify-between`}>
                    <div className={`font-bold text-primary text-3xl mt-2 text-left ${props.USR >= 1.5 ? 'lg:text-2xl' : props.USR === 1 ? 'lg:text-3xl' : props.USR === 1.25 ? 'lg:text-3xl' : 'lg:text-3xl'}`}>
                        {props?.studioDisplayName}
                    </div>
                    <div className={` -ml-2 flex items-start text-secondary text-sm font-semibold mt-1 text-left ${props.USR >= 1.5 ? 'lg:w-[90%] lg:text-base' : 'lg:w-[85%] lg:text-lg'} lg:mt-0`}>
                        <MapPin className="h-5 min-w-7" /> 
                        {isMobile ? 
                            <>{props?.locality}, {props?.city.charAt(0).toUpperCase() + props?.city.slice(1)}</>
                            :
                            <>
                                <div className="flex flex-col -mt-1">
                                    <div>{props?.address + ','}</div>
                                    <div>{props?.locality},</div>
                                    <div>{props?.city.charAt(0).toUpperCase() + props?.city.slice(1)}</div>
                                </div>
                            </>
                        }
                    </div>
                    {/* Facilities */}
                    <div className={`flex flex-wrap gap-2 mt-2 w-full lg:mt-`}>
                        {topFacilities[0] && 
                        <div className={`text-xs lg:text-sm font-semibold px-3 py-1 w-fit lg:w-[25%] overflow-hidden rounded-full border-2 whitespace-nowrap text-primary border-secondary bg-lightSecondary`}>
                            {finalFacilityRender(0)}
                        </div>}
                        {topFacilities[1] && 
                        <div className={`text-xs lg:text-sm font-semibold px-3 py-1 w-fit lg:w-[25%] overflow-hidden rounded-full border-2 whitespace-nowrap text-purple border-purple bg-lightPurple`}>
                            {finalFacilityRender(1)}
                        </div>}
                        {topFacilities[2] && (!isMobile || canRenderThis(finalFacilityRender(0), finalFacilityRender(1), finalFacilityRender(2))) &&
                        <div className={`text-xs lg:text-sm font-semibold px-3 py-1 w-fit lg:w-[25%] overflow-hidden rounded-full border-2 whitespace-nowrap text-primary border-secondary bg-lightSecondary`}>
                            {finalFacilityRender(2)}
                        </div>}
                        {!isMobile && topFacilities[3] && 
                        <div className={`text-xs lg:text-sm font-semibold px-3 py-1 w-fit lg:w-[20%] overflow-hidden rounded-full border-2 whitespace-nowrap text-purple border-purple bg-lightPurple`}>
                            {finalFacilityRender(3)}
                        </div>}
                    </div>
                </div>

                {/* Rating */}
                <div className={`absolute top-6 right-4 lg:right-8 flex flex-col items-end`}>
                    <div className={`px-3 py-[2px] text-sm lg:text-base lg:p-0 bg-white bg-opacity-75 rounded-full flex items-end gap-1`}>
                        {/* Star for small screens */}
                        <div className="lg:hidden flex items-center">
                            <Star height="11px" color="var(--primary)" />
                            <span>{props?.NoOfReview ? props?.rating.toFixed(2) : 'No Rating Available'}</span>
                        </div>

                        {/* Star Rating for large screens */}
                        <div className="hidden lg:block">
                            <StarRating 
                                rating={props?.rating}
                                NoOfReview={props?.NoOfReview}
                                color={'purple'}
                                showNoOfReviews={false}
                            />
                        </div>

                        <div className="hidden sm:black">
                            {props?.rating === 0 ? 'No Rating Available' : props?.rating.toFixed(2)}
                        </div>
                    </div>
                    {props?.isOfferAvailable && 
                        <div className="mt-2 px-2 py-1 bg-purple bg-opacity-60 lg:bg-opacity-100 text-logoGray lg:text-white text-sm text-bold lg:text-base rounded-full">
                            <div className='flex items-center gap-2'>
                                <BadgeIndianRupee height='16px' />
                                Special Offer!
                            </div>
                        </div>
                    }     
                </div>
            </div>

            <div className={`w-full flex justify-between -mt-[3.5px]`}>
                <div className={`w-[40%] lg:w-full lg:pl-[9%] rounded-bl-2xl rounded-br-3xl p-3 pt-0 border-l-2 border-b-2 border-gray-300 flex gap-2 cursor-pointer`}>
                    {isMobile  && topFacilities[3] && <div className={`text-xs lg:text-sm font-semibold px-4 py-1 w-[99.99%] lg:w-[20%] h-fit overflow-hidden rounded-full border-2 whitespace-nowrap text-purple border-purple bg-lightPurple`}>
                        {finalFacilityRender(3)}
                    </div>}
                    {!isMobile && topFacilities[4] && <div className={`${props.USR >= 1.5 ? 'ml-[24vw]' : props.USR === 1 ? 'ml-[22vw]' : props.USR === 1.25 ? 'ml-[22vw]' : 'ml-[22vw]'} text-sm font-semibold px-4 py-1 w-[99.99%] lg:w-[25%] h-fit overflow-hidden rounded-full border-2 whitespace-nowrap text-primary border-secondary bg-lightSecondary`}>
                        {topFacilities[4] && formatName(topFacilities[4])}
                    </div>}
                    {!isMobile && topFacilities[6] && <div className={`text-sm font-semibold px-4 py-1 w-[99.99%] lg:w-[25%] h-fit overflow-hidden rounded-full border-2 whitespace-nowrap text-purple border-purple bg-lightPurple`}>
                        {topFacilities[5] && formatName(topFacilities[5])}
                    </div>}
                </div>

                {/* rounded-part */}
                <div className={`w-full lg:w-1/3`}>
                    <div className={`z-10 rounded-tl-[60px] -ml-[2px] pt-7 mr-4 border-t-2 border-l-2 border-gray-300 h-[82%] flex gap-2 justify-end items-end`}>
                        <button onClick={() => handleNavigation()} className={`px-4 py-2 bg-secondary text-lg font-semibold text-tertiary rounded-full ml-6 w-fit lg:w-[80%] overflow-hidden`}>
                            ₹{priceToDisplay !== null ? priceToDisplay : 'xxxx'}/month
                        </button>
                        <button onClick={() => handleNavigation()} className={`h-10 w-10 text-tertiary bg-purple rounded-full flex items-center justify-center text-2xl font-bold rotate-45`}>
                            <Arrow color='#ffffff' height='20px' />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudioCard;