import { Timestamp } from "@firebase/firestore";

/**
 * Checks if a given Firebase timestamp is on or after the most recent Monday.
 *
 * This function first calculates the date of the most recent Monday 
 * (including today if today is Monday). It then compares this Monday's date 
 * with the date represented by the given Firebase timestamp and returns true 
 * if the timestamp is on or after the recent Monday, otherwise false.
 * 
 * @param {Timestamp} firebaseTimestamp -   The Firebase timestamp to be checked.
 * @returns {Boolean}                   -   Returns true if the date from the timestamp is on or after the most recent Monday, false otherwise.
 */
export const hasUpdatedThisWeek = (firebaseTimestamp) => {
    const ts = new Timestamp(firebaseTimestamp.seconds, firebaseTimestamp.nanoseconds);
    const inputDate = ts.toDate();

    const today = new Date();
    const day = today.getDay();
    const diffToMonday = day === 0 ? 6 : day - 1;

    const recentMonday = new Date(today);
    recentMonday.setDate(today.getDate() - diffToMonday);
    recentMonday.setHours(0, 0, 0, 0);

    return inputDate >= recentMonday;
}

/**
 * Checks if a given Firebase timestamp is on or after today.
 *
 * This function converts the input Firebase timestamp to a JavaScript Date,
 * resets the current date to midnight, and compares both to determine if the
 * input timestamp is on or after today's date.
 * 
 * @param {Timestamp} firebaseTimestamp -   The Firebase timestamp to be checked.
 * @returns {Boolean}                   -   Returns true if the date from the timestamp is on or after today, false otherwise.
 */
export const hasUpdatedToday = (firebaseTimestamp) => {
    const ts = new Timestamp(firebaseTimestamp.seconds, firebaseTimestamp.nanoseconds);
    const inputDate = ts.toDate();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return inputDate >= today;
}
