import React, { createContext, useState, useEffect } from "react";
import { fetchAllGyms } from "../helperFunctions/GymInfoHelpers/FetchGymsList";
import { fetchAllStudios } from "../helperFunctions/StudioClassesHelper/StudioClassesHelper";
import { fetchCities } from "../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { requestLocationAccess } from "../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { sortBySmartCriteria } from "../helperFunctions/AIandMore/GymScoringAlgorithm";
import useAuthStatus from "./clientAuth";
import { checkIfUserHasPlan } from "../helperFunctions/userDatabaseHelper";

export const GymContext = createContext();

export const GymProvider = ({ children }) => {
    const [allGyms, setAllGyms] = useState(() => {
        const storedGyms = localStorage.getItem("allGyms");
        return storedGyms ? JSON.parse(storedGyms) : [];
    });

    const [allStudios, setAllStudios] = useState(() => {
        const storedStudios = localStorage.getItem("allStudios");
        return storedStudios ? JSON.parse(storedStudios) : [];
    });

    const isMobile                                              = window.innerWidth <= 430;
    const {user}                                                = useAuthStatus();
    const [userFromHook           ,            setUserFromHook] = useState(user);
    const [USR                    ,                     setUSR] = useState(window.devicePixelRatio);
    const [userLocation           ,            setUserLocation] = useState();
    const [allCities              ,               setAllCities] = useState();
    const [loading                ,                 setLoading] = useState(false);
    const [doesUserHasAFitnessPlan, setDoesUserHasAFitnessPlan] = useState(false);
    const [entireFitnessPlanData  ,   setEntireFitnessPlanData] = useState(null);
    const [selectedCity ,   setSelectedCity] = useState(() => {
        return (
            (window.location.href.includes("ghaziabad") && "Ghaziabad") ||
            (window.location.href.includes("ncr") && "NCR") ||
            (window.location.href.includes("noida") && "Noida") ||
            localStorage.getItem("selectedCity") ||
            "Delhi"
        );
    });


    useEffect(() => {
        setUserFromHook(user);
        const checkFitnessPlanExistance = async () => {
            if(user?.uid) {
                const hasPlan = await checkIfUserHasPlan(user?.uid)
                setEntireFitnessPlanData(hasPlan) // this will either be entire plan document or null
                setDoesUserHasAFitnessPlan(hasPlan !== null ? true : false);
            }
        }
        checkFitnessPlanExistance();
    }, [user])

    // Handle Resize
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // console.log("Device Pixel Ratio: ", devicePixelRatio);
        // console.log("Screen Width (Physical Pixels): ", window.screen.width);
        // console.log("Viewport Width (DIPs): ", window.innerWidth);
        // console.log("${USR >= 1.5 ? '' : USR === 1 ? '' : USR === 1.25 ? '' : ''}")
        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetches user location
    useEffect(() => {
        const getLocation = async () => {
            const location = await requestLocationAccess();
            setUserLocation(location)
        }
        getLocation();
    }, [])

    useEffect(() => {
        const fetchGymsAndClasses = async () => {
            // if (!userLocation) return; // Prevent execution until userLocation is set

            try {
                // LocalStorage Data
                const localGyms    = JSON.parse(localStorage.getItem("allGyms")) || [];
                const localStudios = JSON.parse(localStorage.getItem("allStudios")) || [];
                const localCities  = JSON.parse(localStorage.getItem("allCities")) || [];
                // console.log("Selected City: ", selectedCity)
                // console.log("City on gyms : ", localGyms?.[0]?.city)
                // console.log(localCities, localGyms, localStudios);

                // If no local gyms, set loading for the initial fetch
                if (localGyms?.length === 0) setLoading(true);

                const gyms      = await fetchAllGyms(selectedCity.toLowerCase());
                const classes   = await fetchAllStudios(selectedCity.toLowerCase());
                
                // Save to state and local storage
                if(localGyms !== gyms || localStudios !== classes) {
                    const sortedGyms = sortBySmartCriteria(gyms, {budget : [0, 100000]}, userLocation);
                    // if(userLocation == null) setAllGyms(gyms);
                    setAllGyms(sortedGyms);
                    setAllStudios(classes);
                    // if city is ncr then store only 100 gyms in the local storage
                    if (selectedCity.toLowerCase() === 'ncr') localStorage.setItem("allGyms", JSON.stringify(gyms.slice(0, 100)));
                    else localStorage.setItem("allGyms", JSON.stringify(gyms));
                    
                    localStorage.setItem("allStudios", JSON.stringify(classes));
                    setLoading(false);
                }
                
                // Fetching all the cities
                if (localCities?.length === 0 ) {
                    const cities = await fetchCities();
                    setAllCities(cities);
                    localStorage.setItem("allCities" , JSON.stringify(cities))
                } else {
                    setAllCities(localCities)
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching gyms and classes:", error);
                setLoading(false);
            }
        };
    
        // Fetch only if local storage data is unavailable or outdated
        fetchGymsAndClasses();
    }, [selectedCity, userLocation]);    

    // console.log("ALALAAL : ", allGyms)
    // console.log("ASASASA : ", allStudios)

    useEffect(() => {
        // Save selectedCity to local storage when it changes
        localStorage.setItem("selectedCity", selectedCity);
    }, [selectedCity]);

    return (
        <GymContext.Provider value={{ 
            allGyms, 
            allStudios, 
            allCities, 
            loading, 
            setLoading, 
            selectedCity, 
            setSelectedCity, 
            userLocation, 
            setUserLocation, 
            USR, 
            isMobile, 
            user : userFromHook,     // IMPORTNAT : Be sure to update the user using the next line's setUser whenever you uodate user in the database
            setUser :setUserFromHook,// Both locally and in localStorage *******
            doesUserHasAFitnessPlan, 
            entireFitnessPlanData}}>
            {children}
        </GymContext.Provider>
    );
};
