import Basic from './Website_content/Basic.svg'
import Pro from './Website_content/Pro.svg'
import Premium from './Website_content/premium.svg'
import TickForPlanPrimary from './Website_content/TickForPlanPrimary.svg'
import TickForPlanPurple from './Website_content/TickForPlanPurple.svg'
import TickForPlanWhite from './Website_content/TickForPlanWhite.svg'

/**
 * A structure for basic, pro and premium plans' cards handling texts, background and colors for these cards
 * @param {object} basePlan     - contains mrp and selling price of the plan
 * @param {object} mediumPlan   - contains mrp and selling price of the plan
 * @param {object} premiumPlan  - contains mrp and selling price of the plan
 * @returns 
 */
export const plans = (basePlan, mediumPlan, premiumPlan) => [
    {
        id               : 0,
        name             : "Basic",
        description      : "Ideal for Beginners",
        image            : Basic,
        mrp              : basePlan?.mrp,
        price            : basePlan?.sellingPrice,
        planData         : basePlan,
        benefits         : [
            'Gym Membership',
            '1hr Fitness Consultation',
            '1% Cashback upto ₹250',
        ],
        tick           : TickForPlanPurple,
        textColor      : 'purple',
        infoText       : "The Basic plan is great for newcomers looking to start their fitness journey with essential perks."
    },
    {
        id             : 1,
        name           : "Pro",
        description    : "Ideal for Beginners",
        image          : Pro,
        mrp            : mediumPlan?.mrp,
        price          : mediumPlan?.sellingPrice,
        planData       : mediumPlan,
        benefits       : [
            'Basic Plan Perks',
            'Personalized Diet Plans',
            'Personalized Exercise Plans',
            'Cancellation',
            'Freezing',
        ],
        tick           : TickForPlanPrimary,
        textColor      : 'primary',
        infoText       : "The Pro plan offers additional diet and workout customization, perfect for those serious about fitness.",
    },
    {
        id             : 2,
        name           : "Premium",
        description    : "Ideal for Beginners",
        image          : Premium,
        mrp            : premiumPlan?.mrp,
        price          : premiumPlan?.sellingPrice,
        planData       : premiumPlan,
        benefits       : [
            'Medium Plan Perks',
            'Wider Cancellation',
            'Wider Freezing',
            'Access to Exercise Videos',
            'Flexible Memberships',
            'Train Rex Goodies',
        ], 
        tick           : TickForPlanWhite,
        textColor      : 'white',
        infoText       : "The Premium plan provides the most flexibility, exclusive access to exercise videos, and special goodies.",
    }
];