import { cloud_url } from "../../../../config/cloudURL";

// export const fetchWeeklyPlan = async (data) => {
//     try {
//         const response = await fetch(`${cloud_url}/generate_weekly_plan`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify(data),
//         });
//         if (!response.ok) {
//             throw new Error('Network error or server returned an error.');
//         }
//         return await response.json();
//     } catch (error) {
//         console.error("Fetch error:", error);
//         return { status: 'error', message: 'Failed to call the server.' };
//     }
// };

export const sendFitnessPlanFormDataToBackend = async (userId, formData) => {
    try {
        console.log("USER ID :", userId)
        console.log("FORMDATA : ", formData)
        
        const response = await fetch(cloud_url + '/generate_weekly_plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId, 
                formData
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to save fitness plan to the backend.', response.message);
            // return false;
        }

        return true;
    } catch (error) {
        console.error("Error sending fitness plan data:", error);
        return { status: 'error', message: 'Failed to send data to backend.' };
    }
};
