/**
 * Stores the keys of APIs
 */

// MapBox Key
export const mapBoxKey = 'pk.eyJ1IjoibWFwdHJhaW5yZXgiLCJhIjoiY20zaDYwb3U5MDZibzJsc2E3Z3NrbG1pbiJ9.InsmhQWm8rZ5bvsIrE-U6w';

// TrainRex Email
export const trainrexEmail = 'info@trainrex.org';

export const AlgoliaAPIKey = 'MGVBNW1DT0';

export const AlgoliaAdminAPIKey = '6ea8ae1cd3ce1bad5e73ee0e1462007d';

export const razorpayLiveKey = 'rzp_live_PjuEiFCgxSVySz';

export const razorpayTestKet = 'rzp_test_xWMw9b7Eg3ntwK'