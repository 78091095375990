import "react-datepicker/dist/react-datepicker.css";
import { useContext, useEffect, useState } from "react";
import Alert from "../components/customComponents/Alert";
import { updateUserDetails } from "../helperFunctions/userDatabaseHelper";
import { GymContext } from "../hooks/FetchEverythingContext";
import { getCountryCode } from "../config/countryCodes";
import { emailChecker, phoneNumberChecker } from "../helperFunctions/ConstraintsChecker";
import { AllMissingFields } from "../helperFunctions/AIandMore/UserFitnessForm/UIComponents/DietAndFitnessPlanQuestions";


/**
 * 
 * @param {} displayName 
 * @param {} user 
 * @param {} setView 
 * @param {} setShowPaymentPlans 
 * @param {} handleSubmit 
 * @param {} answers 
 * @returns 
 */
const GetUserEmail = ({ user, setView, setShowPaymentPlans, handleSubmit, answers}) => {
    const {setUser                       } = useContext(GymContext)
    const [displayName  ,  setDisplayName] = useState(user?.displayName === 'User' ? '' : user?.displayName || '');
    const [email        ,        setEmail] = useState((user?.email       && user?.email       !== '') ? user.email       : '');
    const [phoneNumber  ,  setPhoneNumber] = useState((user?.phoneNumber && user?.phoneNumber !== '') ? user.phoneNumber : '');
    const [dob          ,          setDob] = useState((answers?.dob      && answers?.dob      !== '') ? answers.dob      : '');
    const [gender       ,       setGender] = useState((answers?.gender   && answers?.gender   !== '') ? answers.gender   : '');
    const [submitting   ,   setSubmitting] = useState(false);
    
    // Keep these for future use
    // console.log(1)
    // console.log('1: user       :',user       ) 
    // console.log('1: email      :',email      )
    // console.log('1: displaNamw :',displayName)
    // console.log('1: Phone      :',phoneNumber)
    // console.log('1: DOB        :',dob        )
    // console.log('1: gender     :',gender     )
    // console.log('1: submitting :',submitting )
    // console.log(1)

    
    useEffect(() => {
        if (user) {
        setDisplayName(user.displayName === 'User' ? '' : user.displayName || '');
        setEmail(user.email || '');
        setPhoneNumber(user.phoneNumber || '');
        }
        if (answers) {
        setDob(answers.dob || '');
        setGender(answers.gender || '');
        }
    }, [user, answers]);


    const handleEmailSubmit = async () => {
        if(email === '') {
            Alert('error', 'Please enter the missing details');
            return ;
        }

        const phoneNumberWithCountryCode = phoneNumber?.length === 13 ? phoneNumber : `${getCountryCode()}${phoneNumber}`

        if(!phoneNumberChecker(phoneNumberWithCountryCode)) {
            Alert('error', 'Please enter a 10 digit phone number')
            return;
        }

        if(!emailChecker(email)) {
            Alert('error', 'Please enter a valid email address')
            return;
        }
        
        setSubmitting(true);

        setShowPaymentPlans(true);

        const newUser = {
            ...user,
            email,
            displayName,
            phoneNumber : phoneNumberWithCountryCode,
            dob,
            gender,
        };
        
        await updateUserDetails(user.uid, {email, displayName, phoneNumber : phoneNumberWithCountryCode, dob, gender})
        localStorage.setItem("user", JSON.stringify(newUser));
        setUser(newUser)
        setSubmitting(false);
    }

    return (
        <div className={`fixed inset-0 h-[100vh] w-[100vw] flex`}>
            <div className={`h-fit w-fit flex flex-col  rounded-3xl p-4`}>
                <div className="flex flex-col">
                    {/* Why we need email? */}
                    <div className="text-center mb-8 mt-[8vh]">
                        <div className="text-2xl font-semibold">Could you please provide the missing details?</div>
                        <div className="">This will really help us personalize your journey on Train Rex and tailor your experience.</div>
                    </div>
                    <div className="w-full flex flex-col">

                        <AllMissingFields 
                            user           ={user}
                            displayName    ={displayName}
                            setDisplayName ={setDisplayName}
                            email          ={email}
                            setEmail       ={setEmail}
                            phoneNumber    ={phoneNumber}
                            setPhoneNumber ={setPhoneNumber}
                            dob            ={dob}
                            setDob         ={setDob}
                            gender         ={gender}
                            setGender      ={setGender}
                        />

                        {/* Submit Button */}
                        <div className="flex justify-between mt-8 w-[100vw] p-4 fixed bottom-0 left-0">
                            <button type='button' onClick={() => setView('userDetails')} className="bg-secondary text-white px-6 py-2 rounded-2xl">
                                Back
                            </button>
                            <button type='button' disabled={submitting} onClick={() => {handleEmailSubmit(); handleSubmit()}} className="bg-secondary text-white px-6 py-2 rounded-2xl">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>            
    );
}

export default GetUserEmail;