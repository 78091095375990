import { ExternalLink, MapPin } from "lucide-react";
import StarRating from "../StarRating";
import { TRVerified } from "../../assets/Website_content/svg";
import { getNavigationLink } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { convertTo12HourFormat } from "../../helperFunctions/basicHelper";
import { formatTimeSlots } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/TimingHelper";
import { processTimings } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/TimingHelper";
import { useState } from "react";
import CancellationPolicyModal from "../Modals/Policies/CancellationPolicyModal";
import FreezingPolicyModal from "../Modals/Policies/FreezingPolicyModal";

const GymInfoSection = ({ currGymData }) => {
    const timingsList = processTimings(currGymData?.timeSlots);
    const [selectedRating        ,         setSelectedRating] = useState("Overall");
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const [showFreezingPolicy    ,    setShowFreezingPolicy ] = useState(false);

    const ratingOptions = ["Overall", "Ambience", "Staff", "Equipments", "Management", "Vibe"];

    return (
        <>
        <div className="w-full flex justify-between mt-4">
            <div className="flex flex-col text-left">
            <div className="flex items-center gap-1 lg:gap-2">
                <div className="text-2xl font-bold text-primary">
                {currGymData?.gymDisplayName || currGymData.name}
                </div>
                {currGymData?.trainRexVerified && <TRVerified height="28px" />}
                <div className="flex">
                {currGymData?.isWomensOnly && <TRVerified height="28px" />}
                </div>
            </div>

            <div className="text-left text-primary font-semibold mt-2 flex gap-2 items-center">
                <MapPin className="scale-90 lg:scale-100" />
                <div>
                    {currGymData?.address}, {currGymData?.locality}
                </div>
            </div>

            {(currGymData?.mapLink || currGymData.latitude) && (
                <a
                    href={
                        currGymData?.mapLink ||
                        getNavigationLink(
                        parseFloat(currGymData.latitude),
                        parseFloat(currGymData.longitude)
                        )
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative flex items-center bg-transparent rounded-xl text-complementPurple font-bold hover:text-complementPurple transition-all duration-300"
                >
                    <ExternalLink className="w-5 h-5 md:w-6 md:h-6" />
                    <span className="pt-1">Get Directions</span>
                </a>
            )}
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
                {/* Dropdown to select rating type */}
                <select
                    value={selectedRating}
                    onChange={(e) => setSelectedRating(e.target.value)}
                    className="border rounded-full px-3 py-2 outline-none h-fit"
                >
                    {ratingOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                {/* Star Rating Component */}
                <StarRating
                    rating={selectedRating === 'Overall' ? currGymData?.allRatings?.OverallRating : currGymData?.allRatings?.[selectedRating]}
                    NoOfReview={currGymData?.NoOfReview}
                    color="secondary"
                />
            </div>
        </div>

        <div className="p-4 md:p-6 border border-gray-500 rounded-2xl mt-4">
            <div className="font-semibold text-lg md:text-xl text-left">
                Business Hours
            </div>

            <div className="flex flex-col gap-3 mt-4">
                {timingsList.length > 0 ? (
                    timingsList.map(({ day, slots }, index) => (
                    <div
                        key={index}
                        className="text-sm md:text-lg font-bold text-primary flex justify-between items-center"
                    >
                        <div>{day}</div>
                        <div>{formatTimeSlots(slots)}</div>
                    </div>
                    ))
                ) : (
                    // Fallback for single opening and closing time
                    <div className="text-sm md:text-lg font-bold text-primary flex justify-between items-center">
                        <div>Monday to Saturday</div>
                        <div className="sm:ml-4">
                            {currGymData?.openingTime && currGymData?.closingTime 
                            ? `${convertTo12HourFormat(currGymData.openingTime)} to ${convertTo12HourFormat(currGymData.closingTime)}`
                            : "Closed"}
                        </div>
                    </div>
                )}
            </div>
        </div>

        <div className={`rounded-2xl shadow-2xl p-3 flex justify-around mt-8 w-full`} style={{boxShadow : '0px 0px 6px gray'}}>
            <div onClick={() => setShowCancellationPolicy(true)} className="flex flex-col min-w-[50%] lg:min-w-[35%] cursor-pointer rounded-2xl hover:scale-110 hover:bg-lightPurple transition-all duration-500">
                <div className={`lg:text-2xl font-semibold text-purple`}>Cancellation</div>
                <div className={`text-[10px] lg:text-base`}>Cancellation option available.</div>
            </div>
            <div className="rounded-full min-h-full min-w-1 bg-purple border-[1px]"></div>
                <div onClick={() => setShowFreezingPolicy(true)} className="flex flex-col min-w-[50%] lg:min-w-[35%] cursor-pointer rounded-2xl hover:scale-110 hover:bg-lightPurple transition-all duration-500">
                <div className={`lg:text-2xl font-semibold text-purple`}>Freezing</div>
                <div className={`text-[10px] lg:text-base text-gray-700`}>Pause your membership.</div>
            </div>
        </div>

        {showCancellationPolicy && <CancellationPolicyModal showCancellationPolicy={showCancellationPolicy} setShowCancellationPolicy={setShowCancellationPolicy} />}
        {showFreezingPolicy     && <FreezingPolicyModal     showFreezingPolicy={showFreezingPolicy} setShowFreezingPolicy={setShowFreezingPolicy} />}
        </>
    );
};

export default GymInfoSection;