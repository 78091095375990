/**
 *    PlanProvider Component & PlanContext
 *
 *    Provides context for managing and sharing user fitness plan data across the app.
 *    It fetches and caches the weekly and daily fitness plans, along with user details from Firebase.
 *    Automatically sets the current day as the selected day and loads the plan accordingly.
 *
 *    @context PlanContext
 *    @returns {JSX.Element} Context provider wrapping child components with access to plan-related state.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { checkIfUserHasPlanForDay, getUserDetails } from "../userDatabaseHelper";
import useAuthStatus from "../../hooks/clientAuth";

export const PlanContext = createContext();

// Context for fetching data only once from firebase for weekly fitness plan
export const PlanProvider = ({ children }) => {
    // Cache for already fetched day plans; keys will be day names.
    const [weeklyPlan, setWeeklyPlan] = useState({});
    const [dailyPlan, setDailyPlan] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [loadingDaily, setLoadingDaily] = useState(true);
    const [selectedDay, setSelectedDay] = useState("");
    const {user}        = useAuthStatus();

    // Fetch the plan data for the selected day once the user is available
    useEffect(() => {
        const fetchPlanForSelectedDay = async () => {
            if (!selectedDay) return;
            if (!userDetails) return;

            setLoadingDaily(true);
            const userId = user?.uid;

            // If plan for the selected day is already cached, use it
            if (weeklyPlan[selectedDay]) {
                setDailyPlan(weeklyPlan[selectedDay]);
                setLoadingDaily(false);
            } else {
                try {
                    const plan = await checkIfUserHasPlanForDay(userId, selectedDay);
                    // Cache and set the fetched plan
                    setWeeklyPlan((prev) => ({ ...prev, [selectedDay]: plan }));
                    setDailyPlan(plan);
                } catch (error) {
                    console.error(`Error fetching plan for ${selectedDay}:`, error);
                } finally {
                    setLoadingDaily(false);
                }
            }
        };

        fetchPlanForSelectedDay();
    }, [selectedDay, userDetails]);

    // Set the default day based on the current day
    useEffect(() => {
        const dayNames = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const currentDay = dayNames[new Date().getDay()];
        setSelectedDay(currentDay);
    }, []);

    // Listen for auth state changes and fetch user details once a user is available
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async () => {
            if (user) {
                try {
                    const userData = await getUserDetails(user.uid);
                    setUserDetails(userData);
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            } else {
                setUserDetails(null);
            }
        });

        return () => unsubscribe();
    }, [user]);

    return (
        <PlanContext.Provider
            value={{
                weeklyPlan,
                setWeeklyPlan,
                dailyPlan,
                userDetails,
                loadingDaily,
                selectedDay,
                setSelectedDay,
            }}
        >
            {children}
        </PlanContext.Provider>
    );
};
