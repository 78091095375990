import { collection, getDoc, doc, getDocs, updateDoc } from "@firebase/firestore";
import { db, dbGym } from "../../config/firebase";
import axios from "axios";
import { gymList, cityList } from "../../components/Databases";
import { getGymProps } from "../GymInfoHelpers/getGymProps";

// Fetch Top Blogs
export const fetchAllBlogs = async () => {
    const blogs = [];
    const blogSnapshot = await getDocs(collection(db, 'blogs'));
    for (const blog of blogSnapshot.docs) {
        const blogData = blog.data();
        // console.log("BLOG DATA", blogData.image)
        blogs.push({
            gymDisplayName: blogData.title,
            content: blogData.content,
            image: blogData.image,
            locality: blogData.author,
            time: blogData.timestamp
        });
    }
    return blogs;
};

// Get User City
export async function getLocation() {
    // it will return the following attributes:
    // country, countryCode, regionName, city, lat, lon, zip and timezone
    const res = await axios.get("http://ip-api.com/json");
    return res.data.city
}

const isMonday = () => {
    const today = new Date();
    return today.getDay() === 1; // 1 means Monday in JavaScript Date
};

/**
 * Helps in fetching all the gym data
 * Needed for Product Portfolio Page + Buying the membership
 * @param {String} city
 * @param {String} gymName
 */
export const fetchGymData = async (city, gymName) => {
    try {
        // Create a reference to the gym document
        const gymRef = doc(db, cityList, city, gymList, gymName);
        const gymDoc = await getDoc(gymRef);

        if (gymDoc.exists()) {
            const gymData = gymDoc.data();

            // Increment the view count if not in development mode
            if (process.env.NODE_ENV !== 'development') {
                let newViews = { total: 1, thisWeek: 1 };

                // Check if views already exist
                if (gymData.views) {
                    // If today is Monday, reset `thisWeek` count
                    const thisWeekViews = isMonday() ? 1 : gymData.views.thisWeek + 1;

                    // Update the view counts
                    newViews = {
                        total: gymData.views.total + 1,  // Increment total views
                        thisWeek: thisWeekViews  // Increment or reset `thisWeek` views
                    };
                }

                // Update the document with new view counts
                await updateDoc(gymRef, {
                    views: newViews
                });
            }

            // Return the gym data
            return (await getGymProps(gymData));
        } else {
            throw new Error('404: No Gym Found');
        }
    } catch (error) {
        // console.error("Error fetching gym data:", error);
        throw error;
    }
};

export const fetchCities = async () => {
    const cities = [];
    const citySnapshot = await getDocs(collection(db, cityList));

    // Iterate through city snapshot and filter out unwanted cities
    for (const city of citySnapshot.docs) {
        if (city.id !== 'testcity' || city.id !== 'edinburgh') {
            cities.push(city.id);
        }
    }

    // Add "All Cities" as the first option
    cities.unshift('NCR');

    return cities;
};


/**
 * Updates all gyms in the "Cities/delhi/Gyms" collection with exclusive price fields.
 * Skips the gym with the gymName 'gypsy'.
 */
export const updateExclusivePricesForGyms = async () => {
    try {
        // Get all gyms from the "Cities/delhi/Gyms" collection
        const gymsCollectionRef = collection(dbGym, "Gyms");
        const gymsSnapshot = await getDocs(gymsCollectionRef);

        // Iterate through each gym document
        gymsSnapshot.forEach(async (gymDoc) => {
            const gymData = gymDoc.data();
            const { dailyPrice, monthlyPrice, quarterlyPrice, halfYearlyPrice, yearlyPrice } = gymData;

            // Create new exclusive price fields with numeric values
            const exclusivePrices = {
                exclusiveDailyPrice: Number(dailyPrice) || 0,
                exclusiveMonthlyPrice: Number(monthlyPrice) || 0,
                exclusiveQuarterlyPrice: Number(quarterlyPrice) || 0,
                exclusiveHalfYearlyPrice: Number(halfYearlyPrice) || 0,
                exclusiveYearlyPrice: Number(yearlyPrice) || 0,
            };

            // Update the gym document with the new fields
            const gymRef = doc(dbGym, "Gyms", gymDoc.id);
            await updateDoc(gymRef, exclusivePrices);

        });
    } catch (error) {
        // console.error("Error updating exclusive prices for gyms:", error);
    }
};

export const getSearchedGyms = async (city, searchQuery) => {
    try {
        const gymRef = collection(db, cityList, city, gymList);

        // List of gyms to exclude based on codeName
        const excludedGyms = [
            '21fitness',
            'notlisted',
            'arnavgym',
        ];

        const gymSnapshot = await getDocs(gymRef);
        const gymData = gymSnapshot.docs.map((doc) => doc.data());

        // Filter out gyms that have a codeName in the excluded list
        const filteredGyms = gymData.filter(gym => !excludedGyms.includes(gym.codeName));

        // console.log("Filtered Gym Data", filteredGyms);
        return filteredGyms;
    } catch (error) {
        // console.error("Error fetching gyms:", error);
        return null; 
    }
};

export const getDailyTips = () => {
    const tips = [
        "Trying to build muscle? Don't take cold showers after workouts.",
        "Protein intake is crucial! Aim for at least 1.6g per kg of body weight.",
        "Consistency beats intensity—stay dedicated to your fitness routine.",
        "Don't skip leg day! Strong legs support overall strength and stability.",
        "Hydration matters—drink plenty of water before, during, and after workouts.",
        "Rest is just as important as training. Get 7-9 hours of sleep daily.",
        "Creatine can help improve strength and muscle recovery—consider supplementing.",
        "Progressive overload is key! Increase weights gradually for muscle growth.",
        "Don't ignore mobility work—stretching and warm-ups prevent injuries.",
        "Fuel your workouts with proper nutrition—carbs and protein go hand in hand.",
        "Lifting heavy? Focus on proper form to avoid injuries and maximize gains.",
        "Muscle grows outside the gym—make sure to prioritize recovery.",
        "Train smart! A well-structured plan beats random workouts any day.",
        "Mix compound and isolation exercises for balanced muscle development.",
        "Don't overtrain—listen to your body and allow muscles to recover.",
        "Strengthen your core—it helps with balance and prevents injuries.",
        "Skipping meals post-workout? Your muscles need fuel to grow!",
        "Track your progress—small improvements lead to big gains over time.",
        "Take rest days seriously—overworking can lead to burnout and injuries.",
        "Protein shakes are great but whole foods should be your primary source of nutrients."
    ];
    
    return tips[Math.floor(Math.random() * tips.length)];
};

/**
 * get isOfferAvailable
 */
export const getIsOfferAvailable = (validity) => {
    let isOfferAvailable = false;
            
    if (validity) {
        if (validity.seconds) {
            // If it's a Firestore Timestamp-like object
            const offerDate = new Date(validity.seconds * 1000);
            isOfferAvailable = offerDate >= new Date();
        } else if (validity instanceof Date) {
            // If it's already a Date object
            isOfferAvailable = validity >= new Date();
        } else {
            // console.warn("Unrecognized offerValidTill format:", validity, gymName);
        }
    }
    return isOfferAvailable;
}