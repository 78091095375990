/**
 * pageViewAnalytics.js
 * 
 * Helpers to tackle the pageView event 
 */

import { doc, increment, setDoc, Timestamp, updateDoc, getDoc } from "@firebase/firestore"
import { cityList, gymList, interestedGyms } from "../../components/Databases"
import { db } from "../../config/firebase";
import { hasUpdatedThisWeek, hasUpdatedToday } from "./checkTimestamp";

/**
 * If gymPage is viewed,
 * add gym to user -> Interested Gym collection
 * (if already present, increment the number of times visitited)
 * 
 * If the doc exists, it increments `timesVisited`.
 * If not, it creates the doc with default structure.
 * 
 * @param {Ref} userRef     -   user's document ref
 * @param {Object} gymData  -   details of the gym
 * @returns {Boolean}       -   true, if successful; false otherwise
 */
export const addGymToUserDoc = async (userRef, gymData) => {
    try {
        const gymRef = doc(userRef, interestedGyms, gymData.gymID);

        const existingDoc = await getDoc(gymRef);

        if (existingDoc.exists()) {
            await setDoc(gymRef, {
                mostRecentVisit : Timestamp.now(),
                timesVisited    : increment(1)
            }, { merge: true });
        } else {

            await setDoc(gymRef, {
                mostRecentVisit     :   Timestamp.now(),
                timesVisited        :   1,
                paymentsPageVisited :   false,
                months              :   {
                    1               :   0,
                    3               :   0,
                    6               :   0,
                    12              :   0,
                },
                gymDisplayName      :   gymData.gymDisplayName,   
            });
        }

        return true;
    } catch (error) {
        console.error("Error in addGymToUserDoc: ", error.code, error.message);
        return false;
    }
}


/**
 * Update Gym Views on the main database.
 * 
 * Increments `views.total`, and conditionally updates `views.thisWeek` and `views.today`
 * based on whether the gym's recent view timestamp was today or this week.
 * 
 * @param {Object} gymData - Details of the gym (must include `city`, `gymName`, and optionally `views.recentUpdate`).
 * @returns {boolean} - True if successful, false otherwise.
 */
export const increaseGymViews = async (gymData) => {
    try {
        if (!gymData?.city || !gymData?.gymName) {
            console.error("Missing city or gymName in gymData");
            return false;
        }

        const gymRef = doc(db, cityList, gymData.city, gymList, gymData.gymName);
        const now = Timestamp.now();

        const existingViews = gymData.views || {};
        const lastUpdate    = existingViews.recentUpdate;

        // Current values
        const total     = typeof existingViews.total     === "number" ? existingViews.total     : 0;
        const thisWeek  = typeof existingViews.thisWeek  === "number" ? existingViews.thisWeek  : 0;
        const today     = typeof existingViews.today     === "number" ? existingViews.today     : 0;

        // Prepare new values
        const newTotal     = total + 1;
        const newThisWeek  = lastUpdate && hasUpdatedThisWeek(lastUpdate) ? thisWeek + 1 : 1;
        const newToday     = lastUpdate && hasUpdatedToday(lastUpdate)    ? today + 1    : 1;

        // Skip update if no value would change
        if (
            newTotal     <= total &&
            newThisWeek  <= thisWeek &&
            newToday     <= today
        ) {
            console.log("No view update needed — all values already up to date.");
            return true;
        }

        const updatePayload = {
            'views.total'        : increment(1),
            'views.recentUpdate' : now,
            'views.thisWeek'     : lastUpdate && hasUpdatedThisWeek(lastUpdate) ? increment(1) : 1,
            'views.today'        : lastUpdate && hasUpdatedToday(lastUpdate)    ? increment(1) : 1
        };

        console.log("Updating gym views with payload:", updatePayload);
        await updateDoc(gymRef, updatePayload);
        return true;

    } catch (error) {
        console.error("Error in increaseGymViews:", error);
        return false;
    }
};